<template>
  <div class="w-full space-y-1">
    <slot name="label" :label="label">
      <p v-if="label">
        {{ label }}
        <span v-if="required && !success" class="text-error"> *</span>
      </p>
    </slot>
    <div
      class="flex flex-wrap leading-tight bg-gray-50 border-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2 gap-1"
      :class="{
        'bg-gray-50': success,
        'border-error bg-red-50 focus:border-rose focus:ring-1 focus:ring-rose focus:outline-none':
          !success && check,
        'rounded-default': !rounded,
        'rounded-none': rounded,
      }"
    >
      <div
        class="border-1 border-gray-200 p-1 px-2 rounded-full"
        v-for="(number, index) in items"
        :key="index"
      >
        <div class="flex gap-1 items-center justify-center">
          {{ number }}
          <div
            class="bg-primary text-white p-1 rounded-full flex items-center justify-center cursor-pointer"
          >
            <icon
              class=""
              :size="10"
              name="times"
              @click="removeItem(number)"
            />
          </div>
        </div>
      </div>
      <input
        type="tel"
        maxlength="10"
        v-model="itemText"
        :placeholder="!success ? placeholder : ''"
        class="bg-gray-50 w-full outline-none p-1"
        :class="{
          'bg-gray-50 border-textLight': success,
          'border-error bg-red-50 placeholder-red-900': !success && check,
        }"
        @keyup.enter="addItem"
        @keyup.delete="popItem"
        @blur="addItem"
        v-bind="$attrs"
      />
      <input type="hidden" v-model="formattedItems" />
    </div>
    <div v-if="!success && check" class="text-xs" style="color: #ec3118">
      <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      itemText: '',
    };
  },
  props: {
    value: {},
    label: { type: String },
    placeholder: { type: String, required: false },
    items: { type: Array, default: () => [] },
    size: { type: Number, default: 16 },
    errors: { type: Array, default: () => [] },
    check: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
  },
  computed: {
    formattedItems() {
      return this.items.join(' | ');
    },
    success() {
      return this.errors.length === 0;
    },
  },
  methods: {
    addItem() {
      if (this.itemText !== '') {
        this.items.push(this.itemText);
        this.itemText = '';
        this.$emit('input', this.items);
      }
    },
    removeItem(number) {
      const index = this.items.indexOf(number);
      if (index !== -1) {
        this.items.splice(index, 1);
        this.$emit('input', this.items);
      }
    },
    popItem() {
      this.items.pop();
      this.$emit('input', this.items);
    },
  },
};
</script>
