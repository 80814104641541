<template>
  <div>
    <div class=" bg-white p-5">
      School Overview
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
