<template>
  <modal-template>
    <template #title>
      <p>Password Resetted successfully</p>
    </template>
    <div class=" bg-white p-5">
      <p class=" text-textLight">New Password</p>
      <p class=" text-2xl text-orange">{{ user.password }}</p>
    </div>
    <template #footer>
      <div class=" mt-5">
        <base-button
          label="cancel"
          :primary="false"
          class="py-1 px-4 text-textMedium bg-gray-100 hover:bg-danger hover:text-error rounded"
          @click="handleClose"
        />
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
export default {
  props: ['user'],
  components: {
    ModalTemplate
  },
  methods: {
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    }
  }
};
</script>

<style></style>
