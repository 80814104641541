// import termService from "../../services/userService";
import { userService } from '../../services/userService';
import { userInfoService } from '../../services/storageService';

const state = {
  user: null,
  error: '',
  authCategories: [],
  authCodes: [],
  authModules: [],
};

const mutations = {
  setUser: (state, user) => {
    state.user = user;
    // userInfoService.saveUser(user);
  },
  setError: (state, { message }) => {
    state.error = message;
  },
  setAuth: (state, user) => {
    // console.log('state user', user);
    state.user.actions = user.authorized_actions;
    state.user.authCategories = user.categories;
    state.user.authModules = user.modules;
    // console.log('set auth 1', state.user, state);

    state.authCodes = user.authorized_actions;
    state.authCategories = user.categories;
    state.authModules = user.modules;
    userInfoService.saveUser(state.user);
  },
};
const actions = {
  setUser({ commit }, user) {
    commit('setUser', user);
  },
  setAuth({ commit }, user) {
    console.log('user', user);
    commit('setAuth', user)
  },
  async signin({ commit }, signin) {
    try {
      const { user } = await userService.signin(signin);
      commit('setUser', user);
      return true;
    } catch (e) {
      
      commit('setError', { code: e.errorCode, message: e.message });
      return false;
    }
  },
  async getMe({ commit }, getMe) {
    try {
      const user = await userService.getMe(getMe);
      commit('setUser', user);
      return user;
    } catch (e) {
      commit('setError', { code: e.errorCode, message: e.message });
      return false;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async logout({ commit }) {
    // commit("setText", "successfully logged out not saved.");
    try {
      commit('setUser', null);
      userService.logout();
      userInfoService.removeUser();
      return true;
    } catch (e) {
      return false;
    }
  },
};

const getters = {
  allTerms: (state) => {
    return state.terms;
  },
  status: (state) => {
    return state.status;
  },
  getText: (state) => {
    return state.text;
  },
  termsError: (state) => {
    return state.termsError;
  },
  user: (state) => {
    if (!state.user) {
      return userService.getMe();
    }
    return state.user;
  },
  error: (state) => state.error,
  authCategories:  (state) => {
    return state.authCategories;
  },
  authModules:  (state) => {
    return state.authModules;
  },
  authCodes: (state) => {
    // if (!state.user) {
    //   // console.log('auth 1', state, state.user, userInfoService.getUser().actions);
    //   return userInfoService.getUser().actions
    // }
    // // return []
    // console.log('auth 1', state, state.user, state.actions);
    // return state.user && state.user.actions ? state.user.actions: []
    return state.authCodes;
  },
  isGranted: state => code => {
    if (!state.user) {
      return false
    }
    if (typeof code == 'string') {
      return state.user.actions.includes(code) || false
    } else if (typeof code == 'object') {
      return state.user.actions.some(action => code.includes(action)) || true
    } else return false
  },
};

const usersStore = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};

export default usersStore;
