<template>
  <div>
    <base-title title="Discounts">
      <template #actions>
        <div class="flex">
          <base-button
            icon="plus"
            label="Add New"
            class="px-3"
            @click="handleCreateNew"
            code="dis-add"
          />
        </div>
      </template>
    </base-title>
    <div class="bg-white p-5 mt-2 rounded-default">
      <d-table
        #default="{ item }"
        :headers="HEADERS"
        :items="items"
        :totalItems="items.length"
        :hasActions="true"
        :handlers="handlers"
        :loading="loading"
        code="dis-view"
        :hideColumnSort="true"
      >
        <td v-for="(header, i) in HEADERS" :key="i">
          <div class="px-2">
            <div v-if="header.key === 'name'">
              <p class="text font-semibold text-textDarkest uppercase">
                {{ item[header.key] }}
              </p>
            </div>
            <!-- <div v-else-if="header.key === 'discount'">
            <p class="text font-semibold uppercase text-textDarkest">
              {{
                item.discount + (item.discount_type == 'rate' ? '%' : ' ETB')
              }}
            </p>
          </div> -->
            <div v-else-if="header.dynamic">
              <p class="text font-semibold uppercase text-textDarkest">
                {{ getPaymentType(header.key, item.discounts) }}
                {{
                  getDiscountType(header.key, item.discounts) == 'rate'
                    ? ' %'
                    : ' ETB'
                }}
              </p>
            </div>
            <div v-else-if="header.key === 'status'">
              <status-chip
                :label="item[header.key]"
                style="max-width: fit-content"
              />
            </div>
            <div v-else>
              <div class="px-4">
                {{ discounts[item._id][header.key] }}
              </div>
            </div>
          </div>
        </td>
      </d-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapState } from 'vuex';
import DTable from '@/components/table/DTable.vue';
import { getPropByString as getProp } from '@/utils';
import eventBus from '@/eventBus.js';
export default {
  components: {
    DTable,
  },
  data() {
    return {
      payment_types: [],
      handlers: [
        // {
        //   label: 'Detail',
        //   icon: 'eye',
        //   handler: this.handleDetail,
        //   idParam: true,
        // },

        {
          label: 'Edit',
          icon: 'pencil-alt',
          handler: this.handleEdit,
          fullParam: true,
          filter: () => this.authCodes.includes('dis-edit'),
        },
        {
          label: 'Delete',
          icon: 'trash-alt',
          handler: this.handleDelete,
          idParam: true,
          filter: () => this.authCodes.includes('dis-rm'),
        },
      ],
      loading: false,
      items: [],
      discounts: {},
      // headers: [
      //   { label: 'Name', key: 'name' },
      //   // { label: 'discount', key: 'discount' },
      //   // { label: 'Status', key: 'status' },
      // ],
    };
  },
  computed: {
    ...mapGetters(['authCodes']),
    ...mapState('main', ['error']),
    ...mapGetters(['authCodes']),
    HEADERS() {
      let payment_types = this.payment_types.map((payment_type) => {
        return {
          label: payment_type.name,
          key: payment_type._id,
          dynamic: true,
        };
      });
      let dynamic_headers = [{ label: 'Name', key: 'name' }, ...payment_types];
      dynamic_headers.push({ label: 'Status', key: 'status' });
      console.log('header', dynamic_headers);
      return dynamic_headers;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleCreateNew() {
      let componentProps = {
        school: this.school_id,
      };
      eventBus.$emit('open-modal', {
        modalId: 32,
        componentProps,
        cb: this.fetchDocumentsList,
      });
    },

    getProp,

    async fetchDocumentsList() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'school/discount',
      });
      if (response && !response.error) {
        this.items = response.record;
        this.populateDiscounts(this.items);
      } else {
        this.items = [];
      }
      this.loading = false;
    },
    getPaymentType(key, PT) {
      return PT.find((v) => {
        return v.payment_type == key;
      })
        ? PT.find((v) => {
            return v.payment_type == key;
          }).discount
        : 0;
    },
    getDiscountType(key, PT) {
      return PT.find((v) => {
        return v.payment_type == key;
      })
        ? PT.find((v) => {
            return v.payment_type == key;
          }).discount_type
        : '';
    },
    populateDiscounts(items) {
      items.forEach((item) => {
        item.discounts.forEach((discount) => {
          if (!this.discounts[item._id]) {
            this.discounts[item._id] = {};
          }
          this.discounts[item._id][discount.payment_type.name] =
            discount.discount +
            ' ' +
            (discount.discount_type == 'rate' ? '%' : ' ETB');
        });
      });
    },

    async handleEdit(item) {
      let componentProps = {
        oldForm: item,
      };
      eventBus.$emit('open-modal', {
        modalId: 32,
        componentProps,
        cb: this.fetchDocumentsList,
      });
    },

    handleDelete(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the discount',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },
    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/discount/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'deleted successfully' });
        this.fetchDocumentsList();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    async fetchPaymentTypesList() {
      let response = await this.request({
        method: 'get',
        url: 'school/paymentType/active',
      });
      if (response && !response.error) {
        this.payment_types = response.record;
      }
    },
  },

  created() {
    this.fetchPaymentTypesList();
    this.fetchDocumentsList();
  },
};
</script>

<style></style>
