<template>
  <div class="flex space-x-5 items-center  hover:bg-gray-100 px-3">
    <div @click="handleClick" class=" w-full">
      <component :is="component" class=" w-full" :content="content" />
    </div>
    <div v-if="actionMode == 'icon'" class="flex space-x-3">
      <div v-for="(action, i) in actions" :key="i">
        <icon
          :name="action.icon"
          class="cursor-pointer text-textDark hover:text-textMedium"
          v-tippy="{ content: action.label, placement: 'top', arrow: true }"
        />
      </div>
    </div>
    <!-- <div v-if="actionMode === 'ellipsis'">
      <actions :handlers="actions" :item="content" btnO9n="horizontal" />
    </div> -->
    <div>
      <icon
        :name="started ? 'clock' : 'check-circle'"
        :size="18"
        class=""
        :class="{
          'text-green-500': completed,
          'text-yellow-500': started,
          'text-textLight': !completed && !started
        }"
      />
    </div>
  </div>
</template>

<script>
import PdfContentEntry from './PdfContentEntry.vue';
import SimulationContentEntry from './SimulationContentEntry.vue';
import VideoContentEntry from './VideoContentEntry.vue';
import CurriculumCategoryEntry from './CurriculumCategoryEntry';
import Actions from '@/components/shared/ActionSelector.vue';
import eventBus from '@/eventBus.js';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';

export default {
  props: {
    index: {
      type: Number
    },
    actionMode: {
      type: String,
      default: 'ellipsis' // icon, ellipsis
    },
    contentType: {
      type: String,
      required: true
    },
    content: {
      required: true
    },
    started: {
      type: Boolean,
      default: false
    },
    completed: {
      type: Boolean,
      default: false
    }
  },

  components: {
    PdfContentEntry,
    SimulationContentEntry,
    VideoContentEntry,
    CurriculumCategoryEntry,
    Actions
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('main', ['error']),
    // contentType() {
    //   if (this.index < 4) return 'curriculum_category';
    //   else return 'video';
    // },
    component() {
      return this.contentType === 'simulation'
        ? SimulationContentEntry
        : this.contentType === 'video'
        ? VideoContentEntry
        : this.contentType === 'document'
        ? PdfContentEntry
        : this.contentType === 'curriculum_category'
        ? CurriculumCategoryEntry
        : '';
    },
    actions() {
      return [
        {
          label: 'Edit',
          icon: 'pencil-alt',
          handler: this.handleEdit,
          fullParam: true
        },
        {
          label: 'Remove',
          icon: 'trash-alt',
          handler: this.handleRemove,
          idParam: true
        },
        {
          label: 'Move Up',
          icon: 'arrow-up',
          handler: this.handleMoveUp
        },
        {
          label: 'Move Down',
          icon: 'arrow-down',
          handler: this.handleMoveDown
        }
        // {
        //   label: 'Add Content After',
        //   icon: 'plus',
        //   handler: () => {}
        // },
        // {
        //   label: 'Add Content Before',
        //   icon: 'plus',
        //   handler: () => {}
        // },
        // {
        //   label: 'Add Category Before',
        //   icon: 'plus',
        //   handler: () => {}
        // },
        // {
        //   label: 'Add Category After',
        //   icon: 'plus',
        //   handler: () => {}
        // }
      ];
    }
  },

  methods: {
    ...mapActions('main', ['request']),
    handleClick() {
      this.$emit('click', this.content);
    },

    onDeleted() {
      this.$emit('deleted');
    },

    onUpdated() {
      this.$emit('updated');
    },

    handleMoveDown() {
      this.$emit('movedown');
    },

    handleMoveUp() {
      this.$emit('moveup');
    },

    async handleRemove(id) {
      let url = 'reb/courseContent/' + id;
      if (this.contentType === 'curriculum_category')
        url = 'reb/curriculumCategory/' + id;

      let response = await this.request({ method: 'delete', url });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'Item Deleted successfully' });
        this.onDeleted();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true
        });
      }
    },

    handleEdit(oldContent) {
      let componentProps = {
        [this.contentType == 'curriculum_category'
          ? 'category'
          : 'content']: oldContent
      };
      eventBus.$emit('open-modal', {
        modalId: this.contentType == 'curriculum_category' ? 6 : 5,
        cb: this.onUpdated,
        componentProps
      });
    }
  }
};
</script>

<style></style>
