import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { LanguageService } from '@/services/storageService';
import en from './t/en.js';
import tig from './t/tig.js';

Vue.use(VueI18n);
let currentLanguage = LanguageService.getCurrentLanguage();
if (!currentLanguage) {
  currentLanguage = 'en';
  LanguageService.setCurrentLanguage(currentLanguage);
}
export const i18n = new VueI18n({
  locale: currentLanguage,
  fallbackLocale: 'en',
  messages: {
    en,
    tig
  }
});

/**
 * FYI: if you want to change a language use the ff code in a view or component method
 *
 * LanguageService.setCurrentLanguage('tig');
 * this.$i18n.locale = 'tig';
 */
