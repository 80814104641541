var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2 px-4 text-textMedium space-y-2"},[_c('div',{staticClass:"p-2 text-2xl text-center border-b border-brightGray"},[_vm._v(" Select Course ")]),_c('div',{staticClass:"space-y-1"},[_c('div',[_c('div',{staticClass:"py-2"},[_c('base-input',{attrs:{"placeholder":"Search Course..."},on:{"enter":function($event){return _vm.fetchUser(_vm.search)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div')]),_c('div',{staticClass:"space-y-1 overflow-y-auto p-2",staticStyle:{"height":"50vh"}},[(_vm.search && _vm.filteredCustomers.length === 0 && !_vm.working)?_c('div',{staticClass:"text-lg"},[_vm._v(" No record matching to your search ")]):_vm._e(),(!_vm.search && !_vm.working)?_c('div',[_vm._v(" type in course name or code in the box above. ")]):_vm._e(),(_vm.working)?_c('div',{staticClass:"w-full mt-10 flex justify-center"},[_c('bar-loader',{staticClass:"mx-auto w-1/3 block"})],1):_vm._e(),(!_vm.working)?_c('div',_vm._l((_vm.filteredCustomers),function(course){return _c('div',{key:course._id,staticClass:"flex items-start space-x-2 cursor-pointer p-2 rounded",class:_vm.selectedId === course._id || _vm.selectedIds.includes(course._id)
              ? 'bg-primary'
              : 'hover:bg-lightGreen',on:{"click":function($event){return _vm.selectCourse(course)}}},[_c('div',[_c('div',{staticClass:"w-16 h-16 rounded bg-brightGray",class:_vm.selectedId === course._id || _vm.selectedIds.includes(course._id)
                  ? 'bg-white'
                  : 'bg-primary'},[(course.image)?_c('img',{directives:[{name:"viewer",rawName:"v-viewer",value:({ inline: false, movable: true }),expression:"{ inline: false, movable: true }"}],staticClass:"w-full h-full rounded hover:opacity-75",attrs:{"src":_vm.IMAGES_PATH + course.image,"alt":""}}):_vm._e()])]),_c('div',{staticClass:"w-full",class:_vm.selectedId === course._id || _vm.selectedIds.includes(course._id)
                ? 'text-white'
                : 'text-textDark'},[_c('div',{staticClass:"flex justify-space w-full"},[_c('div',{staticClass:"text-lg uppercase space-x-2 flex items-center",class:_vm.selectedId === course._id ||
                  _vm.selectedIds.includes(course._id)
                    ? 'text-white'
                    : 'text-textDark'},[_c('p',[_vm._v(_vm._s(course.name))])]),_c('div',{staticClass:"ml-auto"},[(_vm.selectedId === course._id)?_c('icon',{staticClass:"text-white",attrs:{"name":"check-circle","size":21}}):_vm._e()],1)]),_c('div',{staticClass:"flex space-x-2 items-center",class:_vm.selectedId === course._id || _vm.selectedIds.includes(course._id)
                  ? 'text-white'
                  : 'text-textDark'},[_vm._m(0,true),_c('div',{staticClass:"w-3 h-1",class:_vm.selectedId === course._id ||
                  _vm.selectedIds.includes(course._id)
                    ? 'bg-white'
                    : 'bg-primary'}),_c('div',[_vm._v(_vm._s(course.grade))])])])])}),0):_vm._e()]),_c('div',[_c('pagination',{attrs:{"currentPage":_vm.currentPage,"pageSize":_vm.pageSize,"totalPages":_vm.totalPages},on:{"changepage":_vm.handlePageChange,"changepagesize":_vm.handlePageSizeChange}})],1),_c('div',{staticClass:"flex justify-between pt-4 pb-2"},[_c('base-button',{staticClass:"py-1 px-4 text-textMedium bg-gray-100 hover:bg-danger hover:text-error rounded",attrs:{"label":"cancel","primary":false},on:{"click":_vm.cancel}}),(_vm.selectedCustomer || _vm.selectedCustomers.length)?_c('base-button',{staticClass:"py-1 px-4 rounded",attrs:{"label":"continue"},on:{"click":_vm.handleContinue}}):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_vm._v("GRADE")])])
}]

export { render, staticRenderFns }