<template>
  <div class="text-textMedium space-y-4">
    <!-- <div class="flex space-x-2 justify-between border-b border-dgray pb-2">
      <div class="font-semibold">Students</div>
      <div class="text-15">Found {{ students.length }} search result{{ students.length > 1 ? 's' : '' }}</div>
    </div> -->
    <!-- TODO circule progress -->
    <loader v-if="loading"></loader>
    
    <div v-else-if="students.length > 0" class="space-y-1">
      <student v-for="student in students" :key="student._id" :student="student" />
    </div>
    <div v-else>
      <div v-if="initial" class="space-y-1">
        <file-search class="h-48" />
        <div class="text-center">Search for students</div>
      </div>
      <div v-else>
        <div class="font-medium">Oops! No student</div>
        <div class="text-sm">Oops no student can be found with the given search keyword and the filter criteria.</div>
      </div>
    </div>
  </div>
</template>

<script>
import Student from './Student.vue'
import FileSearch from '../../shared/svgs/FileSearch.vue'
export default {
  components: { Student, FileSearch },
  props: ['students', 'loading', 'initial'],
}
</script>

<style></style>
