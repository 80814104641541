<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventBus from '../../eventBus';
export default {
  computed: {
    ...mapGetters('socket', ['sioClient', 'clientConnected'])
  },
  watch: {
    clientConnected: {
      immediate: true,
      handler() {
        if (this.clientConnected) this.init();
      }
    }
  },
  methods: {
    signMeOut() {
      eventBus.$emit('sign-out');
    },
    init() {
      this.sioClient.on('Forced_Sign_Out', this.signMeOut);
      this.sioClient.on('Suspended', this.signMeOut);
      this.sioClient.on('Office_Suspended', this.signMeOut);
      //some more events
    }
  }
};
</script>

<style></style>
