<template>
  <div class="flex">
    <!-- <full-calendar
      class=" w-screen"
      :options="calendarOptions"
      :events="[{ title: 'eventOne', date: '2023-01-19' }]"
      :event-sources="eventSources"
    >
      <template v-slot:eventContent="arg">
        <div class=" bg-orange">
          {{ arg }}
        </div>
      </template>
    </full-calendar> -->
    <!-- <calendar /> -->
    <!-- <div id="calendar" style="height: 800px"></div> -->
    <div class="bg-white w-full">
      <!-- header -->
      <div>
        <div class="flex justify-center my-4 px-4">
          <!-- view selector -->
          <div
            class="mr-20 flex bg-gray-100 py-1 px-1 rounded-default"
            style="max-width: fit-content"
          >
            <div
              v-for="(view, i) in VIEW_OPTIONS"
              :key="i"
              class="py-1 px-3 cursor-pointer"
              :class="{ ' bg-white': calendarView === view.value }"
              @click="changeCalendarView(view.value)"
            >
              {{ view.title }}
            </div>
          </div>
          <div
            v-if="authCodes.includes('cal-add')"
            class="w-10 h-10 flex items-center justify-center rounded-full bg-gray-100 cursor-pointer"
            @click="handleCreateEvent"
          >
            <icon name="plus" class="text-secondary" />
          </div>
        </div>

        <div v-if="authCodes.includes('cal-view')" class="w-full">
          <TostUiCalendar
            style="height: 800px"
            class="w-full"
            :events="events"
            :view="calendarView"
            @clickEvent="handleClickEvent"
            :options="{
              template: theme,
              taskView: false,
              allDayView: false,
              weekOptions: { taskView: false },
            }"
            :weekOptions="{ taskView: false }"
            :taskView="false"
            :allDayView="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import FullCalendar from 'vue-fullcalendar';
import TostUiCalendar from '@toast-ui/vue-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import { static_roles } from '@/config/constants';
import moment from 'moment';
import eventBus from '../../../eventBus';
import { mapActions, mapGetters } from 'vuex';
// import 'fullcalendar/dist/fullcalendar.css';

// const calendar = new Calendar('#calendar', {

// });

export default {
  components: {
    TostUiCalendar,
  },

  data() {
    return {
      module: 'calendar',
      permission: {},
      VIEW_OPTIONS: [
        { title: 'Day', value: 'day' },
        { title: 'Week', value: 'week' },
        { title: 'Month', value: 'month' },
      ],
      calendarView: 'month',

      theme: {
        common: {
          gridSelection: {
            backgroundColor: '#02457a',
            border: '1px dotted #515ce6',
          },
        },
      },
      events: [
        {
          id: 0,
          title: 'title one',
          start: moment().toISOString(),
          end: moment().add(moment.duration(2, 'hours')).toISOString(),
          backgroundColor: '#02457a',
          color: '#ffffff',
          isAllday: false,
          // category: ''
        },
      ],
      calendarOptions: {
        initialView: 'dayGridYear',
        events: [
          {
            id: '1',
            title: 'event-1',
            start: '2023-01-19T01:00:00',
            end: '2023-01-19T03:00:00',
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters(['user', 'authCodes']),
    eventSources() {
      // const self = this;
      return [
        // {
        //   events(start, end, timezone, callback) {
        //     setTimeout(() => {
        //       callback(self.events.filter(() => Math.random() > 0.5));
        //     }, 1000);
        //   }
        // }
        ...this.events,
      ];
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    moment,
    handleClickEvent(event) {
      
      let componentProps = {
        event,
      };
      eventBus.$emit('open-modal', {
        modalId: 21,
        componentProps,
        cb: this.fetchEvents,
      });
    },
    changeCalendarView(view) {
      this.calendarView = view;
    },
    handleCreateEvent() {
      let componentProps = {
        school: this.selectedSchoolId,
        isSchoolOfficial: this.isSchoolOfficial,
      };
      eventBus.$emit('open-modal', {
        modalId: 20,
        componentProps,
        cb: this.fetchEvents,
      });
    },
    async fetchEvents() {
      const response = await this.request({
        method: 'get',
        url: 'school/event/me',
      });

      if (response && !response.error) {
        this.events = response.record.map((event) => ({
          ...event,
          title: event.name,
          backgroundColor: event.primaryColor,
          color: event.secondaryColor,
          id: event._id,
        }));
      } else {
        this.events = [];
      }
    },

    checkStatic() {
      const role = this.user.role;
      if (!static_roles.includes(role)) {
        this.isSchoolOfficial = true;
        const permissions = this.user.permissions;
        let permission = permissions.find((p) => p.module.name === this.module);
        this.permission = permission;
      }
    },
  },

  created() {
    this.fetchEvents();
  },
};
</script>

<style>
/* @import '~fullcalendar/dist/fullcalendar.css'; */
</style>
