<template>
  <div>
    <div>
      <base-title title="Manage Academic Year" class="mb-2">
        <template #actions>
          <div class="flex justify-center items-center space-x-2">
            <!-- <base-button
              label="Add Multiple"
              icon="plus"
              class="bg-gray-100 flex-shrink-0"
              :primary="false"
              code="aca-add"
              @click="handleMultipleAcademicYear"
            /> -->
            <base-button
              label="Add New"
              icon="plus"
              @click="handleMultipleAcademicYear"
              code="aca-add"
              class="bg-gray-100 flex-shrink-0"
            />
            <base-select
              placeholder=""
              v-model="activeGrade"
              :items="GRADE_OPTIONS"
              searchable
            />
            <base-select
              placeholder=""
              v-model="activeSection"
              :items="SECTION_OPTIONS"
              searchable
            />
          </div>
        </template>
      </base-title>

      <loader type="list" :amount="6" v-if="loading"></loader>

      <div v-else-if="!loading && items.length">
        <div
          v-if="authCodes.includes('aca-view')"
          class="grid grid-cols-3 gap-2"
        >
          <div
            v-for="(item, i) in items"
            :key="i"
            class="bg-white p-2 item-grid rounded-default"
          >
            <div class="flex justify-between w-full">
              <div class="flex space-x-2 items-center">
                <p class="text-textDarkest uppercase">
                  {{ item.name }}
                </p>
                <!-- <base-check-box
                    :selected="item.isRegular"
                    label="regular payment"
                  /> -->
              </div>
              <div>
                <status-chip rounded :label="item.status" />
              </div>
            </div>
            <div>
              <div class="flex space-x-5 mt-3">
                <icon
                  v-if="authCodes.includes('aca-rm')"
                  name="trash-alt"
                  class="cursor-pointer text-textMedium"
                  @click="handleDeleteItem(item._id)"
                />
                <icon
                  v-if="authCodes.includes('aca-edit')"
                  name="pencil-alt"
                  class="cursor-pointer text-textMedium"
                  @click="handleEditItem(i)"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-else class="text-error flex h-88 items-center justify-center">
          You don't have a privilege to see this!
        </div> -->
      </div>
      <base-no-data v-else message="No academic years added" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus.js';

export default {
  data() {
    return {
      activeGrade: '',
      activeSection: '',
      gradeItems: [],
      sectionItems: [],
      items: [],
      working: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['authCodes']),
    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name + ' - ' + grade.enrollment.code,
        value: grade._id,
      }));
    },

    SECTION_OPTIONS() {
      return this.sectionItems.map((section) => ({
        label: section.name,
        value: section._id,
      }));
    },
  },
  watch: {
    activeGrade() {
      this.fetchSections();
    },
    activeSection() {
      this.handleFetchItems();
    },
  },
  methods: {
    ...mapActions('main', ['request']),

    handleMultipleAcademicYear() {
      eventBus.$emit('open-modal', {
        modalId: 40,
        cb: this.handleFetchItems,
      });
    },

    handleCreateAcademicYear() {
      let componentProps = {
        grade: this.activeGrade,
        section: this.activeSection,
      };
      eventBus.$emit('open-modal', {
        modalId: 8,
        cb: this.handleFetchItems,
        componentProps,
      });
    },

    handleItemDetail() {
      this.$router.push({
        name: 'grade-list',
      });
    },

    async handleFetchItems() {
      this.loading = true;
      this.items = [];
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/section/' + this.activeSection,
      });
      if (response && !response.error) this.items = response.record;
      else this.items = [];
      this.loading = false;
    },

    handleEditItem(index) {
      let componentProps = {
        oldForm: this.items[index],
        section: this.activeSection,
      };
      eventBus.$emit('open-modal', {
        modalId: 8,
        componentProps,
        cb: this.handleFetchItems,
      });
    },

    handleDeleteItem(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the item',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },

    /// NOTE: do not actully delete the academic year just disable it.
    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/academicYear/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'academic year deleted successfully',
        });
        this.handleFetchItems();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
        this.activeGrade = response.record[0]._id;
      } else {
        this.gradeItems = [];
        this.activeGrade = '';
      }
    },

    async fetchSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/class/' + this.activeGrade,
      });
      if (response && !response.error) {
        this.sectionItems = response.record;
        this.activeSection = response.record[0]._id;
      } else {
        this.sectionItems = [];
        this.activeSection = '';
      }
    },
  },
  async created() {
    this.working = true;
    await this.fetchGrades();
    this.working = false;
  },
};
</script>

<style></style>
