<template>
  <div>
    <base-title
      :title="
        academic_year.name
          ? `Teacher Course Assignment for ${academic_year.name}`
          : 'No Active Academic Year On this Section'
      "
    >
      <template #infos>
        <div class="flex space-x-3">
          <p class="text-textDarkest font-semibold">HOMEROOM TEACHER -</p>
          <p
            v-if="academic_year && academic_year.homeroom"
            class="font-semibold uppercase text-textDarkest hover:underline cursor-pointer"
          >
            {{ academic_year.homeroom.firstName }}
            {{ academic_year.homeroom.middleName }}
          </p>
          <p v-else class="text-orange font-semibold">NOT ASSIGNED</p>
        </div>
      </template>
      <template #actions>
        <div class="flex space-x-3">
          <base-select :items="ACADEMIC_YEARS" v-model="activeAy" searchable />
        </div>
      </template>
    </base-title>
    <div class="bg-white w-full p-1 px-4 mt-2 rounded-default">
      <div class="py-2">
        <div v-if="activeAy">
          <courses-list :ay_id="activeAy" />
        </div>
        <base-no-data v-else message="No Active Course List" />
      </div>
    </div>
  </div>
</template>

<script>
import CoursesList from './components/CoursesList.vue';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
// import BaseSelectPlus from '../../../../components/base/BaseSelectPlus.vue';
export default {
  components: {
    CoursesList,
  },
  data() {
    return {
      academic_years: [],
      academic_year: {},
      section: '',
      activeAy: '',
      noData: false,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    ACADEMIC_YEARS() {
      return this.academic_years.map((academic_year) => ({
        label: academic_year.class.name + ' - ' + academic_year.section.name,
        value: academic_year._id,
      }));
    },
  },
  watch: {
    activeAy() {
      this.fetchAcademicYear();
    },
  },

  methods: {
    ...mapActions('main', ['request']),

    async fetchActiveAcademicYears() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/active',
      });
      if (response && !response.error) {
        this.academic_years = response.record;
        this.academic_year = response.record[0];
        this.activeAy = this.academic_year._id;
      } else {
        this.academic_years = [];
        this.noData = true;
      }
    },

    async fetchAcademicYear() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/' + this.activeAy,
      });
      if (response && !response.error) {
        this.academic_year = response.record;
      } else {
        this.academic_year = {};
        this.noData = true;
      }
    },
  },

  async created() {
    await this.fetchActiveAcademicYears();
  },
};
</script>

<style></style>
