var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:""},[_c('base-title',{attrs:{"title":"Manage Channels"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"flex"},[_c('base-button',{staticClass:"px-3",attrs:{"icon":"exchange-alt","label":"Sync"},on:{"click":_vm.syncContents}})],1)]},proxy:true}])}),_c('div',{staticClass:"flex space-x-5 mb-0 bg-white py-5 px-5"},[_c('div',{staticClass:"cursor-pointer",class:{
        'border-b-2 border-primary': _vm.activeTab == 'all_channels',
        'text-textLight': _vm.activeTab != 'all_channels',
      },on:{"click":function($event){return _vm.selectTab('all_channels')}}},[_c('p',{staticClass:"text"},[_vm._v("All Channels")])]),_c('div',{staticClass:"cursor-pointer",class:{
        'border-b-2 border-primary': _vm.activeTab == 'subscribed_courses',
        'text-textLight': _vm.activeTab != 'subscribed_courses',
      },on:{"click":function($event){return _vm.selectTab('subscribed_courses')}}},[_vm._v(" My Subscriptions ")])]),_c('div',{staticClass:"bg-white"},[(_vm.activeTab === 'all_channels')?_c('div',{staticClass:"px-8 py-10"},[_c('all-channels')],1):_vm._e(),(_vm.activeTab === 'subscribed_courses')?_c('div',{staticClass:"px-8"},[_c('school-subscriptions')],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }