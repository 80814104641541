<template>
  <tippy
    placement="bottom"
    trigger="click"
    theme="light"
    arrow
    size="large"
    interactive
    ref="setting-menu-tt"
  >
    <template v-slot:trigger>
      <div
        class="w-8 h-8 bg-brightGray rounded-full flex justify-center items-center hover:opacity-75 cursor-pointer"
      >
        <avatar v-if="shortName" :name="shortName" />
      </div>
    </template>
    <div class="p-6 flex flex-col items-center justify-center">
      <div
        class="w-20 h-20 rounded-full bg-gray-100 flex items-center justify-center"
      >
        <img
          v-if="photo"
          v-viewer="{ inline: false, movable: true }"
          class="w-full h-full rounded-full hover:opacity-75"
          :src="PROFILE_PATH + photo"
          alt=""
        />
        <avatar v-else-if="shortName" :name="shortName" :size="80" />
      </div>
      <div class="p-4">
        <div class="text-xl capitalize font-semibold">{{ name }}</div>
        <div class="font-sans">@{{ username }}</div>
      </div>
      <div class="p-2">
        <div
          class="p-2 px-4 flex items-center justify-center rounded-default bg-primary cursor-pointer hover:opacity-75"
          @click="handleSettings"
        >
          <span class="text-white">Account Settings</span>
        </div>
        <div class="cursor-pointer mt-6 hover:opacity-75" @click="signout">
          Sign out
        </div>
      </div>
    </div>
  </tippy>
</template>

<script>
import Avatar from '@/components/shared/Avatar/Avatar.vue';
import { PROFILE_PATH } from '@/config/config';
import { _2Letters } from '@/mixins/_2Letters';
import { mapGetters, mapActions } from 'vuex';
export default {
  components: { Avatar },
  mixins: [_2Letters],
  data() {
    return {
      PROFILE_PATH,
    };
  },
  computed: {
    ...mapGetters(['user']),
    role() {
      return this.user.role?.role_name;
    },
    username() {
      return this.user.username;
    },
    name() {
      return this.user.name;
    },
    shortName() {
      return this.user ? this.get_2Letters(this.user.name) : '';
    },
    photo() {
      return this.user.photo;
    },
    my_office() {
      return this.user.office;
    },
  },
  methods: {
    ...mapActions(['logout']),
    hideDialog() {
      this.$refs['setting-menu-tt'].tip.hide();
    },
    async signout() {
      let signedOut = await this.logout();
      if (signedOut) window.location.reload();
      this.hideDialog();
    },
    handleSettings() {
      this.$router.push('/setting');
      this.hideDialog();
    },
  },
};
</script>

<style></style>
