<template>
  <div
    class="bg-white flex flex-col"
  >
    <div
      class=" flex-grow overflow-auto"
      v-if="items.length && !loading"
    >
      <div
        v-for="(item, i) in items"
        :key="i"
        @click="handleContactSelected(item)"
      >
        <!-- <student-entry
          v-if="activeTab === 'students'"
          :contact="item"
          :selected="selectedContactId === item._id"
        />
        <parent-entry
          v-else-if="activeTab === 'parents'"
          :contact="item"
          :selected="selectedContactId === item._id"
        /> -->
        <contact-entry
          :contact="item"
          :selected="selectedContactId === item._id"
        />
      </div>
    </div>
    <loader v-else-if="loading"></loader>
    <div v-else class=" flex-grow flex my-auto items-center justify-center">
      <div class=" bg-grayBg rounded-full shadow-lg py-2 px-5">
        <p class=" text-sm text-textDark select-none">
          no contacts available
        </p>
      </div>
    </div>
    <!-- <div
      class=" flex bg-white shadow z-50"
      style="box-shadow: 0 0 #fff, 0 0 #fff, 0  0 #fff"
    >
      <div
        class=" flex-grow flex py-2 pb-0   flex-col items-center cursor-pointer hover:border-secondary "
        v-for="(tab, i) in tabs"
        :class="{ 'border-b border-secondary': activeTab === tab.title }"
        style="border-bottom-width: 6px"
        :key="i"
        @click="setActiveTab(tab.title)"
      >
        <div class=" flex flex-col items-center space-y-1">
          <icon :name="tab.icon" />
          <p class="ma-0 pa-0 text-sm">{{ tab.title }}</p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
// import StudentEntry from './StudentEntry.vue';
import ContactEntry from './ContactEntry.vue';

export default {
  props: ['activeSection', 'selectedContactId'],
  components: {
    ContactEntry
  },
  data() {
    return {
      loading: false,
      tabs: [
        {
          icon: 'user',
          title: 'parents'
        },
        {
          icon: 'user',
          title: 'students'
        },
        {
          icon: 'bell',
          title: 'others'
        }
      ],
      activeTab: 'parents', // parents, students, others
      items: []
    };
  },

  watch: {
    activeSection() {
      this.fetchSectionContacts();
    },
    activeTab() {
      this.items = [];
      this.fetchSectionContacts();
    }
  },

  methods: {
    ...mapActions('main', ['request']),
    setActiveTab(tab) {
      this.activeTab = tab;
    },

    handleStudentSelected() {
      
      this.$emit('studentSelected', this.contact._id);
    },

    handleParentSelected() {
      
      this.$emit('parentSelected', this.contact._id);
    },

    handleContactSelected(contact) {
      this.$emit('contactSelected', contact._id);
    },

    async fetchContacts() {
      this.loading = true;

      let response = await this.request({
        method: 'get',
        url: 'message/top_contacts'
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
      this.loading = false;
    }
  },

  created() {
    this.fetchContacts();
  }
};
</script>

<style></style>
