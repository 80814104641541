<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title> Remark </template>
    <template>
      <div class="space-y-5">
        <base-input
          label="Remark"
          placeholder="remark"
          v-model="Form.remark"
          :errors="errors.remark"
        />

        <base-check-box
          label="Send Notification to Parent"
          :selected="Form.sendNotification"
          @toggle="changeSendNotification"
        />

        <base-check-box
          label="Has Permission"
          :selected="Form.havePermission"
          @toggle="changeHavepermission"
        />
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import BaseCheckBox from '@/components/base/BaseCheckBox.vue';
//import eventBus from '@/eventBus';
import { mapActions } from 'vuex';

export default {
  components: { ModalTemplate, BaseCheckBox },
  props: [],
  data() {
    return {
      Form: {
        remark: '',
        sendNotification: true,
        havePermission: false,
      },
      errors: {},
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb, response) {
      this.$emit('close', { invokeCb, response });
    },
    async handleSave() {
      this.handleClose(true, {
        remarkResponse: this.Form.remark,
        sendNotification: this.Form.sendNotification,
        havePermission: this.Form.havePermission,
      });
    },
    changeSendNotification() {
      
      this.Form.sendNotification = !this.Form.sendNotification;
      this.Form = { ...this.Form };
    },
    changeHavepermission() {
      this.Form.havePermission = !this.Form.havePermission;
      this.Form = { ...this.Form };
    },
  },
  created() {},
};
</script>

<style></style>
