import PaymentTypesList from '@/views/protected/school_structure_management/fee/PaymentTypesList.vue';
import PaymentAmountsList from '@/views/protected/school_structure_management/fee/PaymentAmountsList.vue';
import GroupsList from '@/views/protected/school_structure_management/fee/GroupsList.vue';
import PayersList from '@/views/protected/school_structure_management/fee/PayersList.vue';
import DiscountList from '@/views/protected/school_structure_management/fee/DiscountList.vue';
import GenerateBill from '@/views/protected/school_structure_management/fee/GenerateBill.vue';
import MessageView from '@/views/protected/sms/views/SendMessageView.vue';

export default [
  {
    path: '/sms',
    name: 'send-sms',
    component: MessageView,
    props: true,
  },
  {
    path: ':school_id?/payment-types',
    name: 'payment-types',
    component: PaymentTypesList,
    props: true,
  },
  {
    path: ':school_id?/group',
    name: 'group',
    component: GroupsList,
    props: true,
  },
  {
    path: ':school_id?/payment-group',
    name: 'payment-group',
    component: PaymentAmountsList,
    props: true,
  },
  {
    path: ':school_id?/payers',
    name: 'payers',
    component: PayersList,
    props: true,
  },
  {
    path: ':school_id?/discount',
    name: 'discounts',
    component: DiscountList,
    props: true,
  },
  {
    path: ':school_id?/generate-bill',
    name: 'generate-bill',
    component: GenerateBill,
    props: true,
  },
];
