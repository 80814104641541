import { validate } from '@/utils.js';
export const validation = {
  methods: {
    validate,
    isValid() {
      let errors = this.validate(this.meta, this.$v);
      if (errors) {
        this.errors = errors;
        return false;
      } else {
        this.errors = {};
        return true;
      }
    }
  }
};
