<template>
  <div>
    <base-title class="capitalize" :title="`${status}`">
      <template #actions>
        <div class="flex justify-center items-center space-x-2">
          <base-select
            placeholder=""
            v-model="activeGrade"
            :items="GRADE_OPTIONS"
            searchable
          />
          <base-select
            placeholder=""
            v-model="activeSection"
            :items="SECTION_OPTIONS"
            searchable
          />
        </div>
      </template>
    </base-title>

    <BaseSwitch :items="statusOptions" v-model="status" padding="small" />
    <div class="bg-white p-1 px-4 mt-2 rounded-default overflow-x-auto">
      <div>
        <d-table
          #default="{ item }"
          :headers="headers"
          :items="items"
          :hasActions="true"
          :handlers="handlers"
          :loading="loading"
          code="stu-view"
          :buttons="TBUTTONS"
          @withdraw="handleWithdraw"
          @readmission="handleReadmission"
        >
          <td v-for="(header, i) in headers" :key="i">
            <div class="px-2">
              <div v-if="header.key === 'student' && item.student">
                {{ item.student.firstName }} {{ item.student.middleName }}
                {{ item.student.lastName }}
              </div>
              <div v-else-if="header.key === 'userId'">
                {{ item.student.userId }}
              </div>
              <div v-else-if="header.key === 'class' && item.requestedBy">
                {{ item['class'].name }}
              </div>
              <div v-else-if="header.key === 'section' && item.requestedBy">
                {{ item['section'] ? item['section'].name : '-' }}
              </div>
              <div v-else-if="header.key === 'approvedBy' && item.approvedBy">
                {{ item.approvedBy.firstName }} {{ item.approvedBy.middleName }}
              </div>
              <div v-else-if="header.key === 'requestedBy' && item.requestedBy">
                {{ item.requestedBy.firstName }}
                {{ item.requestedBy.middleName }}
              </div>
              <div v-else-if="header.key === 'approvedOn'">
                {{ item.approvedOn | formatDate }}
              </div>
              <div v-else-if="header.key === 'status'">
                <status-chip
                  :label="item['status']"
                  style="max-width: fit-content"
                />
              </div>
              <!-- <div v-else-if="header.key === 'section'">
                {{ item.section ? item.section.name : '--' }}
              </div> -->

              <div v-else class="capitalize">
                {{ getProp(item, header.key) }}
              </div>
            </div>
          </td>
        </d-table>
      </div>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import eventBus from '../../../../eventBus';
import { mapActions, mapGetters } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';
import { formatDate } from '../../../../utils';
import BaseSwitch from '../../../../components/base/BaseSwitch.vue';

export default {
  components: { DTable, BaseSwitch },
  filters: { formatDate },
  data() {
    return {
      TBUTTONS: [
        {
          label: 'Readmission',
          value: 'readmission',
          icon: 'sign-in-alt',
          // code: 'enrst-add',
        },
      ],
      TBUTTONSWITHDRAWN: [
        {
          label: 'Withdraw',
          value: 'withdraw',
          icon: 'sign-out-alt',
          // code: 'enrst-add',
        },
      ],
      TBUTTONSREADMISSION: [
        {
          label: 'Readmission',
          value: 'readmission',
          icon: 'sign-in-alt',
          // code: 'enrst-add',
        },
      ],
      statusOptions: [
        { label: 'Withdrawn', value: 'withdrawn' },
        { label: 'Readmission', value: 'readmission' },
      ],
      status: 'withdrawn',
      PROFILE_PATH,
      items: [],
      module: 'student',
      permission: {},
      activeGrade: '',
      activeSection: '',
      gradeItems: [],
      sectionItems: [],
      loading: false,
      headers: [
        { label: 'App On', key: 'approvedOn' },
        { label: 'Student', key: 'student' },
        { label: 'Sch ID', key: 'schoolId' },
        { label: 'Grade', key: 'class' },
        { label: 'Section', key: 'section' },

        { label: 'Reason', key: 'reason' },
        { label: 'Req By', key: 'requestedBy' },
        { label: 'App By', key: 'approvedBy' },
        { label: 'Status', key: 'status' },
      ],
      handlers: [
        {
          label: 'Approve',
          icon: 'check-circle',
          name: 'approve',
          handler: this.handleApprove,
          idParam: true,
          // filter: () => this.authCodes.includes('stu-rm'),
        },
        {
          label: 'Reject',
          icon: 'times',
          name: 'reject',
          handler: this.handleReject,
          idParam: true,
          // filter: () => this.authCodes.includes('stu-rm'),
        },
      ],
      url: 'school/readmission/',
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
    ...mapGetters('main', ['error']),
    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },

    SECTION_OPTIONS() {
      return this.sectionItems.map((section) => ({
        label: section.name,
        value: section._id,
      }));
    },
  },
  watch: {
    status: {
      immediate: true,
      deep: true,
      handler: async function (val) {
        if (val == 'readmission') {
          this.TBUTTONS = this.TBUTTONSREADMISSION;
          this.url = 'school/readmission/';
          this.other_status = 'Readmission';
          await this.fetchGrades();
        } else {
          this.TBUTTONS = this.TBUTTONSWITHDRAWN;
          this.url = 'school/withdrawal/';
          this.other_status = 'Withdrawal';
          await this.fetchGrades();
        }
      },
    },
    activeGrade() {
      this.fetchSections();
    },
    activeSection() {
      this.fetchVerification();
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,
    handleWithdraw() {
      let componentProps = {
        usertype: 'Student',
        // aySection_id: this.aySection_id,
        multiple: false,
      };
      eventBus.$emit('open-modal', {
        modalId: 37,
        componentProps,
        cb: this.withdraw,
      });
    },
    handleReadmission() {
      let componentProps = {
        usertype: 'Student',
        // aySection_id: this.aySection_id,
        multiple: false,
      };
      eventBus.$emit('open-modal', {
        modalId: 37,
        componentProps,
        cb: this.readmission,
      });
    },
    handleSelectStudent() {
      let componentProps = {
        usertype: 'Student',
        aySection_id: this.aySection_id,
        multiple: true,
      };
      eventBus.$emit('open-modal', {
        modalId: 37,
        componentProps,
        cb: this.handleEnrollStudent,
      });
    },
    async readmission({ response: { customer: student } }) {
      console.log('selected stu', student);
      eventBus.$emit('open-modal', {
        modalId: 50,
        componentProps: { student: student },
        cb: this.fetchVerification,
      });
    },
    async withdraw({ response: { customer: student } }) {
      console.log('selected stu', student);
      eventBus.$emit('open-modal', {
        modalId: 51,
        componentProps: { student: student },
        cb: this.fetchVerification,
      });
    },
    handleDetail(req) {
      eventBus.$emit('open-modal', {
        modalId: 48,
        componentProps: { id: req._id, cta_id: req.teacherCourse },
      });
    },
    handleReject(id) {
      let componentProps = {
        message: 'Are you sure you want to reject this ' + this.status,
        title: 'Reject',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedReject,
        cbParams: { id },
      });
    },
    async proceedReject({ id }) {
      let response = await this.request({
        method: 'put',
        url: this.url + 'reject/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: this.status + ' rejected successfully',
        });
        this.fetchVerification();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    async fetchVerification() {
      this.loading = true;
      if (this.activeGrade && this.activeSection) {
        let response = await this.request({
          method: 'get',
          url: this.url + `${this.activeGrade}/${this.activeSection}`,
        });
        if (response && !response.error) this.items = response.record;
        else this.items = [];
      } else this.items = [];

      this.loading = false;
    },

    handleApprove(id) {
      let componentProps = {
        message: 'Are you sure you want to Approve this.',
        title: 'Approve',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedApprove,
        cbParams: { id },
      });
    },
    async proceedApprove({ id }) {
      let response = await this.request({
        method: 'put',
        url: this.url + 'approve/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Course grade verified successfully',
        });
        await this.fetchVerification();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
        this.activeGrade = response.record[0]._id;
      } else {
        this.gradeItems = [];
        this.activeGrade = '';
      }
    },

    async fetchSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/class/' + this.activeGrade,
      });
      if (response && !response.error) {
        this.sectionItems = response.record;
        this.activeSection = response.record[0]._id;
      } else {
        this.sectionItems = [];
        this.activeSection = '';
      }
    },
  },

  async created() {
    await this.fetchGrades();
  },
};
</script>

<style></style>
