<template>
  <div class="">
    <base-title title="Profile Setting" />
    <div class="mt-1 bg-white p-4">
      <tabs>
        <tab title="Update Password">
          <change-password></change-password>
        </tab>
        <tab title="Profile Information">
          <profile-information></profile-information>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import Tab from '@/components/tab/Tab.vue';
import Tabs from '@/components/tab/Tabs.vue';
import ChangePassword from '@/views/protected/setting/settingTabs/ChangePassword.vue';
import ProfileInformation from '@/views/protected/setting/settingTabs/ProfileInformation.vue';

export default {
  components: {
    Tab,
    Tabs,
    ChangePassword,
    ProfileInformation,
  },
};
</script>
