<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title> Withdraw {{ student.firstName }} </template>
    <template>
      <base-input
        v-model="Form.lastDateClassAttended"
        type="date"
        label="Last Date Class Attended"
      />
      <base-text-area
        :rows="4"
        label="Reason for withdrawal"
        v-model="Form.reason"
      />
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '../../eventBus';
import { mapActions, mapState } from 'vuex';

export default {
  components: { ModalTemplate },
  props: ['student'],
  data() {
    return {
      Form: {
        reason: '',
        student: '',
        lastDateClassAttended: '',
      },
      isWorking: false,
    };
  },
  computed: {
    ...mapState('main', ['berror']),
  },
  created() {
    this.Form.student = this.student._id;
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      this.isWorking = true;

      let base_url = 'school/withdrawal';

      let response = await this.request({
        method: 'post',
        url: base_url,
        data: Object.keys(this.Form).reduce(
          (acc, key) =>
            this.Form[key] !== '' ? { ...acc, [key]: this.Form[key] } : acc,
          {}
        ),
      });
      if (response && !response.error) {
        this.handleClose(true);
        eventBus.$emit('open-toast', {
          message: 'Student withdrawn successfully',
        });
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true,
        });
      }
      this.isWorking = false;
    },
  },
};
</script>

<style></style>
