// import ProfileSetting from "@/views/protected/setting/Setting.vue";
import MyChannels from "@/views/protected/channel/MyChannels.vue";
import ChannelDetail from "@/views/protected/channel/ChannelDetail.vue";
import ContentDisplayPage from "@/views/protected/content/ContentDisplayPage.vue";
import CourseDetail from "@/views/protected/course/CourseDetail.vue";
// import SearchEngine from "../../views/protected/common/SearchEngine.vue";

export default [
    // {
    //     path: 'setting',
    //     name: 'profile-setting',
    //     component: ProfileSetting
    // },
    {
        path: 'channels',
        name: 'my-channels',
        component: MyChannels
    },
    // {
    //     path: 'search',
    //     name: 'search',
    //     component: SearchEngine
    // },
    {
        path: 'course/:courseId',
        name: 'n-course-detail',
        component: CourseDetail,
        props: true,
    },

    {
        path: 'channels/:channelId',
        name: 'channel-detail',
        component: ChannelDetail,
        props: true,
    },
    {
        path: 'course/:courseId/content/:contentId/:categoryId?',
        name: 'content-display-page',
        component: ContentDisplayPage,
        props: true,
    },
]