<template>
  <div class="my-3 space-y-4">
    <div class="flex items-center justify-between w-full pt-4">
      <div>
        <div class="uppercase text-lg text-textMedium tracking-wider">
          Grade submission
        </div>
        <div class="text-sm">
          Turn on the switch in order to let instructors able to enter grade for
          the students.
        </div>
      </div>
      <div class="flex justify-end">
        <switch-button
          class="px-3 rounded-sm"
          v-model="submission"
          @input="GradeSubmissionChanged"
        />
      </div>
    </div>
    <spacer />

    <div class="">
      <div class="flex items-center justify-between w-full">
        <p class="uppercase text-lg text-textMedium tracking-wider">
          Grade Labeling
        </p>
        <div class="flex justify-end">
          <base-button
            label="Save"
            class="px-3 rounded-sm"
            v-if="isDirty"
            @click="handleSave"
          />
        </div>
      </div>
      <div class="mt-1">
        <div class="grid grid-cols-3 gap-5">
          <base-input
            label="High is more than"
            placeholder=""
            type="number"
            v-model="Form.high"
            :errors="errors.high"
            check
          />

          <base-input
            label="Moderate is more than"
            placeholder=""
            type="number"
            v-model="Form.moderate"
            :errors="errors.moderate"
            check
          />

          <base-input
            label="Low is less than"
            placeholder=""
            type="number"
            v-model="Form.moderate"
            :errors="errors.moderate"
            check
          />
        </div>
      </div>
    </div>
    <spacer />

    <div class="">
      <div class="flex items-center justify-between w-full">
        <p class="uppercase text-lg text-textMedium tracking-wider">
          Promotion Criterion
        </p>
      </div>

      <div class="grid grid-cols-2 gap-5">
        <base-input
          label="Pass Mark"
          placeholder=""
          type="number"
          v-model="academic_config.pass_mark"
          :errors="errors.pass_mark"
          check
        />
        <base-input
          label="Maximum Mark"
          placeholder=""
          type="number"
          v-model="academic_config.max_mark"
          :errors="errors.max_mark"
          check
        />
        <base-input
          label="Minimum Average"
          placeholder=""
          type="number"
          v-model="academic_config.minimum_average"
          :errors="errors.minimum_average"
          check
        />

        <base-input
          label="Maximum Number Of Subject Fails"
          placeholder=""
          type="number"
          v-model="academic_config.maximum_subject_fail"
          :errors="errors.maximum_subject_fail"
          check
        />
      </div>

      <div class="pt-2 flex items-center justify-between w-full">
        <p class="uppercase text-lg text-textMedium tracking-wider">
          MAJOR COURSE PROMOTION CRITERION
        </p>
      </div>

      <div class="mt-1">
        <div class="space-y-3">
          <div
            class="grid grid-cols-12 items-center gap-1"
            v-if="academic_config.major_subjects.length > 0"
          >
            <div class="col-span-4">Subject</div>
            <div class="col-span-4">Above %</div>
          </div>
          <div>
            <div
              v-for="(subject, i) in academic_config.major_subjects"
              :key="i"
              class="grid grid-cols-3 items-center gap-1 space-y-3"
            >
              <base-select
                placeholder="Subject"
                v-model="subject.course"
                :errors="errors.course"
                :items="SUBJECT_OPTIONS"
                searchable
              />
              <base-input
                label=""
                placeholder=""
                type="number"
                :min="0"
                :max="100"
                v-model="subject.minimum_mark"
                :errors="errors.minimum_mark"
              />
              <div
                class="cursor-pointer text-error bg-gray-200 hover:bg-opacity-50 p-2 rounded-lg"
                @click="removeSubject(i)"
              >
                <icon name="trash-alt" :size="12" class="" />
              </div>
            </div>
            <div class="flex p-6 justify-center items-center">
              <div
                v-if="academic_config.major_subjects.length == 0"
                class="p-4 rounded-full border-2 border-primary text-primary cursor-pointer"
                @click="addSubject"
              >
                <icon name="plus" :size="48" class="" />
              </div>
              <div v-else class="flex justify-between items-center px-3 w-full">
                <base-button
                  icon="plus"
                  label="add new"
                  @click="addSubject"
                  class="bg-gray-200 text-textDarkest px-3"
                  :primary="false"
                />
              </div>
            </div>
          </div>

          <div class="flex justify-end">
            <base-button
              label="Save"
              class="px-3 rounded-sm"
              @click="handleAcademicConfig"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import eventBus from '@/eventBus.js';
import { minValue, required, maxValue } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

import SwitchButton from '../../../../components/base/SwitchButton.vue';

export default {
  components: { SwitchButton },
  props: ['id'],
  mixins: [validation],
  data() {
    return {
      working: false,
      isDirty: false,
      submission: false,
      errors: {},
      Form: {
        low: 45,
        moderate: 75,
        high: 75,
      },
      academic_config: {
        pass_mark: 0,
        max_mark: 0,
        minimum_average: 0,
        maximum_subject_fail: 0,
        major_subjects: [],
      },
      subjectList: [],
      meta: [
        { key: 'Form.low', label: 'This' },
        { key: 'Form.moderate', label: 'This' },
        { key: 'Form.high', label: 'This' },
      ],
      initial: true,
    };
  },

  watch: {
    // Form: function() {
    //   this.isDirty = true;
    // }
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['low'].includes(key)) {
            acc[key] = {
              required,
              minValue: minValue(0),
              maxValue: maxValue(100),
            };
          } else if (['moderate'].includes(key)) {
            acc[key] = {
              required,
              minValue: minValue(0),
              maxValue: maxValue(100),
            };
          } else if (['high'].includes(key)) {
            acc[key] = {
              required,
              minValue: minValue(0),
              maxValue: maxValue(100),
            };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    SUBJECT_OPTIONS() {
      return this.subjectList.map((subject) => ({
        label: subject.name,
        value: subject._id,
      }));
    },
  },

  methods: {
    ...mapActions('main', ['request']),

    addSubject() {
      this.academic_config.major_subjects = [
        ...this.academic_config.major_subjects,
        { course: '', minimum_mark: 0 },
      ];
    },

    removeSubject(index) {
      this.academic_config.major_subjects.splice(index, 1);
      this.academic_config.major_subjects = [
        ...this.academic_config.major_subjects,
      ];
      this.academic_config = { ...this.academic_config };
    },

    async GradeSubmissionChanged() {
      // console.log('GradeSubmissionChanged');
      let response = await this.request({
        method: 'put',
        url: 'school/config/grade-submission-status',
        data: { gradeSubmission: this.submission },
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'Updated Successfully' });
      } else eventBus.$emit('open-toast', { error: true, message: this.error });
    },

    async fetchItem() {
      let response = await this.request({
        method: 'get',
        url: 'school/config/' + (this.id ? '/' + this.id : ''),
      });
      if (response && !response.error) {
        const label = response.record.label;
        const academic_config = response.record.academic_config;
        this.submission = response.record.gradeSubmission;

        if (label) {
          this.Form.low = response.record.label.low;
          this.Form.moderate = response.record.label.moderate;
          this.Form.high = response.record.label.high;
        }
        if (academic_config) {
          this.academic_config.pass_mark = academic_config.pass_mark;
          this.academic_config.max_mark = academic_config.max_mark;
          this.academic_config.minimum_average =
            academic_config.minimum_average;

          this.academic_config.maximum_subject_fail =
            academic_config.maximum_subject_fail;

          if (academic_config.major_subjects.length > 0) {
            this.academic_config.major_subjects =
              academic_config.major_subjects.map((major) => ({
                course: major.course,
                minimum_mark: major.minimum_mark,
              }));
          } else this.academic_config.major_subjects = [];
        }

        this.Form = { ...this.Form };
      }
    },
    async fetchSubjectList() {
      let response = await this.request({
        method: 'get',
        url: 'school/subject/active',
      });
      if (response && !response.error) {
        this.subjectList = response.record;
      } else {
        this.subjectList = [];
      }
    },
    async handleSave() {
      this.working = true;
      this.initial = false;
      if (!this.isValid()) return;
      let response = await this.request({
        method: 'post',
        url: 'school/config',
        data: { label: this.Form },
      });
      if (response && !response.error) {
        this.working = false;
        eventBus.$emit('open-toast', { message: 'Updated Successfully' });
        this.isDirty = false;
        this.fetchItem();
      } else {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.initial = true;
    },

    async handleAcademicConfig() {
      this.working = true;
      this.initial = false;
      if (!this.isValid()) return;
      let response = await this.request({
        method: 'put',
        url: 'school/config/academic-config',
        data: { ...this.academic_config },
      });
      if (response && !response.error) {
        this.working = false;
        eventBus.$emit('open-toast', { message: 'Updated Successfully' });
        this.isDirty = false;
        this.fetchItem();
      } else {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.initial = true;
    },
  },

  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    // Object.keys(this.Form).forEach((key)=> {
    //   this.$watch(`Form.${key}`, ()=> {
    //     console.log('key', key, this.Form.key);
    //     if(['low', 'moderate'].includes(key) && this.Form.low && this.Form.moderate && this.Form.low >= this.Form.moderate){
    //       this.errors['low'].push('erroro here ')
    //       this.errors['moderate'] = ['erroro here ']
    //     }
    //   })
    // })
    await this.fetchItem();
    await this.fetchSubjectList();
    Object.keys(this.Form).forEach((key) =>
      this.$watch(`Form.${key}`, () => {
        this.isDirty = true;
      })
    );
  },
};
</script>

<style></style>
