<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title>
      {{ isUpdate ? 'Update' : 'Create New' }} Subject
    </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-2 gap-5">
          <base-input
            class="col-span-2"
            label="Name"
            placeholder="name"
            v-model="Form.name"
            :errors="errors.name"
            capitalize
            check
            required
          />
          <base-input
            class="col-span-2"
            label="Code"
            placeholder="Subject Code"
            v-model="Form.code"
            :errors="errors.code"
            caps
            check
            required
          />
          <base-multi-select
            class="col-span-2"
            label="Grades"
            placeholder=""
            v-model="Form.classes"
            :items="GRADE_OPTIONS"
            :errors="errors.classes"
            :searchable="true"
            @input="handleToggle"
            check
          />
          <div class="col-span-2 flex">
            <base-check-box
              :selected="allClasses"
              @toggle="handleToggleGrade"
              :size="20"
            />
            <div>All Grades</div>
          </div>
          <switch-button label="Is Active" v-model="Form.status" />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import SwitchButton from '../base/SwitchButton.vue';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm'],
  mixins: [validation],
  data() {
    return {
      Form: {
        name: '',
        code: '',
        classes: [],
        status: '',
      },
      classes: [],
      errors: {},
      working: false,
      meta: [
        { key: 'Form.name', label: 'Name' },
        { key: 'Form.code', label: 'Code' },
        { key: 'Form.classes', label: 'Grades' },
      ],
      initial: true,
    };
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name', 'code'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    ...mapState('main', ['error']),
    isUpdate() {
      if (this.oldForm) return true;
      else return false;
    },
    GRADE_OPTIONS() {
      return this.classes.map((grade) => ({
        label: grade.name + ' - ' + grade.enrollment.code,
        value: grade._id,
      }));
    },
    allClasses() {
      return this.GRADE_OPTIONS.length == this.Form.classes.length;
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    handleToggle(n) {
      this.allClasses = this.classes.length === n.length;
      if (this.allClasses) {
        this.Form.classes = this.classes.map((grade) => grade._id);
      }
    },
    handleToggleGrade() {
      if (this.allClasses) {
        this.Form.classes = [];
      } else {
        this.Form.classes = this.GRADE_OPTIONS.map(({ value }) => value);
      }
    },

    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      if (this.Form.classes.length == 0) delete this.Form.classes;
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
      this.initial = true;
      if (!this.Form.classes) this.Form.classes = [];
    },
    populateData() {
      console.log('old form', this.oldForm, this.Form);
      // Object.keys(this.oldForm).forEach((key) => {
      //   this.Form[key] = this.oldForm[key];
      // });
      this.Form.name = this.oldForm.name;
      this.Form.code = this.oldForm.code;

      this.Form.classes = this.oldForm.classes;

      this.Form['status'] = ['closed', 'inactive'].includes(
        this.oldForm['status']
      )
        ? false
        : true;
    },
    async handleCreate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      let response = await this.request({
        method: 'post',
        url: 'school/subject',
        data: newForm,
      });
      if (response && !response.error) {
        // this.item = response.item;
        eventBus.$emit('open-toast', {
          message: 'Subject created successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },

    async handleUpdate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      newForm = {
        name: newForm.name,
        code: newForm.code,
        classes: newForm.classes,
        status: newForm.status,
      };

      let response = await this.request({
        method: 'put',
        url: 'school/subject/' + this.oldForm._id,
        data: newForm,
      });
      if (response && !response.error) {
        // this.item = response.item;
        eventBus.$emit('open-toast', {
          message: 'Subject updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },
    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active/',
      });
      if (response && !response.error) {
        this.classes = response.record;
        // this.Form.classes = this.classes.map((grade) => grade._id);
      } else {
        this.classes = [];
      }
    },
  },
  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    await this.fetchGrades();
    if (this.isUpdate) this.populateData();
    else this.Form.classes = this.classes.map((c) => c._id);
  },
};
</script>

<style></style>
