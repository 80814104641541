<template>
  <div class="space-y-2">
    <div
      class="bg-white shadow-default flex items-center justify-around rounded-default"
    >
      <div
        class="flex items-center space-x-3 py-2 w-full justify-center cursor-pointer"
        v-for="(step, i) in steps"
        :key="i"
        :class="{
          ' h-full border-r-2': i !== steps.length - 1,
          'border-gray': i < activeStep,
          'border-primary': i === activeStep,
          'border-brighterGray': i > activeStep,
          'bg-lgray': i > activeStep,
        }"
        @click="handleChangeStep(i)"
      >
        <div
          v-if="i >= activeStep"
          class="flex items-center justify-center w-7 h-7 rounded-full"
          :class="
            i > activeStep
              ? 'bg-gray-100 rounded-full'
              : activeStep === i
              ? 'bg-primary text-white'
              : 'bg-white text-textMedium'
          "
        >
          {{ i + 1 }}
        </div>

        <div
          class="text-textDark"
          :class="
            activeStep === i
              ? 'text-info'
              : i < activeStep
              ? 'text-teal'
              : 'text-textMedium'
          "
        >
          {{ step }}
        </div>

        <div>
          <div class="text-primary" v-if="i < activeStep">
            <icon name="check-circle" :size="16" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div v-for="(step, index) in steps" :key="step">
        <div v-show="activeStep === index">
          <slot :name="step"></slot>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between pt-2">
      <div>
        <base-button
          v-show="activeStep > 0"
          label="Back"
          :primary="false"
          class="bg-gray-100 px-4 rounded text-sm hover:bg-danger hover:text-error"
          @click="handleBack"
        />
      </div>

      <div class="flex justify-end">
        <base-button
          v-show="activeStep < steps.length - 1"
          label="Next"
          class="px-4 text-sm"
          @click="handleNext"
        />
        <base-button
          v-show="activeStep === steps.length - 1 && allow_create"
          :label="finish_label"
          :working="working"
          icon="check-circle"
          class="px-4 text-sm"
          @click="$emit('finished')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '../eventBus';

export default {
  props: {
    steps: {
      required: true,
    },
    finish_label: {
      default: 'Create',
    },
    working: {
      default: false,
      type: Boolean,
    },
    allow_create: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      activeStep: 0,
      jumpTo: 0,
    };
  },
  methods: {
    handleChangeStep(step) {
      this.jumpTo = step;
      this.$emit('step');
    },
    handleBack() {
      if (this.activeStep === 0) return;
      this.activeStep -= 1;
    },
    handleNext() {
      if (this.activeStep === this.steps.length - 1) return;
      else {
        this.$emit('next');
      }
    },
  },
  created() {
    eventBus.$on('proceed', async (type) => {
      if (type == 'step') {
        this.activeStep = this.jumpTo;
      } else this.activeStep += 1;
    });
  },
};
</script>

<style></style>
