<template>
  <modal-template
    @cancel="handleClose"
    @save="handleSave"
    :working="working"
    saveLabel="generate"
  >
    <template #title> generate schedule </template>
    <template>
      <div>
        <base-multi-select
          required
          check
          label="Select sections"
          placeholder=""
          v-model="Form.sections"
          :items="SECTION_OPTIONS"
          :errors="errors.sections"
          :searchable="true"
        />
        <div class="my-4">
          <div class="flex space-x-3 items-center">
            <base-check-box
              :selected="allSections"
              @toggle="handleToggleGrade"
              :size="20"
            />
            <p class="select-none">Generate for All Sections</p>
          </div>
        </div>
        <div class="mt-5">
          <p class="mb-2">Colors</p>
          <div class="w-full flex flex-wrap gap-y-4 gap-x-4 my-5">
            <div
              v-for="(color, i) in colors"
              :key="i"
              :class="{
                'border-4 w-full rounded-full border-secondary':
                  i == selectedColorIndex,
              }"
              style="max-width: fit-content"
              @click="selectedColorIndex = i"
            >
              <div class="h-12 w-12 rounded-full flex cursor-pointer shadow-lg">
                <div
                  class="w-1/2 rounded-l-full"
                  :style="{ 'background-color': color.primary }"
                ></div>
                <div
                  class="w-1/2 rounded-r-full"
                  :style="{ 'background-color': color.secondary }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import BaseCheckBox from '@/components/base/BaseCheckBox.vue';
//import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import { eventColors } from '@/config/constants.js';
import eventBus from '../../../../eventBus';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate, BaseCheckBox },
  props: ['school_id', 'isSchoolOfficial'],
  mixins: [validation],
  data() {
    return {
      Form: {
        sections: [],
        primaryColor: '',
        secondaryColor: '',
        allSections: false,
      },
      errors: {},
      colors: eventColors,
      selectedColorIndex: 1,
      sections: [],
      meta: [{ key: 'Form.sections', label: 'Sections' }],
      working: false,
      initial: true,
    };
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['sections'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },

  computed: {
    ...mapGetters('main', ['berror']),
    SECTION_OPTIONS() {
      return this.sections.map((section) => ({
        label: section.class.name + ' - ' + section.section.name,
        value: section._id,
      }));
    },
    allSections() {
      return this.SECTION_OPTIONS.length == this.Form.sections.length;
    },
  },

  watch: {
    'Form.allSections': function (n) {
      if (n) {
        this.Form.sections = this.sections.map((section) => section._id);
      } else {
        this.Form.sections = [];
      }
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      if (this.Form.sections.length == 0) delete this.Form.sections;
      this.initial = false;
      if (!this.isValid()) return;
      let newForm = {
        sections: this.Form.sections,
      };
      this.working = true;
      let response = await this.request({
        method: 'post',
        url: 'school/session/generate',
        data: newForm,
      });

      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'schedule generated successfully',
        });
        this.handleClose();
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror || 'error generating schedule',
          error: true,
        });
      }
      this.working = false;

      this.initial = true;
      if (!this.Form.sections) this.Form.sections = [];
    },
    handleToggleGrade() {
      if (this.allSections) {
        this.Form.sections = [];
      } else {
        this.Form.sections = this.SECTION_OPTIONS.map(({ value }) => value);
      }
    },

    async fetchSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/active',
      });
      if (response && !response.error) {
        this.sections = response.record;
      } else {
        this.sections = [];
      }
    },
  },
  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    await this.fetchSections();
    this.Form.sections = this.sections.map((section) => section._id);
  },
};
</script>

<style></style>
