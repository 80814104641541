<template>
  <div>
    <base-title
      :title="
        academic_year.name
          ? `Mark List Of ${academic_year.name}`
          : 'No Academic Year History On this Section'
      "
      class="mb-2"
    >
      <template #actions>
        <div class="flex justify-center items-center space-x-2">
          <base-select
            placeholder=""
            v-model="activeGrade"
            :items="GRADE_OPTIONS"
            searchable
          />
          <base-select
            placeholder=""
            v-model="activeSection"
            :items="SECTION_OPTIONS"
            searchable
          />

          <base-select
            placeholder=""
            :items="ACADEMIC_YEARS"
            v-model="activeAy"
            searchable
          />
        </div>
      </template>
    </base-title>
    <div class="bg-white w-full p-1 px-4 mt-2 rounded-default">
      <div class="py-2">
        <div v-if="activeAy">
          <grade-book :ay_id="activeAy" />
        </div>
        <base-no-data v-else message="No Active Mark List" />
      </div>
    </div>
  </div>
</template>

<script>
import GradeBook from './components/GradeBook.vue';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
// import BaseSelectPlus from '../../../../components/base/BaseSelectPlus.vue';
export default {
  components: {
    GradeBook,
  },
  data() {
    return {
      activeGrade: '',
      activeSection: '',
      activeAy: '',
      gradeItems: [],
      sectionItems: [],
      academic_years: [],
      academic_year: {},
      noData: false,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },

    SECTION_OPTIONS() {
      return this.sectionItems.map((section) => ({
        label: section.name,
        value: section._id,
      }));
    },
    ACADEMIC_YEARS() {
      return this.academic_years.map((academic_year) => ({
        label: academic_year.name,
        value: academic_year._id,
      }));
    },
  },
  watch: {
    activeGrade() {
      this.fetchSections();
    },
    activeSection() {
      this.fetchAcademicYears();
    },
    activeAy() {
      this.fetchAcademicYear();
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
        this.activeGrade = response.record[0]._id;
      } else {
        this.gradeItems = [];
        this.activeGrade = '';
      }
    },

    async fetchSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/class/' + this.activeGrade,
      });
      if (response && !response.error) {
        this.sectionItems = response.record;
        this.activeSection = response.record[0]._id;
      } else {
        this.sectionItems = [];
        this.activeSection = '';
      }
    },
    async fetchAcademicYears() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/section/' + this.activeSection,
      });
      if (response && !response.error) {
        this.academic_years = response.record;
        this.activeAy = this.academic_years[0]._id;
        this.academic_year = response.record[0];
      } else {
        this.academic_years = [];
        this.activeAy = '';
        this.noData = true;
      }
    },

    async fetchAcademicYear() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/' + this.activeAy,
      });
      if (response && !response.error) {
        this.academic_year = response.record;
      } else {
        this.academic_year = {};
        this.noData = true;
      }
    },
  },

  async created() {
    await this.fetchGrades();
  },
};
</script>

<style></style>
