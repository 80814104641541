import Vue from 'vue';
import BaseButton from './base/BaseButton.vue';
import BaseInput from './base/BaseInput.vue';
import BaseNoData from './base/BaseNoData.vue';
import BaseRadio from './base/BaseRadio.vue';
import BaseSelect from './base/BaseSelect.vue';
import BaseMultiSelect from './base/BaseMultiSelect.vue';
import BaseMultitype from './base/BaseMultiType.vue';
import BaseCheckBox from './base/BaseCheckBox.vue';
import BaseTextArea from './base/BaseTextArea.vue';
import Icon from '@/components/shared/Icon/Icon.vue';
import BaseChips from './base/BaseChips.vue';
import Spacer from './base/Spacer.vue';
import BaseTitle from '@/components/collection/Title.vue';
import StatusChip from '@/components/base/Chip.vue';
import Loader from '@/components/shared/Loader.vue';
import BaseQrcode from '@chenfengyuan/vue-qrcode';
import BaseEthCalendar from '@/components/base/BaseEthCalendar';
import BaseList from './base/BaseList.vue';

const baseComponents = {
  BaseButton,
  BaseInput,
  BaseNoData,
  BaseRadio,
  BaseSelect,
  BaseCheckBox,
  BaseMultiSelect,
  BaseMultitype,
  BaseTextArea,
  Icon,
  BaseChips,
  Spacer,
  BaseTitle,
  StatusChip,
  Loader,
  BaseQrcode,
  BaseEthCalendar,
  BaseList,
};

export default function registerGlobally() {
  Object.keys(baseComponents).forEach((key) => {
    Vue.component(key, baseComponents[key]);
  });
}
