<template>
  <div class="lg:grid lg:grid-cols-3 flex space-x-0 h-full shadow-default">
    <div
      class="flex-grow space-y-6 lg:col-span-2 border-r-4 border-grayBg px-2"
    >
      <grading-config :id="id" class="px-4 py-3" />
    </div>
    <grading-letter-config
      :id="id"
      class="flex-grow-0 flex-shrink-0 min-w-24 p-4 rounded border-textLight"
    />
  </div>
</template>

<script>
import GradingConfig from './components/GradingConfig.vue';
import GradingLetterConfig from './components/GradingLetterConfig.vue';

export default {
  props: ['id'],
  components: {
    GradingConfig,
    GradingLetterConfig,
  },
};
</script>

<style></style>
