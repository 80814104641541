<template>
  <div class="my-3">
    <div class="flex justify-end items-center mb-3 space-x-5">
      <base-button
        icon="check-circle"
        label="save"
        @click="handleSave"
        
        class="px-3 rounded-sm"
      />
    </div>
    <div class="mt-1 px-5 space-y-3">
      <base-check-box
        label="Include Service Bills"
        :selected="Form.include_service_bills"
        @toggle="changeServiceBill"
      />
      <base-check-box
        label="Include Admission Bills"
        :selected="Form.include_admission_bills"
        @toggle="changeAdmissionBill"
      />

      <div class="px-5" v-if="Form.include_admission_bills">
        <div class="flex justify-end">
          <base-button
            :primary="false"
            icon="plus"
            class="text-primary bg-primary bg-opacity-25 p-1"
            @click="addTerm()"
            title="Add Field"
          />
        </div>
        <div class="w-1/2 flex space-x-5 pt-4">
          <span class="w-2/3">Admission Prerequisite Bills</span>
          <span>Term</span>
        </div>

        <div
          v-for="(admission_bill, index) in Form.admission_fee"
          :key="index"
          class="p-2 cursor-pointer items-center"
        >
          <div class="w-1/2 flex justify-center space-x-5">
            <base-select
              :items="PAYMENT_TYPE_OPTIONS"
              v-model="admission_bill.payment_type"
            />
            <base-input
              placeholder="term"
              type="number"
              v-model="admission_bill.min_term"
              filled
            />
            <div class="flex space-x-2 items-center">
              <base-button
                :primary="false"
                icon="plus"
                class="text-primary bg-gray-100 hover:bg-dgray p-1"
                @click="addTerm(index)"
                title="Add Field Above"
              />
              <base-button
                v-if="Form.admission_fee.length > 1"
                :primary="false"
                icon="trash-alt"
                class="text-error bg-gray-100 hover:bg-dgray p-1"
                @click="removeTerm(index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '../../../eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';

export default {
  props: ['id'],
  data() {
    return {
      working: false,
      Form: {
        include_service_bills: false,
        include_admission_bills: false,
        admission_fee: [],
      },
      payment_types: [],
      expanded: {},
    };
  },
  computed: {
    ...mapState('main', ['error']),
    PAYMENT_TYPE_OPTIONS() {
      return this.payment_types.map((payment_type) => ({
        label: payment_type.name,
        value: payment_type._id,
      }));
    },
  },
  watch: {
    Form() {},
  },
  methods: {
    ...mapActions('main', ['request']),
    changeServiceBill() {
      this.Form.include_service_bills = !this.Form.include_service_bills;
      this.Form = { ...this.Form };
    },
    changeAdmissionBill() {
      this.Form.include_admission_bills = !this.Form.include_admission_bills;
      this.Form = { ...this.Form };
    },
    addCategory() {
      eventBus.$emit('open-modal', {
        modalId: 33,
        cb: this.setCategory,
      });
    },
    updateCategory(i) {
      let componentProps = {
        oldForm: { ...this.Form.categories[i], i },
      };
      eventBus.$emit('open-modal', {
        modalId: 33,
        componentProps,
        cb: this.modifyCategory,
      });
    },
    removeCategory(i) {
      let componentProps = {
        message: 'Are you sure you want to delete the item',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.deleteCategory,
        cbParams: { i },
      });
    },

    async getAdmissionBills() {
      let response = await this.request({
        method: 'get',
        url: 'school/config',
      });
      if (response && !response.error) {
        const fee_config = response.record;
        this.Form.admission_fee = response.record.admission_fee;
        this.populateForm(fee_config);
      }
    },
    async fetchPaymentTypesList() {
      let response = await this.request({
        method: 'get',
        url: 'school/paymentType/regular',
      });
      if (response && !response.error) {
        this.payment_types = response.record;
      }
    },
    populateForm(data) {
      const admission_fee = data.admission_fee.map((admission) => {
        return {
          payment_type: admission.payment_type._id,
          min_term: admission.min_term,
        };
      });
      this.Form = {
        include_service_bills: data.include_service_bills,
        include_admission_bills: data.include_admission_bills,
        admission_fee: admission_fee,
      };
    },

    addTerm(i) {
      if (i) {
        this.Form.admission_fee.splice(i, 0, {
          payment_type: '',
          min_term: 1,
        });
      } else {
        this.Form.admission_fee.push({
          payment_type: '',
          min_term: 1,
        });
      }

      // errors.splice(index, 0, {});
    },
    removeTerm(i) {
      this.Form.admission_fee.splice(i, 1);
      // errors.splice(index, 1);
    },
    async handleSave() {
      this.working = true;
      let response = await this.request({
        method: 'put',
        url: 'school/config/admission-fee',
        data: this.Form,
      });
      if (response && !response.error) {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: 'fee config updated successfully',
        });
        this.getAdmissionBills();
      } else {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },
  created() {
    this.getAdmissionBills();
    this.fetchPaymentTypesList();
  },
};
</script>

<style></style>
