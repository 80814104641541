<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title>
      Change School Status
    </template>
    <template>
      <base-select
        :items="STATUS_OPTIONS"
        label="Select new Status"
        v-model="Form.status"
      />
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '../../eventBus';
import { mapActions, mapState } from 'vuex';


export default {
  components: { ModalTemplate },
  props: ['status', 'id'],
  data() {
    return {
      Form: {
        status: ''
      },
      STATUS_OPTIONS: [
        // {
        //   label: 'Pending',
        //   value: 'pending'
        // },
        // {
        //   label: 'Verified',
        //   value: 'verified'
        // },
        // {
        //   label: 'Suspended',
        //   value: 'suspended'
        // }
        
        {
          label: 'Active',
          value: 'active'
        }, 
        {
          label: 'Inactive',
          value: 'inactive'
        }, 
      ]
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      let response = await this.request({
        method: 'put',
        url: 'school/schoolProfile/update-status/' + this.id,
        data: this.Form
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'status changed successfully'
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true
        });
      }
    }
  },
  computed: {
    ...mapState('main', ['berror']),
  },
  created() {
    this.Form.status = this.status;
    console.log('sta', this.status);
  }
};
</script>

<style></style>
