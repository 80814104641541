<template>
  <div>
    <base-title title="Manage School Officials">
      <template #actions>
        <div class="flex">
          <base-button
            icon="plus"
            label="Add New"
            :isVisible="permission.add"
            class="px-3"
            @click="handleCreateNew"
            code="stf-add"
          />
        </div>
      </template>
    </base-title>
    <div class="bg-white p-1 px-4 mt-2 rounded-default">
      <div>
        <d-table
          #default="{ item }"
          :headers="headers"
          :items="items"
          :totalItems="totalItems"
          :pageRequest="true"
          :hasActions="true"
          :handlers="handlers"
          :loading="loading"
          :searchProps="searchProps"
          code="stf-view"
          sortUrl="school/official/my-officials"
        >
          <td v-for="(header, i) in headers" :key="i">
            <div class="px-2">
              <div v-if="header.key === 'profilePic'">
                <div class="flex space-x-5 items-center">
                  <div
                    class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center"
                  >
                    <img
                      v-if="item.profilePic"
                      :src="PROFILE_PATH + item.profilePic"
                      v-viewer="{ inline: false, movable: true }"
                      class="w-full h-full object-cover rounded-full cursor-pointer"
                      alt=""
                      crossorigin="anonymous"
                    />
                    <icon
                      v-else
                      :size="18"
                      name="user"
                      class="w-full h-full"
                    ></icon>
                  </div>
                  <div>
                    <p
                      class="cursor-pointer hover:underline font-semibold text-textDarkest uppercase"
                      @click="handleDetail(item.id)"
                    >
                      {{ item.firstName }} {{ item.middleName }}
                    </p>
                    <p class="text-textLight">{{ item.username }}</p>
                  </div>
                </div>
              </div>
              <div v-else-if="header.key === 'phoneNumber'">
                {{ item['phoneNumber'].join(' | ') }}
              </div>
              <div v-else-if="header.key === 'status'">
                <status-chip
                  :label="item['status']"
                  style="max-width: fit-content"
                />
              </div>
              <div v-else>
                {{ getProp(item, header.key) }}
              </div>
            </div>
          </td>
        </d-table>
      </div>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import eventBus from '../../../../eventBus';
import { mapActions, mapGetters } from 'vuex';
import { static_roles } from '@/config/constants';
import { PROFILE_PATH } from '@/config/config.js';
export default {
  components: { DTable },
  data() {
    return {
      PROFILE_PATH,
      items: [],
      totalItems: '',
      module: 'official',
      permission: {},
      loading: false,
      headers: [
        { label: 'User', key: 'profilePic' },
        { label: 'Official Id', key: 'userId' },
        { label: 'Role', key: 'role.name' },
        { label: 'Gender', key: 'gender' },
        { label: 'Phone Number', key: 'phoneNumber' },
        { label: 'School', key: 'school.name' },
        { label: 'Status', key: 'status' },
        // { label: '', key: '' },
        // { label: 'Label Five', key: '' },
      ],
      handlers: [
        {
          label: 'Detail',
          icon: 'eye',
          name: 'detail',
          handler: this.handleDetail,
          idParam: true,
          filter: () => this.authCodes.includes('stf-dtl'),
        },

        {
          label: 'Edit',
          icon: 'pencil-alt',
          name: 'update',
          handler: this.handleEdit,
          idParam: true,
          filter: () => this.authCodes.includes('stf-edit'),
        },
        {
          label: 'Change Status',
          icon: 'pencil-alt',
          name: 'update',
          handler: this.handleChangeStatus,
          fullParam: true,
          filter: () => this.authCodes.includes('stf-stat'),
        },
        {
          label: 'Reset Password',
          icon: 'pencil-alt',
          name: 'update',
          handler: this.handleResetPassword,
          idParam: true,
          filter: () => this.authCodes.includes('stf-rst'),
        },
        {
          label: 'Delete',
          icon: 'trash-alt',
          name: 'delete',
          handler: this.handleDelete,
          idParam: true,
          filter: () => this.authCodes.includes('stf-rm'),
        },
      ],
      searchProps: {
        searchUrl: 'school/official/table-search',
      },
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,
    handleDelete(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the item',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },
    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/official/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'deleted successfully' });
        this.fetchOfficials();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    handleEdit(id) {
      this.$router.push({ name: 'school-official-update', params: { id } });
    },
    handleDetail(id) {
      this.$router.push({ name: 'school-official-detail', params: { id } });
    },
    handleCreateNew() {
      this.$router.push({ name: 'school-official-create' });
    },
    async handleResetPassword(id) {
      let response = await this.request({
        method: 'put',
        url: 'school/official/reset-password/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-modal', {
          modalId: 5,
          componentProps: { user: response.record },
        });
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    async fetchOfficials() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'school/official/my-officials?page=1&limit=10',
      });
      if (response && !response.error) {
        this.items = response.record;
        this.totalItems = response.total;
      } else this.items = [];
      this.loading = false;
    },
    handleChangeStatus(user) {
      eventBus.$emit('open-modal', {
        modalId: 4,
        componentProps: {
          status: user.status,
          id: user._id,
          profile_type: 'school-officials',
        },
        cb: this.fetchOfficials,
      });
    },

    checkStatic() {
      const role = this.user.role;
      if (!static_roles.includes(role)) {
        this.isSchoolOfficial = true;
        const permissions = this.user.permissions;
        let permission = permissions.find((p) => p.module.name === this.module);
        this.permission = permission;
        this.handlers = this.handlers.filter(
          (action) => permission[action.name]
        );
      }
    },
  },

  created() {
    this.fetchOfficials();
  },
};
</script>

<style></style>
