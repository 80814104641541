import CourseAuthorManamgement from '@/views/protected/user_management/course_author_management/Index.vue';
import CreateCourseAuthor from '@/views/protected/user_management/course_author_management/Create.vue';
import CourseAuthorDetail from '@/views/protected/user_management/course_author_management/Detail.vue';
import ParentManagement from '@/views/protected/user_management/parent_management/Index.vue';
import ParentCreate from '@/views/protected/user_management/parent_management/Create.vue';
import ParentDetail from '@/views/protected/user_management/parent_management/Detail.vue';
import SchoolOfficialManagement from '@/views/protected/user_management/school_official_management/Index.vue';
import StudentManageent from '@/views/protected/user_management/student_management/Index.vue';
import StudentCreate from '@/views/protected/user_management/student_management/Create.vue';
import StudentDetail from '@/views/protected/user_management/student_management/Detail.vue';
import TeacherOffdateManagement from '@/views/protected/user_management/teacher_management/OffdayManagement.vue';
import TeacherManagement from '@/views/protected/user_management/teacher_management/Index.vue';
import TeacherCreate from '@/views/protected/user_management/teacher_management/Create.vue';
import TeacherDetail from '@/views/protected/user_management/teacher_management/Detail.vue';
import ManageUsersDashboard from '@/views/protected/user_management/dashboard/Index.vue';
import SchoolOfficialCreate from '@/views/protected/user_management/school_official_management/Create.vue';
import SchoolOfficialDetail from '@/views/protected/user_management/school_official_management/Detail.vue';
import ImportStudents from '@/views/protected/migration/Index.vue';

export default [
  {
    path: 'overview',
    name: 'user-management-overview',
    component: ManageUsersDashboard,
  },
  {
    path: 'student',
    name: 'manage-student',
    component: StudentManageent,
  },
  {
    path: 'student/create',
    name: 'student-create',
    component: StudentCreate,
  },
  {
    path: 'student/update/:id',
    name: 'student-update',
    component: StudentCreate,
    props: true,
  },
  {
    path: 'student/detail/:id',
    name: 'student-detail',
    component: StudentDetail,
    props: true,
  },
  {
    path: 'teacher',
    name: 'manage-teacher',
    component: TeacherManagement,
  },
  {
    path: 'teacher/create',
    name: 'teacher-create',
    component: TeacherCreate,
  },
  {
    path: 'teacher/detail/:id',
    name: 'detail-teacher',
    component: TeacherDetail,
    props: true,
  },
  {
    path: 'teacher/detail/:id/offdate',
    name: 'teacher-offday',
    component: TeacherOffdateManagement,
    props: true,
  },

  {
    path: 'teacher/update/:id',
    name: 'update-teacher',
    component: TeacherCreate,
    props: true,
  },
  {
    path: 'school-official',
    name: 'school-official-list',
    component: SchoolOfficialManagement,
  },
  {
    path: 'school-official/create',
    name: 'school-official-create',
    component: SchoolOfficialCreate,
  },
  {
    path: 'school-official/update/:id',
    name: 'school-official-update',
    component: SchoolOfficialCreate,
    props: true,
  },
  {
    path: 'school-official/detail/:id',
    name: 'school-official-detail',
    component: SchoolOfficialDetail,
    props: true,
  },
  {
    path: 'parent',
    name: 'manage-parent',
    component: ParentManagement,
  },
  {
    path: 'parent/update/:id',
    name: 'parent-update',
    component: ParentCreate,
    props: true,
  },
  {
    path: 'parent/create',
    name: 'parent-create',
    component: ParentCreate,
    props: true,
  },
  {
    path: 'parent/detail/:id',
    name: 'parent-detail',
    component: ParentDetail,
    props: true,
  },
  {
    path: 'course-author',
    name: 'manage-course-autor',
    component: CourseAuthorManamgement,
  },
  {
    path: 'course-author/create',
    name: 'create-course-autor',
    component: CreateCourseAuthor,
  },
  {
    path: 'course-author/update/:id',
    name: 'update-course-autor',
    component: CreateCourseAuthor,
    props: true,
  },
  {
    path: 'course-author/detail/:id',
    name: 'detail-course-autor',
    component: CourseAuthorDetail,
    props: true,
  },
  {
    path: 'import-students',
    name: 'import-students',
    component: ImportStudents,
    props: false,
  },

];
