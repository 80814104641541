<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title>
      Add Book
    </template>
    <template>
      <div class=" flex space-x-5 py-6">
        <div class=" w-1/5 space-y-4 ">
          <div class=" w-full">
            <div
              class=" w-full h-56 bg-grayBg cursor-pointer"
              @click="handleOpenDialog('file-input')"
            >
              <img
                v-if="coverPageObj || Form.coverPage"
                :src="coverPageObj || LIBRARY_METADATA + Form.coverPage"
                alt=""
                class=" h-full w-full object-cover"
              />
            </div>
            <input
              type="file"
              class=""
              style="display:none;"
              ref="file-input"
              @change="handleSelectFile('file-input', 'Form.coverPage')"
              accept=".png, .jpg, .jpeg, .gif"
            />
          </div>
          <div
            class=" w-full h-40 bg-grayBg flex justify-center items-center cursor-pointer"
            @click="Form.path ? removePath() : handleUploadPath()"
          >
            <icon
              name="file-upload"
              class=" text-textDarkest"
              :size="40"
              v-if="!Form.path"
            />
            <icon
              name="slim-times"
              class=" text-textDarkest"
              :size="40"
              v-else
            />
            <input
              type="file"
              class=""
              style="display:none;"
              ref="path-input"
              @change="inputPath"
            />
          </div>
        </div>
        <div class=" w-4/5 space-y-5">
          <div class=" space-y-4">
            <base-input
              label="Author"
              placeholder=""
              v-model="Form.author"
              :errors="errors.author"
            />
            <base-input
              label="Title"
              placeholder=""
              v-model="Form.name"
              :errors="errors.name"
            />
            <base-text-area label="Description" v-model="Form.description" />
            <base-select
              label="Resource Type"
              placeholder=""
              v-model="Form.resourceType"
              :items="RESOURCE_TYPE_OPTIONS"
              :errors="errors.description"
            />
          </div>
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import objUrl from '@/mixins/objUrl.js';
import { LIBRARY_METADATA } from '@/config/config.js';

export default {
  components: { ModalTemplate },
  mixins: [objUrl],
  props: ['parent', 'book'],

  data() {
    return {
      errors: {},
      Form: {
        name: '',
        author: '',
        category: this.parent,
        coverPage: '',
        description: '',
        resourceType: 'text',
        path: ''
      },
      coverPageObj: '',
      pathDirty: false,
      LIBRARY_METADATA
    };
  },
  computed: {
    ...mapState('main', ['error']),
    RESOURCE_TYPE_OPTIONS() {
      return [
        { label: 'Audio', value: 'audio' },
        { label: 'Video', value: 'video' },
        { label: 'Document', value: 'text' }
      ];
    },

    isUpdate() {
      return Boolean(this.book);
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },

    handleUploadPath() {
      this.pathDirty = true;
      this.$refs['path-input'].click();
    },

    inputPath() {
      this.Form.path = this.$refs['path-input'].files[0];
      
    },

    removePath() {
      this.pathDirty = false;
      this.Form.path = '';
    },

    async handleSave() {
      let formdata = new FormData();
      formdata.append('name', this.Form.name);
      formdata.append('author', this.Form.author);
      formdata.append('category', this.Form.category);
      if (this.coverPageObj) formdata.append('coverPage', this.Form.coverPage);
      formdata.append('description', this.Form.description);
      if (this.pathDirty) formdata.append('path', this.Form.path);

      let response = await this.request({
        method: this.isUpdate ? 'put' : 'post',
        url: 'reb/library/' + (this.isUpdate ? this.book._id : ''),
        data: formdata
      });

      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'book inserted successfully' });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true
        });
      }
    },

    populate() {
      this.Form = {
        name: this.book.name,
        author: this.book.author,
        category: this.book.category,
        coverPage: this.book.coverPage,
        description: this.book.description,
        resourceType: 'text',
        path: this.book.description
      };
    }
  },
  created() {
    if (this.isUpdate) this.populate();
  }
};
</script>

<style></style>
