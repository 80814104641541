<template>
  <div>
    <base-title title="Grade verification">
      <template #actions>
        <div class="flex justify-center items-center space-x-2">
          <base-select
            placeholder=""
            v-model="activeGrade"
            :items="GRADE_OPTIONS"
            searchable
          />
          <base-select
            placeholder=""
            v-model="activeSection"
            :items="SECTION_OPTIONS"
            searchable
          />
        </div>
      </template>
    </base-title>

    <div class="bg-white p-1 px-4 mt-2 rounded-default overflow-x-auto">
      <div>
        <d-table
          #default="{ item }"
          :headers="headers"
          :items="items"
          :hasActions="true"
          :totalItems="totalItems"
          :handlers="handlers"
          :loading="loading"
          code="stu-view"
        >
          <td v-for="(header, i) in headers" :key="i">
            <div class="px-2">
              <div v-if="header.key === 'teacher'">
                <p
                  v-if="item.requested_by && item.requested_by.firstName"
                  class="font-semibold uppercase text-textDarkest hover:underline cursor-pointer"
                >
                  {{ item.requested_by.fullName }}
                </p>
                <p v-else class="text-orange font-semibold">NOT ASSIGNED</p>
              </div>
              <div v-else-if="header.key === 'section'">
                {{ item.section.name }}
              </div>

              <div v-else-if="header.key === 'course'">
                <div class="flex space-x-5 items-center">
                  <icon
                    name="course"
                    class="w-20 h-20 object-cover rounded-lg"
                    alt=""
                  />
                  <div class="flex space-x-3 items-center">
                    <p class="font-semibold uppercase text-textDarkest">
                      {{ item.course.name }}
                    </p>
                    <p>{{ item.course_code }}</p>
                  </div>
                </div>
              </div>
              <div v-else-if="header.key === 'status'">
                <status-chip
                  :label="item['status']"
                  style="max-width: fit-content"
                />
              </div>
              <!-- <div v-else-if="header.key === 'section'">
                {{ item.section ? item.section.name : '--' }}
              </div> -->

              <div v-else class="capitalize">
                {{ getProp(item, header.key) }}
              </div>
            </div>
          </td>
        </d-table>
      </div>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import eventBus from '../../../../eventBus';
import { mapActions, mapGetters } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';

export default {
  components: { DTable },
  data() {
    return {
      activeGrade: '',
      activeSection: '',
      gradeItems: [],
      sectionItems: [],
      totalItems: '',
      PROFILE_PATH,
      items: [],
      loading: false,
      headers: [
        { label: 'Instructor', key: 'teacher' },
        { label: 'Class', key: 'class.name' },
        { label: 'Section', key: 'section' },
        { label: 'Course', key: 'course.name' },
        { label: 'Request Type', key: 'request_type' },
        { label: 'Status', key: 'status' },
      ],
      handlers: [
        {
          label: 'Detail',
          icon: 'eye',
          name: 'detail',
          handler: this.handleDetail,
          fullParam: true,
          // filter: () => this.authCodes.includes('stu-rm'),
        },
        {
          label: 'Verify',
          icon: 'check-circle',
          name: 'verify',
          handler: this.handleVerify,
          idParam: true,
          // filter: () => this.authCodes.includes('stu-rm'),
        },
        {
          label: 'Reject',
          icon: 'times',
          name: 'reject',
          handler: this.handleReject,
          idParam: true,
          // filter: () => this.authCodes.includes('stu-rm'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
    ...mapGetters('main', ['error']),
    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name + ' - ' + grade.enrollment.code,
        value: grade._id,
      }));
    },

    SECTION_OPTIONS() {
      return this.sectionItems.map((section) => ({
        label: section.name,
        value: section._id,
      }));
    },
  },
  watch: {
    activeGrade() {
      this.fetchSections();
    },
    activeSection() {
      this.fetchVerification();
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,
    handleDetail(req) {
      eventBus.$emit('open-modal', {
        modalId: 43,
        componentProps: { id: req._id, cta_id: req.teacherCourse },
      });
    },
    handleReject(id) {
      let componentProps = {
        message: 'Are you sure you want to reject this courses grade.',
        title: 'Reject',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedReject,
        cbParams: { id },
      });
    },
    async proceedReject({ id }) {
      let response = await this.request({
        method: 'put',
        url: 'school/gradeSubmission/reject/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Course grade rejected successfully',
        });
        this.fetchVerification();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    async fetchVerification() {
      this.loading = true;
      const url = `school/gradeSubmission/${this.activeGrade}/${this.activeSection}`;
      let response = await this.request({
        method: 'get',
        url: url,
      });
      if (response && !response.error) {
        this.items = response.record;
        this.totalItems = response.total;
      } else {
        this.items = [];
        this.totalItems = 0;
      }
      this.loading = false;
    },

    handleVerify(id) {
      let componentProps = {
        message: 'Are you sure you want to verify this courses grade.',
        title: 'Verify',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedVerify,
        cbParams: { id },
      });
    },
    async proceedVerify({ id }) {
      let response = await this.request({
        method: 'put',
        url: 'school/gradeSubmission/approve/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Course grade verified successfully',
        });
        await this.fetchVerification();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
        this.activeGrade = response.record[0]._id;
      } else {
        this.gradeItems = [];
        this.activeGrade = '';
      }
    },

    async fetchSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/class/' + this.activeGrade,
      });
      if (response && !response.error) {
        this.sectionItems = response.record;
        this.activeSection = response.record[0]._id;
      } else {
        this.sectionItems = [];
        this.activeSection = '';
      }
    },
  },

  async created() {
    this.working = true;
    await this.fetchGrades();
    this.working = false;
  },
};
</script>

<style></style>
