<template>
  <div class="text-textMedium space-y-4">
    <!-- <div class="flex space-x-2 justify-between border-b border-dgray pb-2">
      <div class="font-semibold">parents</div>
      <div class="text-15">Found {{ parents.length }} search result{{ parents.length > 1 ? 's' : '' }}</div>
    </div> -->
    <!-- TODO circule progress -->
    <loader v-if="loading"></loader>
    
    <div v-else-if="parents.length > 0" class="space-y-1">
      <parent v-for="parent in parents" :key="parent._id" :parent="parent" />
    </div>
    <div v-else>
      <div v-if="initial" class="space-y-1">
        <file-search class="h-48" />
        <div class="text-center">Search for parents</div>
      </div>
      <div v-else>
        <div class="font-medium">Oops! No parent</div>
        <div class="text-sm">Oops no parent can be found with the given search keyword and the filter criteria.</div>
      </div>
    </div>
  </div>
</template>

<script>
import Parent from './Parent.vue'
import FileSearch from '../../shared/svgs/FileSearch.vue'
export default {
  components: { Parent, FileSearch },
  props: ['parents', 'loading', 'initial'],
}
</script>

<style></style>
