<template>
  <div>
    <div>
      <d-table
        #default="{ item }"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :hasActions="true"
        :handlers="handlers"
        :loading="loading"
        :buttons="TBUTTONS"
        @assign_homeroom="handleAssignHomeroom"
        searchUrl="tableSearch/course-list"
        :searchProps="searchProps"
        :hideColumnSort="true"
      >
        <td v-for="(header, i) in headers" :key="i">
          <div class="px-2">
            <div v-if="header.key === 'course'">
              <div class="flex space-x-5 items-center">
                <icon
                  name="course"
                  class="w-20 h-20 object-cover rounded-lg"
                  alt=""
                />
                <div class="flex space-x-3 items-center">
                  <p class="font-semibold uppercase text-textDarkest">
                    {{ item.course.name }}
                  </p>
                  <p>{{ item.course_code }}</p>
                </div>
              </div>
            </div>
            <div v-else-if="header.key === 'teacher'">
              <div v-if="item.teacher.length > 0">
                <div
                  class="inline-flex"
                  v-for="(teacher, i) in item.teacher"
                  :key="i"
                >
                  <p
                    v-if="teacher && teacher.firstName"
                    class="font-semibold uppercase text-textDarkest hover:underline cursor-pointer"
                  >
                    {{ i == 1 ? ' , ' : '' }} {{ teacher.firstName }}
                    {{ teacher.middleName }}
                  </p>
                </div>
              </div>
              <p v-else class="text-orange font-semibold">NOT ASSIGNED</p>
            </div>

            <div v-else-if="header.key === 'course.status'">
              <status-chip
                :label="getProp(item, 'course.status')"
                style="max-width: fit-content"
              />
            </div>
            <div v-else>
              {{ getProp(item, header.key) }}
            </div>
          </div>
        </td>
      </d-table>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import { IMAGES_PATH } from '@/config/config.js';
import eventBus from '../../../../../eventBus';
export default {
  components: { DTable },
  props: ['ay_id'],
  data() {
    return {
      items: [],
      IMAGES_PATH,
      Form: {},
      totalItems: '',
      loading: false,
      headers: [
        { label: 'Course', key: 'course' },
        { label: 'Teacher', key: 'teacher' },
        // { label: 'Phone Number', key: 'phoneNumber' },
        { label: 'Status', key: 'course.status' },
      ],
      TBUTTONS: [
        {
          label: 'Assign Homeroom Teacher',
          value: 'assign_homeroom',
          icon: 'plus',
        },
      ],
      handlers: [
        // {
        //   label: 'Course Detail',
        //   icon: 'eye',
        //   handler: this.handleDetail,
        //   idParam: true,
        // },
        {
          label: 'Link Teacher',
          icon: 'link',
          handler: this.handleLinkTeacher,
          idParam: true,
          filter: () => this.authCodes.includes('encor-lnk'),
        },
        {
          label: 'Unlink Teachers',
          icon: 'list-alt',
          handler: this.showTeachersList,
          idParam: true,
          filter: () => this.authCodes.includes('encor-unlnk'),
        },
        {
          label: 'Set Sessions',
          icon: 'cog',
          handler: this.handleConfigCourse,
          fullParam: true,
          filter: () => this.authCodes.includes('encor-ses'),
        },
        {
          label: 'Remove Course',
          icon: 'times',
          handler: this.handleRemoveCourse,
          idParam: true,
          filter: () => this.authCodes.includes('encor-rm'),
        },
      ],

      searchProps: {
        searchUrl: 'school/teacherCourse/table-search/',
        searchId: '',
      },
    };
  },
  computed: {
    ...mapGetters(['authCodes']),
  },
  watch: {
    ay_id() {
      this.fetchItems();
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,

    async handleConfigCourse(param) {
      let componentProps = {
        updateSessions: param.sessions,
        ctaId: param._id,
      };
      eventBus.$emit('open-modal', {
        modalId: 25,
        cb: this.fetchItems,
        componentProps,
      });
    },

    async fetchItems() {
      this.loading = true;
      this.items = [];
      let response = await this.request({
        method: 'get',
        url: 'school/teacherCourse/ay/' + this.ay_id,
      });
      if (response && !response.error) {
        this.items = response.record;
        this.totalItems = response.total;
      } else {
        this.items = [];
      }
      this.loading = false;
    },

    /// show the detail of a course
    handleDetail(id) {
      /// TODO: GET COURSE DETAIL
      this.$router.push({ name: 'course-detail', params: { id } });
    },

    // unlink the course from the section
    handleRemoveCourse(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the item',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedRemoveCourse,
        cbParams: { id },
      });
    },

    /// proceed unlinking the section
    async proceedRemoveCourse({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/teacherCourse/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Course Removed Successfully',
        });
        this.fetchItems();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    /// start linking the teacher to the course
    handleLinkTeacher(cta_id) {
      let componentProps = {
        usertype: 'Teacher',
      };
      eventBus.$emit('open-modal', {
        modalId: 7,
        cb: this.onTeacherSelected,
        cbParams: { cta_id },
        componentProps,
      });
    },

    /// TODO: when the teacher is selected link it to the selected section
    async onTeacherSelected({ response: { customer: teacher }, cta_id }) {
      let success = await this.linkTeacherCourse(cta_id, teacher);
      if (success) this.fetchItems();
    },

    /// link the teacher to the course
    async linkTeacherCourse(cta_id, teacher) {
      let response = await this.request({
        method: 'put',
        url: 'school/teacherCourse/' + cta_id,
        data: {
          teacher: teacher._id,
        },
      });

      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'teacher assigned successfully',
        });
        return true;
      } else {
        eventBus.$emit('open-toast', {
          messge: 'error assigning teacher',
          error: true,
        });
        return false;
      }
    },

    handleAssignHomeroom() {
      let componentProps = {
        usertype: 'Teacher',
      };
      eventBus.$emit('open-modal', {
        modalId: 7,
        cb: this.onHomeroomSelected,
        componentProps,
      });
    },

    async onHomeroomSelected({ response: { customer: teacher } }) {
      let success = await this.linkHomeroom(teacher);
      if (success) this.$parent.fetchAcademicYear();
    },

    /// link the teacher to the course
    async linkHomeroom(teacher) {
      let response = await this.request({
        method: 'put',
        url: 'school/academicYear/assign-homeroom/' + this.ay_id,
        data: {
          homeroom: teacher._id,
        },
      });

      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Homeroom Teacher assigned successfully',
        });
        return true;
      } else {
        eventBus.$emit('open-toast', {
          messge: 'error assigning homeroom teacher',
          error: true,
        });
        return false;
      }
    },

    /// teacher list
    async showTeachersList(id) {
      let componentProps = {
        cta_id: id,
      };
      eventBus.$emit('open-modal', {
        modalId: 13,
        componentProps,
        cb: this.fetchItems,
      });
    },

    /// unlink the teacher from the course
    async handleRemoveTeacher() {
      let response = await this.request({ method: 'put', url: '' });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'teacher removed successfully',
        });
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    /// select the course from the popup
    // handleSelectCourse() {
    //   eventBus.$emit('open-modal', {
    //     modalId: 11,
    //     cb: this.onCourseSelected,
    //   });
    // },

    /// when the user is selected from the popup
    async onCourseSelected({ response: { customer: course } }) {
      /// TODO: link the course to the section

      let success = await this.linkCourseSection(course);
      if (success) this.fetchItems();
    },

    /// assign the course to the section
    async linkCourseSection(course) {
      /// TODO: return true or false

      let response = await this.request({
        method: 'post',
        url: 'school/teacherCourse/',
        data: {
          academic_year: this.ay_id,
          class: this.grade_id,
          section: this.section_id,
          course: course._id,
          school: this.school_id,
        },
      });

      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'course linked successfully' });
        return true;
      } else {
        eventBus.$emit('open-toast', {
          messge: 'error linking course',
          error: true,
        });
        return false;
      }
    },
  },

  created() {
    this.fetchItems();
  },
};
</script>
<style></style>
