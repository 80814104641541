<template>
  <div class=" fixed top-0 left-0 right-0 bottom-0 z-50 bg-white overflow-auto">
    <single-transcript-print />
  </div>
</template>

<script>
import SingleTranscriptPrint from './SingleTranscriptPrint.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    SingleTranscriptPrint
  },
  computed: {
    ...mapGetters('printer', [])
  }
};
</script>

<style></style>
