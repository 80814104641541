<template>
  <div class="space-y-2">
    <base-title class="uppercase" :title="`RE Exam`">
      <template #actions>
        <div class="flex justify-center items-center space-x-2">
          <base-button
            label="Take RE Exam"
            icon="check-circle"
            v-if="selectedItems.length > 0"
            @click="handleEditStatus"
            class="flex-none rounded-lg"
          />
          <base-select
            :items="PROGRAM_OPTIONS"
            v-model="activeProgram"
            searchable
            class=""
          />
          <base-select
            :items="DEPARTMENT_OPTIONS"
            v-model="activeDepartment"
            searchable
            class=""
          />
        </div>
      </template>
    </base-title>

    <BaseSwitch
      :items="statusOptions"
      v-model="status"
      class="rounded-lg"
      padding="small"
    />

    <div class="bg-white p-1 px-4 mt-2">
      <div>
        <d-table
          #default="{ item }"
          :headers="headers"
          :items="items"
          :hasActions="false"
          :handlers="handlers"
          :loading="loading"
          :searchProps="searchProps"
          code="stu-view"
          :multiSelect="true"
          @selectedItemIDs="selectedItems1($event)"
        >
          <td v-for="(header, i) in headers" :key="i">
            <div class="px-2">
              <div v-if="header.key === 'profilePic'">
                <div class="flex space-x-5 items-center">
                  <div
                    class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center"
                  >
                    <img
                      v-if="item.student.profilePic"
                      :src="PROFILE_PATH + item.student.profilePic"
                      v-viewer="{ inline: false, movable: true }"
                      class="w-full h-full object-cover rounded-full cursor-pointer"
                      alt=""
                      crossorigin="anonymous"
                    />
                    <icon
                      v-else
                      :size="18"
                      name="user"
                      class="w-full h-full"
                    ></icon>
                  </div>
                  <div>
                    <p
                      class="cursor-pointer hover:underline font-semibold text-textDarkest uppercase"
                      @click="handleDetail(item.id)"
                    >
                      {{ item.student.fullName }}
                    </p>
                    <p class="text-textLight">{{ item.student.username }}</p>
                  </div>
                </div>
              </div>
              <div v-else-if="header.key === 'userId'">
                {{ item.student.userId }}
              </div>
              <div v-else-if="header.key === 'course' && item.course">
                {{ item.course.name }}
              </div>
              <!-- <div class="capitalize" v-else-if="header.key === 'teacher'">
                {{ item.teacherCourse.teacher.fullName }}
              </div> -->
              <div v-else-if="header.key === 'program'">
                {{ item['program'] ? item['program']['name'] : 'NA' }}
              </div>
              <div v-else-if="header.key === 'department'">
                {{ item['department'] ? item['department']['name'] : 'NA' }}
              </div>

              <div v-else-if="header.key === 'phoneNumber'">
                {{ item['phoneNumber'].join(' | ') }}
              </div>
              <div v-else-if="header.key === 'school'">
                {{ item['school'] ? item['school']['name'] : 'NA' }}
              </div>
              <div v-else-if="header.key === 'class'">
                {{ item.class ? item.class.name : '--' }}
              </div>
              <div v-else-if="header.key === 'section'">
                {{ item.section ? item.section.name : '--' }}
              </div>
              <!-- <div v-else-if="header.key === 'status'">
                <status-chip
                  :label="item['status']"
                  style="max-width: fit-content"
                />
              </div> -->
              <div v-else class="capitalize">
                {{ getProp(item, header.key) }}
              </div>
            </div>
          </td>
        </d-table>
      </div>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import eventBus from '../../../../eventBus';
import { mapActions, mapGetters } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';
import BaseSwitch from '../../../../components/base/BaseSwitch.vue';

export default {
  components: { DTable, BaseSwitch },
  data() {
    return {
      statusOptions: [
        { label: 'Pending', value: 'pending' },
        { label: 'On process', value: 'process' },
        { label: 'Completed', value: 'completed' },
      ],
      status: 'pending',
      selectedItems: [],
      activeProgram: '',
      activeDepartment: '',
      programItems: [],
      departmentItems: [],
      PROFILE_PATH,
      items: [],
      module: 'student',
      loading: false,
      headers: [
        { label: 'User', key: 'profilePic' },
        { label: 'Sch Id', key: 'schoolId' },
        // { label: 'Instructor', key: 'teacher' },
        { label: 'Course', key: 'course' },
        { label: 'Program', key: 'program' },
        { label: 'Department', key: 'department' },
        { label: 'Class', key: 'class' },
        { label: 'Section', key: 'section' },
        { label: 'Status', key: 'status' },
      ],
      handlers: [
        // {
        //   label: 'Edit Status',
        //   icon: 'edit',
        //   handler: this.handleEditStatus,
        //   idParam: true,
        //   // filter: () => this.authCodes.includes('enrst-rm'),
        // },
      ],
      searchProps: {
        searchUrl: 'student/studentProfile/table-search',
      },
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
    ...mapGetters('main', ['error']),
    PROGRAM_OPTIONS() {
      return this.programItems.map((program) => ({
        label: program.name,
        value: program._id,
      }));
    },
    DEPARTMENT_OPTIONS() {
      return this.departmentItems.map((department) => ({
        label: department.name,
        value: department._id,
      }));
    },
  },
  watch: {
    activeProgram() {
      this.handleFetchDepartment();
    },
    activeDepartment() {
      this.fetchStudents();
    },

    status: {
      deep: true,
      immediate: false,
      handler: function () {
        this.fetchStudents();
      },
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,
    // async handleEditStatus(){
    //   eventBus.$emit('open-modal', {
    //     modalId: 51,
    //     componentProps: {students: this.selectedStudents},
    //     cd: this.fetchStudents()
    //   })
    // },
    selectedItems1(e) {
      this.selectedItems = e;
    },
    handleEditStatus() {
      if (this.selectedItems.length == 0)
        return eventBus.$emit('open-toast', {
          error: true,
          message: 'You have to select students first.',
        });
      let componentProps = {
        message: 'Are you sure those students have to take RE Exam.',
        title: 'RE Exam',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedREExam,
      });
    },
    async proceedREExam() {
      let response = await this.request({
        method: 'post',
        url: 'school/reexam/',
        data: { students: this.selectedItems },
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'RE Exam successfully',
        });
        this.fetchStudents();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    async fetchStudents() {
      this.loading = true;

      let base_url = 'school/reexam/';
      let rear = '';
      if (this.status == 'pending') rear = 'not-taken/';
      else if (this.status == 'process') rear = 'on-progress/';
      else if (this.status == 'completed') rear = 'taken/';

      let url = `${base_url}${rear}${this.activeProgram}/${this.activeDepartment}`;

      let response = await this.request({
        method: 'get',
        url: url,
      });
      if (response && !response.error) this.items = response.record;
      else this.items = [];
      this.loading = false;
    },
    async handleFetchProgram() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'school/official/allowed_programs/Reexam',
      });
      if (response && !response.error) {
        this.programItems = response.record;
        if (response.record.length > 0) {
          this.activeProgram = response.record[0]._id;
        }
      } else {
        this.programItems = [];
      }
      this.loading = false;
    },
    async handleFetchDepartment() {
      this.loading = true;
      this.activeDepartment = '';
      let response = await this.request({
        method: 'get',
        url: 'school/official/allowed_depts/Reexam/' + this.activeProgram,
      });
      if (response && !response.error) {
        this.departmentItems = response.record;
        this.activeDepartment = response.record[0]._id;
      } else {
        this.departmentItems = [];
      }
      this.loading = false;
    },
  },

  async created() {
    await this.handleFetchProgram();
  },
};
</script>

<style></style>
