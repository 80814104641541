<template>
  <div class="loader flex space-x-1">
    <div
      class="w-1 h-10 bg-primary grow-loader"
      v-for="i in [0, 1, 2, 3, 4]"
      :key="i"
      :style="{ animationDelay: i * 0.125 + 's' }"
    ></div>
  </div>
</template>

<script>
export default {}
</script>

<style>
.grow-loader {
  animation: grow-loader 1s infinite;
}
@keyframes grow-loader {
  0% {
    transform: scaleY(0.5);
  }
  50% {
    transform: scaleY(1.5);
  }
  100% {
    transform: scaleY(0.5);
  }
}
</style>
