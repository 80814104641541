import Overview from '@/views/protected/school_management/Overview.vue';
import SchoolList from '@/views/protected/school_management/List.vue';
import Create from '@/views/protected/school_management/Create.vue';
import MySchool from '@/views/protected/school_management/MySchool.vue';
import SchoolCourses from '@/views/protected/school_courses/SchoolCourses.vue';

export default [
  {
    path: 'overview',
    name: 'school-overview',
    component: Overview,
  },
  {
    path: 'list',
    name: 'school-list',
    component: SchoolList,
  },
  {
    path: 'create',
    name: 'school-create',
    component: Create,
  },
  {
    path: 'update/:id',
    name: 'school-update',
    component: Create,
    props: true,
  },
  {
    path: 'my-school',
    name: 'my-school',
    component: MySchool,
    props: true,
  },
  {
    path: 'school-courses',
    name: 'school-courses',
    component: SchoolCourses,
  },
];
