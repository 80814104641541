<template>
  <div class="back flex items-center justify-center min-h-screen">
    <div class="bg-white grid md:grid-cols-2 gap-2 rounded-xl shadow-lg p-3">
      <div class="flex flex-col justify-center items-center gap-4 py-4">
        <div class="w-48">
          <!-- <img src="@/assets/henonLogo.png" class="" /> -->
          <!-- <img src="@/assets/logolion.jpg" class="rounded-full" /> -->
          <img src="@/assets/wegagen.png" class="rounded-full" />
          <!-- <img src="@/assets/cbe.png" class="rounded-full" /> -->
        </div>
        <!-- <div class="text-primary text-2xl font-bold uppercase">
          Lion International Bank
        </div> -->
        <!-- <div class=" text-primary text-2xl font-bold uppercase">Wegagen International Bank</div> -->
        <!-- <div class="text-primary text-2xl font-bold uppercase">
          Commercial Bank of Ethiopia
        </div> -->
        <div class="text-secondary text-2xl font-bold uppercase">
          Henon School Management
        </div>
      </div>
      <div
        class="flex flex-col justify-center items-center p-7 md:border-l-1 border-gray-200"
      >
        <div class="flex gap-4 justify-center items-center">
          <div class="">
            <img src="@/assets/henonLogo.png" class="h-10" />
          </div>
          <div class="text-xl uppercase text-textDarkest">
            Henon KG-12 Management
          </div>
        </div>
        <div
          class="flex flex-col gap-5 p-2 py-5 rounded-xl xl:w-112 lg:w-96 md:w-80 sm:w-80"
        >
          <div class="">
            <div class="text-sm font-semibold">Login to continue</div>
          </div>
          <div class="flex flex-col gap-5">
            <div v-if="error" class="text-sm" style="color: #ec3118">
              {{ error }}
            </div>
            <base-input
              label="Username"
              placeholder="Enter username"
              v-model="username"
              :errors="errors.username"
              class="text-sm"
            />
            <base-input
              :type="inputType"
              label="Password"
              placeholder="Enter password"
              v-model="password"
              :errors="errors.password"
              @enter="handleLogin"
              class="text-sm"
            />
            <base-check-box
              label="Show Password"
              :selected="selected"
              @toggle="showPassword"
            />
            <base-button
              label="Sign in"
              class="uppercase rounded py-2"
              :working="working"
              @click="handleLogin"
            />
            <div class="text-center text-sm">
              <span>Copyright © 2024 Powered by </span>
              <span class="font-bold"> Henon EdTech</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validation } from './../../mixins/validationMixin.js';
import { navService } from '@/services/storageService';
import { navs } from '@/config/constants';

export default {
  mixins: [validation],
  data() {
    return {
      username: '',
      password: '',
      working: false,
      errors: {},
      meta: [
        { key: 'username', label: 'USERNAME' },
        { key: 'password', label: 'PASSWORD' },
      ],
      initial: true,
      selected: false,
      inputType: 'password',
      navs1: {
        'school-setup': [
          {
            label: 'Grades',
            name: 'grade',
            icon: 'atom',
            slug: 'cls',
            code: 'sch-gr',
            path: '/school/grade-list',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Sections',
            name: 'section',
            icon: 'chalkboard-user',
            slug: 'cls',
            code: 'sch-sec',
            path: '/school/section-list',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Academic Years',
            name: 'academic-year',
            icon: 'calendar',
            slug: 'str',
            code: 'sch-aca',
            path: '/school/academic-year',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Subjects',
            name: 'subjects',
            icon: 'list-alt',
            slug: 'cor',
            code: 'sch-sub',
            path: '/school/subject',
            allowed_for: ['super_admin', 'registrar'],
          },
          // {
          //   icon: 'school',
          //   name: 'my-school',
          //   slug: 'sch',
          //   label: 'My School',
          //   path: '/school/my-school',
          //   allowed_for: [],
          // },
        ],

        admission: [
          {
            label: 'Students',
            name: 'students',
            icon: 'user-graduate',
            slug: 'stu',
            code: 'adm-stu',
            path: '/registrations/student',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Teachers',
            name: 'teachers',
            icon: 'chalkboard-user',
            slug: 'tr',
            code: 'adm-thr',
            path: '/registrations/teacher',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'School Officials',
            name: 'school-official',
            icon: 'school',
            slug: 'sco',
            code: 'adm-stf',
            path: '/registrations/school-official',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Parents',
            name: 'parents',
            icon: 'family-1',
            slug: 'par',
            code: 'adm-par',
            path: '/registrations/parent',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Migration',
            name: 'parents',
            icon: 'file-upload',
            slug: 'par',
            code: 'adm-stu',
            path: '/registrations/import-students',
            allowed_for: ['super_admin', 'registrar'],
          },
          // { icon: 'user', label: 'Officials', path: '/registrations/official' },
        ],

        enrollment: [
          {
            label: 'Student Enrollment',
            name: 'academic-section',
            icon: 'compress',
            slug: 'cls',
            code: 'enrl-stu',
            path: '/enrollment/academic-section',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Course Enrollment',
            name: 'teacher-course',
            icon: 'signature',
            slug: 'cls',
            code: 'enrl-cor',
            path: '/enrollment/teacher-course',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Mark List',
            name: 'mark-list',
            icon: 'address-book',
            slug: 'cls',
            code: 'aca-mkl',
            path: '/enrollment/mark-list',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        'time-table': [
          {
            label: 'Calendar',
            icon: 'calendar',
            slug: 'cal',
            code: 'tmt-cal',
            path: '/schedule/calendar',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Session',
            icon: 'gear',
            slug: 'scd',
            code: 'tmt-ses',
            path: '/schedule/session',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Schedule',
            icon: 'clock',
            slug: 'scd',
            code: 'tmt-sdl',
            path: '/schedule/period',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        attendance: [
          {
            label: 'Overview',
            icon: 'meter',
            slug: 'att',
            code: 'att-da',
            path: '/attendance/dashboard',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Attendance',
            icon: 'attendance-1',
            slug: 'att',
            code: 'att-att',
            path: '/attendance/detail',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        fee: [
          // {
          //   icon: 'atom',
          //   label: 'Group',
          //   slug: 'grp',
          //   path: '/fee/group',
          //   allowed_for: ['super_admin', 'registrar'],
          // },
          {
            icon: 'info-circle',
            label: 'Payment Type',
            slug: 'pty',
            code: 'fee-pt',
            path: '/fee/payment-types',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'dollar-sign',
            label: 'Payment Group',
            slug: 'pa',
            code: 'fee-pg',
            path: '/fee/payment-group',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'user',
            label: 'Payer',
            slug: 'pyr',
            code: 'fee-pyr',
            path: '/fee/payers',
            allowed_for: ['super_admin', 'registrar'],
          },

          {
            icon: 'exchange-alt',
            label: 'Discount',
            slug: 'dsc',
            code: 'fee-dis',
            path: '/fee/discount',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'cog',
            label: 'Generate Bill',
            slug: 'dsc',
            code: 'fee-gen',
            path: '/fee/generate-bill',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        'shl-management': [
          {
            label: 'Academic Year',
            icon: 'user',
            slug: 'acy',
            code: 'adm-stf',
            path: '/shl-management/academic-year',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Grade',
            icon: 'user',
            slug: 'cls',
            path: '/shl-management/grade-list',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Section',
            icon: 'user',
            slug: 'sec',
            path: '/shl-management/section-list',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        grading: [
          {
            icon: 'meter',
            label: 'Overview',
            slug: 'gr',
            path: '/grading/overview',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        settings: [
          {
            icon: 'gear',
            name: 'school-pref',
            label: 'School Preferences',
            slug: 'rl',
            code: 'set-pre',
            path: '/settings/school-pref',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'chart-bar',
            name: 'role',
            slug: 'rl',
            code: 'set-rl',
            label: 'Roles',
            path: '/settings/roles',
            allowed_for: ['super_admin', 'shl_registrar'],
          },
          {
            icon: 'key',
            name: 'permissions',
            slug: 'rl',
            code: 'set-pm',
            label: 'Permissions',
            path: '/settings/permissions',
            allowed_for: ['super_admin', 'shl_registrar'],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['error', 'authCodes', 'authModules']),
  },
  methods: {
    ...mapActions(['signin', 'setAuth']),
    ...mapActions('main', ['request']),
    navsFiltered() {
      return navs.filter((nav) => this.authModules.includes(nav.code));
    },
    async getAuth() {
      let res = await this.request({
        url: `school/module/my-auth`,
        method: 'get',
      });
      let user = res && res.item ? res.item : {};
      this.setAuth(user);
    },
    async handleLogin() {
      this.initial = false;
      if (!this.isValid()) return;
      this.working = true;
      let response = await this.signin({
        username: this.username,
        password: this.password,
      });

      if (response) {
        // navService.saveActiveNav('dashboard');
        // window.location.reload();
        // navService.saveActiveNav('Dashboard')

        // if(authNavs.length){
        //   authNavs.map(v=> {
        //     this.navs1[v.label.toLowerCase()]
        //   })
        // }else {
        //   this.$router.push('/dashboard')
        // }

        // let active =
        // this.navs1[this.activeNav.toLowerCase()].filter(nav => this.authCategories.find(cat => cat == nav.code))
        // await this.getAuth();
        let authNavs = this.navsFiltered();
        console.log('before loco', authNavs, this.authModules);
        if (authNavs.length) {
          console.log('from nas', authNavs[0].to);
          navService.saveActiveNav(authNavs[0].label);
          this.$router.push(`/${authNavs[0].to}`);
        } else {
          console.log('loco');
          navService.saveActiveNav('Dashboard');
          this.$router.push('/dashboard');
        }

        // if (this.authCodes.includes('da-view')) {
        //   navService.saveActiveNav('Dashboard')
        //   this.$router.push('/dashboard')
        // } else if (this.authCodes.includes('gr-view')) {
        //   navService.saveActiveNav('School-setup')
        //   this.$router.push('/school')
        // } else if (this.authCodes.includes('stu-view')) {
        //   navService.saveActiveNav('Admission')
        //   this.$router.push('/registrations')
        // } else if (this.authCodes.includes('enrst-view')) {
        //   navService.saveActiveNav('Enrollment')
        //   this.$router.push('/enrollment')
        // } else if (this.authCodes.includes('cal-view')) {
        //   navService.saveActiveNav('Time-table')
        //   this.$router.push('/schedule')
        // } else if (this.authCodes.includes('daatt-view')) {
        //   navService.saveActiveNav('Attendance')
        //   this.$router.push('/attendance')
        // } else if (this.authCodes.includes('fee-view')) {
        //   navService.saveActiveNav('Fee')
        //   this.$router.push('/fee')
        // } else {
        //   this.$router.push('/dashboard')
        // }
        // window.location.reload()
        // window.location.reload();
      } else {
        this.password = '';
        this.initial = true;
      }
      this.working = false;
    },
    showPassword() {
      if (this.inputType === 'password') {
        this.selected = true;
        this.inputType = 'text';
      } else {
        this.selected = false;
        this.inputType = 'password';
      }
    },
  },
  watch: {
    username() {
      if (!this.initial) this.isValid();
    },
    password() {
      if (!this.initial) this.isValid();
    },
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },
};
</script>

<style scoped>
.back {
  background-image: repeating-linear-gradient(
      135deg,
      rgba(189, 189, 189, 0.1) 0px,
      rgba(189, 189, 189, 0.1) 2px,
      transparent 2px,
      transparent 4px
    ),
    linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}
</style>
