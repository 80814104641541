<template>
  <div class="grid grid-cols-6 gap-3 p-8">
    <div class="space-y-1 col-span-5">
      <d-table
        #default="{ item }"
        :headers="headers"
        :items="bills"
        :loading="loading"
        :hasActions="true"
        :totalItems="bills.length"
        :hideColumnSort="true"
      >
        <td v-for="(header, index) in headers" :key="index">
          <div v-if="header.key === 'profilePic'">
            <div class="flex gap-3 items-center">
              <div
                class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center"
              >
                <img
                  v-if="item.profilePic"
                  :src="PROFILE_PATH + item.profilePic"
                  v-viewer="{ inline: false, movable: true }"
                  class="w-full h-full object-cover rounded-full"
                  alt=""
                  crossorigin="anonymous"
                />
                <icon
                  v-else
                  :size="18"
                  name="user"
                  class="w-full h-full"
                ></icon>
              </div>
              <div @click="displayUserDetail(item._id)">
                <p
                  class="text font-semibold text-textDarkest uppercase cursor-pointer hover:underline"
                >
                  {{ item.firstName }} {{ item.middleName }}
                </p>
              </div>
            </div>
          </div>

          <!-- <div v-else-if="header.key === 'phoneNumber'">
              {{ item['phoneNumber'].join(' | ') }}
            </div> -->

          <div v-else-if="header.key === 'payer'">
            {{ `${item['payer']} (${item['payer_percent']}%)` }}
          </div>

          <div v-else-if="header.key === 'discount'">
            {{
              item['discount'] +
              (item['discount_type'] == 'rate' ? '%' : ' ETB')
            }}
          </div>

          <div v-else-if="header.key === 'total_bill'">
            <base-input
              type="number"
              v-model="item.total_bill"
              :errors="errors.total_bill"
              class="w-24"
            />
            <!-- @enter="addRow(index)" -->
          </div>
          <div
            v-else-if="header.key === 'action'"
            class="flex space-x-4 items-start"
          >
            <div
              class="p-2 bg-brighterGray text-error hover:bg-error hover:text-white cursor-pointer active:bg-danger rounded-full"
              @click="removeRow(item.userId)"
            >
              <icon name="times" :size="13" />
            </div>
          </div>
          <div
            v-else
            class="flex space-x-4 items-center text-center h-full px-1"
          >
            {{ getProp(item, header.key) }}
            <!-- <base-input
                v-model="item[header.key]"
                :errors="errors[index][header.key]"
                bordered
                padding="small"
                class="flex-auto"
                @enter="addRow(index)"
              /> -->
          </div>
        </td>
      </d-table>
      <div class="flex space-x-2 justify-end">
        <input
          v-if="fileSelector"
          type="file"
          class="hidden"
          ref="csv-file-input"
          @input="handleFileSelect"
        />
        <base-button
          v-if="false"
          :label="importing ? 'importing' : 'import csv'"
          :primary="false"
          class="text-sm px-2 rounded"
          :class="{ 'bg-brightGray hover:text-primary': !importing }"
          @click="openFileSelector"
        />
        <base-button
          label="remove all students"
          :primary="false"
          class="bg-gray-100"
          icon="times"
          @click="removeAllRows"
        />
        <base-button
          label="add student"
          :primary="false"
          class="bg-gray-100"
          icon="plus"
          @click="handleSelectStudent()"
        />
        <!-- @click="addRow(payments.length - 1)" -->
      </div>
    </div>

    <div class="uppercase border-l-1 border-dgray">
      <div class="flex flex-col items-center p-2">
        <div class="text-textMedium text-2xl font-medium">
          {{ totalCustomers | formateNumber }}
        </div>
        <div class="text-textLight text-sm">students</div>
      </div>
      <br />
      <div class="flex flex-col items-center p-2">
        <div class="text-textMedium text-2xl font-medium">
          {{ totalAmount | formateNumber }}
        </div>
        <div class="text-textLight text-sm">total amount</div>
      </div>
      <div class="flex justify-end items-center flex-wrap">
        <base-button
          v-if="false"
          label="order bill"
          icon="plus"
          class="px-2 text-sm uppercase rounded"
          @click="preUploadPayment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VuePapaParse from 'vue-papa-parse';
import Vue from 'vue';
Vue.use(VuePapaParse);
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import DTable from '@/components/table/DTable.vue';
import eventBus from '@/eventBus';
import { PROFILE_PATH } from '@/config/config.js';
import { getPropByString } from '../../../utils';
// import eventBus from '@/eventBus';
export default {
  components: { DTable },
  mixins: [validation],
  data() {
    return {
      PROFILE_PATH,
      allValid: false,
      payments: [],
      errors: [],
      importing: false,
      unwathcCallbacks: [],
      headers: [
        { label: 'User', key: 'profilePic' },
        { label: 'UserId', key: 'userId' },
        { label: 'Gender', key: 'gender' },
        { label: 'Grade', key: 'grade' },
        // { label: 'Phone Number', key: 'phoneNumber' },
        { label: 'Amount', key: 'amount' },
        { label: 'Payer', key: 'payer' },
        { label: 'Discount', key: 'discount' },
        { label: 'Bill amount', key: 'total_bill' },
        { label: '', key: 'action' },
      ],
      fileSelector: false,
    };
  },
  filters: {
    formateNumber(number) {
      return number.toLocaleString();
    },
  },
  computed: {
    ...mapGetters('main', ['berror']),
    ...mapGetters('fee', ['bills', 'loading', 'payment_type', 'amount']),

    formTemplate() {
      let template = {
        cust_util_id: undefined,
      };
      template['amount'] = 0;
      return template;
    },

    totalAmount() {
      return this.bills.reduce(
        (acc, { total_bill }) => acc + Number(total_bill),
        0
      );
    },
    totalCustomers() {
      return this.bills.length;
    },
    customers() {
      return this.bills.map((payee) =>
        Object.keys(this.formTemplate).reduce(
          (acc, key) => ({
            ...acc,
            [key]: key === 'amount' ? +payee[key] : payee[key],
          }),
          {}
        )
      );
    },
  },
  watch: {
    initial: {
      immediate: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      },
    },
    bills: {
      immediate: false,
      deep: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      },
    },
    headers() {
      this.validationHandler();
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    ...mapActions('fee', [
      'setBills',
      'addBills',
      'clearBills',
      'setLoader',
      'clearAmount',
    ]),
    displayUserDetail(id) {
      let componentProps = {
        id,
        type: 'student',
        url: 'student/studentProfile/',
      };
      eventBus.$emit('open-modal', {
        modalId: 39,
        componentProps,
      });
    },

    getProp: getPropByString,
    validationHandler() {
      this.resetErrors(0);
      if (!this.initial)
        this.payments.forEach((payee, i) =>
          Object.keys(this.formTemplate).forEach((key) => {
            if (!payee[key])
              this.errors[i][key] = [
                this.headers.find((header) => header.key === key).label +
                  ' is required',
              ];
            else this.errors[i][key] = [];
          })
        );
    },

    addRow(index) {
      this.bills = [
        ...this.bills.slice(0, index + 1),
        { ...this.formTemplate },
        ...this.bills.slice(index + 1).map(({ ...rest }) => ({ ...rest })),
      ];
    },
    removeRow(userId) {
      const index = this.bills.findIndex((bill) => bill.userId === userId); // Find the index of the bill with the specified ID
      if (index >= 0)
        this.setBills([
          ...this.bills.slice(0, index),
          ...this.bills.slice(index + 1).map(({ ...rest }) => ({ ...rest })),
        ]);
    },
    removeAllRows() {
      this.clearBills();
      this.errors = [];
    },
    importCsv(file) {
      this.importing = true;
      this.$papa.parse(file, {
        skipEmptyLines: true,
        complete: ({ data }) => {
          this.importing = false;
          // let lastIndex = this.payments.length;
          data.slice(1).forEach((bill) => {
            const cust_bill = Object.keys(this.formTemplate).reduce(
              (customer, key, i) => ({ ...customer, [key]: bill[i] }),
              {}
            );
            data.total_amount = Number(data.total_amount);
            this.addBills([cust_bill]);
          });
          this.resetFile();
        },
      });
    },
    handleFileSelect() {
      let file = this.$refs['csv-file-input'].files[0];
      this.importCsv(file);
    },
    openFileSelector() {
      this.fileSelector = true;
      this.$nextTick(() => {
        this.$refs['csv-file-input'].click();
      });
    },
    resetFile() {
      this.fileSelector = false;
    },
    resetErrors(startIndex) {
      if (startIndex < 0) startIndex = 0;
      this.bills
        .slice(startIndex)
        .forEach(
          (payee, i) =>
            (this.errors[i + startIndex] = Object.keys(
              this.formTemplate
            ).reduce((acc, key) => ({ ...acc, [key]: [] }), {}))
        );
    },
    resetForm() {
      this.clearBills();
    },

    handleSelectStudent() {
      let componentProps = {
        usertype: 'Student',
        multiple: true,
      };
      eventBus.$emit('open-modal', {
        modalId: 37,
        componentProps,
        cb: this.handleAddStudentsBill,
      });
    },

    async handleAddStudentsBill({ response: { customer: students } }) {
      // console.log('amount: ', this.amount);
      console.log('hello');

      this.setLoader(true);
      let stu_ids = students.map((student) => student._id);
      let response = await this.request({
        method: 'put',
        url: 'school/fee/add/' + this.payment_type,
        data: { students: stu_ids, amount: this.amount },
      });
      if (response && !response.error) {
        const students_bill = response.record;
        this.addBills([...students_bill]);

        eventBus.$emit('open-toast', {
          message: response.message,
          warning: response.warning,
        });
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.setLoader(false);
    },
  },
  beforeMount() {},
  beforeDestroy() {
    this.clearBills();
    this.clearAmount();
  },
  validations() {
    return {
      bills: {
        ...this.bills.reduce(
          (acc, payee, index) => ({
            ...acc,
            [index]: {
              ...Object.keys(this.formTemplate).reduce(
                (acc, key) => ({ ...acc, [key]: { required } }),
                {}
              ),
            },
          }),
          {}
        ),
      },
    };
  },
};
</script>

<style></style>
