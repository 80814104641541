<template>
  <div class="bg-white py-5">
    <div class="px-2 flex justify-between">
      <div class="flex space-x-5 mb-5" v-if="teacherProfile">
        <div class="w-20 h-20 rounded-full bg-grayBg">
          <img
            :src="PROFILE_PATH + teacherProfile.profilePic"
            v-viewer="{ inline: false, movable: true }"
            alt=""
            class="w-full h-full rounded-full object-cover"
          />
        </div>
        <div>
          <p class="text-lg text-textDarkest font-semibold">
            {{ teacherProfile.firstName }} {{ teacherProfile.middleName }}
          </p>
          <p class="text-textMedium text-sm">{{ teacherProfile.username }}</p>
        </div>
      </div>
      <div>
        <base-button
          label="Save"
          class="px-3 rounded-default"
          @click="handleSave"
          
          v-if="isDirty"
        />
      </div>
    </div>
    <div>
      <week-session-view
        mode="selection"
        @selected="onSelected($event)"
        :selectedSessions="selectedSessions"
      />
    </div>
  </div>
</template>

<script>
import WeekSessionView from '@/views/protected/schedule/components/WeekSessionView';
import eventBus from '@/eventBus.js';
import { mapActions } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';

export default {
  props: ['id'],
  components: {
    WeekSessionView,
  },
  data() {
    return {
      isDirty: false,
      teacherProfile: '',
      PROFILE_PATH,
      selectedSessions: [],
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    async fetchTeacher() {
      let response = await this.request({
        method: 'get',
        url: 'school/teacherProfile/' + this.id,
      });
      if (response && !response.error) {
        this.teacherProfile = response.record[0];
      } else {
        this.teacherProfile = '';
      }
    },

    onSelected(sessionId) {
      this.isDirty = true;
      if (this.selectedSessions.includes(sessionId))
        return this.selectedSessions.splice(
          this.selectedSessions.indexOf(sessionId),
          1
        );
      this.selectedSessions.push(sessionId);
      this.selectedSessions = [...this.selectedSessions];
      // this.selectedSessions = sessions;
    },

    async handleSave() {
      let response = await this.request({
        method: 'put',
        url: 'school/teacherProfile/update-offday/' + this.id,
        data: {
          off_days: this.selectedSessions,
        },
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'updated successfully' });
        this.fetchItems();
        this.isDirty = false;
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    async fetchItems() {
      let response = await this.request({
        method: 'get',
        url: 'school/teacherProfile/get-offday/' + this.id,
      });
      if (response && !response.error) {
        this.selectedSessions = response.record;
      } else {
        this.selectedSessions = [];
      }
    },
  },

  created() {
    this.fetchTeacher();
    this.fetchItems();
  },
};
</script>

<style></style>
