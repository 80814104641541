<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title> {{ isUpdate ? 'Update' : 'Create New' }} Group </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-1 gap-5">
          <base-input
            label="Name *"
            placeholder="name"
            v-model="Form.name"
            :errors="errors.name"
            check
            capitalize
          />
          <base-select
            label="payment type"
            placeholder="payment type"
            :items="PAYMENT_TYPE_OPTIONS"
            v-model="Form.payment_type"
            :errors="errors.payment_type"
          />

          <base-input
            type="number"
            label="amount"
            placeholder="amount"
            v-model="Form.amount"
            :errors="errors.amount"
          />

          <switch-button label="Is Active" v-model="Form.status" />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import SwitchButton from '@/components/base/SwitchButton.vue';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm'],
  mixins: [validation],
  data() {
    return {
      Form: {
        name: '',
        payment_type: '',
        amount: 0,
        status: 'active', // active, inactive
      },
      payment_types: [],
      errors: {},
      meta: [
        { key: 'Form.name', label: 'Name' },
        { key: 'Form.payment_type', label: 'Payment Type' },
        { key: 'Form.amount', label: 'Amount' },
      ],
      working: false,
      initial: true,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    isUpdate() {
      if (this.oldForm) return true;
      else return false;
    },
    PAYMENT_TYPE_OPTIONS() {
      return this.payment_types.map((payment_type) => ({
        label: payment_type.name,
        value: payment_type._id,
      }));
    },
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name', 'payment_type', 'amount'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
    },
    populateData() {
      Object.keys(this.Form).forEach((key) => {
        this.Form[key] = this.oldForm[key];
      });

      this.Form['payment_type'] = this.oldForm['payment_type']._id;

      this.Form['status'] = ['inactive'].includes(this.oldForm['status'])
        ? false
        : true;
    },
    async handleCreate() {
      this.initial = false;
      if (!this.isValid()) return;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      this.working = true;

      let response = await this.request({
        method: 'post',
        url: 'school/group',
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Transport Group created successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
      this.initial = true;
    },

    async handleUpdate() {
      this.initial = false;
      if (!this.isValid()) return;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';
      newForm = {
        name: newForm.name,
        payment_type: newForm.payment_type,
        amount: newForm.amount,
        status: newForm.status,
      };

      this.working = true;

      let response = await this.request({
        method: 'put',
        url: 'school/group/' + this.oldForm._id,
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Transport Group updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
      this.initial = true;
    },
    async getPaymentTypes() {
      const response = await this.request({
        method: 'get',
        url: 'school/paymentType/transport',
      });
      if (response) {
        this.payment_types = response.record;
        this.Form.payment_type = this.payment_types[0]._id;
      } else {
        this.payment_types = [];
      }
    },
  },
  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    await this.getPaymentTypes();
    if (this.isUpdate) this.populateData();
  },
};
</script>

<style></style>
