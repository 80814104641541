<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title> Create Event </template>
    <template>
      <div>
        <base-input
          required
          label="Name"
          placeholder="event name"
          v-model="Form.name"
          :errors="errors.name"
          capitalize
          check
        />
        <!-- <datepicker class=" border-orange" /> -->
        <div class="flex space-x-5 my-2">
          <div class="w-1/2">
            <p class="mb-1">Start <span class="">*</span></p>
            <base-input
              type="datetime-local"
              v-model="Form.start"
              class=" "
              :errors="errors.start"
              check
            />
          </div>
          <div class="w-1/2">
            <p class="mb-1">End <span class="">*</span></p>
            <base-input
              type="datetime-local"
              v-model="Form.end"
              class=" "
              :errors="errors.end"
              check
            />
          </div>
        </div>
        <!-- <div class="flex w-full space-x-5">
          <div class="w-1/2">
            <base-select
              required
              label="Category"
              placeholder="category"
              v-model="Form.eventType"
              :items="EVENT_TYPE_OPTIONS"
              :errors="errors.eventType"
              check
            >
              <template #option="{ item }">
                <div class="flex justify-between">
                  <div class="flex space-x-3 items-center">
                    <icon :name="item.icon" />
                    <p>{{ item.label }}</p>
                  </div>
                  <div>
                    <div
                      class="h-3 w-3"
                      :class="[]"
                      :style="{ 'background-color': item.color }"
                    ></div>
                  </div>
                </div>
              </template>
            </base-select>
          </div>
        </div> -->
        <!-- colors selector -->
        <p class="text-textDarkest mt-8">Colors</p>
        <div class="w-full flex flex-wrap gap-y-4 gap-x-4 my-5">
          <div
            v-for="(color, i) in colors"
            :key="i"
            :class="{
              'border-4 w-full rounded-full border-secondary':
                i == selectedColorIndex,
            }"
            style="max-width: fit-content"
            @click="selectedColorIndex = i"
          >
            <div class="h-12 w-12 rounded-full flex cursor-pointer shadow-lg">
              <div
                class="w-1/2 rounded-l-full"
                :style="{ 'background-color': color.primary }"
              ></div>
              <div
                class="w-1/2 rounded-r-full"
                :style="{ 'background-color': color.secondary }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import eventBus from '../../../eventBus';
import { eventColors, events } from '@/config/constants.js';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate },
  props: ['isSchoolOfficial', 'school'],
  mixins: [validation],
  data() {
    return {
      Form: {
        name: '',
        primaryColor: '',
        secondaryColor: '',
        start: '',
        end: '',
        // eventType: '',
      },
      selectedColorIndex: 0,
      errors: {},
      ctaList: [],
      colors: eventColors,
      meta: [
        { key: 'Form.name', label: 'Name' },
        { key: 'Form.start', label: 'Start' },
        { key: 'Form.end', label: 'End' },
        // { key: 'Form.eventType', label: 'Event type' },
      ],
      initial: true,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    CTA_OPTIONS() {
      return this.ctaList.map((cta) => ({
        label: cta.course.name,
        value: cta._id,
      }));
    },
    EVENT_TYPE_OPTIONS() {
      return events;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },

    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      this.Form.primaryColor = this.colors[this.selectedColorIndex].primary;
      this.Form.secondaryColor = this.colors[this.selectedColorIndex].secondary;
      this.Form['school'] = this.school;

      let response = await this.request({
        method: 'post',
        url: 'school/event',
        data: this.Form,
      });
      if (response && !response.error) {
        this.handleClose(true);
        return eventBus.$emit('open-toast', { message: 'saved successfully' });
      }
      eventBus.$emit('open-toast', {
        message: this.error,
        error: true,
      });

      this.handleClose();
      this.initial = true;
    },
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, nv) => {
          if (['name', 'start', 'end'].includes(nv)) {
            acc[nv] = { required };
          }
          return acc;
        }, {}),
      },
    };
  },
  created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    if (this.school.name) {
      this.Form.name = this.school.name;
      this.Form.start = this.school.name;
      this.Form.end = this.school.name;
      this.Form.eventType = this.school.eventType;
    }
  },
};
</script>

<style scoped></style>
