<template>
  <div>
    <base-title title="Manage Course Authors">
      <template #actions>
        <div class="flex">
          <base-button
            icon="plus"
            label="Add New"
            class="px-3"
            @click="handleCreateNew"
          />
        </div>
      </template>
    </base-title>
    <div class="bg-white p-5 mt-2 rounded-default">
      <d-table
        #default="{ item }"
        :headers="headers"
        :items="items"
        :hasActions="true"
        :handlers="handlers"
        :hideColumnSort="true"
      >
        <td v-for="(header, i) in headers" :key="i">
          <div v-if="header.key === 'profilePic'">
            <div class="flex space-x-5 items-center">
              <div class="h-10 w-10 rounded-full bg-gray-100">
                <img
                  :src="PROFILE_PATH + item.profilePic"
                  class="w-full h-full object-cover rounded-full"
                  alt=""
                />
              </div>
              <div>
                <p class="text font-semibold text-textDarkest uppercase">
                  {{ item.firstName + ' ' + item.lastName }}
                </p>
                <p class="text-textLight">{{ item.username }}</p>
              </div>
            </div>
          </div>
          <div v-else>
            {{ getProp(item, header.key) }}
          </div>
        </td>
      </d-table>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import eventBus from '../../../../eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';
export default {
  components: { DTable },
  data() {
    return {
      PROFILE_PATH,
      items: [],
      headers: [
        { label: '', key: 'profilePic' },
        { label: 'Role', key: 'role' },
        { label: 'Gender', key: 'gender' },
        { label: 'Phone Number', key: 'phoneNumber' },
        // { label: '', key: '' },
        // { label: 'Label Five', key: '' },
      ],
      handlers: [
        {
          label: 'Detail',
          icon: 'eye',
          handler: this.handleDetail,
          idParam: true,
        },

        {
          label: 'Edit',
          icon: 'pencil-alt',
          handler: this.handleEdit,
          idParam: true,
        },
        {
          label: 'Delete',
          icon: 'trash-alt',
          handler: this.handleDelete,
          idParam: true,
        },
        {
          label: 'Reset Password',
          icon: 'pencil-alt',
          handler: this.handleResetPassword,
          idParam: true,
        },
        {
          label: 'Change Status',
          icon: 'pencil-alt',
          handler: this.handleChangeStatus,
          fullParam: true,
        },
      ],
    };
  },

  computed: {
    ...mapState('main', ['error']),
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,
    handleDelete(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the item',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },
    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'reb/official/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'deleted successfully' });
        this.fetchOfficials();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    handleEdit(id) {
      this.$router.push({ name: 'update-course-autor', params: { id } });
    },
    handleDetail(id) {
      this.$router.push({ name: 'detail-course-autor', params: { id } });
    },
    handleCreateNew() {
      this.$router.push({ name: 'create-course-autor' });
    },
    async handleResetPassword(id) {
      let response = await this.request({
        method: 'put',
        url: 'reb/official/reset-password/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-modal', {
          modalId: 5,
          componentProps: { user: response.record },
        });
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    async fetchOfficials() {
      let response = await this.request({ method: 'get', url: 'reb/official' });
      if (response && !response.error) this.items = response.record;
      else this.items = [];
    },
    handleChangeStatus(user) {
      eventBus.$emit('open-modal', {
        modalId: 4,
        componentProps: { status: user.status, id: user._id },
      });
    },
  },

  created() {
    this.fetchOfficials();
  },
};
</script>

<style></style>
