<template>
  <div>
    <base-title :title="'Academic Status'">
      <template #actions>
        <div class="flex space-x-3">
          <base-button
            label="Edit Status"
            icon="check-circle"
            v-if="
              selectedItems.length > 0 &&
              !['dropout', 'withdrawal'].includes(status)
            "
            @click="handleEditStatus"
            class="flex-none rounded-lg"
          />
          <base-select
            placeholder=""
            v-model="activeGrade"
            :items="GRADE_OPTIONS"
            searchable
          />
          <base-select
            placeholder=""
            v-model="activeSection"
            :items="SECTION_OPTIONS"
            searchable
          />
        </div>
      </template>
    </base-title>

    <BaseSwitch :items="statusOptions" v-model="status" padding="small" />

    <div class="bg-white p-1 px-4 mt-2">
      <div>
        <d-table
          #default="{ item }"
          :headers="headers"
          :items="items"
          :hasActions="action"
          :handlers="handlers"
          :loading="loading"
          :searchProps="searchProps"
          code="stu-view"
          :multiSelect="true"
          @selectedItemIDs="selectedItems1($event)"
        >
          <td v-for="(header, i) in headers" :key="i">
            <div class="px-2">
              <div v-if="header.key === 'profilePic'">
                <div class="flex space-x-5 items-center">
                  <div
                    class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center"
                  >
                    <img
                      v-if="item.profilePic"
                      :src="PROFILE_PATH + item.profilePic"
                      v-viewer="{ inline: false, movable: true }"
                      class="w-full h-full object-cover rounded-full cursor-pointer"
                      alt=""
                      crossorigin="anonymous"
                    />
                    <icon
                      v-else
                      :size="18"
                      name="user"
                      class="w-full h-full"
                    ></icon>
                  </div>
                  <div>
                    <p
                      class="cursor-pointer hover:underline font-semibold text-textDarkest uppercase"
                      @click="handleDetail(item.id)"
                    >
                      {{ item.fullName }}
                    </p>
                    <p class="text-textLight">{{ item.username }}</p>
                  </div>
                </div>
              </div>
              <div v-else-if="header.key === 'userId'">
                {{ item.userId }}
              </div>
              <div v-else-if="header.key === 'course'">
                {{ item.course.name }}
              </div>
              <div v-else-if="header.key === 'program'">
                {{ item['program'] ? item['program']['name'] : 'NA' }}
              </div>
              <div v-else-if="header.key === 'department'">
                {{ item['department'] ? item['department']['name'] : 'NA' }}
              </div>

              <div
                v-else-if="
                  header.key === 'phoneNumber' && item.phoneNumber.length
                "
              >
                {{ item['phoneNumber'].join(' | ') }}
              </div>
              <div v-else-if="header.key === 'school'">
                {{ item['school'] ? item['school']['name'] : 'NA' }}
              </div>
              <div v-else-if="header.key === 'section'">
                {{ item.section ? item.section.name : '--' }}
              </div>
              <div v-else-if="header.key === 'academic_status'">
                <!-- {{ item.status }} -->
                <status-chip
                  :label="item['status']"
                  style="max-width: fit-content"
                />
              </div>
              <div v-else class="capitalize">
                {{ getProp(item, header.key) }}
              </div>
            </div>
          </td>
        </d-table>
      </div>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import eventBus from '../../../../eventBus';
import { mapActions, mapGetters } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';
import BaseSwitch from '../../../../components/base/BaseSwitch.vue';

export default {
  components: { DTable, BaseSwitch },
  data() {
    return {
      statusOptions: [
        { label: 'Active', value: 'active' },
        // { label: 'Dropout', value: 'dropout' },
        // { label: 'Withdrawal', value: 'withdrawal' },
        { label: 'Completed', value: 'completed' },
        { label: 'Graduated', value: 'graduated' },
        { label: 'Dismissed', value: 'dismissed' },
      ],
      status: 'active',
      action: false,
      activeGrade: '',
      activeSection: '',
      gradeItems: [],
      sectionItems: [],
      PROFILE_PATH,
      selectedItems: [],
      items: [],
      module: 'student',
      loading: false,
      headers: [
        { label: 'User', key: 'profilePic' },
        { label: 'Sch Id', key: 'userId' },
        { label: 'Phone Number', key: 'phoneNumber' },
        // { label: 'Department', key: 'department' },
        // { label: 'Course', key: 'course' },
        // { label: 'Section', key: 'section' },
        { label: 'Status', key: 'academic_status' },
      ],
      handlers: [
        {
          label: 'Edit Status',
          icon: 'edit',
          handler: this.handleEditStatus,
          idParam: true,
          // filter: () => this.authCodes.includes('enrst-rm'),
        },
      ],
      searchProps: {
        searchUrl: 'student/studentProfile/table-search',
      },
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },

    SECTION_OPTIONS() {
      return this.sectionItems.map((section) => ({
        label: section.name,
        value: section._id,
      }));
    },
  },
  watch: {
    activeGrade() {
      this.fetchSections();
    },
    activeSection() {
      this.fetchStudents();
    },

    status: {
      deep: true,
      immediate: false,
      handler: async function () {
        await this.fetchStudents();
        if (['dropout', 'withdrawal'].includes(this.status)) this.action = true;
        else this.action = false;
      },
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,
    selectedItems1(e) {
      this.selectedItems = e;
    },
    async handleEditStatus() {
      if (this.selectedItems.length == 0)
        return eventBus.$emit('open-toast', {
          error: true,
          message: 'You have to select students first.',
        });
      eventBus.$emit('open-modal', {
        modalId: 49,
        componentProps: { students: this.selectedItems },
        cb: this.refresh,
      });
    },
    async refresh() {
      await this.fetchStudents();
      eventBus.$emit('emptySelected');
    },
    handleApprove(id) {
      let componentProps = {
        message: 'Are you sure you want to approve the exempted courses.',
        title: 'This action is not reversible.',
      };
      console.log(' im here');
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedApprove,
        cbParams: { id },
      });
    },
    handleReject(id) {
      let componentProps = {
        message: 'Are you sure you want to reject the exempted course.',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedReject,
        cbParams: { id },
      });
    },
    async proceedApprove({ id }) {
      let response = await this.request({
        method: 'put',
        url: 'school/courseExemption/approve/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'Approved successfully' });
        await this.fetchStudents();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    async proceedReject({ id }) {
      let response = await this.request({
        method: 'put',
        url: 'school/courseExemption/reject/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'Rejected successfully' });
        this.fetchStudents();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    handleDetail(id) {
      console.log('detail here for the exempted courses', id);
    },
    async fetchStudents() {
      this.loading = true;

      let base_url = 'student/studentProfile/';
      let rear = '';
      if (this.status == 'dropout') rear = 'get-my-dropout/';
      else if (this.status == 'active') rear = 'get-my-active/';
      else if (this.status == 'withdrawal') rear = 'get-my-withdrawn/';
      else if (this.status == 'completed') rear = 'get-my-completed/';
      else if (this.status == 'graduated') rear = 'get-my-graduated/';
      else if (this.status == 'dismissed') rear = 'get-my-dismissed/';

      let url = `${base_url}${rear}${this.activeGrade}/${this.activeSection}`;

      let response = await this.request({
        method: 'get',
        url: url,
      });
      if (response && !response.error) this.items = response.record;
      else this.items = [];
      this.loading = false;
    },

    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
        this.activeGrade = response.record[0]._id;
      } else {
        this.gradeItems = [];
        this.activeGrade = '';
      }
    },

    async fetchSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/class/' + this.activeGrade,
      });
      if (response && !response.error) {
        this.sectionItems = response.record;
        this.activeSection = response.record[0]._id;
      } else {
        this.sectionItems = [];
        this.activeSection = '';
      }
    },
  },

  async created() {
    await this.fetchGrades();
  },
};
</script>

<style></style>
