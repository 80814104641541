var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[(_vm.label)?_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.label)),(_vm.required)?_c('span',{staticClass:"text-error"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"mt-0"},[_c('textarea',_vm._b({staticClass:"w-full custom-text-input border-1 border-dgray rounded text-textDarkest",class:{
        'bg-gray-100': _vm.success && !_vm.bordered,
        'bg-danger': !_vm.success && !_vm.bordered,
        'border-textLight': _vm.success && _vm.bordered,
        'border-error': !_vm.success && _vm.bordered,
        'border rounded': _vm.bordered,
        'py-2 px-3': _vm.padding === 'big',
        'py-1 px-2': _vm.padding === 'small',
        'rounded-none': _vm.rounded,
        'rounded-default': !_vm.rounded,

      },style:('font-size: ' + _vm.size + 'px;'),attrs:{"rows":_vm.rows},on:{"input":_vm.handleInput}},'textarea',_vm.$attrs,false))]),(!_vm.success)?_c('div',{staticClass:"text-xs",staticStyle:{"color":"red"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i},[_vm._v(_vm._s(error))])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }