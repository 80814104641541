<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title>
      {{ 'Create Multiple Academic Years' }}
    </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-2 gap-5">
          <base-input
            class="col-span-2"
            label="Academic Year Name"
            placeholder="Academic Year name"
            v-model="Form.name"
            :errors="errors.name"
            capitalize
            required
            check
          />
          <base-input
            v-if="isGreg"
            label="Start Date"
            placeholder="start date"
            v-model="Form.startDate"
            :errors="errors.startDate"
            type="date"
            check
            required
          />
          <base-input
            v-if="isGreg"
            label="End Date"
            placeholder="end date"
            v-model="Form.endDate"
            :errors="errors.endDate"
            type="date"
            check
            required
          />
          <div class="flex col-span-2 gap-5" v-if="!isGreg">
            <div class="">
              <span class=""
                >Start Date <span class="text-error"> *</span>
              </span>
              <div class="grid grid-cols-10 gap-2">
                <div class="col-span-3">
                  <base-input
                    placeholder="dd"
                    type="number"
                    v-model="tempStartDate.date"
                    :errors="errors.date"
                    check
                  />
                </div>
                <div class="col-span-3">
                  <base-input
                    placeholder="mm"
                    type="number"
                    v-model="tempStartDate.month"
                    :errors="errors.month"
                    check
                  />
                </div>
                <div class="col-span-4">
                  <base-input
                    placeholder="yyyy"
                    type="number"
                    v-model="tempStartDate.year"
                    :errors="errors.year"
                    check
                  />
                </div>
              </div>
            </div>
            <div class="">
              <span class="">End Date <span class="text-error"> *</span> </span>
              <div class="grid grid-cols-10 gap-2">
                <div class="col-span-3">
                  <base-input
                    placeholder="dd"
                    type="number"
                    v-model="tempEndDate.edate"
                    :errors="errors.edate"
                    check
                  />
                </div>
                <div class="col-span-3">
                  <base-input
                    placeholder="mm"
                    type="number"
                    v-model="tempEndDate.emonth"
                    :errors="errors.emonth"
                    check
                  />
                </div>
                <div class="col-span-4">
                  <base-input
                    placeholder="yyyy"
                    type="number"
                    v-model="tempEndDate.eyear"
                    :errors="errors.eyear"
                    check
                  />
                </div>
              </div>
            </div>
          </div>
          <base-multi-select
            class="col-span-2"
            label="Grades"
            placeholder=""
            v-model="Form.grades"
            :items="GRADE_OPTIONS"
            :errors="errors.grades"
            :searchable="true"
            check
          />
          <base-multi-select
            class="col-span-2"
            label="Active Sections"
            placeholder=""
            v-model="Form.sections"
            :items="SECTION_OPTIONS"
            :errors="errors.section"
            :searchable="true"
            @input="handleToggleSection"
          />
          <div class="col-span-2 flex">
            <base-check-box
              :selected="allSections"
              @toggle="handleToggleGrade"
              :size="20"
            />
            <div>All Sections</div>
          </div>

          <div class="col-span-2 flex">
            <switch-button label="Is Active" v-model="Form.status" />
          </div>
          <div class="col-span-2 flex">
            <switch-button
              label="Close Previous Active Academic Years"
              v-model="Form.close_previous"
            />
          </div>

          <div
            v-if="Form.close_previous"
            class="col-span-2 flex cursor-pointer rounded-lg text-primary bg-gray-100"
          >
            N.B - All Academic Years of the selected grades and sections will be
            closed, and new one will be created and be active! Histories of
            previous datas will be still be available!
          </div>
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import SwitchButton from '../base/SwitchButton.vue';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm'],
  mixins: [validation],
  data() {
    return {
      Form: {
        name: '',
        startDate: '',
        endDate: '',
        grades: [],
        sections: [],
        status: '', // active, closed, not_started
        close_previous: false, // active, closed, not_started
      },
      tempStartDate: {
        date: '',
        month: '',
        year: '',
      },
      tempEndDate: {
        edate: '',
        emonth: '',
        eyear: '',
      },
      gradeItems: [],
      sectionItems: [],
      errors: {},
      meta: [
        { key: 'Form.sections', label: 'Sections' },
        { key: 'Form.name', label: 'Name' },
        { key: 'Form.startDate', label: 'Start date' },
        { key: 'Form.endDate', label: 'End date' },
      ],
      working: false,
      initial: true,
    };
  },
  validations() {
    const formValidation = {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['sections', 'startDate', 'endDate', 'name'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
    const ethDateValidation = {
      tempStartDate: {
        ...Object.keys(this.tempStartDate).reduce((acc, key) => {
          if (['date'].includes(key)) {
            acc[key] = {
              required,
              minValue: minValue(1),
              maxValue: maxValue(30),
            };
          } else if (['month'].includes(key)) {
            acc[key] = {
              required,
              minValue: minValue(1),
              maxValue: maxValue(13),
            };
          } else if (['year'].includes(key)) {
            acc[key] = {
              required,
              // minValue: minValue(1920),
              // maxValue: maxValue(2023),
            };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
      tempEndDate: {
        ...Object.keys(this.tempEndDate).reduce((acc, key) => {
          if (['edate'].includes(key)) {
            acc[key] = {
              required,
              minValue: minValue(1),
              maxValue: maxValue(30),
            };
          } else if (['emonth'].includes(key)) {
            acc[key] = {
              required,
              minValue: minValue(1),
              maxValue: maxValue(13),
            };
          } else if (['eyear'].includes(key)) {
            acc[key] = {
              required,
              // minValue: minValue(1920),
              // maxValue: maxValue(2023),
            };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };

    return this.isGreg
      ? { ...formValidation }
      : { ...formValidation, ...ethDateValidation };
  },
  computed: {
    ...mapState('main', ['error', 'globalSchoolConfig']),
    isGreg() {
      return (
        this.globalSchoolConfig &&
        this.globalSchoolConfig.calendarType == 'greg'
      );
    },

    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },
    SECTION_OPTIONS() {
      return this.sectionItems.map((section) => ({
        label: section.class.name + ' - ' + section.name,
        value: section._id,
      }));
    },
    allSections() {
      return this.SECTION_OPTIONS.length == this.Form.sections.length;
    },
  },
  watch: {
    'Form.grades': {
      deep: true,
      immediate: false,
      handler: function () {
        this.fetchSections();
      },
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },

    handleToggleSection(n) {
      this.allSections = this.sections.length === n.length;
    },
    handleToggleGrade() {
      if (this.allSections) {
        this.Form.sections = [];
      } else {
        this.Form.sections = this.SECTION_OPTIONS.map(({ value }) => value);
      }
    },
    async handleSave() {
      this.initial = false;
      if (!this.isGreg) this.formatDate();
      if (!this.isValid()) return;
      else this.handleCreate();
      this.initial = true;
    },

    async handleCreate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      delete newForm.grades;

      let response = await this.request({
        method: 'post',
        url: 'school/academicYear/multiple',
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Academic year updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },

    async handleFetchGrades() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
      } else {
        this.gradeItems = [];
      }
      this.loading = false;
    },
    async fetchSections() {
      this.loading = true;
      let response = await this.request({
        method: 'post',
        url: 'school/section/active-multiple',
        data: {
          classes: this.Form.grades,
        },
      });
      if (response && !response.error) {
        this.sectionItems = response.record;
      } else {
        this.sectionItems = [];
      }
      this.loading = false;
    },
    async fetchAcademicSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicsection/ay/' + this.oldForm._id,
      });
      if (response && !response.error) {
        this.ay_sections = response.record;
      } else {
        this.ay_sections = [];
      }
    },
    formatDate() {
      this.Form.startDate = `${this.tempStartDate.year}-${this.tempStartDate.month}-${this.tempStartDate.date}`;
      this.Form.endDate = `${this.tempEndDate.eyear}-${this.tempEndDate.emonth}-${this.tempEndDate.edate}`;
    },
  },
  async created() {
    if (!this.isGreg) {
      this.meta.push(
        { key: 'tempStartDate.date', label: 'Start date' },
        { key: 'tempStartDate.month', label: 'Start month' },
        { key: 'tempStartDate.year', label: 'Start year' },
        { key: 'tempEndDate.edate', label: 'End date' },
        { key: 'tempEndDate.emonth', label: 'End month' },
        { key: 'tempEndDate.eyear', label: 'End year' }
      );
    }

    await this.handleFetchGrades();

    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
  },
};
</script>

<style></style>
