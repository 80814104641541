<template>
  <div class="text-textMedium space-y-4">
    <!-- <div class="flex space-x-2 justify-between border-b border-dgray pb-2">
      <div class="font-semibold">teachers</div>
      <div class="text-15">Found {{ teachers.length }} search result{{ teachers.length > 1 ? 's' : '' }}</div>
    </div> -->
    <!-- TODO circule progress -->
    <loader v-if="loading"></loader>
    
    <div v-else-if="teachers.length > 0" class="space-y-1">
      <teacher v-for="teacher in teachers" :key="teacher._id" :teacher="teacher" />
    </div>
    <div v-else>
      <div v-if="initial" class="space-y-1">
        <file-search class="h-48" />
        <div class="text-center">Search for teachers</div>
      </div>
      <div v-else>
        <div class="font-medium">Oops! No teacher</div>
        <div class="text-sm">Oops no teacher can be found with the given search keyword and the filter criteria.</div>
      </div>
    </div>
  </div>
</template>

<script>
import Teacher from './Teacher.vue'
import FileSearch from '../../shared/svgs/FileSearch.vue'
export default {
  components: { Teacher, FileSearch },
  props: ['teachers', 'loading', 'initial'],
}
</script>

<style></style>
