var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid grid-cols-3"},[_c('div',{},[_c('p',{staticClass:"uppercase text-lg text-textMedium tracking-wider"},[_vm._v(" idcard entities ")]),_c('base-multi-select',{staticClass:"py-2",attrs:{"label":"Select IDcard Entities","placeholder":"","items":[
        { label: 'Fullname', value: true },
        { label: 'Photo', value: true },
        { label: 'Age', value: true },
        { label: 'Class', value: true },
        { label: 'User ID', value: true },
      ]}})],1),_c('div',{staticClass:"bg-white grid grid-cols-2 gap-2 col-span-3"},[_vm._m(0),_c('div',{staticClass:"bg-white border-gray-200 border-1 rounded-lg py-3"},[_c('div',{staticClass:"border-b-1 h-12 bg-black p-2 flex items-center gap-3"}),_c('div',{staticClass:"grid grid-cols-8 gap-2 items-center p-2"},[_vm._m(1),_c('div',{staticClass:"col-span-3"},[_c('base-qrcode',{attrs:{"value":'HN-ST0001',"options":{ width: 120 }}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white rounded-lg border-gray-200 border-1"},[_c('div',{staticClass:"rounded-t-lg border-b-1 border-gray-300 bg-gray-50 p-2 flex items-center justify-between gap-3"},[_c('div',{staticClass:"flex items-center gap-2"},[_c('img',{staticClass:"w-12 h-12 rounded-full",attrs:{"src":require("@/assets/henonLogo.png"),"alt":""}}),_c('div',{},[_c('p',{staticClass:"uppercase font-semibold"},[_vm._v("example school")]),_c('p',{staticClass:"text-xs text-gray-500"},[_vm._v("Student ID")])])]),_c('div',{staticClass:"flex items-center gap-2 bg-gray-100 p-2 rounded-default"},[_c('p',{staticClass:"text-xs"},[_vm._v("Powered by:")]),_c('img',{staticClass:"w-6 h-6",attrs:{"src":require("@/assets/henonLogo.png"),"alt":""}})])]),_c('div',{staticClass:"grid grid-cols-8 gap-2 items-center p-2"},[_c('div',{staticClass:"h-48 col-span-3"},[_c('img',{staticClass:"h-full object-cover",attrs:{"src":require("@/assets/avatars/photo2.jpg"),"alt":""}})]),_c('div',{staticClass:"flex flex-col gap-3 col-span-5 px-6"},[_c('div',{},[_c('p',{staticClass:"text-xs text-gray-400"},[_vm._v("Full Name / ሙሉ ስም")]),_c('p',{staticClass:"text-sm"},[_vm._v("John Steve")])]),_c('div',{},[_c('p',{staticClass:"text-xs text-gray-400"},[_vm._v("ID Number / መለያ ቁጥር")]),_c('p',{staticClass:"text-sm"},[_vm._v("HN-ST0001")])]),_c('div',{},[_c('p',{staticClass:"text-xs text-gray-400"},[_vm._v("Grade / ክፍል")]),_c('p',{staticClass:"text-sm"},[_vm._v("6th")])]),_c('div',{},[_c('p',{staticClass:"text-xs text-gray-400"},[_vm._v("Sex / ፆታ")]),_c('p',{staticClass:"text-sm"},[_vm._v("Male")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-3 col-span-5 px-6"},[_c('div',{},[_c('p',{staticClass:"text-xs text-gray-400"},[_vm._v("Phone No / ስልክ ቁጥር")]),_c('p',{staticClass:"capitalize text-sm"},[_vm._v("+251987654321")])]),_c('div',{},[_c('p',{staticClass:"text-xs text-gray-400"},[_vm._v("Region / ክልል")]),_c('p',{staticClass:"capitalize text-sm"},[_vm._v("example")])]),_c('div',{},[_c('p',{staticClass:"text-xs text-gray-400"},[_vm._v("City / ከተማ")]),_c('p',{staticClass:"capitalize text-sm"},[_vm._v("example")])]),_c('div',{},[_c('p',{staticClass:"text-xs text-gray-400"},[_vm._v("Subcity or Woreda / ክፍለከተማ")]),_c('p',{staticClass:"capitalize text-sm"},[_vm._v("example")])])])
}]

export { render, staticRenderFns }