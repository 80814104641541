<template>
  <div
    class="bg-white p-5 py-2 flex justify-between items-center rounded-default"
  >
    <p class="text-textDark font-medium text-lg">{{ title }}</p>
    <slot name="infos"></slot>

    <div>
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title'],
};
</script>

<style></style>
