<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title> {{ isUpdate ? 'Update' : 'Create New' }} Payer </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-1 gap-5">
          <base-input
            label="Name *"
            placeholder="name"
            v-model="Form.name"
            :errors="errors.name"
            check
          />
          <base-input
            label="Covered amount in percent(%) *"
            placeholder="0 - 100"
            type="number"
            v-model="Form.percent"
            :errors="errors.percent"
            check
          />
          <!-- <base-check-box
            v-model="Form.isSelf"
            :selected="Form.isSelf"
            label="is self coverage"
            :errors="errors.isSelf"
          /> -->
          <!-- <switch-button label="is self coverage" v-model="Form.isSelf" /> -->

          <switch-button label="Is Active" v-model="Form.status" />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import SwitchButton from '../../base/SwitchButton.vue';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import minValue from 'vuelidate/lib/validators/minValue';
import maxValue from 'vuelidate/lib/validators/maxValue';

export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm', 'school'],
  mixins: [validation],
  data() {
    return {
      Form: {
        school: this.school,
        name: '',
        percent: undefined,
        // isSelf: undefined,
        status: 'active', // active, inactive
      },
      isDiscountTypeRate: true,
      errors: {},
      meta: [
        { key: 'Form.name', label: 'Name' },
        { key: 'Form.percent', label: 'Percent' },
      ],
      working: false,
      initial: true,
    };
  },
  watch: {
    isDiscountTypeRate() {
      this.isDiscountTypeRate ? (this.Form.discount_type = 'rate') : false;
    },
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name'].includes(key)) {
            acc[key] = { required };
          } else if (['percent'].includes(key)) {
            acc[key] = {
              required,
              minValue: minValue(0),
              maxValue: maxValue(100),
            };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    ...mapState('main', ['error']),
    isUpdate() {
      if (this.oldForm) return true;
      else return false;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
    },
    populateData() {
      Object.keys(this.oldForm).forEach((key) => {
        this.Form[key] = this.oldForm[key];
      });
      this.isDiscountTypeRate = this.Form.discount_type == 'rate';
      this.Form['status'] = ['inactive'].includes(this.oldForm['status'])
        ? false
        : true;
    },
    async handleCreate() {
      this.initial = false;
      if (!this.isValid()) return;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      this.working = true;

      let response = await this.request({
        method: 'post',
        url: 'school/payer',
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'discount updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;

      this.initial = true;
    },

    async handleUpdate() {
      this.initial = false;
      if (!this.isValid()) return;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';
      newForm = {
        name: newForm.name,
        percent: newForm.percent,
        status: newForm.status,
      };

      this.working = true;

      let response = await this.request({
        method: 'put',
        url: 'school/payer/' + this.oldForm._id,
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'discount updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;

      this.initial = true;
    },
  },
  created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    if (this.isUpdate) this.populateData();
  },
};
</script>

<style></style>
