var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('tippy',{ref:"attendance-block",attrs:{"placement":"right","theme":"light","arrow":"","size":"large","interactive":""},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"h-4 w-4 bg-gray-100 rounded-sm hover:opacity-75 flex justify-center items-center",class:{
          'bg-success': _vm.status === 'present',
          'bg-orange': _vm.status === 'late',
          'bg-error': _vm.status === 'absent',
        },attrs:{"name":"block"}},[_c('p',{staticClass:"text-xs"})])]},proxy:true}])},[_c('div',{staticClass:"w-72 flex flex-col items-start px-5 py-5"},[_c('div',{staticClass:"text-sm mb-1"},[_vm._v(" "+_vm._s(_vm.moment(_vm.fullDate).format('LLLL'))+" ")]),_c('div',{staticClass:"flex space-x-3 items-center"},[_c('div',{staticClass:"h-3 w-3 rounded-full",class:{
            'bg-success': _vm.status === 'present',
            'bg-orange': _vm.status === 'late',
            'bg-error': _vm.status === 'absent',
          }}),_c('div',{staticClass:"flex space-x-3"},[_c('p',[_vm._v(_vm._s(_vm.status))]),(_vm.status === 'absent')?_c('p',[_vm._v(","+_vm._s(_vm.permissionStatement))]):_vm._e()])]),_c('div',{staticClass:"border-l-4 px-3 border-orange my-3"},[_vm._v(" "+_vm._s(_vm.remark)+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }