<template>
  <div>
    <div v-if="authCodes.includes('att-view')">
      <base-title title="Student Attendance"></base-title>

      <div class="grid grid-cols-4 gap-2">
        <div class="col-span-3 bg-white p-2 mt-1 overflow-auto">
          <d-table
            #default="{ item }"
            :headers="headers"
            :items="attendances"
            :totalItems="attendances.length"
            :loading="loading"
            :searchProps="searchProps"
            :hideColumnSort="true"
          >
            <td v-for="(header, i) in headers" :key="i">
              <div class="px-2">
                <div v-if="header.key === 'profilePic'">
                  <div class="flex space-x-5 items-center">
                    <div
                      class="h-10 w-10 rounded-full bg-gray-100 cursor-pointer flex justify-center items-center"
                    >
                      <img
                        v-if="item.profilePic"
                        :src="PROFILE_PATH + item.profilePic"
                        v-viewer="{ inline: false, movable: true }"
                        class="w-full h-full object-cover rounded-full"
                        alt=""
                        crossorigin="anonymous"
                      />
                      <icon
                        v-else
                        :size="18"
                        name="user"
                        class="w-full h-full"
                      ></icon>
                    </div>
                    <div @click="displayUserDetail(item._id)">
                      <p
                        class="cursor-pointer hover:underline font-semibold text-textDarkest uppercase"
                      >
                        {{ item.firstName + ' ' + item.middleName }}
                      </p>
                      <p class="text-textLight">{{ item.email }}</p>
                    </div>
                  </div>
                </div>
                <div v-else-if="header.key === 'phoneNumber'">
                  {{ item['phoneNumber'].join(' | ') }}
                </div>
                <div v-else-if="header.key === 'status'">
                  <div class="flex justify-between items-center">
                    <div
                      class="h-8 w-8 rounded-full flex justify-center items-center cursor-pointer hover:bg-success hover:text-white"
                      :class="{
                        'bg-success text-white':
                          attendanceStatuses[item._id] &&
                          attendanceStatuses[item._id]['status'] === 'present',
                        ' bg-gray-100 text-textDarkest':
                          !attendanceStatuses[item._id] ||
                          attendanceStatuses[item._id]['status'] != 'present',
                      }"
                      @click="changeStatus(item._id, 'present')"
                    >
                      <p class="" style="max-width: fit-content">P</p>
                    </div>

                    <div
                      class="h-8 w-8 rounded-full flex justify-center items-center cursor-pointer hover:bg-orange hover:text-white"
                      :class="{
                        'bg-orange text-white':
                          attendanceStatuses[item._id] &&
                          attendanceStatuses[item._id]['status'] === 'late',
                        ' bg-gray-100 text-textDarkest':
                          !attendanceStatuses[item._id] ||
                          attendanceStatuses[item._id]['status'] != 'late',
                      }"
                      @click="changeStatus(item._id, 'late')"
                    >
                      <p class="" style="max-width: fit-content">L</p>
                    </div>
                    <div
                      class="h-8 w-8 rounded-full flex justify-center items-center cursor-pointer hover:bg-error hover:text-white"
                      :class="{
                        'bg-error text-white':
                          attendanceStatuses[item._id] &&
                          attendanceStatuses[item._id]['status'] === 'absent',
                        ' bg-gray-100 text-textDarkest':
                          !attendanceStatuses[item._id] ||
                          attendanceStatuses[item._id]['status'] != 'absent',
                      }"
                      @click="changeStatus(item._id, 'absent')"
                    >
                      <p class="" style="max-width: fit-content">A</p>
                    </div>
                  </div>
                </div>
                <div v-else class="capitalize">
                  {{ getProp(item, header.key) }}
                </div>
              </div>
            </td>
          </d-table>
        </div>

        <div class="mt-1">
          <div class="bg-white p-5 h-full">
            <div
              class="bg-orange text-white px-2 py-3 flex items-center rounded-default w-full mb-5 text-sm"
            >
              <div>
                <icon name="" />
              </div>

              <p v-if="attendances.length && newAttendance">
                You are inserting a new attendance.
              </p>
              <p v-else>You are updating an attendance</p>
            </div>

            <div>
              <apexchart
                ref="donut"
                width="250"
                type="donut"
                :options="chartOptions"
                :series="series"
                @dataPointSelection="handleSelectStatus"
                @markerClick="handleMarkerClick"
              ></apexchart>
            </div>

            <div class="space-y-4">
              <base-select
                placeholder=""
                v-model="activeGrade"
                :items="GRADE_OPTIONS"
                searchable
              />
              <base-select
                placeholder=""
                v-model="activeSection"
                :items="SECTION_OPTIONS"
                searchable
              />

              <base-select
                placeholder=""
                :items="ACADEMIC_YEARS"
                v-model="activeAy"
                searchable
              />

              <base-input
                v-if="isGreg"
                label="Attendance Date"
                placeholder=""
                v-model="attendanceDate"
                :errors="errors.date"
                type="date"
              />
              <base-eth-calendar
                v-if="!isGreg"
                label="Attendance Date"
                v-model="Form.attendanceDate"
                :errors="errors.attendanceDate"
                :isUpdate="isUpdate"
              />
            </div>
            <div class="flex justify-between mt-8">
              <base-button
                icon="check-circle"
                label="save"
                @click="handleSave"
                class="px-3"
                code="att-edit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else class=" text-error uppercase">
      You don't have privilege to see this!
    </div> -->
  </div>
</template>

<script>
import { PROFILE_PATH } from '@/config/config.js';
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import { mapActions, mapGetters, mapState } from 'vuex';
import eventBus from '@/eventBus';
// import AttendanceMap from './AttendanceMap.vue';
import moment from 'moment';

// import VdatePick from 'vue-date-pick';

export default {
  components: {
    DTable,
    // VdatePick,
    // AttendanceMap
  },
  data() {
    return {
      working: false,
      newAttendance: false,
      activeGrade: '',
      activeSection: '',
      activeAy: '',
      gradeItems: [],
      sectionItems: [],
      academic_years: [],
      academic_year: {},
      headers: [
        { label: 'User', key: 'profilePic' },
        { label: 'Student Id', key: 'userId' },
        { label: 'Gender', key: 'gender' },
        { label: 'Phone Number', key: 'phoneNumber' },
        { label: 'Attendance', key: 'status' },
      ],
      currentDate: '',
      PROFILE_PATH,
      isNew: false,
      isDirty: false,
      loading: false,
      attendanceDate: moment(this.attendanceDate).format('YYYY-MM-DD'),
      STATUS_OPTIONS: ['present', 'absent', 'late'],
      Form: {
        grade: '',
        section: '',
      },
      errors: {},
      chartOptions: {
        labels: ['present', 'absent', 'late'],
        legend: {
          position: 'bottom',
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
          },
        },
        // fill: {
        //   colors: ['#3bb979', '#ec3118', '#ffa500']
        // },
        colors: ['#3bb979', '#ff6b6c', '#ffc145'],
        // selection: {
        //   fill: {
        //     color: '#ffffff',
        //     opacity: 0.0
        //   }
        // },
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
        },
      },
      selectedStatusIndex: -1,
      attendanceStatuses: {},
      attendances: [],
      searchProps: {
        searchUrl: 'school/attendance/table-search/',
        searchId: this.activeAy,
      },
    };
  },

  computed: {
    ...mapGetters(['user', 'authCodes']),
    ...mapState('main', ['globalSchoolConfig']),

    isUpdate() {
      if (!this.newAttendance) return true;
      else return false;
    },
    isGreg() {
      return (
        this.globalSchoolConfig &&
        this.globalSchoolConfig.calendarType == 'greg'
      );
    },

    series() {
      let absent = 0;
      let present = 0;
      let late = 0;

      //   this.attendances.forEach(attendanceBlock => {
      //     attendanceBlock.students.forEach(student => {
      //       if (student.status === 'present') present++;
      //       else if (student.status === 'absent') absent++;
      //       else if (student.status === 'late') late++;
      //     });
      //   });

      Object.keys(this.attendanceStatuses).forEach((key) => {
        if (this.attendanceStatuses[key]['status'] === 'present') present++;
        if (this.attendanceStatuses[key]['status'] === 'absent') absent++;
        if (this.attendanceStatuses[key]['status'] === 'late') late++;
      });

      return [present, absent, late];
    },

    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },

    SECTION_OPTIONS() {
      return this.sectionItems.map((section) => ({
        label: section.name,
        value: section._id,
      }));
    },
    ACADEMIC_YEARS() {
      return this.academic_years.map((academic_year) => ({
        label: academic_year.name,
        value: academic_year._id,
      }));
    },

    selectedStatus() {
      if (this.selectedStatusIndex === -1) return 'All';
      return this.STATUS_OPTIONS[this.selectedStatusIndex];
    },
  },

  watch: {
    activeGrade() {
      this.fetchSections();
    },
    activeSection() {
      this.fetchAcademicYears();
    },
    activeAy() {
      this.fetchAttendance();
    },

    attendanceDate() {
      this.fetchAttendance();
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,

    displayUserDetail(id) {
      let componentProps = {
        id,
        type: 'student',
        url: 'student/studentProfile/',
      };
      eventBus.$emit('open-modal', {
        modalId: 39,
        componentProps,
      });
    },

    async handleSave() {
      this.working = true;
      let data = [];
      Object.keys(this.attendanceStatuses).forEach((key) => {
        data.push({
          student: key,
          sendNotification: this.attendanceStatuses[key]['sendNotification'],
          hasPermission: this.attendanceStatuses[key]['havePermission'],
          remark: this.attendanceStatuses[key]['remark'],
          status: this.attendanceStatuses[key]['status'],
        });
      });

      let formattedDate = '';
      if (this.attendanceDate) {
        formattedDate = moment(this.attendanceDate).format('YYYY-MM-DD');
      }

      try {
        let response = await this.request({
          method: 'post',
          url: 'school/attendance/' + this.activeAy,
          data: {
            attendance: data,
            date: formattedDate || undefined,
          },
        });

        if (response && !response.error) {
          this.working = false;
          eventBus.$emit('open-toast', { message: 'submitted successfully' });
        } else {
          this.working = false;
          eventBus.$emit('open-toast', {
            message: this.error,
            error: true,
          });
        }

        this.isDirty = false;
      } catch (error) {
        eventBus.$emit('open-taost', {
          message: this.error,
          error: true,
        });
      }
    },

    handleCancel() {},

    setRemark({
      studentId,
      status,
      response: { remarkResponse, havePermission, sendNotification },
    }) {
      let remark = '';

      remark = remarkResponse;

      this.attendanceStatuses[studentId] = {
        status,
        remark,
        havePermission,
        sendNotification,
      };

      this.attendanceStatuses = { ...this.attendanceStatuses };
    },

    changeStatus(studentId, status) {
      this.isDirty = true;
      if (status === 'absent')
        eventBus.$emit('open-modal', {
          modalId: 22,
          cb: this.setRemark,
          cbParams: { studentId, status },
        });
      else {
        this.attendanceStatuses[studentId] = { status };
        this.attendanceStatuses = { ...this.attendanceStatuses };
      }
    },

    handleSelectStatus(_, __, selected) {
      this.setSelectedStatusIndex(selected.dataPointIndex);
    },

    handleMarkerClick(markerClick) {
      console.info(markerClick);
      return;
    },

    setSelectedStatusIndex(index) {
      if (index === this.selectedStatusIndex) this.selectedStatusIndex = -1;
      else this.selectedStatusIndex = index;
    },

    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
        this.activeGrade = response.record[0]._id;
      } else {
        this.gradeItems = [];
        this.activeGrade = '';
      }
    },

    async fetchSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/class/' + this.activeGrade,
      });
      if (response && !response.error) {
        this.sectionItems = response.record;
        this.activeSection = response.record[0]._id;
      } else {
        this.sectionItems = [];
        this.activeSection = '';
      }
    },
    async fetchAcademicYears() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/section/' + this.activeSection,
      });
      if (response && !response.error) {
        this.academic_years = response.record;
        this.activeAy = this.academic_years[0]._id;
        this.academic_year = response.record[0];
      } else {
        this.academic_years = [];
        this.activeAy = '';
        this.noData = true;
      }
    },

    async fetchAttendance() {
      this.loading = true;
      let formattedDate = '';

      if (this.attendanceDate)
        formattedDate = moment(this.attendanceDate).format('YYYY-MM-DD');
      let response = await this.request({
        method: 'get',
        url: 'school/attendance/' + this.activeAy + '/' + formattedDate,
      });
      this.attendances = [];
      this.attendanceStatuses = {};

      this.attendanceBlock = {};
      if (response && !response.error) {
        this.newAttendance = response.first;
        this.attendances = response.record ?? [];
        this.attendances.forEach((student) => {
          this.attendanceStatuses[student._id] = {
            status: student.status || 'present',
            remark: student.remark || undefined,
            havePermission: student.havePermission,
          };
        });

        this.attendanceStatuses = { ...this.attendanceStatuses };
      }

      this.loading = false;
    },
  },

  async created() {
    await this.fetchGrades();
  },
};
</script>

<style></style>
