<template>
  <div id="app">
    <printer v-if="printer_on" />
    <socket v-if="signedIn" />
    <entry />
    <modals />
    <toast />
    <div
      class="hidden tippy-tooltip light-theme primary-theme tippy-popper tippy-arrow"
    ></div>
    <template v-if="signedIn">
      <timer v-working="resetInactivityCounter" />
      <sign-me-out />
    </template>
  </div>
</template>
<script>
import Modals from '@/components/modals/Modals.vue';
import Printer from './components/printer/Printer.vue';
import Toast from './components/collection/Toast.vue';
import Socket from './components/socket/socket.vue';
import Entry from './views/entry/Entry.vue';
import Timer from './components/Timer.vue';
import SignMeOut from './components/socket/SignMeOut.vue';
import eventBus from './eventBus';
import { mapActions, mapGetters } from 'vuex';
import { tokenService } from '@/services/storageService';
// import { navs } from '@/config/constants';

export default {
  components: {
    Modals,
    Socket,
    Toast,
    Entry,
    Printer,
    Timer,
    SignMeOut,
  },
  data() {
    return {
      navs: {
        'school-setup': [
          {
            label: 'Grades',
            name: 'grade',
            icon: 'atom',
            slug: 'cls',
            code: 'sch-gr',
            path: '/school/grade-list',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Sections',
            name: 'section',
            icon: 'chalkboard-user',
            slug: 'cls',
            code: 'sch-sec',
            path: '/school/section-list',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Academic Years',
            name: 'academic-year',
            icon: 'calendar',
            slug: 'str',
            code: 'sch-aca',
            path: '/school/academic-year',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Subjects',
            name: 'subjects',
            icon: 'list-alt',
            slug: 'cor',
            code: 'sch-sub',
            path: '/school/subject',
            allowed_for: ['super_admin', 'registrar'],
          },
          // {
          //   icon: 'school',
          //   name: 'my-school',
          //   slug: 'sch',
          //   label: 'My School',
          //   path: '/school/my-school',
          //   allowed_for: [],
          // },
        ],

        admission: [
          {
            label: 'Students',
            name: 'students',
            icon: 'user-graduate',
            slug: 'stu',
            code: 'adm-stu',
            path: '/registrations/student',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Teachers',
            name: 'teachers',
            icon: 'chalkboard-user',
            slug: 'tr',
            code: 'adm-thr',
            path: '/registrations/teacher',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'School Officials',
            name: 'school-official',
            icon: 'school',
            slug: 'sco',
            code: 'adm-stf',
            path: '/registrations/school-official',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Parents',
            name: 'parents',
            icon: 'family-1',
            slug: 'par',
            code: 'adm-par',
            path: '/registrations/parent',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Migration',
            name: 'parents',
            icon: 'file-upload',
            slug: 'par',
            code: 'adm-stu',
            path: '/registrations/import-students',
            allowed_for: ['super_admin', 'registrar'],
          },
          // { icon: 'user', label: 'Officials', path: '/registrations/official' },
        ],

        enrollment: [
          {
            label: 'Student Enrollment',
            name: 'academic-section',
            icon: 'compress',
            slug: 'cls',
            code: 'enrl-stu',
            path: '/enrollment/academic-section',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Course Enrollment',
            name: 'teacher-course',
            icon: 'signature',
            slug: 'cls',
            code: 'enrl-cor',
            path: '/enrollment/teacher-course',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Mark List',
            name: 'mark-list',
            icon: 'address-book',
            slug: 'cls',
            code: 'aca-mkl',
            path: '/enrollment/mark-list',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        'time-table': [
          {
            label: 'Calendar',
            icon: 'calendar',
            slug: 'cal',
            code: 'tmt-cal',
            path: '/schedule/calendar',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Session',
            icon: 'gear',
            slug: 'scd',
            code: 'tmt-ses',
            path: '/schedule/session',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Schedule',
            icon: 'clock',
            slug: 'scd',
            code: 'tmt-sdl',
            path: '/schedule/period',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        attendance: [
          {
            label: 'Overview',
            icon: 'meter',
            slug: 'att',
            code: 'att-da',
            path: '/attendance/dashboard',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Attendance',
            icon: 'attendance-1',
            slug: 'att',
            code: 'att-att',
            path: '/attendance/detail',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        fee: [
          // {
          //   icon: 'atom',
          //   label: 'Group',
          //   slug: 'grp',
          //   path: '/fee/group',
          //   allowed_for: ['super_admin', 'registrar'],
          // },
          {
            icon: 'info-circle',
            label: 'Payment Type',
            slug: 'pty',
            code: 'fee-pt',
            path: '/fee/payment-types',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'dollar-sign',
            label: 'Payment Group',
            slug: 'pa',
            code: 'fee-pg',
            path: '/fee/payment-group',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'user',
            label: 'Payer',
            slug: 'pyr',
            code: 'fee-pyr',
            path: '/fee/payers',
            allowed_for: ['super_admin', 'registrar'],
          },

          {
            icon: 'exchange-alt',
            label: 'Discount',
            slug: 'dsc',
            code: 'fee-dis',
            path: '/fee/discount',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'cog',
            label: 'Generate Bill',
            slug: 'dsc',
            code: 'fee-gen',
            path: '/fee/generate-bill',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        'shl-management': [
          {
            label: 'Academic Year',
            icon: 'user',
            slug: 'acy',
            code: 'adm-stf',
            path: '/shl-management/academic-year',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Grade',
            icon: 'user',
            slug: 'cls',
            path: '/shl-management/grade-list',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            label: 'Section',
            icon: 'user',
            slug: 'sec',
            path: '/shl-management/section-list',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        grading: [
          {
            icon: 'meter',
            label: 'Overview',
            slug: 'gr',
            path: '/grading/overview',
            allowed_for: ['super_admin', 'registrar'],
          },
        ],

        settings: [
          {
            icon: 'gear',
            name: 'school-pref',
            label: 'School Preferences',
            slug: 'rl',
            code: 'set-pre',
            path: '/settings/school-pref',
            allowed_for: ['super_admin', 'registrar'],
          },
          {
            icon: 'chart-bar',
            name: 'role',
            slug: 'rl',
            code: 'set-rl',
            label: 'Roles',
            path: '/settings/roles',
            allowed_for: ['super_admin', 'shl_registrar'],
          },
          {
            icon: 'key',
            name: 'permissions',
            slug: 'rl',
            code: 'set-pm',
            label: 'Permissions',
            path: '/settings/permissions',
            allowed_for: ['super_admin', 'shl_registrar'],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('main', ['darkMode']),
    ...mapGetters(['authModules']),
    ...mapGetters('printer', ['printer_on']),
    signedIn() {
      return !!tokenService.getToken();
    },
  },
  methods: {
    ...mapActions(['logout']),
    ...mapActions('main', ['request', 'setData']),
    ...mapActions('timer', ['reset']),
    resetInactivityCounter() {
      this.reset({ stateKey: 'inactivity' });
    },
  },
  created() {
    eventBus.$on('reset-inactivity-counter', () =>
      this.resetInactivityCounter()
    );
    eventBus.$on('Unauthorized', async () => {
      // let authNavs = navs.filter((nav)  => this.authModules.includes(nav.code))
      // if(authNavs.length){
      //   this.$router.push(`/${authNavs[0].to}`)
      // } else {
      // this.$router.push('/dashboard')
      let signedOut = await this.logout();
      if (signedOut) window.location.reload();
      // }
    });
    eventBus.$on('sign-out', async () => {
      console.log('over herefaga');
      let signedOut = await this.logout();
      if (signedOut) window.location.reload();
    });
  },
};
</script>

<style>
#app {
  font-family: Inter-Regular;
  /* font-family: Lato, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
}

@font-face {
  font-family: 'Helvetica';
  src: local('Helvetica'),
    url(./assets/fonts/helvetica/Helvetica.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'),
    url(./assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'),
    url(./assets/fonts/lato/Lato_Regular.ttf) format('truetype');
}
</style>
