import Vue from 'vue';
import VueRouter from 'vue-router';
import ROUTES from './routes.js';
import { tokenService } from '../services/storageService';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: ROUTES
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(route => route.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    route => route.meta.onlyWhenLoggedOut
  );
  const loggedIn = !!tokenService.getToken();

  if (loggedIn) {
    const mainPath = '/dashboard';

    if (onlyWhenLoggedOut && isPublic) return next(mainPath);
  } else {
    if (!isPublic)
      return next({
        path: '/signin'
      });
    // return next({
    //   path: '/'
    // });
  }

  next();
});

export default router;
