<template>
  <div class="">
    <div class="">
      <p class="uppercase text-lg text-textMedium tracking-wider">
        communication book
      </p>
    </div>
    <div class="grid grid-cols-2 gap-3">
      <div class="border-1 border-gray-100 rounded-lg p-4 flex flex-col gap-3">
        <p class="text-center">Create Template</p>
        <base-input
          label="Template Title"
          :check="true"
          required
          placeholder="Daily/Weekly communication book"
          v-model="Form.title"
        />
        <div class="grid grid-cols-5 items-end gap-2">
          <base-input
            label="Questionnaire"
            :check="true"
            required
            placeholder="E.g - How does he perform in class"
            class="col-span-4"
            v-model="question"
          />
          <base-button label="Add" icon="plus" @click="addQuestion" />
        </div>
        <div class="border-1 border-gray-300 rounded-lg h-32 overflow-auto p-3">
          <div class="" v-if="Form.questionnaire.length">
            <div class="flex flex-col gap-2">
              <div
                class="flex items-center justify-between hover:bg-gray-100 rounded-default p-1"
                v-for="(question, i) in Form.questionnaire"
                :key="i"
              >
                <div class="flex items-start gap-3">
                  <P class="">{{ i + 1 }}.</P>
                  <p>{{ question.question }}?</p>
                </div>
                <icon
                  name="times"
                  class="text-red-500 cursor-pointer"
                  @click="removeQuestion(i)"
                />
              </div>
            </div>
          </div>
          <p class="text-gray-400" v-else>
            Questions will be displayed here respectively.
          </p>
        </div>
        <div class="grid grid-cols-5 items-end gap-2 mt-6">
          <base-input
            label="Answers"
            :check="true"
            required
            placeholder="Good"
            class="col-span-3"
            v-model="answer"
          />
          <base-input
            label="Point"
            :check="true"
            required
            placeholder="4"
            v-model="value"
          />
          <base-button label="Add" icon="plus" @click="addAnswer" />
        </div>
        <div
          class="border-1 border-gray-300 rounded-lg h-32 overflow-auto p-3 flex flex-col gap-2"
        >
          <div class="" v-if="Form.answers.length">
            <div
              class="flex items-center justify-between hover:bg-gray-100 rounded-default p-1"
              v-for="(answer, i) in Form.answers"
              :key="i"
            >
              <div class="flex items-center gap-3">
                <input
                  type="radio"
                  name="choices"
                  :value="answer.title"
                  v-model="Form.defaultAnswer.title"
                />
                <label for="choices" class="capitalize"
                  >{{ answer.title }} [{{ answer.value }}]</label
                >
                <status-chip
                  label="default"
                  style="max-width: fit-content"
                  rounded
                  v-if="Form.defaultAnswer.title === answer.title"
                />
              </div>
              <icon
                name="times"
                class="text-red-500 cursor-pointer"
                @click="removeAnswer(i)"
                ref="deleteAnswer"
              />
            </div>
          </div>
          <p class="text-gray-400" v-else>Answers will be displayed here.</p>
        </div>
        <div class="flex justify-end">
          <base-button label="Submit" icon="check-circle" @click="submitForm" />
        </div>
      </div>

      <div class="border-1 border-gray-100 rounded-lg p-4 flex flex-col gap-3">
        <div class="flex justify-between">
          <p class="text-center">Template Preview</p>

          <base-button
            label="Delete Template"
            icon="trash-alt"
            @click="deleteTemplate"
            v-if="selectedBook != null"
          />
        </div>
        <base-select
          label="Select communication book"
          :items="SAVED_FORMS"
          placeholeder="Communication books"
          v-model="selectedBook"
          clearable
        />
        <div class="flex flex-col gap-4" v-if="Form.answers.length">
          <div
            class="flex flex-col gap-1"
            v-for="(question, i) in Form.questionnaire"
            :key="i"
          >
            <p>{{ i + 1 }}. {{ question.question }}?</p>
            <div class="grid grid-cols-5">
              <div
                class="flex gap-2"
                v-for="(answer, j) in Form.answers"
                :key="j"
              >
                <input type="radio" :name="`choice${i}`" />
                <label :for="`choice${i}`" class="capitalize">
                  {{ answer.title }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <base-no-data v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import eventBus from '@/eventBus';

export default {
  data() {
    return {
      question: '',
      answer: '',
      value: '',
      forms: [],
      selectedBook: null,
      Form: {
        title: '',
        questionnaire: [],
        answers: [],
        defaultAnswer: {
          title: '',
          value: '',
        },
      },
    };
  },

  computed: {
    SAVED_FORMS() {
      return this.forms.map((form) => ({
        label: form.title,
        value: form._id,
      }));
    },
  },

  watch: {
    selectedBook() {
      this.populateForm(
        this.forms.filter((form) => form._id == this.selectedBook)[0]
      );
    },
  },
  methods: {
    ...mapActions('main', ['request']),

    addQuestion() {
      if (this.question == '') {
        eventBus.$emit('open-toast', {
          message: 'please write question first',
          error: true,
        });
        return;
      }
      this.Form.questionnaire.push({ question: this.question });
      this.question = '';
    },
    addAnswer() {
      if (this.answer == '' || this.value == '') {
        eventBus.$emit('open-toast', {
          message: 'please write answer and/or value',
          error: true,
        });

        return;
      }

      this.Form.answers.push({ title: this.answer, value: this.value });
      this.answer = '';
      this.value = '';
    },

    removeQuestion(i) {
      this.Form.questionnaire.splice(i, 1);
    },

    removeAnswer(i) {
      this.Form.answers.splice(i, 1);
    },

    populateForm(data) {
      this.Form = {
        title: data.title,
        questionnaire: data.questionnaire,
        answers: data.answers,
        defaultAnswer: data.defaultAnswer,
      };
    },

    async fetchForm() {
      let response = await this.request({
        method: 'get',
        url: 'school/commbook/template',
      });
      if (response && !response.error) {
        this.forms = response.record;
      }
    },

    async deleteTemplate() {
      let componentProps = {
        message: 'Are you sure you want to delete this template',
        title: 'This action is not reversible.',
      };

      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDeleteTemplate,
      });
    },

    async proceedDeleteTemplate() {
      let response = await this.request({
        method: 'delete',
        url: `school/commbook/template/:${this.selectedBook}`,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: response.message,
          error: false,
        });
        this.fetchForm();
      }
    },

    async submitForm() {
      if (!this.questionnaire || !this.answers) {
        eventBus.$emit('open-toast', {
          message: 'Check all fields',
          error: true,
        });

        return;
      }
      let response = await this.request({
        method: 'post',
        url: 'school/commbook/template',
        data: { ...this.Form },
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Form submitted successfully',
          error: false,
        });

        this.forms = response.record;
      } else
        eventBus.$emit('open-toast', {
          message: response.error,
          error: true,
        });
    },
  },
  async created() {
    await this.fetchForm();
  },
};
</script>
<style scoped>
@media print {
  @page {
    size: landscape;
  }
}
</style>
