<template>
  <div
    class="px-5 py-2 hover:bg-grayBg cursor-pointer flex space-x-5 border-b-1 border-gray-100"
    :class="{ 'bg-grayBg': selected }"
  >
    <div
      class="h-12 w-12 rounded-full bg-gray-100 flex justify-center items-center"
    >
      <img
        v-if="contact.profilePic"
        :src="PROFILE_PATH + contact.profilePic"
        v-viewer="{ inline: false, movable: true }"
        class="w-full h-full object-cover rounded-full"
        alt=""
        crossorigin="anonymous"
      />
      <icon v-else :size="18" name="user" class="w-full h-full"></icon>
    </div>
    <div class="flex-grow">
      <div class="flex space-x-3">
        <p class="text-textDark capitalize font-semibold flex-grow">
          {{ contact.firstName }}
          {{ contact.middleName }}
        </p>
        <div class="bg-success text-white w-2 h-2 flex items-center rounded-full">
          <!-- <p class=" text-xs">{{ contact.relation }}</p> -->
        </div>
      </div>
      <div class="flex space-x-3 items-center">
        <p class="text-textLight text-xs">{{ contact.role }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { PROFILE_PATH } from '@/config/config.js';
export default {
  props: ['contact', 'selected'],
  data() {
    return {
      PROFILE_PATH,
    };
  },
};
</script>

<style></style>
