<template>
  <div
  @click="displayUserDetail(parent._id)"
  class="flex space-x-1 rounded-default cursor-pointer bg-white hover:bg-grayBg p-2"
>
  <!-- <pre>{{parent}}</pre> -->
  <div class="px-2">
    <div
      class="h-12 w-12 rounded-full bg-gray-100 flex justify-center items-center"
    >
      <img
        v-if="parent.profilePic"
        :src="PROFILE_PATH + parent.profilePic"
        v-viewer="{ inline: false, movable: true }"
        class="w-full h-full object-cover rounded-full"
        alt=""
        crossorigin="anonymous"
      />
      <icon v-else :size="18" name="user" class="w-full h-full"></icon>
    </div>
  </div>
  <div class="flex-auto">
    <div class="flex justify-between">
      <div class="capitalize">
        {{
          `${parent.firstName} ${parent.middleName || ''} ${
            parent.lastName
          }`
        }}
      </div>
      <div
        class="text-sm text-white rounded-sm p-2 py-1"
        :class="{
          'bg-verified' : parent.status === 'verified',
          'bg-pending' : parent.status === 'pending',
          'bg-suspended' : parent.status === 'suspended'
        }"
      >
        {{ parent.status }}
      </div>
    </div>
    <div class="flex space-x-2 items-center text-sm">
      <icon name="phone-alt" :size="10" />
      <div class="flex space-x-2">
        <div
          v-for="phoneNumber in parent.phoneNumber"
          :key="phoneNumber"
          class="pr-2 border-r-2 border-dgray"
        >
          {{ phoneNumber || '' }}
        </div>
      </div>
      <div class="tracking-widest">{{ parent.userId }}</div>
    </div>
    <br>
    <hr class="text-gray-100">
  </div>
</div>

</template>

<script>
import { BASE_URL } from '@/config/config';
import { ago } from '@/utils';
import { mapActions } from 'vuex';
import eventBus from '@/eventBus';
import { PROFILE_PATH } from '../../../config/config';
export default {
  props: ['parent'],
  data() {
    return {
      BASE_URL,
      PROFILE_PATH,
    };
  },
  filters: { ago },
  methods: {
    ...mapActions('main', ['setData']),
    displayUserDetail(id) {
      let componentProps={
        id,
        type: 'parent',
        url: 'parent/parentProfile/'
      }
      eventBus.$emit('open-modal', {
        modalId: 39,
        componentProps
      });
    },

  },
};
</script>

<style></style>
