<template>
  <div
    class=" py-4 cursor-pointer flex space-x-3 items-center hover:bg-gray-100 z-0"
    @click="handleClick"
  >
    <div>
      <icon name="folder-solid" :size="20" class=" text-yellow-500" />
    </div>
    <div class="flex-grow">
      {{ content.name }}
    </div>
    <div class="">
      <p class=" text-sm font-light text-textDark">
        {{ content.no_contents }} Resources
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      required: true
    }
  },
  methods: {
    handleActions() {
      
    },
    handleClick() {
      
    }
  }
};
</script>

<style></style>
