<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title>
      {{ isUpdate ? 'Update' : 'Create New' }} Section
    </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-1 gap-5">
          <base-input
            label="Section Name *"
            placeholder="section name"
            v-model="Form.name"
            :errors="errors.name"
            check
          />

          <!-- <base-select
            label=""
            placeholder=""
            v-model="Form.mode"
            :items="MODE_OPTIONS"
            :errors="errors.mode"
          /> -->

          <switch-button label="Is Active" v-model="Form.status" />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import SwitchButton from '../base/SwitchButton.vue';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm', 'grade_id'],
  mixins: [validation],
  data() {
    return {
      Form: {
        class: this.grade_id,
        name: '',
        // mode: '',

        status: '', // active, closed, inactive
      },
      working: false,
      errors: {},
      meta: [{ key: 'Form.name', label: 'Section name' }],
      initial: true,
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    isUpdate() {
      if (this.oldForm) return true;
      else return false;
    },
    // MODE_OPTIONS() {
    //   return [
    //     { label: 'Children', value: 'children' },
    //     { label: 'Adult', value: 'adult' }
    //   ];
    // }
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
      this.initial = true;
    },
    populateData() {
      Object.keys(this.oldForm).forEach((key) => {
        this.Form[key] = this.oldForm[key];
      });

      this.Form['status'] = ['closed', 'inactive'].includes(
        this.oldForm['status']
      )
        ? false
        : true;
    },
    async handleCreate() {
      this.working = true;

      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      let response = await this.request({
        method: 'post',
        url: 'school/section',
        data: newForm,
      });
      if (response && !response.error) {
        this.item = response.item;
        eventBus.$emit('open-toast', {
          message: 'section updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true,
        });
      }
      this.working = false;
    },

    async handleUpdate() {
      this.working = true;

      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      newForm = {
        name: newForm.name,
        class: newForm.class,
        status: newForm.status,
      };

      let response = await this.request({
        method: 'put',
        url: 'school/section/' + this.oldForm._id,
        data: newForm,
      });
      if (response && !response.error) {
        this.item = response.item;
        eventBus.$emit('open-toast', {
          message: 'grade updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true,
        });
      }
      this.working = false;
    },
  },
  created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    if (this.isUpdate) this.populateData();
  },
};
</script>

<style></style>
