<template>
  <div class="">
    <div class="border-1 border-gray-100 shadow-default p-10 rounded-lg">
      <p class="text-center font-semibold text-lg">
        Academic Background / Student Documents
      </p>
      <loader v-if="loading"></loader>
      <div
        class="grid grid-cols-4 gap-16 mt-12"
        v-else-if="userDetail.attachments.length"
      >
        <div
          class="border-1 flex flex-col items-center border-gray-100 rounded-lg p-4 hover:bg-blue-50"
          v-for="(doc, i) in userDetail.attachments"
          :key="i"
          :title="doc.fileLabel"
        >
          <div class="w-32 h-40">
            <img
              v-if="doc.fileType == 'pdf'"
              :src="
                doc.fileType === 'image'
                  ? `${ATTACHMENTS_PATH}${doc.filePath.split('attachments')[1]}`
                  : pdfPic
              "
              class="object-cover w-full h-full cursor-pointer"
              alt=""
              @click="showPdf(i)"
            />
            <img
              v-else-if="doc.fileType == 'image'"
              :src="
                doc.fileType === 'image'
                  ? `${ATTACHMENTS_PATH}${doc.filePath.split('attachments')[1]}`
                  : pdfPic
              "
              v-viewer="{ inline: false, movable: true }"
              class="object-cover w-full h-full cursor-pointer"
              alt=""
            />
          </div>
          <p class="text-center truncate cursor-default">
            {{ doc.fileLabel }}
          </p>
        </div>
      </div>
      <base-no-data v-else message="no document added" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PROFILE_PATH, ATTACHMENTS_PATH } from '@/config/config.js';
import eventBus from '@/eventBus';
import pdfPic from '@/assets/pdfBlue.png';

export default {
  props: {
    userId: { type: String },
    routeURL: { type: String },
  },

  data() {
    return {
      userDetail: '',
      PROFILE_PATH,
      ATTACHMENTS_PATH,
      loading: false,
      pdfPic,
    };
  },

  methods: {
    ...mapActions('main', ['request']),

    async fetchUser() {
      this.loading = true;
      let response = await this.request({
        method: 'get', //this.userId
        url: this.routeURL + this.userId,
      });
      if (response && !response.error) {
        this.userDetail = response.record;
        if (this.userType == 'school official') {
          this.userDetail.role = this.userDetail.role.name;
        }
      } else {
        this.userDetail = '';
      }
      this.loading = false;
    },
    showPdf(i) {
      let componentProps = {
        path: this.userDetail.attachments[i].filePath,
      };
      console.log(i, componentProps);
      eventBus.$emit('open-modal', {
        modalId: 54,
        componentProps,
      });
    },
  },

  async created() {
    await this.fetchUser();
  },
};
</script>

<style></style>
