<template>
  <div>
    <base-title :title="(update ? 'Update' : 'Create') + ' Parent'">
      <template #actions>
        <div class="flex space-x-4">
          <base-button
            :primary="false"
            icon="arrow-left"
            label="Go Back"
            class="bg-gray-100 text-textDarkest px-3"
            @click="handleGoBack"
          />
          <base-button
            icon="plus"
            :label="update ? 'Update' : 'Create'"
            class="px-3"
            :working="working"
            @click="update ? handleUpdate() : handleCreate()"
          />
        </div>
      </template>
    </base-title>
    <div class="mt-2 bg-white p-5">
      <stepper
        :steps="steps"
        :working="working"
        @finished="update ? handleUpdate() : handleCreate()"
        :finish_label="update ? 'Update' : 'Create'"
      >
        <template #Basic>
          <loader type="list" :amount="6" v-if="loading"></loader>

          <div v-else class="grid grid-cols-4 gap-3 my-8">
            <div class="border-1 p-4 rounded-default border-gray-100 shadow-sm">
              <span class="font-bold">UPLOAD PARENT PHOTO</span>
              <div
                class="w-40 h-48 my-8 cursor-pointer mx-auto"
                @click="handleOpenDialog('profile-input')"
              >
                <img
                  :src="
                    !profilePicObj
                      ? PROFILE_PATH + Form.profilePic
                      : profilePicObj
                  "
                  alt=""
                  class="object-cover w-full h-full"
                  crossorigin="anonymous"
                />
                <div class="mt-8">
                  <input
                    class=""
                    style="display: none"
                    ref="profile-input"
                    @change="
                      handleSelectFile('profile-input', 'Form.profilePic')
                    "
                    type="file"
                    name="file-input"
                  />
                  <div
                    class="bg-primary text-white p-2 w-full rounded-default cursor-pointer text-center mt-4"
                  >
                    Upload Photo
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-span-3 border-1 p-4 rounded-default border-gray-100 shadow-sm"
            >
              <span class="font-bold">PERSONAL</span>
              <div class="grid grid-cols-3 gap-5 my-8">
                <base-input
                  required
                  label="Name"
                  placeholder="name"
                  v-model="Form.firstName"
                  :errors="errors.firstName"
                  capitalize
                  check
                />
                <base-input
                  label="Father's Name"
                  placeholder="Father's name"
                  v-model="Form.middleName"
                  :errors="errors.middleName"
                  capitalize
                />
                <base-input
                  label="Grandfather's Name"
                  placeholder="grandfather's name"
                  v-model="Form.lastName"
                  :errors="errors.lastName"
                  capitalize
                />

                <base-select
                  required
                  label="Gender"
                  placeholder="gender"
                  v-model="Form.gender"
                  :errors="errors.gender"
                  :items="GENDER_OPTIONS"
                  check
                />
                <base-input
                  v-if="isGreg"
                  label="Birth Date"
                  v-model="Form.birthdate"
                  :errors="errors.birthdate"
                  type="date"
                />
                <base-eth-calendar
                  v-if="!isGreg"
                  label="Birth Date"
                  v-model="Form.birthdate"
                  :errors="errors.birthdate"
                  :isUpdate="update"
                />
                <base-input
                  label="Occupation"
                  placeholder="occupation"
                  v-model="Form.occupation"
                  :errors="errors.occupation"
                  capitalize
                />
              </div>

              <span class="font-bold">ADDRESS</span>
              <div class="grid grid-cols-3 gap-5 my-8">
                <base-select
                  bordered
                  label="Region"
                  placeholder="region"
                  v-model="Form.region"
                  :errors="errors.region"
                  :items="REGION_OPTIONS"
                />
                <base-input
                  label="City"
                  placeholder="city"
                  v-model="Form.city"
                  :errors="errors.city"
                  capitalize
                />
                <base-input
                  label="Sub City"
                  placeholder="Sub City"
                  v-model="Form.subcity"
                  :errors="errors.subcity"
                  capitalize
                />
                <base-input
                  label="Street Address"
                  placeholder="Street Address"
                  v-model="Form.address"
                  :errors="errors.address"
                  capitalize
                />
                <base-input
                  label="House Number"
                  placeholder="House Number"
                  v-model="Form.houseNumber"
                  :errors="errors.houseNumber"
                  capitalize
                />
              </div>

              <span class="font-bold">CONTACT</span>
              <div class="grid grid-cols-3 gap-5 my-8">
                <div class="">
                  <span>phone Number <span class="text-error">*</span></span>
                  <div class="grid grid-cols-6 gap-2">
                    <base-select
                      class="col-span-2"
                      :items="COUNTRY_CODE_OPTIONS"
                      v-model="countryCode"
                      :minWFull="true"
                    />
                    <base-multitype
                      class="col-span-4"
                      placeholder="phone number"
                      v-model="Form.phoneNumber"
                      :items="Form.phoneNumber"
                      :errors="errors.phoneNumber"
                      check
                    />
                  </div>
                </div>
                <base-input
                  label="Email"
                  placeholder="email"
                  v-model="Form.email"
                  :errors="errors.email"
                  check
                />
              </div>
            </div>
          </div>
        </template>
        <template #More>
          <div>{{ mode }} {{ id }}</div>
        </template>
      </stepper>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Stepper from '@/components/Stepper.vue';
import { required, email } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import eventBus from '@/eventBus';
import { PROFILE_PATH } from '@/config/config.js';
import objUrl from '@/mixins/objUrl.js';
import profilePics from '@/assets/avatars/avatar04.png';
import { formatInputDate } from '@/utils';

export default {
  components: { Stepper },
  props: {
    mode: { type: String, default: 'create' },
    id: { type: String },
  },
  mixins: [validation, objUrl],
  data() {
    return {
      initial: true,
      working: false,
      PROFILE_PATH,
      steps: ['Basic'],
      loading: false,
      Form: {
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        birthdate: '',
        occupation: '',
        region: '',
        city: '',
        subcity: '',
        address: '',
        houseNumber: '',
        phoneNumber: [],
        email: '',
        profilePic: '',
      },
      countryCode: '+251',
      profilePicObj: profilePics,
      errors: {},
      meta: [
        { key: 'Form.firstName', label: 'Name' },
        { key: 'Form.gender', label: 'Gender' },
        { key: 'Form.phoneNumber', label: 'Phone Number' },
        { key: 'Form.email', label: 'This' },
      ],
    };
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['firstName', 'gender'].includes(key)) {
            acc[key] = { required };
          } else if (['phoneNumber'].includes(key)) {
            acc[key] = { required };
          } else if (['email'].includes(key)) {
            acc[key] = { email };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    ...mapGetters(['user']),
    ...mapState('main', ['globalSchoolConfig']),

    isGreg() {
      return (
        this.globalSchoolConfig &&
        this.globalSchoolConfig.calendarType == 'greg'
      );
    },

    REGION_OPTIONS() {
      return [
        { label: 'Addis Ababa', value: 'Addis Ababa' },
        { label: 'Afar', value: 'Afar' },
        { label: 'Amahara', value: 'Amahara' },
        { label: 'Beninshangul', value: 'Beninshangul' },
        { label: 'Dire Dawa', value: 'Dire Dawa' },
        { label: 'Gambela', value: 'Gambela' },
        { label: 'Harar', value: 'Harar' },
        { label: 'Oromo', value: 'Oromo' },
        { label: 'Sidama', value: 'Sidama' },
        { label: 'Somali', value: 'Somali' },
        { label: 'Southern Region', value: 'Southern Region' },
        { label: 'Tigray', value: 'Tigray' },
      ];
    },
    GENDER_OPTIONS() {
      return [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ];
    },
    COUNTRY_CODE_OPTIONS() {
      return [
        { label: '+251', value: '+251' },
        { label: '+1', value: '+1' },
        { label: '+44', value: '+44' },
        { label: '+32', value: '+32' },
        { label: '+33', value: '+33' },
        { label: '+49', value: '+49' },
        { label: '+30', value: '+30' },
        { label: '+249', value: '+249' },
        { label: '+971', value: '+971' },
        { label: '+41', value: '+41' },
        { label: '+86', value: '+86' },
        { label: '+45', value: '+45' },
        { label: '+253', value: '+253' },
        { label: '+20', value: '+20' },
        { label: '+291', value: '+291' },
      ];
    },

    update() {
      if (this.id) return true;
      return false;
    },
  },
  methods: {
    ...mapActions('main', ['request', 'upload']),
    handleGoBack() {
      this.$router.back();
    },

    async handleCreate() {
      this.initial = false;
      if (!this.isValid())
        return eventBus.$emit('open-toast', {
          message: 'please check all the fields',
          error: true,
        });
      const multidatas = ['phoneNumber'];

      let formData = new FormData();
      Object.keys(this.Form).forEach((key) => {
        if (multidatas.includes(key) && this.Form[key])
          formData.append(key, JSON.stringify(this.Form[key]));
        else if (this.Form[key] != '' && this.Form[key] != undefined)
          formData.append(key, this.Form[key]);
      });

      this.working = true;
      let response = await this.upload({
        method: 'post',
        url: 'parent/parentProfile',
        data: formData,
      });
      if (response && !response.error) {
        this.$router.push({ name: 'manage-parent' });
        eventBus.$emit('open-modal', {
          modalId: 3,
          componentProps: { user: response.record },
        });
        return eventBus.$emit('open-toast', {
          message: 'successfully created parent',
        });
      } else
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      this.working = false;

      this.initial = true;
    },

    populateForm(data) {
      this.Form = {
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        gender: data.gender,
        birthdate: this.isGreg
          ? formatInputDate(data.birthdate)
          : data.birthdate,
        region: data.region,
        city: data.city,
        subcity: data.subcity,
        address: data.address,
        houseNumber: data.houseNumber,
        phoneNumber: data.phoneNumber,
        occupation: data.occupation,
        email: data.email,
        profilePic: data.profilePic,
        // school: data.school,
      };
    },

    async handleFetchUser() {
      let response = await this.request({
        method: 'get',
        url: 'parent/parentProfile/' + this.id,
      });

      if (response && !response.error) {
        this.populateForm(response.record);
      }
    },

    async handleUpdate() {
      this.initial = false;
      if (!this.isValid())
        return eventBus.$emit('open-toast', {
          message: 'please check all the fields',
          error: true,
        });

      const multidatas = ['phoneNumber'];
      if (typeof this.Form.profilePic == 'string') delete this.Form.profilePic;

      let formData = new FormData();
      Object.keys(this.Form).forEach((key) => {
        if (multidatas.includes(key) && this.Form[key])
          formData.append(key, JSON.stringify(this.Form[key]));
        else if (this.Form[key] != '' && this.Form[key] != undefined)
          formData.append(key, this.Form[key]);
      });

      this.working = true;

      let response = await this.upload({
        method: 'put',
        url: 'parent/parentProfile/' + this.id,
        data: formData,
      });
      if (response && !response.error) {
        this.$router.push({ name: 'manage-parent' });
        return eventBus.$emit('open-toast', {
          message: 'successfully updated official',
        });
      } else
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      this.working = false;
      this.initial = true;
    },
    async populateDefaultForms() {
      let response = await this.request({
        method: 'get',
        url: 'school/schoolProfile/my-school',
      });
      if (response && !response.error) {
        const school = response.record;
        this.Form['region'] = school['region'];
        this.Form['city'] = school['city'];
      }
    },
  },

  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    this.loading = true;

    if (this.update) await this.handleFetchUser();
    else await this.populateDefaultForms();
    this.loading = false;

    if (this.Form.profilePic) {
      this.profilePicObj = this.PROFILE_PATH + this.Form.profilePic;
    }
  },
};
</script>

<style></style>
