<template>
  <div class="">
    <pre>{{ isDataLoaded }}</pre>
    <pre>{{ sheetNames }}</pre>
    <tabs>
      <tab
        v-for="({ sheetName, students, headers = [], length }, index) in sheets"
        :key="index"
        :title="`${sheetName} (${length})`"
      >
        <d-table
          :headers="headers.map((label) => ({ label, key: label }))"
          :items="students"
          :hasActions="false"
          :handlers="[]"
          :hideColumnSort="true"
        ></d-table>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Tab from '../../../../components/tab/Tab.vue';
import Tabs from '../../../../components/tab/Tabs.vue';
import DTable from '../../../../components/table/DTable.vue';
export default {
  components: { Tabs, Tab, DTable },
  computed: {
    ...mapGetters('migration', ['isDataLoaded', 'sheetNames', 'sheets']),
  },
};
</script>

<style></style>
