const state = {
    showSingleTranscript: false,
    singleTranscriptData: {},
    currentDate: '',
    dateRange: {},
    customer: {},
    printer_on: false,
}

const mutations = {
    SMART_SET: (state, { stateKey, data }) => {
        state[stateKey] = data
    },
}

const actions = {
    smartSet({ commit }, { stateKey, data }) {
        commit('SMART_SET', {
            stateKey,
            data,
        })
    },
}

const getters = {
    showAccountStmt: state => state.showAccountStmt,
    printer_on: state => state.printer_on,
    dateRange: state => state.dateRange,
    currentDate: state => state.currentDate,
    customer: state => state.customer,
}

const printer = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

export default printer