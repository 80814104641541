<template>
  <div class=" w-2/3 flex flex-col " style="background-color: #202b33">
    <!-- header -->
    <div class=" flex justify-end py-5 px-5">
      <div>
        <p class=" text-gray-400 cursor-pointer">Go To Dashboard 9999</p>
      </div>
    </div>

    <!-- content dispay -->
    <div class=" w-full bg-black h-2/3  my-auto ">
      <iframe
        :src="FILES_PATH + content.path"
        class=" bg-white h-full w-full object-contain"
        frameborder="0"
      ></iframe>
    </div>
    <div class=" pb-4 flex justify-end pr-5">
      <p class=" text-textLight font-semibold">HENON</p>
    </div>
  </div>
</template>

<script>
import { FILES_PATH } from '@/config/config.js';
export default {
  props: ['content'],
  data() {
    return {
      FILES_PATH
    };
  }
};
</script>

<style></style>
