<template>
  <div>
    <div class=" grid grid-cols-4 gap-10">
      <channel-entry
        v-for="(channel, i) in items"
        :key="i"
        @click="handleChannelDetail(channel._id)"
        @updated="fetchItems"
        @deleted="fetchItems"
        :channel="channel"
      />
    </div>
  </div>
</template>

<script>
import ChannelEntry from '@/views/protected/channel/components/ChannelEntry.vue';
import eventBus from '@/eventBus.js';
import { mapActions } from 'vuex';

export default {
  components: {
    ChannelEntry
  },

  data() {
    return {
      items: []
    };
  },

  methods: {
    ...mapActions('main', ['request']),
    handleChannelDetail(channelId) {
      this.$router.push({ name: 'channel-detail', params: { channelId } });
    },

    handleCreateChannel() {
      eventBus.$emit('open-modal', { modalId: 3, cb: this.fetchItems });
    },

    async fetchItems() {
      let response = await this.request({
        method: 'get',
        url: 'reb/channel'
      });
      if (response && !response.error) {
        this.items = response.record || [];
      } else {
        this.items = [];
      }
    }
  },

  created() {
    this.fetchItems();
  }
};
</script>

<style></style>
