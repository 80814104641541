<template>
  <div class="py-2 text-textMedium space-y-4">
    <div class="pb-2 text-lg text-center uppercase">
      <slot name="title">new item</slot>
    </div>
    <div class="space-y-4 px-4">
      <div>
        <slot></slot>
      </div>
      <div>
        <slot name="footer">
          <div class="flex justify-between pt-2 pb-1">
            <base-button
              label="cancel"
              :primary="false"
              class="py-1 px-4 text-textMedium bg-gray-100 hover:bg-danger hover:text-error rounded"
              @click="cancel"
            />
            <base-button
              :label="saveLabel ? saveLabel : 'Save'"
              class="py-1 px-4 rounded"
              :working="working"
              @click="handleSave"
            />
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex';

export default {
  props: ['saveLabel', 'working'],
  methods: {
    async handleSave() {
      this.$emit('save');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  // computed: {
  //   ...mapState("main", [
  //     "working"
  //   ])
  // }
};
</script>

<style></style>
