<template>
  <div class=" px-2">
    <!-- header -->
    <div class=" flex space-x-5 my-8 mb-0 bg-white py-5 px-5">
      <div
        class=" cursor-pointer"
        :class="{
          'border-b-2 border-primary': activeTab == 'all_channels',
          'text-textLight': activeTab != 'all_channels'
        }"
        @click="selectTab('all_channels')"
      >
        <p class=" text ">All Channels</p>
      </div>
      <div
        class=" cursor-pointer"
        @click="selectTab('subscribed_courses')"
        :class="{
          'border-b-2 border-primary': activeTab == 'subscribed_courses',
          'text-textLight': activeTab != 'subscribed_courses'
        }"
      >
        My Subscriptions
      </div>
    </div>

    <!-- <div class=" ">
      <div class=" flex items-center justify-between">
        <p class=" text-xl font-semibold text-textDarkest my-8">
          My Channels
        </p>
      </div>
    </div>
    -->

    <div class=" bg-white ">
      <div class=" px-8 py-10" v-if="activeTab === 'all_channels'">
        <div class=" grid grid-cols-4 gap-10">
          <channel-entry
            v-for="(channel, i) in items"
            :key="i"
            @click="handleChannelDetail(channel._id)"
            @updated="fetchItems"
            @deleted="fetchItems"
            :channel="channel"
          />
        </div>
      </div>
      <div v-if="activeTab === 'subscribed_courses'" class=" px-8">
        <my-subscriptions />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ChannelEntry from './components/ChannelEntry.vue';
import MySubscriptions from './tabs/MySubscriptions.vue';

import eventBus from '@/eventBus.js';
export default {
  components: {
    ChannelEntry,
    MySubscriptions
  },
  data() {
    return {
      tabs: ['all_channels', 'subscribed_courses'],
      items: [],
      activeTab: 'all_channels'
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    selectTab(tab) {
      this.activeTab = tab;
    },
    handleChannelDetail(channelId) {
      this.$router.push({ name: 'channel-detail', params: { channelId } });
    },

    async fetchItems() {
      let response = await this.request({
        method: 'get',
        url: 'reb/channel'
      });
      if (response && !response.error) {
        this.items = response.record || [];
      } else {
        this.items = [];
      }
    },

    handleCreateChannel() {
      eventBus.$emit('open-modal', { modalId: 3, cb: this.fetchItems });
    }
  },

  created() {
    this.fetchItems();
  }
};
</script>

<style></style>
