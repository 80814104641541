<template>
  <div
    class="bg-white rounded-lg py-1 px-1 flex justify-end space-x-2 text-textDark text-sm"
  >
    <div v-if="totalPages > 1" class="w-1/2 flex items-center space-x-4">
      <div
        class="hover:text-primary grow-active hover:bg-brightGray cursor-pointer h-8 w-8 flex justify-center items-center rounded-full"
        @click="changePage(currentPage - 1)"
      >
        <Icon name="chevron-left" :size="14" />
      </div>

      <div
        class="hover:text-primary hover:bg-brightGray cursor-pointer rounded-full flex justify-center items-center px-2"
        style="min-width: 1.8rem;min-height: 1.8rem"
        v-for="i in pages"
        :key="i"
        :class="{ 'text-primary': i === currentPage }"
        @click="changePage(i)"
      >
        {{ i }}
      </div>

      <div
        class="hover:text-primary hover:bg-brightGray cursor-pointer h-8 w-8 flex justify-center items-center rounded-full"
        @click="changePage(currentPage + 1)"
      >
        <Icon name="chevron-right" :size="14" />
      </div>
      <div>{{ totalPages }} pages</div>
    </div>
    <div class="w-1/2 flex justify-end space-x-2">
      <div v-if="totalPages > 1" class="flex space-x-2 items-center">
        <div class="w-3/4 text-right">Go to page</div>
        <BaseInput
          type="number"
          v-model="pageInput"
          padding="small"
          @enter="gotoPage"
        />
      </div>
      <div class="flex space-x-2 items-center">
        <div class="w-3/4 text-right">Rows per page</div>
        <BaseInput
          type="number"
          v-model="pageSizeInput"
          padding="small"
          @enter="changePageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: { type: Number },
    pageSize: { type: Number },
    totalPages: { type: Number }
  },
  data() {
    return {
      pageInput: this.currentPage,
      pageSizeInput: this.pageSize
    };
  },
  computed: {
    pages() {
      let p = [];
      if (this.currentPage >= 2 && this.currentPage <= this.totalPages - 1)
        p = [this.currentPage - 1, this.currentPage, this.currentPage + 1];
      if (this.currentPage === 1)
        p = [2, 3].reduce(
          (acc, i) => {
            if (i <= this.totalPages) acc.push(i);
            return acc;
          },
          [1]
        );
      if (this.currentPage === this.totalPages) {
        p = [this.currentPage - 1, this.currentPage - 2].reduce(
          (acc, i) => {
            if (i >= 1) acc.unshift(i);
            return acc;
          },
          [this.currentPage]
        );
      }
      return p;
    }
  },
  methods: {
    changePageSize() {
      this.$emit('changepagesize', Number(this.pageSizeInput));
      this.pageInput = this.currentPage;
    },
    changePage(page) {
      this.$emit('changepage', page);
    },
    gotoPage() {
      this.changePage(Number(this.pageInput));
    }
  }
};
</script>

<style></style>
