<template>
  <div class=" bg-white py-3 flex items-center px-5 space-x-5">
    <div>
      <icon name="camera" :size="20" class=" text-textLight" />
    </div>
    <div class=" flex-grow w-full">
      <base-input label="" placeholder="Type message here" v-model="message" @enter="sendMessage" />
    </div>
    <div>
      <base-button label="send" class="rounded-full" @click="sendMessage"  />
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus.js';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
export default {
  props: ['receiver'],
  data() {
    return {
      working: false,
      message: ''
    };
  },
  computed: {
    ...mapState('main', ['error']),
  },
  methods: {
    ...mapActions('main', ['request']),
    async sendMessage() {
      this.working = true
      if (!this.receiver) {
        this.working = false;
        return eventBus.$emit('open-toast', {
          message: this.error,
          error: true
        });
      }
      let response = await this.request({
        method: 'post',
        url: 'message/send',
        data: { receiver: this.receiver, message: this.message }
      });
      if (response && !response.error) {
        this.working = false;
        this.message = '';
        this.$emit('messageSent', response.record[0]);
      }
    }
  }
};
</script>

<style></style>
