<template>
  <div>
    <base-title :title="'Manage Grades'" class="mb-2">
      <template #actions>
        <div class="flex space-x-3">
          <base-button
            label="New Grade"
            icon="plus"
            class="px-2"
            @click="handleCreateGrade"
            code="gr-add"
          />
        </div>
      </template>
    </base-title>

    <loader type="list" :amount="6" v-if="loading"></loader>

    <div v-else-if="!loading && items.length" class="grid grid-cols-3 gap-2">
      <div
        class="bg-white p-2 item-grid rounded-default"
        v-for="(item, i) in items"
        :key="i"
      >
        <div class="flex justify-between w-full">
          <div class="flex space-x-2 items-center">
            <p class="text-textDarkest uppercase">
              {{ item.name }}
            </p>
            <div class="w-2 h-2 rounded-full bg-primary"></div>
            <p class="text-textDarkest uppercase">
              {{ item.enrollment.code }}
              <!-- {{ item.enrollment.name }} -->
            </p>
            <div
              v-if="item.sequence"
              class="w-2 h-2 rounded-full bg-primary"
            ></div>
            <p class="text-textDarkest uppercase">
              {{ item.sequence }}
              <!-- {{ item.enrollment.name }} -->
            </p>
          </div>
          <div>
            <status-chip rounded :label="item.status" />
          </div>
        </div>
        <div>
          <div class="flex space-x-5 mt-3">
            <icon
              v-if="authCodes.includes('gr-rm')"
              name="trash-alt"
              class="cursor-pointer text-textMedium"
              @click="handleDeleteItem(item._id)"
            />
            <icon
              v-if="authCodes.includes('gr-edit')"
              name="pencil-alt"
              class="cursor-pointer text-textMedium"
              @click="handleEditItem(i)"
            />
            <!-- <icon v-if="permission.view || !isSchoolOfficial"
              name="eye"
              class=" cursor-pointer text-textMedium"
              @click="handleItemDetail(item._id)"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <base-no-data v-else message="no grades added" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../../eventBus';
export default {
  data() {
    return {
      academicYear: '',
      items: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
  },
  methods: {
    ...mapActions('main', ['request']),
    handleCreateGrade() {
      eventBus.$emit('open-modal', {
        modalId: 9,
        cb: this.handleFetchGrade,
      });
    },

    async handleFetchGrade() {
      this.loading = true;
      this.items = [];

      let response = await this.request({
        method: 'get',
        url: 'school/class/me/',
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
      this.loading = false;
    },

    async handleFetchAY() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/',
      });
      if (response && !response.error) {
        this.academicYear = response.record;
      } else {
        this.academicYear = '';
      }
    },

    async handleEditItem(index) {
      let componentProps = {
        oldForm: this.items[index],
      };
      eventBus.$emit('open-modal', {
        modalId: 9,
        componentProps,
        cb: this.handleFetchGrade,
      });
    },

    async handleDeleteItem(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the selected grade',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },

    /// NOTE: do not actully delete the academic year just disable it.
    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/class/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'grade deleted successfully',
        });
        this.handleFetchGrade();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },

  async created() {
    await this.handleFetchGrade();
  },
};
</script>

<style scoped>
.item-grid {
  animation: slider 0.5s;
}

@keyframes slider {
  0% {
    transform: translateY(20px);
    opacity: 0%;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}
</style>
