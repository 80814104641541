<template>
  <modal-template @cancel="handleClose">
    <template #title> Grade Verification </template>
    <template #default>
      <div class="space-y-5">
        <div class=" ">
          <d-table
            :items="items"
            :headers="headers"
            :totalItems="totalStudents"
            #default="{ item, index }"
            :outlined="true"
            :loading="isloadingGrade"
            :multiSelect="false"
            :hideColumnSort="true"
          >
            <td
              v-for="(header, i) in headers"
              :key="i"
              class="py-2 text-textDark border p-0"
            >
              <div class="px-2">
                <div v-if="header.key === 'no'">
                  {{ index + 1 }}
                </div>
                <div v-else-if="header.key === 'status'">
                  <status-chip
                    :label="getProp(item, header.key)"
                    style="max-width: fit-content"
                  />
                </div>
                <div
                  v-else-if="header.key === 'gender' && item.student.gender"
                  class="capitalize"
                >
                  {{ item.student.gender }}
                </div>
                <div
                  v-else-if="header.key === 'userId' && item.student.userId"
                  class="capitalize"
                >
                  {{ item.student.userId }}
                </div>
                <div
                  v-else-if="header.key === 'student'"
                  class="w-full whitespace-nowrap"
                  style=""
                >
                  <p class="w-full" style="">
                    {{ item.student.firstName }}
                    {{ item.student.middleName }}
                    <!-- {{ getProp(item, 'firstName') }} {{ getProp(item, 'lastName') }} -->
                  </p>
                </div>
                <div v-else-if="header.key.startsWith('result')">
                  {{ getIt(header.key, index, item) }}
                  <!-- <div class=" ">
                    {{ item.assessment_results.manualAssessment[header.key.split('-')[1]] }}
                  </div> -->
                </div>
                <div v-else>
                  {{ getProp(item, header.key) }}
                </div>
              </div>
            </td>
          </d-table>
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import { getPropByString } from '../../utils';
import DTable from '../table/DTable.vue';

export default {
  components: { DTable, ModalTemplate },
  props: ['cta_id', 'id'],
  data() {
    return {
      isloadingGrade: false,
      changedFields: [],
      isDirty: false,
      items: [],
      selected: [],
      selected1: [],
      assessmentList: [],
    };
  },
  computed: {
    ...mapState('main', ['error']),
    headers() {
      return [
        { label: 'No', key: 'no' },
        { label: 'Student', key: 'student' },
        { label: 'ID', key: 'userId' },
        { label: 'Gender', key: 'gender' },
        ...this.assessmentHeaders,
      ];
    },

    assessmentHeaders() {
      return this.assessmentList.map((assessment) => ({
        label: assessment.name + '' + '[' + assessment.maxResult + ']',
        key: 'result-' + assessment._id,
        status: assessment.status,
      }));
    },
    totalStudents() {
      return this.items.length;
    },
  },
  async created() {
    this.isloadingGrade = true;
    await this.fetchAssessments();
    await this.fetchItem();
    this.isloadingGrade = false;
  },
  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,
    async fetchItem() {
      console.log('fetching-items;');
      let response = await this.request({
        method: 'get',
        url: 'school/gradeSubmission/' + this.id,
      });
      if (response && !response.error) {
        this.items = response.record.students;
        this.totalItems = response.total;
      } else {
        this.items = [];
        this.totalItems = 0;
      }
    },
    getIt(key, index, item) {
      return item.assessment_results.find((v) => {
        return v.manualAssessment._id == key.split('-')[1];
      }).result;
    },

    async fetchAssessments() {
      let response = await this.request({
        method: 'get',
        url: 'school/manualAssessment/my-assessments/' + this.cta_id,
      });
      if (response && !response.error) {
        this.assessmentList = response.record;
      } else {
        this.assessmentList = [];
      }
    },

    handleClose(invokeCb = false) {
      this.$emit('close', { invokeCb });
    },
  },
};
</script>

<style></style>
