<template>
  <div class="space-y-2 text-sm">
    <base-title
      :title="
        academic_year.name
          ? `Mass Transfer to ${academic_year.class.name} - ${academic_year.section.name}`
          : 'No Active Academic Year On this Section'
      "
    >
      <template #actions>
        <div class="flex flex-wrap px-4 py-2">
          <base-button
            :working="working"
            label="Mass Transfer"
            icon="exchange"
            class="px-2 text-sm rounded"
            @click="handleMassTransfer"
          />
        </div>
      </template>
    </base-title>
    <div class="space-y-1 p-8">
      <data-table
        #default="{ item }"
        :headers="headers"
        :items="students"
        :loading="loading"
        :totalItems="totalCustomers"
      >
        <td
          v-for="(header, index) in headers"
          :key="index"
          class="align-top"
          :style="{
            width: header.key === 'fullName' ? '200px' : undefined,
          }"
        >
          <div
            v-if="header.key === 'userId'"
            class="flex space-x-2 items-center"
          >
            <div class="flex-auto">
              <base-input
                type="text"
                v-model="item.userId"
                :errors="errors[index].userId"
                bordered
                padding="small"
                required
                @blur="fetchStudentByUserId(item.userId, index)"
                @enter="addRow(index)"
              />
            </div>
          </div>
          <div
            v-else-if="header.key === 'fullName'"
            class="flex space-x-2 items-center"
          >
            <div class="flex-auto">
              <base-input
                type="text"
                v-model="item.fullName"
                :errors="errors[index].fullName"
                bordered
                disabled
                padding="small"
              />
            </div>
          </div>
          <div v-else-if="header.key === 'current_grade'" class="px-1">
            <base-input
              type="text"
              v-model="item.current_grade"
              :errors="errors[index].current_grade"
              bordered
              disabled
              padding="small"
            />
          </div>
          <div
            v-else-if="header.key === 'current_section'"
            class="flex space-x-4 items-start"
          >
            <base-input
              type="text"
              v-model="item.current_section"
              :errors="errors[index].current_section"
              bordered
              disabled
              padding="small"
              class="flex-auto"
            />
          </div>
          <div
            v-else-if="header.key === 'new_grade'"
            class="flex space-x-4 items-start px-1"
          >
            <base-input
              type="text"
              v-model="item.new_grade"
              :errors="errors[index].new_grade"
              bordered
              disabled
              padding="small"
              class="flex-auto"
            />
          </div>
          <div
            v-else-if="header.key === 'new_section'"
            class="flex space-x-4 items-start"
          >
            <base-input
              placeholder=""
              v-model="item.new_section"
              :errors="errors[index].new_section"
              bordered
              disabled
              padding="small"
              class="flex-auto"
            />
          </div>
          <div
            v-else-if="header.key === 'action'"
            class="flex space-x-4 items-start"
          >
            <div
              class="p-3 bg-brighterGray text-textMedium hover:bg-brightGray hover:text-error cursor-pointer active:bg-danger rounded-full"
              @click="removeRow(index)"
            >
              <Icon name="times" :size="10" />
            </div>
          </div>
          <div v-else class="flex space-x-4 items-start px-1">
            <base-input
              v-model="item[header.key]"
              :errors="errors[index][header.key]"
              bordered
              disabled
              padding="small"
              class="flex-auto"
            />
          </div>
        </td>
      </data-table>
      <div class="flex space-x-2 justify-end">
        <input
          v-if="fileSelector"
          type="file"
          class="hidden"
          ref="csv-file-input"
          @input="handleFileSelect"
        />
        <base-button
          :label="importing ? 'importing' : 'import csv'"
          :primary="false"
          class="text-sm px-2 rounded"
          :class="{ 'bg-brightGray hover:text-primary': !importing }"
          :working="importing"
          @click="openFileSelector"
        />
        <base-button
          label="remove all rows"
          :primary="false"
          class="text-sm px-2 rounded bg-brightGray hover:text-primary"
          @click="removeAllRows"
        />
        <base-button
          label="add row"
          :primary="false"
          class="text-sm px-2 rounded bg-brightGray hover:text-primary"
          @click="addRow(students.length - 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import DataTable from '@/components/table/DTable.vue';
import eventBus from '@/eventBus';
// import eventBus from '@/eventBus';
export default {
  props: ['ay_id'],
  components: { DataTable },
  mixins: [validation],
  data() {
    return {
      allValid: false,
      working: false,
      loading: false,
      academic_year: {},
      Form: {
        userId: undefined,
        fullName: undefined,
        current_grade: undefined,
        current_section: undefined,
        new_grade: undefined,
        new_section: undefined,
      },
      headers: [
        { key: 'userId', label: 'USER ID' },
        { key: 'fullName', label: 'FULL NAME' },
        { key: 'current_grade', label: 'CURRENT GRADE' },
        { key: 'current_section', label: 'CURRENT SECTION' },
        { key: 'new_grade', label: 'NEW GRADE' },
        { key: 'new_section', label: 'NEW SECTION' },

        { key: 'action', label: undefined },
      ],

      students: [],
      errors: [],
      formErrors: {},
      importing: false,
      unwathcCallbacks: [],
      fileSelector: false,
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),

    formTemplate() {
      let template = {
        userId: undefined,
        fullName: undefined,
        current_grade: undefined,
        current_section: undefined,
        new_grade: undefined,
        new_section: undefined,
      };

      return template;
    },

    totalCustomers() {
      return this.students.length;
    },
    customers() {
      return this.students;
      //   return this.students.map((payee) =>
      //     Object.keys(this.formTemplate).reduce(
      //       (acc) => ({
      //         ...acc,
      //       }),
      //       {}
      //     )
      //   );
    },
  },
  watch: {
    // customers() {
    //   this.smartSet({ stateKey: 'customers', data: this.customers });
    // },

    initial: {
      immediate: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      },
    },
    Form: {
      immediate: false,
      deep: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      },
    },
    students: {
      immediate: true,
      deep: true,
      handler() {
        this.validationHandler();
        // this.setVal();
      },
    },
    headers() {
      this.validationHandler();
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    validationHandler() {
      this.resetErrors(0);
      if (!this.initial) {
        this.students.forEach((payee, i) =>
          Object.keys(this.formTemplate).forEach((key) => {
            if (!payee[key])
              this.errors[i][key] = [
                this.headers.find((header) => header.key === key).label +
                  ' is required',
              ];
            else this.errors[i][key] = [];
          })
        );
        Object.keys(this.Form).forEach((key) => {
          if (!this.Form[key]) {
            this.formErrors[key] = `${key} is required`;
          } else {
            this.formErrors[key] = ``;
          }
        });
      }
    },

    addRow(index) {
      this.students = [
        ...this.students.slice(0, index + 1),
        { ...this.formTemplate },
        ...this.students.slice(index + 1).map(({ ...rest }) => ({ ...rest })),
      ];
    },
    removeRow(index) {
      this.students = [
        ...this.students.slice(0, index),
        ...this.students.slice(index + 1).map(({ ...rest }) => ({ ...rest })),
      ];
    },
    removeAllRows() {
      this.students = [];
      this.errors = [];
    },
    importCsv(file) {
      this.importing = true;
      this.$papa.parse(file, {
        skipEmptyLines: true,
        complete: ({ data }) => {
          this.importing = false;
          // let lastIndex = this.students.length;
          data.slice(1).forEach((bill) => {
            const student_info = Object.keys(this.formTemplate).reduce(
              (student, key, i) => ({ ...student, [key]: bill[i] }),
              {}
            );
            this.students.push(student_info);
          });
          this.resetFile();
          this.populateData();
        },
      });
    },
    handleFileSelect() {
      let file = this.$refs['csv-file-input'].files[0];
      this.importCsv(file);
    },
    openFileSelector() {
      this.fileSelector = true;
      this.$nextTick(() => {
        this.$refs['csv-file-input'].click();
      });
    },
    resetFile() {
      this.fileSelector = false;
    },
    resetErrors(startIndex) {
      if (startIndex < 0) startIndex = 0;
      this.students
        .slice(startIndex)
        .forEach(
          (payee, i) =>
            (this.errors[i + startIndex] = Object.keys(
              this.formTemplate
            ).reduce((acc, key) => ({ ...acc, [key]: [] }), {}))
        );
    },
    resetForm() {
      this.students = [];
    },
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async preMassTransfer() {
      this.initial = false;
      //   if (!this.isValid()) return;
      let componentProps = {
        title: `Mass Transfer to ${this.academic_year.class.name} - ${this.academic_year.section.name}`,
        message:
          'Are you sure you really want to transfer ' +
          this.totalCustomers.toLocaleString() +
          ' total students',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.handleMassTransfer,
      });
    },

    async populateData() {
      this.working = true;
      this.loading = true;

      let index = 0;
      for (const student of this.students) {
        const student_id = encodeURIComponent(`${student.userId}`);
        let response = await this.request({
          method: 'get',
          url: `student/studentProfile/get-by-userId/${student_id}`,
        });
        if (response) {
          console.log('this.students[index]: ', this.students[index]);
          this.students[index]._id = response.record._id;
          this.students[index].fullName = response.record.fullName;
          this.students[index].current_grade = response.record.class
            ? response.record.class.name
            : '-';
          this.students[index].current_section = response.record.section
            ? response.record.section.name
            : '-';
          this.students[index].new_grade = this.academic_year.class.name;
          this.students[index].new_section = this.academic_year.section.name;

          this.errors[index]['userId'] = [''];
        } else {
          this.errors[index]['userId'] = ['Not found'];
          this.students[index].fullName = '-';
          this.students[index].current_grade = '-';
          this.students[index].current_section = '-';
          this.students[index].new_grade = '-';
          this.students[index].new_section = '-';
        }
        index++;
      }
      this.students = [...this.students];
      this.working = false;
      this.loading = false;
    },
    async fetchStudentByUserId(userId, index) {
      const student_id = encodeURIComponent(`${userId}`);

      let response = await this.request({
        method: 'get',
        url: `student/studentProfile/get-by-userId/${student_id}`,
      });
      if (response) {
        this.students[index]._id = response.record._id;
        this.students[index].fullName = response.record.fullName;
        this.students[index].current_grade = response.record.class
          ? response.record.class.name
          : '-';
        this.students[index].current_section = response.record.section
          ? response.record.section.name
          : '-';
        this.students[index].new_grade = this.academic_year.class.name;
        this.students[index].new_section = this.academic_year.section.name;
        this.students = [...this.students];

        this.errors[index]['userId'] = [''];
      } else {
        this.errors[index]['userId'] = ['Not found'];
      }
    },
    async handleMassTransfer() {
      this.working = true;
      const student_ids = this.students.map((stu) => stu._id);
      const response = await this.request({
        method: 'put',
        url: 'school/studentSection/mass-transfer-section/' + this.ay_id,
        data: {
          students: student_ids,
        },
      });
      if (response) {
        this.handleClose(true);
        eventBus.$emit('open-toast', {
          error: false,
          message: 'Students Transfered successfully',
        });
      } else {
        eventBus.$emit('open-toast', {
          error: true,
          message: this.error,
        });
      }
      this.working = false;
    },
    async fetchAcademicYear() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/' + this.ay_id,
      });
      if (response && !response.error) {
        this.academic_year = response.record;
      } else {
        this.academic_year = {};
        this.noData = true;
      }
    },
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc) => {
          acc['userId'] = { required };
          acc['fullName'] = { required };
          acc['current_grade'] = { required };
          acc['current_section'] = { required };
          acc['new_grade'] = { required };
          acc['new_section'] = { required };

          return acc;
        }, {}),
      },
      students: {
        ...this.students.reduce(
          (acc, payee, index) => ({
            ...acc,
            [index]: {
              ...Object.keys(this.formTemplate).reduce(
                (acc, key) => ({ ...acc, [key]: { required } }),
                {}
              ),
            },
          }),
          {}
        ),
      },
    };
  },
  async created() {
    await this.fetchAcademicYear();
  },
};
</script>

<style></style>
