<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title> {{ isUpdate ? 'Update' : 'Create New' }} Grade </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-1 gap-5">
          <base-input
            label="Grade Name *"
            placeholder="grade name"
            v-model="Form.name"
            :errors="errors.name"
            check
          />
          <base-select
            label="Enrollment Type"
            placeholder="Enrollment Type"
            v-model="Form.enrollment"
            :items="ENROLLMENT_OPTIONS"
            :errors="errors.enrollment"
          />
          <base-input
            label="Sequence *"
            placeholder="Sequence"
            type="number"
            v-model="Form.sequence"
            :errors="errors.sequence"
            check
          />
          <switch-button label="Is Active" v-model="Form.status" />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import SwitchButton from '../base/SwitchButton.vue';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm'],
  mixins: [validation],
  data() {
    return {
      Form: {
        name: '',
        enrollment: '',
        sequence: '',
        status: '', // active, closed, not_started
      },
      enrollmentItems: [],
      errors: {},
      working: false,
      meta: [
        { key: 'Form.name', label: 'Grade name' },
        { key: 'Form.enrollment', label: 'Enrollment Type' },
        { key: 'Form.sequence', label: 'Sequence' },
      ],
      initial: true,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    isUpdate() {
      if (this.oldForm) return true;
      else return false;
    },
    ENROLLMENT_OPTIONS() {
      return this.enrollmentItems.map((enrollment) => ({
        label: enrollment.name,
        value: enrollment._id,
      }));
    },
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name', 'enrollment', 'sequence'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
      this.initial = true;
    },
    populateData() {
      Object.keys(this.oldForm).forEach((key) => {
        this.Form[key] = this.oldForm[key];
      });

      this.Form['enrollment'] = this.oldForm['enrollment']
        ? this.oldForm['enrollment']._id
        : '';
      this.Form['status'] = ['closed', 'inactive'].includes(
        this.oldForm['status']
      )
        ? false
        : true;
    },
    async handleCreate() {
      this.working = true;

      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';
      newForm = {
        name: newForm.name,
        enrollment: newForm.enrollment,
        sequence: newForm.sequence,
        status: newForm.status,
      };

      let response = await this.request({
        method: 'post',
        url: 'school/class',
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'grade updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },

    async handleUpdate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      newForm = {
        name: newForm.name,
        enrollment: newForm.enrollment,
        sequence: newForm.sequence,
        status: newForm.status,
      };

      let response = await this.request({
        method: 'put',
        url: 'school/class/' + this.oldForm._id,
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'grade updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },

    async handleFetchEnrollments() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'school/enrollment/active',
      });
      if (response && !response.error) {
        this.enrollmentItems = response.record;
        this.activeEnrollment = response.record[0]._id;
      } else {
        this.enrollmentItems = [];
        this.activeEnrollment = '';
      }
      this.loading = false;
    },
  },
  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    await this.handleFetchEnrollments();
    if (this.isUpdate) this.populateData();
  },
};
</script>

<style></style>
