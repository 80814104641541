<template>
  <div>
    <base-title
      :title="
        academic_year.name
          ? `Student Enrollment for ${academic_year.name}`
          : 'No Active Academic Year On this Section'
      "
    >
      <template #actions>
        <div class="flex space-x-3">
          <base-button
            icon="plus"
            label="Export"
            :primary="false"
            class="bg-gray-100 flex-shrink-0"
            @click="exportCsv"
          />
          <base-select :items="ACADEMIC_YEARS" v-model="activeAy" searchable />
        </div>
      </template>
    </base-title>

    <div class="bg-white w-full p-1 px-4 mt-2 rounded-default">
      <div class="py-2">
        <div v-if="activeAy && activeGrade">
          <students-list
            ref="student-list"
            :ay_id="activeAy"
            :grade="activeGrade"
          />
        </div>
        <base-no-data v-else message="no sections added" />
      </div>
    </div>
  </div>
</template>

<script>
import StudentsList from './components/StudentsList.vue';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import eventBus from '@/eventBus';
// import BaseSelectPlus from '../../../../components/base/BaseSelectPlus.vue';
export default {
  components: {
    StudentsList,
  },
  data() {
    return {
      academic_years: [],
      academic_year: {},
      section: '',
      activeAy: '',
      activeGrade: '',
      noData: false,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    ACADEMIC_YEARS() {
      return this.academic_years.map((academic_year) => ({
        label: academic_year.class.name + ' - ' + academic_year.section.name,
        value: academic_year._id,
      }));
    },
  },
  watch: {
    activeAy() {
      this.fetchAcademicYear();
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    exportCsv() {
      console.log('this.$refs: ', this.$refs);
      const items = this.$refs['student-list'].items;
      this.handleExportCsv(items);
    },
    handleExportCsv(items) {
      const i = items.map((it) => {
        return { ...it.student, guardian: { ...it.student.parents[0] } };
      });
      const h = [
        { label: 'UserId', key: 'userId' },
        { label: 'First Name', key: 'firstName' },
        { label: 'Father Name', key: 'middleName' },
        { label: 'Grand Father Name', key: 'lastName' },
        { label: 'Gender', key: 'gender' },
        { label: 'Date Of Birth', key: 'birthdate' },
        { label: 'Grade', key: 'class.name' },
        { label: 'Section', key: 'section.name' },
        { label: 'Region', key: 'region' },
        { label: 'City', key: 'city' },
        { label: 'Subcity', key: 'subcity' },

        { label: 'Address', key: 'address' },

        { label: 'Email', key: 'email' },

        { label: 'Phone Number', key: 'phoneNumber' },
        { label: 'Payer', key: 'payer.name' },
        { label: 'Discount', key: 'discount.name' },
        { label: 'Service', key: 'service' },

        { label: 'Guardian First Name', key: 'guardian.parent.firstName' },
        { label: 'Guardian Father Name', key: 'guardian.parent.middleName' },
        {
          label: 'Guardian Grand Father Name',
          key: 'guardian.parent.lastName',
        },
        { label: 'Guardian Gender', key: 'guardian.parent.gender' },

        { label: 'Guardian Date Of Birth', key: 'guardian.parent.birthdate' },
        { label: 'Relationship', key: 'guardian.relation' },
        { label: 'Email', key: 'guardian.parent.email' },
        { label: 'Phone Number', key: 'guardian.parent.phoneNumber' },

        // { label: 'Address', key: 'address' },
        { label: 'Status', key: 'status' },
      ];
      let componentProps = {
        title: `students ${this.academic_year.class.name} - ${this.academic_year.section.name}`,
        headers: h,
        items: i,
      };
      eventBus.$emit('open-modal', { modalId: 35, componentProps });
    },

    async fetchActiveAcademicYears() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/active',
      });
      if (response && !response.error) {
        this.academic_years = response.record;
        this.academic_year = response.record[0];
        this.activeAy = this.academic_year._id;
      } else {
        this.academic_years = [];
        this.noData = true;
      }
    },

    async fetchAcademicYear() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/' + this.activeAy,
      });
      if (response && !response.error) {
        this.academic_year = response.record;
        this.activeGrade = this.academic_year.class._id;
      } else {
        this.academic_year = {};
        this.noData = true;
      }
    },
  },

  async created() {
    await this.fetchActiveAcademicYears();
  },
};
</script>

<style></style>
