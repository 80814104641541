import Roles from '@/views/protected/roles/Roles.vue';
import RolePermission from '@/views/protected/roles/RolePermission.vue';
import Permissions from '@/views/protected/roles/Permissions.vue';

export default [
    {
        path: 'roles',
        name: 'roles',
        component: Roles
    },
    {
        path: 'roles/permission/:id/:module',
        name: 'Role Permission',
        component: RolePermission
    },
    {
        path: 'permissions',
        name: 'permissions',
        component: Permissions
    },

]