<template>
  <div
    @click="displayUserDetail(teacher._id)"
    class="flex space-x-1 rounded-default cursor-pointer bg-white hover:bg-grayBg p-2"
  >
    <!-- <pre>{{teacher}}</pre> -->
    <div class="px-2">
      <div
        class="h-12 w-12 rounded-full bg-gray-100 flex justify-center items-center"
      >
        <img
          v-if="teacher.profilePic"
          :src="PROFILE_PATH + teacher.profilePic"
          v-viewer="{ inline: false, movable: true }"
          class="w-full h-full object-cover rounded-full"
          alt=""
          crossorigin="anonymous"
        />
        <icon v-else :size="18" name="user" class="w-full h-full"></icon>
      </div>
    </div>
    <div class="flex-auto">
      <div class="flex justify-between">
        <div class="capitalize text-textDarkest">
          {{
            `${teacher.firstName} ${teacher.middleName || ''} ${
              teacher.lastName
            }`
          }}
        </div>
        <div
          class="text-sm text-white rounded-sm p-2 py-1"
          :class="{
            'bg-verified': teacher.status === 'verified',
            'bg-pending': teacher.status === 'pending',
            'bg-suspended': teacher.status === 'suspended',
          }"
        >
          {{ teacher.status }}
        </div>
      </div>
      <div class="flex space-x-2 items-center text-sm">
        <icon name="phone-alt" :size="10" />
        <div class="flex space-x-2">
          <div
            v-for="phoneNumber in teacher.phoneNumber"
            :key="phoneNumber"
            class="pr-2 border-r-2 border-dgray"
          >
            {{ phoneNumber || '' }}
          </div>
        </div>
        <div class="tracking-widest">{{ teacher.userId }}</div>
      </div>
      <br />
      <hr class="text-gray-100" />
    </div>
  </div>
</template>

<script>
import { BASE_URL } from '@/config/config';
import { ago } from '@/utils';
import { mapActions } from 'vuex';
import eventBus from '@/eventBus';
import { PROFILE_PATH } from '../../../config/config';
export default {
  props: ['teacher'],
  data() {
    return {
      BASE_URL,
      PROFILE_PATH,
    };
  },
  filters: { ago },
  methods: {
    ...mapActions('main', ['setData']),
    displayUserDetail(id) {
      let componentProps = {
        id,
        type: 'teacher',
        url: 'school/teacherProfile/',
      };
      eventBus.$emit('open-modal', {
        modalId: 39,
        componentProps,
      });
    },
  },
};
</script>

<style></style>
