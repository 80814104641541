<template>
  <div>
    <div class="uppercase p-2 px-4 text-lg">
      List Of teachers for this Course
    </div>
    <div class="overflow-auto mt-10 p-5 space-y-2">
      <loader v-if="loading"></loader>
      <div v-else>
        <div v-if="item.teacher.length > 0">
          <div
            v-for="(teacher, i) in item.teacher"
            :key="i"
            class="border-1 border-gray-100 p-2 px-3"
          >
            <div v-if="teacher" class="flex justify-between items-center">
              <div class="flex space-x-5">
                <div
                  class="w-12 h-12 rounded-full bg-gray-100 flex justify-center items-center"
                >
                  <img
                    v-if="teacher.profilePic"
                    :src="PROFILE_PATH + teacher.profilePic"
                    v-viewer="{ inline: false, movable: true }"
                    class="w-full h-full object-cover rounded-full"
                    alt=""
                    crossorigin="anonymous"
                  />
                  <icon
                    v-else
                    :size="18"
                    name="user"
                    class="w-full h-full"
                  ></icon>
                </div>
                <div>
                  <p
                    class="font-semibold uppercase text-textDarkest hover:underline cursor-pointer"
                  >
                    {{ teacher.firstName }} {{ teacher.middleName }}
                    {{ teacher.middleName }}
                  </p>
                  <p class="text-textLight">{{ teacher.userId }}</p>
                </div>
              </div>
              <div>
                <base-button
                  padding="small"
                  :primary="false"
                  class="bg-transparent border-1 border-orange text-orange py-0 px-2"
                  label="Unlink"
                  @click="handleUnlinkTeacher(teacher._id)"
                />
              </div>
            </div>
            <base-no-data v-else message="no sections added" />
          </div>
        </div>
      </div>
    </div>
    <div class="p-2 px-3 flex mt-3">
      <base-button
        label="close"
        @click="handleClose"
        class="px-3 bg-danger text-error"
        :primary="false"
      />
    </div>
  </div>
</template>

<script>
// import eventBus from '@/eventBus';
import { mapActions, mapState } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';
import eventBus from '@/eventBus.js';

export default {
  components: {},
  props: ['cta_id'],
  data() {
    return {
      PROFILE_PATH,
      item: {},
      working: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {},

    async fetchLinkedTeachers() {
      this.loading = true;
      this.item = {};
      let response = await this.request({
        method: 'get',
        url: 'school/teacherCourse/my-teachers/' + this.cta_id,
      });
      if (response && !response.error) {
        this.item = response.record;
      } else {
        this.item = {};
      }
      this.loading = false;
    },

    async handleUnlinkTeacher(teacher) {
      this.working = true;
      let response = await this.request({
        method: 'put',
        url: 'school/teacherCourse/unlink/' + this.cta_id,
        data: { teacher },
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'teacher unlinked successfylly',
        });
        this.handleClose(true);
        this.working = false;
      } else {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },
  computed: { ...mapState('main', ['error']) },
  created() {
    this.fetchLinkedTeachers();
  },
};
</script>

<style></style>
