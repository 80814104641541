<template>
  <div id="printable" class="bg-white p-8 rounded-default">
    <div class="text-xl font-bold text-textDarkest text-center p-2 pt-0">
      Registered Information
      {{userType}}
    </div>

    <div class="grid grid-cols-4 gap-2">
      <div class="border-1 border-gray-100 shadow-sm p-6 items-center justify-center flex rounded-default">
          <img
            v-if="user.profilePic"
            :src="PROFILE_PATH + user.profilePic"
            v-viewer="{ inline: false, movable: true }"
            class="w-full object-cover"
            alt=""
            crossorigin="anonymous"
          />
          <icon v-else :size="48" name="user" class="w-full h-full"></icon>
      </div>
      <!-- {{ user }} -->

      <div
        class="grid grid-cols-2 p-6 gap-8 border-1 border-gray-100 shadow-sm col-span-2 rounded-default"
      >
        <div>
          <p class="text-textLight text-sm">Full Name</p>
          <p class="capitalize">{{ user.firstName }} {{ user.middleName }}</p>
        </div>
        <div>
          <p class="text-textLight">Gender</p>
          <p class="capitalize">{{ user.gender }}</p>
        </div>
        <div>
          <p class="text-textLight">UserId</p>
          <p class="capitalize">{{ user.userId }}</p>
        </div>
        <div v-if="user.email">
          <p class="text-textLight">Email</p>
          <p class="capitalize">{{ user.email }}</p>
        </div>
        <div>
          <p class="text-textLight">Phone Number</p>
          <p class="capitalize">
            {{ user['phoneNumber'].join(' | ') }}
          </p>
        </div>
        <div>
          <p class="text-textLight">Username</p>
          <p>@{{ user.username }}</p>
        </div>
        <div v-if="user.password">
          <p class="text-textLight">Password</p>
          <p class="capitalize">{{ user.password }}</p>
        </div>
      </div>

      <div class="border-1 border-gray-100 shadow-sm p-6 items-center justify-center flex rounded-default">
        <base-qrcode :value="user.userId"></base-qrcode>
      </div>
    </div>
    <div v-if="userType && userType === 'student'" v-print="'#printable'" class="p-2 flex items-center justify-center mt-2 rounded-default bg-primary shadow-lg cursor-pointer hover:opacity-75"
    >
      <icon name="print" :size="14" class="text-white mr-2" />
      <span class="text-white">PRINT ID</span>
    </div>
  </div>
</template>

<script>
import { PROFILE_PATH } from '@/config/config.js';
import print from 'vue-print-nb'
export default {
  props: ['user', 'userType'],
  directives: {
    print
  },
  data() {
    return {
      PROFILE_PATH,
    };
  },
  methods: {
    // printId() {
    //   window.print()
    // },
  },
};
</script>

<style></style>
