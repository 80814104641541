<template>
  <div class="bg-gray-100 h-screen w-screen flex flex-col" ref="container">
    <Header ref="header" />
    <div :style="{ height: mainHeight + 'px' }">
      <div class="h-full overflow-y-auto flex-auto p-2">
        <component :is="childComponent" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/layout/protected/Header.vue';
export default {
  props: ['childComponent'],
  components: { Header },
  data() {
    return {
      mainHeight: 0,
    };
  },
  mounted() {
    let container = this.$refs['container'];
    let header = this.$refs['header'];
    this.mainHeight = container.clientHeight - header.$el.clientHeight;
  },
};
</script>

<style></style>
