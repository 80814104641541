<template>
  <div class="space-y-3">
    <div>
      <!-- <div class="bg-dgray mt-2" style="height: 1px"></div> -->
      <div class=" flex group">
        <div
          v-if="showScrollBtns"
          class="flex items-center pl-2 pr-3 text-textMedium hover:text-primary hover:bg-lgray active:bg-gray-100 cursor-pointer"
          @click="scrollLeft"
        >
          <icon name="chevron-left" :size="12" />
        </div>

        <div
          class="hide-scroll-bar w-full overflow-x-auto flex space-x-2"
          ref="tab-header"
        >
          <div
            v-for="(tab, i) in realTabs"
            :key="i"
            class="flex-grow flex-shrink-0 flex border-b-2 border-transparent tracking-widest hover:bg-lgray hover:text-primary active:bg-gray-100 justify-center space-y-1 cursor-pointer hover:opacity-75 py-2 px-4"
            :class="{
              'text-primary  border-primary': tab.isActive,
              'text-textMedium': !tab.isActive
            }"
            @click="selectTab(i)"
          >
            <div v-if="tab.icon">
              <icon :name="tab.icon" :size="20" />
            </div>
            <div :class="tab.icon ? 'text-xs' : ''" class="uppercase text-sm">
              {{ tab.title }}
            </div>
          </div>
        </div>

        <div
          v-if="showScrollBtns"
          class="flex items-center pl-3 pr-2 text-textMedium hover:text-primary hover:bg-lgray active:bg-gray-100 cursor-pointer"
          @click="scrollRight"
        >
          <icon name="chevron-right" :size="12" />
        </div>
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0, //selected index
      tabs: [],
      showScrollBtns: false
    };
  },
  computed: {
    realTabs() {
      let tabsLength = this.tabs.length;
      let end = this.showScrollBtns ? tabsLength - 2 : tabsLength;
      return this.tabs.slice(0, end);
    }
  },
  methods: {
    selectTab(i) {
      this.activeIndex = i;
      this.realTabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    },
    scrollLeft() {
      this.$refs['tab-header'].scrollLeft -= 30;
    },
    scrollRight() {
      this.$refs['tab-header'].scrollLeft += 30;
    }
  },
  created() {
    this.tabs = this.$children;
    this.$nextTick(() => {
      let { scrollWidth, clientWidth } = this.$refs['tab-header'];
      this.showScrollBtns = scrollWidth > clientWidth;
      this.selectTab(0);
    });
  }
};
</script>

<style></style>
