<template>
  <modal-template
    @cancel="handleClose"
    @save="handleSave"
    :working="working"
    :saveLabel="isUpdate ? 'Update' : 'Save'"
  >
    <template #title>
      {{ isUpdate ? 'Update' : 'Create New' }} Assessment
    </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-2 gap-5">
          <base-input
            class="col-span-2"
            label="Name"
            placeholder="name"
            v-model="Form.name"
            :errors="errors.name"
            check
            required
          />
          <base-input
            class="col-span-2"
            label="Description"
            placeholder="description"
            v-model="Form.description"
            :errors="errors.description"
          />
          <base-input
            type="number"
            class="col-span-2"
            label="Maximum Mark"
            placeholder="maximum mark"
            v-model="Form.maxResult"
            :errors="errors.maxResult"
            check
            required
          />

          <!-- <switch-button label="Is Active" v-model="Form.status" /> -->
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';

import { mapActions, mapState } from 'vuex';
// import SwitchButton from '../base/SwitchButton.vue';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate },
  props: ['oldForm', 'grade'],

  mixins: [validation],
  data() {
    return {
      Form: {
        class: this.grade,
        name: '',
        description: '',
        maxResult: 0,
        // status: '', // active, closed, not_started
      },
      errors: {},
      meta: [
        { key: 'Form.name', label: 'Name' },
        { key: 'Form.maxResult', label: 'Maximum Mark' },
      ],
      working: false,
      initial: true,
    };
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name', 'maxResult'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    ...mapState('main', ['error', 'globalSchoolConfig']),

    isUpdate() {
      if (this.oldForm && this.oldForm.name) return true;
      else return false;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },

    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
      this.initial = true;
    },
    populateData() {
      //   this.Form.name = this.oldForm.name;
      //   this.Form.desc = this.oldForm.name;

      //   this.Form['status'] = ['closed', 'inactive'].includes(
      //     this.oldForm['status']
      //   )
      //     ? false
      //     : true;

      this.Form = { ...this.oldForm };
      // console.log('new form', this.Form);
    },
    async handleCreate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      let response = await this.request({
        method: 'post',
        url: 'school/academicYear',
        data: newForm,
      });
      if (response && !response.error) {
        // this.item = response.item;
        eventBus.$emit('open-toast', {
          message: 'academic year created successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },

    async handleUpdate() {
      this.working = true;
      let newForm = { ...this.Form };
      //   if (this.Form['status']) newForm['status'] = 'active';
      //   else newForm['status'] = 'inactive';
      newForm = {
        name: newForm.name,
        class: this.grade,
        description: newForm.description,
        maxResult: newForm.maxResult,
        // status: newForm.status,
      };

      let response = await this.request({
        method: 'put',
        url: 'school/assessment/' + this.oldForm._id,
        data: newForm,
      });
      if (response && !response.error) {
        // this.item = response.item;
        eventBus.$emit('open-toast', {
          message: 'assessment updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: `${this.error}`,
          error: true,
        });
      }
      this.working = false;
    },
  },
  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    if (this.isUpdate) {
      this.populateData();
    }
  },
};
</script>

<style></style>
