export default {
  bind: (el, binding) => {
    el.escapeHandler = e => {
      if (e.key === 'Escape') {
        binding.value();
      }
    };
    document.body.addEventListener('keydown', el.escapeHandler);
  },
  unbind: el => {
    document.body.removeEventListener('keydown', el.escapeHandler);
  }
};
