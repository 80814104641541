<template>
  <div class=" py-4 cursor-pointer flex space-x-3 items-center">
    <div>
      <icon name="lightbulb" class=" text-red-600" />
    </div>
    <div class="flex-grow">
      {{ content.name }}
    </div>
    <div class=" px-3">
      <!-- <p class=" font-light text-textDark">02:40</p> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      required: true
    }
  }
};
</script>

<style></style>
