<template>
  <div>
    <base-title :title="'Manage Permissions'" class="mb-2">
      <template #actions>
        <div class="flex space-x-3">
          <base-select :items="ROLE_OPTIONS" v-model="activeRole" searchable />
          <base-button
            label="Save Changes"
            icon="check-circle"
            class="px-2 flex-shrink-0 rounded bg-gray-200"
            :primary="false"
            :working="working"
            @click="updateAuthorizedActions"
            code="pm-edit"
          />
        </div>
      </template>
    </base-title>

    <div
      v-if="authCodes.includes('pm-view')"
      class="bg-white p-1 px-4 mt-2 rounded-default"
    >
      <loader v-if="working"></loader>
      <div v-else-if="modules.length > 0" class="px-2 py-1 space-y-2">
        <div v-for="(module, i) in modules" :key="i">
          <div class="py-1 flex space-x-2 justify-between items-center">
            <div @click="toggleModule(module, i)" class="flex space-x-2">
              <base-check-box
                v-if="i == active_module"
                :size="20"
                :selected="isModuleSelected(module)"
              />
              <!-- @toggle="immutable ? null : toggleModule(module, i)" -->
              <div class="uppercase font-semibold text-textDarkest">
                {{ module.name }}
              </div>
            </div>
            <div
              @click="activateModule(module, i)"
              class="cursor-pointer pl-10"
            >
              <icon v-if="i == active_module" name="chevron-down" />
              <icon v-if="i != active_module" name="chevron-right" />
            </div>
          </div>
          <div v-if="i == active_module">
            <div
              v-if="moduleWorking"
              class="flex justify-center items-center h-40"
            >
              <BarLoader />
            </div>
            <div
              v-else
              class="grid grid-cols-2 gap-1 border-1 border-gray-200 rounded-lg p-2"
            >
              <div
                v-for="(cat, j) in module.categories"
                :key="j"
                class="bg-gray p-2 flex space-x-2 items-start cursor-pointer active:opacity-75"
              >
                <div>
                  <div
                    @click="immutable ? null : toggleCategory(cat)"
                    class="flex space-x-2 items-center py-1"
                  >
                    <base-check-box
                      :selected="isCategorySelected(cat)"
                      :size="20"
                    />
                    <div class="text-textDarkest uppercase">
                      {{ cat.name }} ({{ cat.code }})
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-2 gap-2 border-1 border-gray-200 rounded-lg p-2"
                  >
                    <div
                      v-for="(action, i) in cat.actions"
                      :key="i"
                      class="bg-gray p-2 flex space-x-2 items-start cursor-pointer active:opacity-75"
                      @click="immutable ? null : toggleAction(action.code)"
                    >
                      <base-check-box
                        :selected="authorizedActions.includes(action.code)"
                        :size="20"
                      />
                      <div>
                        <div class="flex space-x-2 justify-between items-start">
                          <div class="text-textDarkest">
                            {{ action.name }}
                          </div>
                          <div
                            class="text-sm bg-primaryalpha rounded-default px-2"
                          >
                            {{ action.code }}
                          </div>
                        </div>
                        <div class="text-sm text-textLight">
                          {{ action.description || 'No description' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-error uppercase pt-3">
        there are no modules here yet.
      </div>
    </div>
    <!-- <div v-else class="text-error flex h-88 items-center justify-center">
      You don't have a privilege to see this!
    </div> -->
  </div>
</template>

<script>
import BarLoader from '../../../components/collection/BarLoader.vue';
import BaseCheckBox from '@/components/base/BaseCheckBox.vue';
import eventBus from '../../../eventBus';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { BarLoader, BaseCheckBox },
  props: ['immutable'],
  data() {
    return {
      working: false,
      modules: [],
      active_module: 0,
      authorizedActions: [],
      moduleWorking: false,
      activeRole: '',
      role_list: [],
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
    ...mapGetters('main', ['berror']),
    ROLE_OPTIONS() {
      return this.role_list.map((role) => ({
        label: role.name,
        value: role._id,
      }));
    },
  },
  watch: {
    activeRole() {
      this.getRole();
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    async fetchRoles() {
      this.working = true;
      let response = await this.request({
        method: 'get',
        url: 'role/my-active-roles',
      });
      if (response && !response.error) {
        this.role_list = response.record;
        // this.selectedRole = response.record[0];
        this.activeRole = response.record[0]._id;
      } else this.role_list = [];
      this.working = false;
    },

    async getAuthorizedActions() {
      this.working = true;
      let res = await this.request({
        url: 'school/module',
        method: 'get',
      });
      this.modules = res && res.record ? res.record : [];
      this.working = false;
    },
    async getRole() {
      this.working = true;

      let res = await this.request({
        url: `role/${this.activeRole}`,
        method: 'get',
      });
      this.authorizedActions = res && res.record ? res.record.actions : [];
      // this.authorizedActions = [...this.authorizedActions];
      this.working = false;
    },
    async getModules() {
      this.working = true;
      let res = await this.request({
        url: 'school/module?limit=100',
        method: 'get',
      });
      this.modules = res && res.record ? res.record : [];
      // getting categories in module 1
      let re = await this.request({
        url: `school/submodule/${this.modules[0]._id}?limit=100`,
        method: 'get',
      });
      this.modules[0].categories = re && re.record ? re.record : [];

      for (let index = 0; index < this.modules[0].categories.length; index++) {
        let res1 = await this.request({
          url: `school/action/${this.modules[0].categories[index]._id}?limit=100`,
          method: 'get',
        });
        this.modules[0].categories[index].actions =
          res1 && res1.record ? res1.record : [];
      }
      console.log('res', this.modules);
      this.working = false;
    },
    isCategorySelected(cat) {
      return cat.actions.every((a) => this.authorizedActions.includes(a.code));
    },
    isModuleSelected(module) {
      let all_actions = [];
      if (module && module.categories && module.categories.length)
        module.categories.map((v) => {
          v.actions.map((g) => {
            all_actions.push(g);
          });
        });
      return all_actions.every((a) => this.authorizedActions.includes(a.code));
    },
    async activateModule(module, i) {
      this.working = true;

      if (this.active_module != i) {
        this.moduleWorking = true;
        this.active_module = i;
        // getting the modules categories
        let re = await this.request({
          url: `school/submodule/${module._id}?limit=100`,
          method: 'get',
        });
        this.modules[i].categories = re && re.record ? re.record : [];
        if (this.modules[i].categories.length)
          for (
            let index = 0;
            index < this.modules[i].categories.length;
            index++
          ) {
            let res1 = await this.request({
              url: `school/action/${this.modules[i].categories[index]._id}?limit=100`,
              method: 'get',
            });
            this.modules[i].categories[index].actions =
              res1 && res1.record ? res1.record : [];
          }
        this.moduleWorking = false;
        this.working = false;
      }
    },
    async updateAuthorizedActions() {
      this.working = true;
      console.log('activeRole: ', this.activeRole);
      let res = await this.request({
        url: `role/actions/${this.activeRole}`,
        method: 'put',
        data: { actions: this.authorizedActions },
      });
      if (res && res.record) {
        eventBus.$emit('open-toast', {
          message: 'Permission changed successfully.',
          error: false,
        });
        await this.getRole();
      } else
        eventBus.$emit('open-toast', { message: this.berror, error: true });
      this.working = false;
    },
    // category
    selectCategory(actionCodes) {
      let unselectedActions = actionCodes.filter(
        (actionCode) => !this.authorizedActions.includes(actionCode)
      );
      this.authorizedActions = [
        ...this.authorizedActions,
        ...unselectedActions,
      ];
    },
    unselectCategory(actionCodes) {
      this.authorizedActions = this.authorizedActions.filter(
        (authCode) => !actionCodes.some((actionCode) => actionCode === authCode)
      );
    },
    categorySelected(actions = []) {
      return actions.every((a) => this.authorizedActions.includes(a.code));
    },
    toggleCategory(category) {
      // console.log('catagory toggle', category)
      let selected = this.categorySelected(category.actions);
      let actionCodes = category.actions.map(({ code }) => code);
      // console.log('act', actionCodes)
      if (selected) this.unselectCategory(actionCodes);
      else this.selectCategory(actionCodes);
    },
    // module
    selectModule(actionCodes) {
      let unselectedActions = actionCodes.filter(
        (actionCode) => !this.authorizedActions.includes(actionCode)
      );
      this.authorizedActions = [
        ...this.authorizedActions,
        ...unselectedActions,
      ];
    },
    unselectModule(actionCodes) {
      this.authorizedActions = this.authorizedActions.filter(
        (authCode) => !actionCodes.some((actionCode) => actionCode === authCode)
      );
    },
    ModuleSelected(actions = []) {
      return actions.every((a) => this.authorizedActions.includes(a.code));
    },
    toggleModule(Module) {
      let all_actions = [];
      Module.categories.map((v) => {
        v.actions.map((g) => {
          all_actions.push(g);
        });
      });

      let selected = this.ModuleSelected(all_actions);
      let actionCodes = all_actions.map(({ code }) => code);
      // console.log('act', actionCodes)
      if (selected) this.unselectModule(actionCodes);
      else this.selectModule(actionCodes);
      // console.log('selected actions', this.authorizedActions);
    },
    // actions

    selectAction(actionCode) {
      this.authorizedActions = [...this.authorizedActions, actionCode];
    },
    unselectAction(actionCode) {
      this.authorizedActions = this.authorizedActions.filter(
        (code) => code !== actionCode
      );
    },
    toggleAction(actionCode) {
      if (this.authorizedActions.includes(actionCode))
        this.unselectAction(actionCode);
      else this.selectAction(actionCode);
    },
  },

  async created() {
    this.working = true;
    await this.fetchRoles();
    await this.getAuthorizedActions();
    await this.getModules();
    await this.getRole();
    this.working = false;
  },
};
</script>

<style></style>
