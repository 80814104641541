var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[(_vm.working)?_c('loader',{attrs:{"type":"dashboard"}}):_c('div',[(_vm.authCodes.includes('da-view'))?_c('div',{staticClass:"w-11/12 mx-auto"},[_c('div',[_c('p',{staticClass:"text-lg text-textDarkest mt-4 mb-6"},[_vm._v("Dashboard")])]),_c('div',{staticClass:"grid grid-cols-4 gap-4"},[_c('div',{staticClass:"col-span-3"},[_c('div',{staticClass:"grid lg:grid-cols-4 grid-cols-2 gap-x-4 gap-y-6 z-20"},_vm._l((_vm.top_cards),function(card,i){return _c('router-link',{key:i,staticClass:"rounded-lg flex bg-white p-5 shadow-lg relative z-10 cursor-pointer",style:({
                background: `linear-gradient(45deg, ${
                  _vm.cards[card.title].color
                })`,
              }),attrs:{"to":_vm.cards[card.title].path}},[_c('div',{staticClass:"rounded-md flex"},[_c('div',{staticClass:"mx-3 my-2"},[_c('icon',{attrs:{"fill":"white","name":_vm.cards[card.title].type,"size":50}})],1),_c('div',{staticClass:"text-white"},[_c('div',[_c('p',{staticClass:"text-sm opacity-75"},[_vm._v(" "+_vm._s(_vm.cards[card.title].title)+" ")])]),_c('div',[_c('p',{staticClass:"font-semibold text-lg"},[_vm._v(_vm._s(card.total_item))])])])])])}),1),_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"bg-white w-full mt-2 p-5"},[_c('div',[_vm._v("Users Activity")]),_c('apexchart',{attrs:{"width":"100%","height":"350","type":"line","options":_vm.chartOneOptions,"series":_vm.seriesOne}})],1)])]),_c('div',{},[_c('div',{staticClass:"w-full max-w-full border-none"},[_c('v-calendar',{style:({
                border: 'none',
                width: '100%',
              }),attrs:{"attributes":_vm.attributes,"weekday-format":(day) => day.substr(0, 3)}})],1),_c('div',{},[_c('p',{staticClass:"p-2"},[_vm._v("Messages")]),(_vm.MESSAGES.length > 0)?_c('div',_vm._l((_vm.MESSAGES),function(message,i){return _c('div',{key:i,staticClass:"px-4"},[_c('div',{staticClass:"mt-2 rounded-md w-full flex h-20 shadow-sm"},[_c('div',{staticClass:"w-1/4 mx-3 my-auto relative"},[_c('div',{staticClass:"w-16 h-16 rounded-full bg-gray-100 flex justify-center items-center"},[(message.profile)?_c('img',{directives:[{name:"viewer",rawName:"v-viewer",value:({ inline: false, movable: true }),expression:"{ inline: false, movable: true }"}],staticClass:"w-full h-full object-cover rounded-full",attrs:{"src":_vm.PROFILE_PATH + message.profile,"alt":"","crossorigin":"anonymous"}}):_c('icon',{staticClass:"w-full h-full",attrs:{"size":18,"name":"user"}})],1),(message.active)?_c('div',{staticClass:"h-3 w-3 rounded-full bg-success absolute bottom-0 right-1"}):_vm._e()]),_c('div',{staticClass:"my-auto w-full"},[_c('div',{staticClass:"flex justify-between"},[_c('p',{staticClass:"text-lg font-semibold"},[_vm._v(_vm._s(message.name))]),_c('p',{staticClass:"text-xs font-semibold pr-2"},[_vm._v(" "+_vm._s(message.date)+" ")])]),_c('p',{staticClass:"text-red-900"},[_vm._v(_vm._s(message.message))])])])])}),0):_c('base-no-data',{attrs:{"message":"no recent messages"}})],1)])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }