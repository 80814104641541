var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-2"},[_c('div',{staticClass:"flex space-x-5 my-8 mb-0 bg-white py-5 px-5"},[_c('div',{staticClass:"cursor-pointer",class:{
        'border-b-2 border-primary': _vm.activeTab == 'all_channels',
        'text-textLight': _vm.activeTab != 'all_channels'
      },on:{"click":function($event){return _vm.selectTab('all_channels')}}},[_c('p',{staticClass:"text"},[_vm._v("All Channels")])]),_c('div',{staticClass:"cursor-pointer",class:{
        'border-b-2 border-primary': _vm.activeTab == 'subscribed_courses',
        'text-textLight': _vm.activeTab != 'subscribed_courses'
      },on:{"click":function($event){return _vm.selectTab('subscribed_courses')}}},[_vm._v(" My Subscriptions ")])]),_c('div',{staticClass:"bg-white"},[(_vm.activeTab === 'all_channels')?_c('div',{staticClass:"px-8 py-10"},[_c('div',{staticClass:"grid grid-cols-4 gap-10"},_vm._l((_vm.items),function(channel,i){return _c('channel-entry',{key:i,attrs:{"channel":channel},on:{"click":function($event){return _vm.handleChannelDetail(channel._id)},"updated":_vm.fetchItems,"deleted":_vm.fetchItems}})}),1)]):_vm._e(),(_vm.activeTab === 'subscribed_courses')?_c('div',{staticClass:"px-8"},[_c('my-subscriptions')],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }