<template>
  <div class="border border-success p-2 pb-1 rounded flex flex-wrap">
    <div
      v-for="(item, index) in value"
      :key="index"
      class="rounded-full px-2 text-sm py-1 m-1 flex value-center space-x-2"
      :class="
        !item.error ? 'bg-primaryalpha text-primary' : 'text-error bg-danger'
      "
    >
      <div>{{ item.value }}</div>
      <div
        class="activeresize cursor-pointer active:bg-primary active:text-white rounded-full p-1"
        @click="removeItem(index)"
      >
        <icon name="times" :size="11" />
      </div>
    </div>
    <input
      placeholder="Enter your entry and hit the ENTER key"
      class="outline-none p-2 w-full"
      v-model="item"
      @keydown.enter="addItem"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      item: '',
    };
  },
  methods: {
    removeItem(index) {
      this.$emit('input', [
        ...this.value.slice(0, index),
        ...this.value.slice(index + 1),
      ]);
    },
    addItem() {
      if (this.item)
        this.$emit('input', [
          ...this.value,
          { value: this.item, error: false },
        ]);

      this.item = '';
    },
  },
};
</script>

<style>
.activeresize {
  transition: transform 0.3s;
}
.activeresize:active {
  transform: scale(1.5);
}
</style>
