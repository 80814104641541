<template>
  <div class="">
    <div
      class="bg-white p-4 animate-pulse grid grid-cols-3 gap-2"
      v-if="type === 'list'"
    >
      <div
        class="h-20 bg-gray-100 rounded-default p-4"
        v-for="i in amount"
        :key="i"
      >
        <div class="flex justify-between mb-2">
          <div class="w-3/5 h-2 rounded-default bg-gray-200"></div>
          <div class="w-1/4 h-2 rounded-default bg-gray-200"></div>
        </div>
        <div class="w-16 h-2 rounded-default bg-gray-200 mb-2"></div>
        <div class="flex gap-4">
          <div class="w-6 h-6 rounded-full bg-gray-200"></div>
          <div class="w-6 h-6 rounded-full bg-gray-200"></div>
        </div>
      </div>
    </div>

    <div
      class="bg-white p-4 animate-pulse grid grid-cols-1 gap-4"
      v-if="type === 'table'"
    >
      <div
        class="grid grid-cols-12 gap-4 items-center"
        v-for="i in amount"
        :key="i"
      >
        <div class="h-10 w-10 bg-gray-100 rounded-full"></div>
        <div class="h-2 w-full col-span-4 bg-gray-100 rounded-full"></div>
        <div class="h-2 w-full col-span-7 bg-gray-100 rounded-full"></div>
      </div>
    </div>

    <div
      class="bg-white p-12 animate-pulse grid grid-cols-4 gap-4"
      v-if="type === 'dashboard'"
    >
      <div class="col-span-3">
        <div class="grid grid-cols-4 gap-4">
          <div
            class="h-32 px-2 bg-gray-200 rounded-lg grid grid-cols-4 items-center gap-4"
            v-for="i in 4"
            :key="i"
          >
            <div class="bg-gray-100 h-12 w-12 rounded-full"></div>
            <div class="col-span-3 grid gap-4">
              <div class="bg-gray-100 h-2 rounded-full"></div>
              <div class="bg-gray-100 h-2 w-1/2 rounded-full"></div>
            </div>
          </div>
        </div>
        <div class="bg-gray-200 p-20 mt-4 rounded-lg gap-32 grid">
          <div class="grid gap-8">
            <div class="bg-gray-100 h-2 w-1/4 rounded-full"></div>
            <div class="bg-gray-100 h-2 w-1/2 rounded-full"></div>
            <div class="bg-gray-100 h-2 rounded-full"></div>
          </div>
          <div class="grid gap-8">
            <div class="bg-gray-100 h-2 w-1/4 rounded-full"></div>
            <div class="bg-gray-100 h-2 w-1/2 rounded-full"></div>
          </div>
        </div>
      </div>
      <div class="bg-gray-200 rounded-lg p-4">
        <div class="bg-gray-100 h-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: { type: String, default: 'table' },
    amount: { type: Number, default: 3 },
  },
};
</script>
