<template>
  <div class="p-8">
    <div class="flex items-center gap-6 p-6 bg-gray-50 rounded-lg">
      <div class="w-20 h-20 rounded-full overflow-hidden">
        <img
          :src="LOGO_PATH + school.logo"
          alt=""
          class="object-cover w-full h-full"
        />
      </div>
      <div class="w-full">
        <p class="text-2xl font-bold uppercase">{{ school.name }}</p>
        <div class="flex justify-between">
          <p>{{ school.moto }}</p>
          <div class="flex gap-4">
            <p>Phone No: {{ school.phoneNumber[0] }}</p>
            <p>Email: {{ school.email }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGO_PATH } from '@/config/config.js';
export default {
  props: ['school'],
  data() {
    return {
      LOGO_PATH,
    };
  },
};
</script>
