<template>
  <div class="h-full">
    <div class="bg-white space-y-8 text-textMedium">
      <div class="w-full h-32 bg-orange relative">
        <img
          src="@/assets/img/hd-2.jpg"
          class="w-full h-full object-cover"
          alt=""
        />
        <div
          class="p-3 cursor-pointer rounded-full bg-black opacity-50 absolute flex justify-items-center justify-between"
          style="right: 10px; bottom: 10px"
          @click="updateCover"
        >
          <icon name="camera" :size="20" class="text-white" />
        </div>
      </div>
      <!-- name container -->
      <div class="flex space-x-3 items-center px-5">
        <div
          class="h-16 w-16 rounded-full bg-gray-100 flex justify-center items-center"
        >
          <img
            v-if="item.logo"
            :src="LOGO_PATH + item.logo"
            v-viewer="{ inline: false, movable: true }"
            class="w-full h-full object-cover rounded-full"
            alt=""
            crossorigin="anonymous"
          />
          <icon v-else :size="35" name="building" class="w-full h-full"></icon>
        </div>
        <div class="my-3 px-3">
          <p class="text-2xl text-textDarkest font-semibold">
            {{ item.name }}
          </p>
          <p v-if="item.moto" class="text-textLight">{{ item.moto }}</p>
        </div>
      </div>
      <!-- tab container -->
      <div class="h-full">
        <tabs class="h-full">
          <tab v-if="authCodes.includes('pre-bsc')" title="Basic Information">
            <school-basic-info :id="school_id" />
          </tab>
          <tab
            v-if="authCodes.includes('pre-shl')"
            title="School Config"
            class="h-full"
          >
            <school-config :id="school_id" class="h-full" />
          </tab>
          <tab title="Grade Config" class="h-full">
            <GradeConfig :id="school_id" class="h-full" />
          </tab>
          <!-- <tab v-if="authCodes.includes('pre-reg')" title="Registration Config">
            <registration-config :id="school_id" />
          </tab> -->
          <tab v-if="authCodes.includes('pre-fee')" title="Fee Config">
            <fee-config :id="school_id" />
          </tab>
          <!-- <tab title="Students">
            <div>Students</div>
          </tab>
          <tab title="Teachers">
            <div>Teachers</div>
          </tab>
          <tab title="Reporting">
            <div>Reporting</div>
          </tab> -->
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/tab/Tabs.vue';
import Tab from '@/components/tab/Tab.vue';
import SchoolConfig from './SchoolConfig.vue';
import GradeConfig from './GradeConfig.vue';
// import RegistrationConfig from './RegistrationConfig.vue';
import FeeConfig from './FeeConfig.vue';

import SchoolBasicInfo from './components/SchoolBasicInfo.vue';
import { mapActions, mapGetters } from 'vuex';
import { LOGO_PATH } from '@/config/config.js';

export default {
  props: ['school_id'],
  components: {
    Tabs,
    Tab,
    // RegistrationConfig,
    FeeConfig,
    SchoolConfig,
    SchoolBasicInfo,
    GradeConfig,
  },
  data() {
    return {
      LOGO_PATH,
      item: {},
      isSchoolOfficial: false,
      Form: {
        cover: '',
      },
      coverObj: '',
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
  },

  methods: {
    ...mapActions('main', ['request']),
    updateCover() {},

    async fetchMySchool() {
      let response = await this.request({
        method: 'get',
        url: 'school/schoolProfile/my-school',
      });
      if (response && !response.error) {
        this.item = response.record;
      } else {
        this.item = {};
      }
    },
  },

  async created() {
    await this.fetchMySchool();
  },
};
</script>

<style></style>
