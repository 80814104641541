<template>
  <div class="my-3">
    <div class="flex items-center justify-between w-full">
      <p class="uppercase text-lg text-textMedium tracking-wider">
        calendar preference
      </p>
    </div>
    <div class="mt-1">
      <div class="grid grid-cols-3 gap-5">
        <base-select
          label="Preferred Calendar Type"
          placeholder=""
          v-model="Form.calendarPref"
          :items="CALENDAR_TYPES"
          :errors="errors.calendarPref"
          @input="handleChangeCalendar"
        />
      </div>
    </div>
    {{ id }}
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapState } from 'vuex';

import eventBus from '@/eventBus.js';
export default {
  props: ['id'],
  data() {
    return {
      working: false,
      errors: {},
      Form: {
        calendarPref: 'eth',
      },
      initial: true,
    };
  },
  computed: {
    ...mapState('main', ['globalSchoolConfig']),
    CALENDAR_TYPES() {
      return [
        { label: 'Gregorian Calendar', value: 'greg' },
        { label: 'Ethiopian Calendar', value: 'eth' },
      ];
    },
  },
  methods: {
    ...mapActions('main', ['request', 'fetchSchoolConfig']),

    async handleChangeCalendar() {
      let componentProps = {
        title: 'Change Calendar Type',
        message: 'Are you sure you want to change the calendar type',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedChange,
      });
    },

    async proceedChange() {
      let response = await this.request({
        method: 'put',
        url: 'school/config/calendar-type/',
        data: {
          calendarType: this.Form.calendarPref,
        },
      });

      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Calendar Type Changed Successfully',
        });
        this.fetchSchoolConfig();
      } else {
        eventBus.$emit('open-toast', {
          message: response.message,
          error: true,
        });
      }
    },
  },
  created() {
    this.Form.calendarPref = this.globalSchoolConfig.calendarType;
  },
};
</script>

<style></style>
