<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title> Set Sessions </template>
    <template>
      <div class="py-5">
        <div class="flex space-x-4 items-center">
          <base-input
            label="Sessions Per Week *"
            placeholder=""
            v-model="Form.sessions"
            :errors="errors.sessions"
            type="number"
            class="w-1/2"
            check
          />
          <!-- <base-input
            label="Per Week"
            placeholder=""
            v-model="Form.lab"
            :errors="errors.lab"
            type="number"
            class=" w-1/2"
          /> -->
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import { minValue, required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate },
  props: ['updateSessions', 'ctaId'],
  mixins: [validation],
  data() {
    return {
      Form: {
        sessions: this.updateSessions || 0,
      },
      errors: {},
      meta: [{ key: 'Form.session', label: 'Session' }],
      initial: true,
    };
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['session'].includes(key)) {
            acc[key] = { required, minValue: minValue(0) };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    computed: {
      ...mapState('main', ['error']),
    },
    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      let response = await this.request({
        method: 'put',
        url: 'school/teacherCourse/config/' + this.ctaId,
        data: this.Form,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'config saved successfully' });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.initial = true;
    },
  },
  created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
  },
};
</script>

<style></style>
