<template>
  <div>
    <!-- <sign-me-out /> -->
  </div>
</template>

<script>
import io from 'socket.io-client';
import { IO_BASE_URL, TOKEN_KEY } from '@/config/config';
import { mapActions, mapGetters } from 'vuex';
// import SignMeOut from "./SignMeOut.vue";
export default {
  components: {
    //   SignMeOut
  },
  computed: {
    ...mapGetters('socket', ['sioClient']),
    ...mapGetters(['user'])
  },
  methods: {
    ...mapActions('socket', ['smartSet']),
    async ioconnect() {
      const token = await localStorage.getItem(TOKEN_KEY);
      let sioClient = io(IO_BASE_URL, {
        withCredentials: true,
        extraHeaders: {
          token
        },
        query: {
          token: token.slice(1, token.length - 1)
        }
      });
      
      this.smartSet({ stateKey: 'sioClient', data: sioClient });
    }
  },
  async created() {
    await this.ioconnect();
    this.sioClient.on('connected', () => {
      
      this.smartSet({ stateKey: 'clientConnected', data: true });
    });

    this.sioClient.on('NEW_MESSAGE', () => {
      /// TODO: PLAY THE SOUND HERE.
      
      const audio = document.createElement('video');
      audio.src = '/test.mp3';
      audio.autoplay = true;
      audio.play();
    });

    this.sioClient.emit('signed_in', { userId: this.user.id });
    
  },
  destroyed() {
    this.smartSet({ stateKey: 'clientConnected', data: false });
  }
};
</script>

<style></style>
