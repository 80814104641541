<template>
  <div class="bg-white py-0 shadow-default z-10">
    <div class="mx-4 ml-0 h-full flex space-x-10 items-center">
      <div class="flex space-x-2 items-center bg-primary">
        <!-- <icon name="clock" :size="20" class="text-primary" /> logo -->
        <div
          class="text-xl font-semibold text-white transform duration-500"
          :class="{
            ' w-12 text-center text-white font-black py-2': collapsed,
            'w-64 px-4 py-2 text-center': !collapsed,
          }"
        >
          {{ collapsed ? 'H' : 'HENON' }}
        </div>
      </div>
      <!-- <component :is="menu" /> -->
      <!-- <SchoolOfficialHeader /> -->
      <div class="flex-auto flex items-center bg-white space-x-5">
        <div
          v-for="(nav, i) in navsFiltered"
          :key="i"
          class="cursor-pointer hover:text-primary font-semibold text-gray-800 tracking-tight uppercase text-sm"
          @click="changeActiveNav(nav)"
        >
          <!-- :class="{
            'text-primary border-b-2 pt-1 border-primary': isActive(nav),
          }" -->

          <div class="flex space-x-2 items-center">
            <icon
              v-if="isActive(nav)"
              :name="nav.icon"
              :size="22"
              :class="{
                'text-primary': isActive(nav),
              }"
            />
            <div>
              <div>{{ nav.label }}</div>
              <div
                v-if="isActive(nav)"
                class="bg-primary w-10 h-1 rounded-lg"
              ></div>
            </div>
          </div>
          <!-- <div v-if="isActive(nav)" class="bg-primary h-1 w-8 rounded-b-full"></div> -->
        </div>
      </div>
      <div class="flex space-x-4 justify-end items-center text-gray-500">
        <div
          class="px-1 font-sans tracking-widest rounded-sm border-1 border-opacity-50 border-primary"
        >
          {{ formatedCounter }}
        </div>
        <div class="hover:text-gray-600">
          <icon name="bell" class="" />
        </div>
        <div
          v-if="authModules.includes('cha')"
          class="hover:text-gray-600 cursor-pointer"
          @click="$router.push({ name: 'chat' })"
        >
          <icon name="comment" class="" />
        </div>
        <!-- <router-link
          to="/settings"
          class="hover:bg-dgray p-2 rounded-full hover:font-semibold"
        >
          <icon name="cog" class="text-textMedium" />
        </router-link> -->
        <div
          v-if="authModules.includes('set')"
          @click="goToSetting"
          class="hover:text-gray-600 cursor-pointer rounded-full hover:font-semibold"
        >
          <icon name="cog" class="" />
        </div>
        <profile />
      </div>
    </div>
  </div>
</template>

<script>
import Profile from '../../card/tippy/Profile.vue';
// import SuperAdminHeader from './SuperAdminHeader.vue';
// // import SchoolManagerHeader from './SchoolManagerHeader.vue';
// // import SchoolRegistrarHeader from './SchoolRegistrarHeader.vue';
// import SchoolOfficialHeader from './SchoolOfficialHeader.vue';
// import RegistrarHeader from './RegistrarHeader.vue';
import { mapGetters, mapActions } from 'vuex';
import { navs } from '@/config/constants';
import { navService } from '@/services/storageService';
import eventBus from '../../../eventBus';

export default {
  components: { Profile },
  data() {
    return {
      navs,
      collapsed: false,
      // menus: {
      //   super_admin: SuperAdminHeader,
      //   // shl_manager: SchoolManagerHeader,
      //   registrar: RegistrarHeader,
      //   school_official: SchoolOfficialHeader,
      // },
    };
  },

  computed: {
    ...mapGetters(['user', 'authModules']),
    ...mapGetters('timer', ['inactivityCounter', 'inactivityPeriod']),
    navsFiltered() {
      // let na = navs
      // console.log('navs', navs, na);
      // return navs
      // let auth = this.user.authModules
      console.log('authModules', this.authModules, navs);
      // return navs;
      return navs.filter((nav) => this.authModules.includes(nav.code));
    },
    // authModules() {
    //   return this.user.authModules
    // },
    menu() {
      const static_roles = ['super_admin', 'registrar'];
      let role = this.user.role || 'school_official';
      if (!static_roles.includes(role)) role = 'school_official';
      return this.menus[role];
    },
    formatedCounter() {
      let durationLeft = this.inactivityPeriod - this.inactivityCounter;
      durationLeft = Math.floor(durationLeft);
      let hours = Math.floor(durationLeft / 3600);
      durationLeft -= hours * 3600;
      let mins = Math.floor(durationLeft / 60);
      durationLeft -= mins * 60;
      let secs = durationLeft;
      let result = '';
      if (hours > 0)
        result =
          this.formatDigit(hours) +
          ':' +
          this.formatDigit(mins) +
          ':' +
          this.formatDigit(secs);
      else result = this.formatDigit(mins) + ':' + this.formatDigit(secs);
      return result;
    },
  },
  methods: {
    ...mapActions('main', ['fetchSchoolConfig', 'request', 'setData']),
    ...mapActions(['setAuth', 'getMe']),
    isActive(nav) {
      // console.log('nav', nav);
      return nav.to.toLowerCase() === this.activeNavigation();
      // return nav.toLowerCase()=='dashboard' ? '' == this.activeNavigation() : nav.toLowerCase() === this.activeNavigation()
    },
    activeNavigation() {
      return this.$route.fullPath.split('/')[1];
    },
    goToSetting() {
      this.setData({ stateKey: 'activeNav', data: 'settings' });
      this.$router.push('/settings');
    },
    changeActiveNav(nav) {
      let nav1 = nav;
      nav = nav.to.toLowerCase();
      // !this.$route.fullPath.includes(nav)
      console.log('nav', this.$route.fullPath.split('/')[1], nav);
      if (this.$route.fullPath.split('/')[1] != nav) {
        navService.saveActiveNav(nav);
        this.setData({ stateKey: 'activeNav', data: nav1.label });
        // if (nav === 'dashboard') this.$router.push('/dashboard');
        // else
        this.$router.push('/' + nav);
      } else window.location.reload();
    },

    formatDigit(digit) {
      if (digit < 10) return '0' + digit;
      return digit;
    },
    async getCredential() {
      let res = await this.request({
        url: `school/module/my-auth`,
        method: 'get',
      });
      let user = res && res.item ? res.item : {};
      this.setAuth(user);
    },
  },
  async created() {
    await this.fetchSchoolConfig();
    await this.getMe();
    await this.getCredential();
    eventBus.$on('collapse', async (collapse) => {
      this.collapsed = collapse;
    });
  },
};
</script>
