<template>
  <!-- period -->
  <div class="pt-4 px-7 bg-white">
    <div class="grid grid-cols-3 gap-20 justify-between">
      <base-select
        label="Grade"
        placeholder=""
        v-model="activeGrade"
        :items="GRADE_OPTIONS"
        :errors="errors.grade"
      />
      <base-select
        label="Section"
        placeholder=""
        v-model="activeSection"
        :items="SECTION_OPTIONS"
        :errors="errors.section"
      />
    </div>
    <div
      v-if="activeSection"
      class="flex space-x-2 py-5 bg-white pb-10"
      style="min-height: 70vh"
    >
      <div
        v-for="(weekDay, i) in WORKING_DAYS"
        :key="i"
        class="w-full"
        style="min-height: 100%"
      >
        <div class="w-full">
          <p class="font-semibold text-sm text-textDark text-center">
            {{ weekDay.label }}
          </p>
        </div>
        <div
          class="mt-3 w-full space-y-2 bg-gray-100 px-2 py-2 rounded-default h-full"
        >
          <session-card
            v-for="(session, k) in weekSessions[i + 1]"
            :sequence="k + 1"
            :key="k"
            @delete="handleDeleteSession($event)"
            @edit="handleUpdateSession($event)"
            @toggle="toggled($event)"
            :session="session"
            :mode="mode"
            :selectedSessions="selectedSessions"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- session end -->
</template>

<script>
import SessionCard from './SessionCard.vue';
import { mapActions, mapGetters } from 'vuex';

// import { static_roles } from '@/config/constants';

export default {
  props: {
    school: String,
    mode: {
      type: String,
      default: 'selection', // selection
    },
    selectedSessions: {
      required: false,
    },
  },
  components: {
    SessionCard,
  },
  data() {
    return {
      module: 'schedule',
      permission: {},
      activeSchool: '',
      activeGrade: '',
      activeSection: '',
      school_list: [],
      gradeItems: [],
      sectionItems: [],
      sessionList: [],
      errors: {},
      working_days: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false,
      }, //   selectedSessions: [
      //   selectedSessions: []
    };
  },
  computed: {
    ...mapGetters(['user']),
    WORKING_DAYS() {
      return Object.keys(this.working_days)
        .map((day) => {
          if (this.working_days[day]) {
            return {
              label: day.toUpperCase(),
              value: day,
            };
          }
        })
        .filter(Boolean);
    },
    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },

    SECTION_OPTIONS() {
      return this.sectionItems.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },
    mondaySessions() {
      return this.sessionList.filter((session) => session.day === 'monday');
    },
    tuesdaySessions() {
      return this.sessionList.filter((session) => session.day === 'tuesday');
    },
    wednesdaySessions() {
      return this.sessionList.filter((session) => session.day === 'wednesday');
    },
    thursdaySessions() {
      return this.sessionList.filter((session) => session.day === 'thursday');
    },
    fridaySessions() {
      return this.sessionList.filter((session) => session.day === 'friday');
    },
    saturdaySessions() {
      return this.sessionList.filter((session) => session.day === 'saturday');
    },
    sundaySessions() {
      return this.sessionList.filter((session) => session.day === 'sunday');
    },
    weekSessions() {
      return {
        1: this.mondaySessions,
        2: this.tuesdaySessions,
        3: this.wednesdaySessions,
        4: this.thursdaySessions,
        5: this.fridaySessions,
        6: this.saturdaySessions,
        7: this.sundaySessions,
      };
    },
  },
  watch: {
    activeSchool(n) {
      this.fetchGrades(n);
    },
    activeGrade(n) {
      this.fetchSections(n);
    },
    activeSection(n) {
      this.fetchSession(n);
    },
  },
  methods: {
    ...mapActions('main', ['request']),

    toggled(sessionId) {
      //   if (this.selectedSessions.includes(sessionId))
      //     return this.selectedSessions.splice(
      //       this.selectedSessions.indexOf(sessionId),
      //       1
      //     );
      //   this.selectedSessions.push(sessionId);
      //   this.selectedSessions = [...this.selectedSessions];
      this.$emit('selected', sessionId);
    },

    async fetchSchools() {
      let response = await this.request({
        method: 'get',
        url: 'school/schoolProfile',
      });
      if (response && !response.error) {
        this.school_list = response.record;
        this.activeSchool = response.record[0]._id;
      } else this.school_list = [];
    },

    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url:
          'school/class/active/' + (this.activeSchool ? this.activeSchool : ''),
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
        this.activeGrade = response.record[0]._id;
      } else {
        this.gradeItems = [];
      }
    },

    async fetchSections(gradeId) {
      let response = await this.request({
        method: 'get',
        url: 'school/section/class/' + gradeId,
      });
      if (response && !response.error) {
        this.sectionItems = response.record;
        this.activeSection = response.record[0]._id;
      } else {
        this.sectionItems = [];
      }
    },

    async fetchSession() {
      let response = await this.request({
        method: 'get',
        url: 'school/schedule/section/' + this.activeSection,
      });
      if (response && !response.error) this.sessionList = response.record;
      else this.sessionList = [];
    },

    async fetchWorkingDays() {
      let response = await this.request({
        method: 'get',
        url: 'school/config/',
      });

      if (response && !response.error) {
        if (response.record.working_days)
          this.working_days = response.record.working_days;
      }
    },
    checkStatic() {
      // const role = this.user.role;
      // if (!static_roles.includes(role)) {
      //   this.isSchoolOfficial = true;
      //   const permissions = this.user.permissions;
      //   let permission = permissions.find((p) => p.module.name === this.module);
      //   this.permission = permission;
      //   // this.handlers = this.handlers.filter((action)=>permission[action.name])
      // }
    },
  },

  created() {
    this.checkStatic();
    this.fetchWorkingDays();
    this.fetchGrades();
    const working = localStorage.getItem('selectedWorkingDays');
    this.weekDays = working.split(',');
  },
};
</script>

<style></style>
