<template>
  <modal-template
    @cancel="handleClose"
    @save="handleSave"
    :working="working"
    :saveLabel="isUpdate ? 'Update' : 'Save'"
  >
    <template #title>
      {{ isUpdate ? 'Update' : 'Create New' }} Academic Year
    </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-2 gap-5">
          <base-input
            class="col-span-2"
            label="Name"
            placeholder="name"
            v-model="Form.name"
            :errors="errors.name"
            check
            required
          />
          <base-input
            v-if="isGreg"
            label="Start Date"
            placeholder="start date"
            v-model="Form.startDate"
            :errors="errors.startDate"
            type="date"
            check
            required
          />
          <base-input
            v-if="isGreg"
            label="End Date"
            placeholder="end date"
            v-model="Form.endDate"
            :errors="errors.endDate"
            type="date"
            check
            required
          />
          <base-eth-calendar
            v-if="!isGreg"
            label="Start Date"
            v-model="Form.startDate"
            :errors="errors.startDate"
            :isUpdate="isUpdate"
          />
          <base-eth-calendar
            v-if="!isGreg"
            label="End Date"
            v-model="Form.endDate"
            :errors="errors.endDate"
            :isUpdate="isUpdate"
          />
          <switch-button label="Is Active" v-model="Form.status" />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { formatInputDate } from '@/utils';

import { mapActions, mapState } from 'vuex';
import SwitchButton from '../base/SwitchButton.vue';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm', 'class', 'section'],

  mixins: [validation],
  data() {
    return {
      Form: {
        class: this.class,
        section: this.section,
        name: '', /// 2014 (year of 2014) - 1st semester
        startDate: '',
        endDate: '',
        status: '', // active, closed, not_started
      },
      errors: {},
      meta: [
        { key: 'Form.name', label: 'Name' },
        { key: 'Form.startDate', label: 'Start date' },
        { key: 'Form.endDate', label: 'End date' },
      ],
      working: false,
      initial: true,
    };
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['startDate', 'endDate', 'name'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    ...mapState('main', ['error', 'globalSchoolConfig']),
    isGreg() {
      return (
        this.globalSchoolConfig &&
        this.globalSchoolConfig.calendarType == 'greg'
      );
    },
    isUpdate() {
      if (this.oldForm && this.oldForm.name) return true;
      else return false;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },

    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
      this.initial = true;
    },
    populateData() {
      this.Form.name = this.oldForm.name;
      if (this.isGreg) {
        this.Form.endDate = formatInputDate(this.oldForm.endDate);
        this.Form.startDate = formatInputDate(this.oldForm.startDate);
      } else {
        this.Form.endDate = this.oldForm.endDate;
        this.Form.startDate = this.oldForm.startDate;
      }

      this.Form['status'] = ['closed', 'inactive'].includes(
        this.oldForm['status']
      )
        ? false
        : true;

      // this.Form = { ...this.Form };
      // console.log('new form', this.Form);
    },
    async handleCreate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      let response = await this.request({
        method: 'post',
        url: 'school/academicYear',
        data: newForm,
      });
      if (response && !response.error) {
        // this.item = response.item;
        eventBus.$emit('open-toast', {
          message: 'academic year created successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },

    async handleUpdate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';
      newForm = {
        name: newForm.name,
        section: this.section,
        startDate: newForm.startDate,
        endDate: newForm.endDate,
        status: newForm.status,
      };

      let response = await this.request({
        method: 'put',
        url: 'school/academicYear/' + this.oldForm._id,
        data: newForm,
      });
      if (response && !response.error) {
        // this.item = response.item;
        eventBus.$emit('open-toast', {
          message: 'academic year updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: `${this.error}`,
          error: true,
        });
      }
      this.working = false;
    },
  },
  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    if (this.isUpdate) {
      this.populateData();
    }
  },
};
</script>

<style></style>
