<template>
  <div class="my-3">
    <div class="flex justify-between items-center mb-3">
      <div class="flex space-x-3 items-center">
        <p class="uppercase text-lg text-textMedium">Grade Letter</p>
        <!-- <div
          class="p-1 rounded-full bg-primary cursor-pointer"
          @click="addGrade"
        >
          <icon name="plus" class="text-white" />
        </div> -->
      </div>
      <!-- <base-button label="save" @click="handleSave" class="px-3 rounded-sm" /> -->
    </div>
    <div class="mt-1">
      <div class="space-y-3">
        <div
          class="grid grid-cols-12 items-center gap-1"
          v-if="Form.grades.length > 0"
        >
          <div class="col-span-4">Label</div>
          <div class="col-span-4">Above %</div>
          <div class="col-span-2">Fail</div>
        </div>
        <div
          v-for="(item, i) in Form.grades"
          :key="i"
          class="grid grid-cols-12 items-center gap-1"
        >
          <base-input
            label=""
            placeholder=""
            type="text"
            class="col-span-4"
            v-model="Form.grades[i].label"
            :errors="errors.low"
          />
          <base-input
            label=""
            placeholder=""
            type="number"
            :min="0"
            :max="100"
            class="col-span-4"
            v-model="Form.grades[i].value"
            :errors="errors.low"
          />
          <base-check-box
            class="col-span-2"
            :selected="Form.grades[i].fail"
            @toggle="handleToggleFail(i)"
            :size="20"
          />
          <div
            class="cursor-pointer text-error bg-gray-200 hover:bg-opacity-50 p-2 rounded-lg"
            @click="removeGrade(i)"
          >
            <icon name="trash-alt" :size="12" class="" />
          </div>
        </div>
        <div class="flex p-6 justify-center items-center">
          <div
            v-if="Form.grades.length == 0"
            class="p-4 rounded-full border-2 border-primary text-primary cursor-pointer"
            @click="addGrade"
          >
            <icon name="plus" :size="48" class="" />
          </div>
          <div v-else class="flex justify-between items-center px-3 w-full">
            <base-button
              icon="plus"
              label="add new"
              @click="addGrade"
              class="bg-gray-200 text-textDarkest px-3"
              :primary="false"
            />
            <base-button
              icon="check-circle"
              label="save"
              @click="handleSave"
              class="px-3 text-xl rounded-sm uppercase"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import eventBus from '@/eventBus.js';
export default {
  props: ['id'],
  data() {
    return {
      isGradingDir: false,
      errors: {},
      Form: {
        working: false,
        grades: [],
      },
    };
  },

  watch: {
    // Form: function() {
    //   this.isDirty = true;
    // }
  },

  methods: {
    ...mapActions('main', ['request']),
    handleToggleFail(i) {
      this.Form.grades[i].fail = !this.Form.grades[i].fail;
    },
    addGrade() {
      this.Form.grades = [
        ...this.Form.grades,
        { label: '', value: 0, fail: false },
      ];
    },

    removeGrade(index) {
      this.Form.grades.splice(index, 1);
      this.Form.grades = [...this.Form.grades];
      this.Form = { ...this.Form };
    },

    async handleSave() {
      this.working = true;
      let response = await this.request({
        method: 'put',
        url: 'school/config/grade-letter',
        data: this.Form,
      });
      if (response && !response.error) {
        this.working = false;
        eventBus.$emit('open-toast', { message: 'updated successfully' });
        this.isDirty = false;
        this.fetchItem();
      } else {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    async fetchItem() {
      let response = await this.request({
        method: 'get',
        url: 'school/config/' + (this.id ? this.id : ''),
      });
      if (response && !response.error) {
        if (response.record.grades)
          this.Form.grades = response.record.grades.map((grade) => ({
            label: grade.label,
            value: grade.value,
            fail: grade.fail,
          }));
      }
    },
  },

  created() {
    this.fetchItem();
    Object.keys(this.Form).forEach((key) =>
      this.$watch(`Form.${key}`, () => {
        this.isDirty = true;
      })
    );
  },
};
</script>

<style></style>
