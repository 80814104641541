<template>
  <div class=" w-2/3 flex flex-col " style="background-color: #202b33">
    <!-- header -->
    <div class=" flex justify-end py-5 px-5">
      <div>
        <p class=" text-gray-400 cursor-pointer" @click="gotoCourse">
          Go To Course
        </p>
      </div>
    </div>

    <!-- content dispay -->
    <div class=" w-full bg-black h-2/3  my-auto ">
      <video
        :src="VIDEOS_PATH + content.path"
        class=" h-full w-full"
        :controls="true"
        @ended="onEnd"
      />
    </div>
    <div class=" pb-4 flex justify-end pr-5">
      <p class=" text-textLight font-semibold">HENON</p>
    </div>
  </div>
</template>

<script>
import { VIDEOS_PATH } from '@/config/config.js';
export default {
  props: ['content', 'ctaId', 'courseId'],
  data() {
    return {
      VIDEOS_PATH
    };
  },

  methods: {
    onStart() {
      this.$emit('start');
    },

    onEnd() {
      this.$emit('end');
    },

    goToCourse() {
      this.$router.push({
        name: 'n-course-detail',
        params: {
          courseId: this.courseId,
          cta: this.ctaId ? this.ctaId : undefined
        }
      });
    }
  },

  created() {
    this.onStart();
  }
};
</script>

<style></style>
