<template>
  <div class="">
    <base-title title="Migration">
      <template #actions>
        <div class="flex">
          <base-button
            icon="cloud-upload"
            label="Upload File"
            class="px-3"
          />
        </div>
      </template>
    </base-title>

    <div class="flex flex-col mt-2 p-6 bg-white">
      <!-- <file-input /> -->
      <data-loaded-overview />
    </div>
  </div>
</template>

<script>
import DataLoadedOverview from './components/DataLoadedOverview.vue';
// import FileInput from './components/FileInput.vue';
export default {
  components: {  DataLoadedOverview },
};
</script>

<style></style>
