<template>
  <div class="my-3">
    <div class="flex items-center justify-between w-full">
      <p class="uppercase text-lg text-textMedium tracking-wider">Schedule</p>
      <div class="flex justify-end">
        <base-button
          label="Save"
          class="px-3 rounded-sm"
          v-if="isDirty"
          @click="handleSave"
        />
      </div>
    </div>
    <div class="mt-1">
      <div>These are set to limit general schedule configurations weekly</div>
      <div class="grid grid-cols-3 gap-5 py-2">
        <base-input
          label="Maximum Weekly Session For Any Course"
          placeholder=""
          type="number"
          v-model="Form.max_session"
          :errors="errors.max_session"
          check
        />
        <base-input
          label="Maximum Weekly Allowed Teacher Day off"
          placeholder=""
          type="number"
          v-model="Form.max_dayoff"
          :errors="errors.max_dayoff"
          check
        />
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus.js';
import { mapActions } from 'vuex';
import { minValue, required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
export default {
  props: ['id'],
  mixins: [validation],
  data() {
    return {
      working: false,
      isDirty: false,
      errors: {},
      Form: {
        max_session: 0,
        max_dayoff: 0,
      },
      meta: [
        { key: 'Form.max_session', label: 'Max session' },
        { key: 'Form.max_dayoff', label: 'Max day off' },
      ],
      initial: true,
    };
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['max_dayoff', 'max_session'].includes(key)) {
            acc[key] = { required, minValue: minValue(0) };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },

  watch: {
    // Form: function() {
    //   this.isDirty = true;
    // }
  },

  methods: {
    ...mapActions('main', ['request']),
    async handleSave() {
      this.working = true;
      this.initial = false;
      if (!this.isValid()) return;
      let response = await this.request({
        method: 'put',
        url: 'school/config/schedule',
        data: { schedule: this.Form },
      });
      if (response && !response.error) {
        this.working = false;
        eventBus.$emit('open-toast', { message: 'updated successfully' });
        this.isDirty = false;
        this.fetchItem();
      } else {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.initial = true;
    },

    async fetchItem() {
      let response = await this.request({
        method: 'get',
        url: 'school/config/' + (this.id ? this.id : ''),
      });
      if (response && !response.error) {
        if (response.record.schedule) {
          this.Form.max_session = response.record.schedule.max_session;
          this.Form.max_dayoff = response.record.schedule.max_dayoff;
          this.Form = { ...this.Form };
        }
      }
    },
  },

  created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    this.fetchItem();
    Object.keys(this.Form).forEach((key) =>
      this.$watch(`Form.${key}`, () => {
        this.isDirty = true;
      })
    );
  },
};
</script>

<style></style>
