const state = {
  loading: false,
  amount: 1,
  bills: [],
  payment_type: undefined,
  paymentTypeBillingId: undefined,
  terms: [
    {
      paymentTermId: '',
      payment_starting_date: '',
      payment_due_date: '',
    },
  ],
  errors: [{}],
};

const mutations = {
  SMART_SET: (state, { stateKey, data }) => {
    state[stateKey] = data;
  },
  setLoader: (state, loading) => {
    state.loading = loading;
  },
  addBills: (state, bills) => {
    state.bills.push(...bills);
  },
  setBills: (state, bills) => {
    state.bills = bills;
    // state.bills = [...state.bills]; // Trigger the reactivity by replacing the entire array
  },
  setAmount: (state, amount) => {
    state.amount = amount;
  },

  clearBills: (state) => {
    state.bills = [];
  },
  clearAmount: (state) => {
    state.amount = 1;
  },

  addTerm: (state, index = 0) => {
    state.terms.splice(index, 0, {
      paymentTermId: '',
      payment_starting_date: '',
      payment_due_date: '',
    });
    state.errors.splice(index, 0, {});
  },
  clearTerms: (state) => {
    state.terms = [
      { paymentTermId: '', payment_starting_date: '', payment_due_date: '' },
    ];
  },
  removeTerm: (state, index) => {
    state.terms.splice(index, 1);
    state.errors.splice(index, 1);
  },
};

const actions = {
  smartSet({ commit }, { stateKey, data }) {
    commit('SMART_SET', {
      stateKey,
      data,
    });
  },
  setLoader({ commit }, loading) {
    commit('setLoader', loading);
  },
  setBills({ commit }, bills) {
    commit('setBills', bills);
  },
  setAmount({ commit }, amount) {
    commit('setAmount', amount);
  },
  addBills({ commit }, bills) {
    commit('addBills', bills);
  },
  clearBills({ commit }) {
    commit('clearBills');
  },
  clearAmount({ commit }) {
    commit('clearAmount');
  },
  addTerm({ commit }, index = 0) {
    commit('addTerm', index);
  },

  clearTerms({ commit }) {
    commit('clearTerms');
  },
  removeTerm({ commit }, index = 0) {
    commit('removeTerm', index);
  },
};

const getters = {
  loading: (state) => state.loading,
  bills: (state) => state.bills,
  amount: (state) => state.amount,
  terms: (state) => state.terms,
  errors: (state) => state.errors,
  payment_type: (state) => state.payment_type,
  paymentTypeBillingId: (state) => state.paymentTypeBillingId,
};

const socket = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default socket;
