<template>
  <div
    @click="displayUserDetail(student._id)"
    class="flex space-x-1 rounded-default cursor-pointer bg-white hover:bg-grayBg py-4"
  >
    <!-- <pre>{{student}}</pre> -->
    <div class="px-2">
      <div
        class="h-12 w-12 rounded-full bg-gray-100 flex justify-center items-center"
      >
        <img
          v-if="student.profilePic"
          :src="PROFILE_PATH + student.profilePic"
          v-viewer="{ inline: false, movable: true }"
          class="w-full h-full object-cover rounded-full"
          alt=""
          crossorigin="anonymous"
        />
        <icon v-else :size="18" name="user" class="w-full h-full"></icon>
      </div>
    </div>
    <div class="flex-auto">
      <div class="flex justify-between">
        <div class="capitalize text-textDarkest">
          {{
            `${student.firstName} ${student.middleName || ''} ${
              student.lastName
            }`
          }}
        </div>
        <div
          class="text-white rounded-sm px-2 flex items-center capitalize"
          :class="{
            'bg-verified': student.status === 'verified',
            'bg-pending': student.status === 'pending',
            'bg-suspended': student.status === 'suspended',
          }"
        >
          {{ student.status }}
        </div>
      </div>
      <div class="flex space-x-2 items-center text-xs">
        <icon name="phone-alt" :size="10" />
        <div class="flex space-x-2">
          <div
            v-for="phoneNumber in student.phoneNumber"
            :key="phoneNumber"
            class="pr-2 border-r-2 border-dgray"
          >
            {{ phoneNumber || '' }}
          </div>
        </div>
        <div class="tracking-widest pr-2 border-r-2 border-dgray">
          {{ student.userId }}
        </div>
        <span class="text-xs"
          >{{ student.class ? student.class.name : ''
          }}{{ student.section ? `-${student.section.name}` : '' }}</span
        >
        <!-- <span class="text-xs"></span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from '@/config/config';
import { ago } from '@/utils';
import { mapActions } from 'vuex';
import eventBus from '@/eventBus';
import { PROFILE_PATH } from '../../../config/config';
export default {
  props: ['student'],
  data() {
    return {
      BASE_URL,
      PROFILE_PATH,
    };
  },
  filters: { ago },
  methods: {
    ...mapActions('main', ['setData']),
    displayUserDetail(id) {
      let componentProps = {
        id,
        type: 'student',
        url: 'student/studentProfile/',
      };
      eventBus.$emit('open-modal', {
        modalId: 39,
        componentProps,
      });
    },
  },
};
</script>

<style></style>
