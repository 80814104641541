<template>
  <!-- session -->
  <div class="flex space-x-2 py-5 bg-white px-2 pb-10" style="min-height: 70vh">
    <div
      v-for="(weekDay, i) in WORKING_DAYS"
      :key="i"
      class="w-full"
      style="min-height: 100%"
    >
      <div class="w-full">
        <p class="font-semibold text-sm text-textDark text-center">
          {{ weekDay.label }}
        </p>
      </div>
      <div
        class="mt-3 w-full space-y-2 bg-gray-100 px-2 py-2 rounded-default h-full"
      >
        <session-card
          v-for="(session, k) in weekSessions[i + 1]"
          :sequence="k + 1"
          :key="k"
          @delete="handleDeleteSession($event)"
          @edit="handleUpdateSession($event)"
          @toggle="toggled($event)"
          :session="session"
          :mode="mode"
          :selectedSessions="selectedSessions"
        />
        <div
          class="p-2 mx-auto mt-3 cursor-pointer bg-white rounded-full shadow-lg"
          style="max-width: fit-content"
          @click="handleCreateSession(weekDay.value)"
          v-if="mode === 'management' && authCodes.includes('ses-add')"
        >
          <icon name="plus" class="text-textMedium" />
        </div>
      </div>
    </div>
  </div>
  <!-- session end -->
</template>

<script>
import SessionCard from './SessionCard.vue';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../../eventBus';
// import { static_roles } from '@/config/constants';

export default {
  props: {
    school: String,
    WORKING_DAYS: Array,
    mode: {
      type: String,
      default: 'management', // selection
    },
    selectedSessions: {
      required: false,
    },
  },
  components: {
    SessionCard,
  },
  data() {
    return {
      module: 'schedule',
      permission: {},
      sessionList: [],

      //   selectedSessions: []
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    ...mapGetters(['authCodes']),
    mondaySessions() {
      return this.sessionList.filter((session) => session.day === 'monday');
    },
    tuesdaySessions() {
      return this.sessionList.filter((session) => session.day === 'tuesday');
    },
    wednesdaySessions() {
      return this.sessionList.filter((session) => session.day === 'wednesday');
    },
    thursdaySessions() {
      return this.sessionList.filter((session) => session.day === 'thursday');
    },
    fridaySessions() {
      return this.sessionList.filter((session) => session.day === 'friday');
    },
    saturdaySessions() {
      return this.sessionList.filter((session) => session.day === 'saturday');
    },
    sundaySessions() {
      return this.sessionList.filter((session) => session.day === 'sunday');
    },
    weekSessions() {
      return {
        1: this.mondaySessions,
        2: this.tuesdaySessions,
        3: this.wednesdaySessions,
        4: this.thursdaySessions,
        5: this.fridaySessions,
        6: this.saturdaySessions,
        7: this.sundaySessions,
      };
    },
  },
  watch: {
    school(n) {
      this.fetchSession(n);
    },
  },
  methods: {
    ...mapActions('main', ['request']),

    toggled(sessionId) {
      //   if (this.selectedSessions.includes(sessionId))
      //     return this.selectedSessions.splice(
      //       this.selectedSessions.indexOf(sessionId),
      //       1
      //     );
      //   this.selectedSessions.push(sessionId);
      //   this.selectedSessions = [...this.selectedSessions];
      this.$emit('selected', sessionId);
    },

    async handleDeleteSession(id) {
      let response = await this.request({
        method: 'delete',
        url: 'school/session/' + id,
      });
      if (response && !response.error) {
        this.fetchSession();
        eventBus.$emit('open-toast', {
          message: 'session deleted successfully.',
        });
      }
    },

    handleCreateSession(day) {
      let componentProps = {
        day,
      };
      eventBus.$emit('open-modal', {
        modalId: 23,
        cb: this.fetchSession,
        componentProps,
      });
    },

    handleUpdateSession(session) {
      let componentProps = {
        session,
      };

      eventBus.$emit('open-modal', {
        modalId: 23,
        cb: this.fetchSession,
        componentProps,
      });
    },

    async fetchSession() {
      let response = await this.request({
        method: 'get',
        url: 'school/session/me',
      });
      if (response && !response.error) this.sessionList = response.record;
      else this.sessionList = [];
    },
  },

  async created() {
    await this.fetchSession();
    const working = localStorage.getItem('selectedWorkingDays');
    this.weekDays = working.split(',');
  },
};
</script>

<style></style>
