<template>
    <div class="flex justify-center">
        <div
          class="border-1 border-gray-100 rounded-default p-12 shadow-lg w-1/3 flex flex-col gap-6"
        >
          <div class="text-center text-xl font-semibold text-textDark">
            CHANGE PASSWORD
          </div>

          <base-input
            label="Old Password"
            placeholder="old password"
            v-model="passwordForm.oldPassword"
            :errors="errors.oldPassword"
            :type="inputType"
            check
          />

          <base-input
            label="New Password"
            placeholder="new password"
            v-model="passwordForm.newPassword"
            :errors="errors.newPassword"
            :type="inputType"
            check
          />

          <base-input
            label="Confirm Password"
            placeholder="confirm password"
            v-model="passwordForm.confirmPassword"
            :errors="errors.confirmPassword"
            :type="inputType"
            check
          />

          <base-check-box
            label="Show Passwords"
            :selected="selected"
            @toggle="showPassword"
          />

          <div class="flex justify-end">
            <base-button
              label="Update"
              @click="updatePassword"
            />
          </div>
        </div>
      </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { required, minLength, sameAs } from 'vuelidate/lib/validators';
    import { validation } from '@/mixins/validationMixin.js';
    import eventBus from '@/eventBus.js';
    export default {
      mixins: [validation],
      data() {
        return {
          working: false,
          passwordForm: {
            newPassword: '',
            oldPassword: '',
            confirmPassword: '',
          },
          selected: false,
          inputType: 'password',
    
          errors: {},
          profileForm: {},
          meta: [
            { key: 'passwordForm.newPassword', label: 'New Password' },
            { key: 'passwordForm.oldPassword', label: 'Old Password' },
            { key: 'passwordForm.confirmPassword', label: 'Confirm Password' },
          ],
          initial: true,
        };
      },
      computed: {
        ...mapGetters('main', ['berror']),
      },
      methods: {
        ...mapActions('main', ['request', 'upload']),
    
        showPassword() {
          if (!this.selected) {
            this.selected = true;
            this.inputType = 'text';
          } else {
            this.selected = false;
            this.inputType = 'password';
          }
        },
    
        async updatePassword() {
          this.working = true;
          this.initial = false;
          if (!this.isValid()) return;
          let response = await this.request({
            method: 'put',
            url: 'school/official/change-password',
            data: {
              newPassword: this.passwordForm.newPassword,
              oldPassword: this.passwordForm.oldPassword,
            },
          });
    
          this.working = false;
          if (response && !response.error) {
            return eventBus.$emit('open-toast', {
              message: 'password updated successfully',
            });
          } else {
            eventBus.$emit('open-toast', { 
                message: this.berror,
                error: true
            });
          }
        },
      },
    
      validations() {
        return {
          passwordForm: {
            ...Object.keys(this.passwordForm).reduce((acc, nv) => {
              if (['oldPassword'].includes(nv)) {
                acc[nv] = { required };
              } else if (['newPassword'].includes(nv)) {
                acc[nv] = { required, minLength: minLength(8) };
              } else if (['confirmPassword'].includes(nv)) {
                acc[nv] = { required, sameAsPassword: sameAs('newPassword') };
              }
    
              return acc;
            }, {}),
          },
        };
      },
    
      created() {
        this.meta.forEach((key) => {
          this.$watch(key.key, () => {
            if (!this.initial) {
              this.isValid();
            }
          });
        });
      },
    };
    </script>
    