<template>
  <div class="p-8">
    <!-- <transition mode="in-out" appear="true"> -->

    <div class="flex justify-end">
      <div class="w-40">
        <base-select
          :items="criteria"
          v-model="selectedCriterion"
          notBordered
          padding="small"
        >
          <span class="text-textLight">Filter by: </span>
          <span class="capitalize">{{ selectedCriterion }}</span>
        </base-select>
      </div>
    </div>

    <div class="grid grid-cols-3 gap-8">
      <template>
        <base-input
          v-if="!isReg"
          type="number"
          label="Amount"
          placeholder="amount"
          v-model="Form.amount"
          :errors="errors.amount"
          bordered
        />
        <div>
          <base-multi-select
            v-if="selectedCriterion === 'group'"
            label="Group"
            placeholder="Group"
            v-model="Form.group"
            :errors="errors.group"
            :items="group_options"
            searchable
          />
          <base-multi-select
            v-if="selectedCriterion === 'grade'"
            label="Grade"
            placeholder="Grade"
            v-model="Form.class"
            :errors="errors.class"
            :items="class_options"
            searchable
          />
          <base-multi-select
            v-if="selectedCriterion === 'section'"
            label="Section"
            placeholder="Section"
            v-model="Form.section"
            :errors="errors.section"
            :items="SECTION_OPTIONS"
            searchable
          />
          <base-check-box
            v-if="selectedCriterion"
            class="flex p-2"
            :label="checkboxLabel"
            :selected="forAll"
            @toggle="allEntities"
          />
        </div>
      </template>
    </div>

    <div class="flex p-2 items-center justify-between">
      <!-- <base-check-box
        :label="checkboxLabel"
        :selected="forAll"
        @toggle="allEntities"
      /> -->

      <!-- <switch-button label="For all students" v-model="Form.forAll" /> -->
      <div class="flex capitalize gap-3">
        <template>
          <base-button
            class="bg-gray-100 flex-shrink-0"
            icon="cog"
            label="generate bills"
            @click="regenerateBills"
            :working="loading"
          />
        </template>
      </div>
      <!-- @click="toggleExpand" -->
    </div>
    <!-- </transition> -->
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseMultiSelect from '../../base/BaseMultiSelect.vue';
// import SwitchButton from '../../base/SwitchButton.vue';
export default {
  components: { BaseMultiSelect },
  name: 'GenerateBillForm',
  props: {
    isReg: { type: Boolean },
  },
  data() {
    return {
      Form: {
        school: this.school,
        amount: undefined,
        group: [],
        class: [],
        section: [],
      },
      selectedCriterion: '',
      forAll: false,
      expanded: true,
      group_options: [],
      class_options: [],
      section_options: [],
      payment_type_options: [],
      errors: {},
    };
  },
  computed: {
    ...mapState('main', ['error']),
    ...mapGetters('fee', ['bills', 'terms', 'payment_type', 'loading']),
    criteria() {
      if (this.isReg) {
        return [
          { label: 'Group', value: 'group' },
          { label: 'Grade', value: 'grade' },
          { label: 'Section', value: 'section' },
        ];
      } else {
        return [
          { label: 'Grade', value: 'grade' },
          { label: 'Section', value: 'section' },
        ];
      }
    },

    // selectedCriterion() {
    //   if (this.isReg) return 'group';
    //   else return 'grade';
    // },

    selectedPaymentType() {
      if (this.payment_type) {
        return this.payment_type_options.find(
          ({ value }) => value == this.payment_type
        );
      } else {
        return '';
      }
    },
    checkboxLabel() {
      return `Select all ${this.selectedCriterion}s`;
    },

    SECTION_OPTIONS() {
      return this.ay_sections.map((section) => ({
        label: section.class.name + ' - ' + section.name,
        value: section._id,
      }));
    },
  },
  watch: {
    payment_type() {
      this.getPaymentGroups();
    },
    'Form.amount'() {
      const amount = this.Form.amount || 1;
      this.setAmount(amount);
      this.setBills(
        this.bills.map((bill) => {
          bill.amount = this.Form.amount;

          let discount_amount =
            bill.discount_type == 'rate'
              ? bill.amount * Number((bill.discount / 100).toFixed(2))
              : bill.discount;

          let total_bill = Number(
            (
              bill.amount * Number((bill.payer_percent / 100).toFixed(2)) -
              Number(discount_amount.toFixed(2))
            ).toFixed(2)
          );
          bill.total_bill = total_bill > 0 ? total_bill : 0;
          return bill;
        })
      );
    },
    // 'Form.class': {
    //   deep: true,
    //   immediate: false,
    //   handler: function (val, oldVal) {
    //     const { valueAdded, difference } = this.compareArrays(val, oldVal);
    //     if (valueAdded) {
    //       this.regenerateBills(val);
    //     }
    //   },
    // },
    // 'Form.group': {
    //   deep: true,
    //   immediate: false,
    //   handler: function (val, oldVal) {
    //     const { valueAdded, difference } = this.compareArrays(val, oldVal);
    //     if (valueAdded) {
    //       this.regenerateBills(difference);
    //     }
    //     this.regenerateBills(difference);
    //   },
    // },
    // 'Form.section': {
    //   deep: true,
    //   immediate: false,
    //   handler: function (val, oldVal) {
    //     const { valueAdded, difference } = this.compareArrays(val, oldVal);
    //     if (valueAdded) {
    //       this.regenerateBills(difference);
    //     }
    //     this.regenerateBills(val);
    //   },
    // },
    'Form.group'() {
      this.clearBills();
    },
    'Form.class'() {
      this.clearBills();
    },
    'Form.section'() {
      this.clearBills();
    },
    selectedCriterion() {
      this.forAll = false;
      this.allEntities();
      // this.Form.group = [];
      // this.Form.class = [];
      // this.Form.section = [];
    },
    isReg() {
      if (this.isReg) {
        this.selectedCriterion = 'group';
      } else {
        this.selectedCriterion = 'grade';
      }
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    ...mapActions('fee', [
      'smartSet',
      'clearBills',
      'setBills',
      'setAmount',
      'addBills',
      'setLoader',
    ]),
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    async handleSave() {
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
    },

    populateData() {
      Object.keys(this.Form).forEach((key) => {
        this.Form[key] = this.oldForm[key];
      });
    },
    async handleGenerateBill() {
      let response = await this.request({
        method: 'post',
        url: 'school/fee/',
        data: {
          terms: this.terms,
          bills: this.bills,
          payment_type: this.payment_type,
        },
      });
      if (response && !response.error) {
        console.log();
      } else {
        eventBus.$emit('open-toast', {
          message: response.message,
          error: true,
        });
      }
    },
    async getPaymentGroups() {
      const response = await this.request({
        method: 'get',
        url:
          'school/paymentAmount/active-groups/' +
          (this.payment_type ? this.payment_type : ''),
      });
      if (response) {
        this.group_options = response.record.map(
          ({ _id: value, name: label }) => ({ label, value })
        );
        this.forAll = false;
        this.allEntities();
      } else {
        this.group_options = [];
      }
    },
    async getClasses() {
      const response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response) {
        this.class_options = response.record.map(
          ({ _id: value, name: label, ...rest }) => ({ label, value, rest })
        );
      } else {
        this.class_options = [];
      }
    },
    async fetchAcademicSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/me',
      });
      if (response && !response.error) {
        this.ay_sections = response.record;
      } else {
        this.ay_sections = [];
      }
    },
    async getPaymentTypes() {
      const response = await this.request({
        method: 'get',
        url: 'school/paymentType',
      });
      if (response) {
        this.payment_type_options = response.record.map(
          ({ _id: value, name: label, ...rest }) => ({ label, value, rest })
        );
      } else {
        this.payment_type_options = [];
      }
    },
    async regenerateBills(query) {
      this.generateBills(this.setBills, query);
    },
    async addGeneratedBills(query) {
      this.generateBills(this.addBills, query);
    },
    async generateBills(generate, query) {
      let students = await this.fetchStudents(query);

      generate(
        students.map((student) => {
          // student.amount = this.Form.amount || 0;
          return student;
        })
      );
    },

    allEntities() {
      if (!this.forAll) {
        if (this.selectedCriterion == 'group') {
          this.Form.class = [];
          this.Form.section = [];
          this.Form.group = this.group_options.map(({ value }) => value);
        } else if (this.selectedCriterion == 'grade') {
          this.Form.group = [];
          this.Form.section = [];
          this.Form.class = this.class_options.map(({ value }) => value);
        } else if (this.selectedCriterion == 'section') {
          this.Form.class = [];
          this.Form.group = [];
          this.Form.section = this.SECTION_OPTIONS.map(({ value }) => value);
        }
        this.forAll = true;
      } else {
        this.Form.class = [];
        this.Form.group = [];
        this.Form.section = [];
        this.forAll = false;
      }
    },

    async fetchStudents() {
      this.setLoader(true);
      let data = {
        payment_type: this.payment_type,
        amount: Number(this.Form.amount) || 0,
      };
      if (this.Form.group.length > 0) {
        data.filter_by = 'group';
        data.group = this.Form.group;
      } else if (this.Form.class.length > 0) {
        data.filter_by = 'class';
        data.class = this.Form.class;
      } else if (this.Form.section.length > 0) {
        data.filter_by = 'section';
        data.section = this.Form.section;
      }
      this.clearBills();
      if (data.filter_by) {
        let response = await this.request({
          method: 'post',
          url: 'school/fee/generate',
          data,
        });
        if (response && !response.error) {
          let students = response.record;
          console.log('response: ', response);

          if (response.warning)
            eventBus.$emit('open-toast', {
              message: response.message,
              warning: true,
            });
          if (students.length > 0)
            this.setBills(
              students.map((student) => {
                student.amount = student.amount || this.Form.amount || 0;
                student.total_bill =
                  student.total_bill || this.Form.amount || 0;
                return student;
              })
            );
        } else {
          eventBus.$emit('open-toast', {
            message: this.error,
            error: true,
          });
        }
      }

      this.setLoader(false);
    },
    compareArrays(value, oldValue) {
      let _valueAdded;
      let difference = [];
      if (value.length > oldValue.length) {
        _valueAdded = true;
        difference = this.getValue(oldValue, value);
      } else {
        _valueAdded = false;
        difference = this.getValue(value, oldValue);
      }
      return { valueAdded: _valueAdded, difference };
    },
    getValue(first = [], second = []) {
      return second.filter((value) => first.indexOf(value) == -1);
    },
  },
  async created() {
    await this.getPaymentTypes();
    await this.getClasses();
    await this.fetchAcademicSections();
    if (this.isUpdate) this.populateData();
  },
};
</script>

<style></style>
