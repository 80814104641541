<template>
  <div>
    <base-title :title="(update ? 'Update' : 'Create') + ' Guardian'">
    </base-title>
    <div class="bg-white p-3 grid grid-cols-3 gap-2">
      <div
        class="border-1 rounded-default text-center border-gray-100 shadow-sm"
      >
        <span class="font-bold">UPLOAD GUARDIAN'S PHOTO</span>

        <div
          class="w-32 h-36 my-1 cursor-pointer mx-auto"
          @click="handleOpenDialog('profile-input')"
        >
          <img
            :src="
              profilePicObj ? profilePicObj : PROFILE_PATH + Form.profilePic
            "
            alt=""
            class="object-cover w-full h-full"
          />

          <div class="mt-4">
            <input
              class=""
              style="display: none"
              ref="profile-input"
              @change="handleSelectFile('profile-input', 'Form.profilePic')"
              type="file"
              name="file-input"
            />
            <div
              class="bg-primary text-white p-2 w-full rounded-default cursor-pointer text-center mt-4 w-full"
            >
              Upload Photo
            </div>
          </div>
        </div>
      </div>
      <div
        class="border-1 p-4 rounded-default border-gray-100 shadow-sm col-span-2"
      >
        <span class="font-bold">PERSONAL</span>
        <div class="grid grid-cols-3 gap-2 my-5">
          <base-input
            required
            label="Name"
            placeholder="name"
            v-model="Form.firstName"
            :errors="errors.firstName"
            capitalize
            check
          />
          <base-input
            required
            label="Father's Name"
            placeholder="father's name"
            v-model="Form.middleName"
            :errors="errors.middleName"
            capitalize
            check
          />
          <base-input
            label="Grandfather's Name"
            placeholder="grandfather's name"
            v-model="Form.lastName"
            :errors="errors.lastName"
            capitalize
          />
          <base-select
            required
            label="Gender"
            placeholder="gender"
            v-model="Form.gender"
            :errors="errors.gender"
            :items="GENDER_OPTIONS"
            capitalize
            check
          />
          <base-input
            v-if="isGreg"
            label="Birth Date"
            v-model="Form.birthdate"
            :errors="errors.birthdate"
            type="date"
            :check="check"
          />

          <base-eth-calendar
            v-if="!isGreg"
            label="Birth Date"
            v-model="Form.birthdate"
            :errors="errors.birthdate"
            :isUpdate="update"
          />
        </div>
      </div>
      <div
        class="border-1 p-3 rounded-default border-gray-100 shadow-sm col-span-2"
      >
        <span class="font-bold">ADDRESS</span>
        <div class="grid grid-cols-3 gap-2 my-5">
          <base-select
            bordered
            label="Region"
            placeholder="region"
            v-model="Form.region"
            :errors="errors.region"
            :items="REGION_OPTIONS"
            searchable
          />
          <base-input
            label="City"
            placeholder="city"
            v-model="Form.city"
            :errors="errors.city"
            capitalize
          />
          <base-input
            label="Subcity"
            placeholder="subcity"
            v-model="Form.subcity"
            :errors="errors.subcity"
            capitalize
          />
          <base-input
            label="Street Address"
            placeholder="street address"
            v-model="Form.address"
            :errors="errors.address"
            capitalize
          />
          <base-input
            label="House Number"
            placeholder="house number"
            v-model="Form.houseNumber"
            :errors="errors.houseNumber"
            capitalize
          />
        </div>
      </div>
      <div class="border-1 p-3 rounded-default border-gray-100 shadow-sm">
        <span class="font-bold">CONTACT</span>
        <div class="grid grid-cols-1 gap-2 my-5">
          <base-multitype
            required
            label="PhoneNumber"
            placeholder="phone number"
            v-model="Form.phoneNumber"
            :errors="errors.phoneNumber"
            check
          />
          <base-input
            label="Email"
            placeholder="email"
            v-model="Form.email"
            :errors="errors.email"
            check
          />
        </div>
      </div>
      <div class="flex justify-end pt-4 col-span-3">
        <base-button
          :icon="update ? 'pencil-alt' : 'plus'"
          :label="update ? 'Update' : 'Add'"
          class="px-3"
          :working="working"
          @click="update ? handleUpdate() : handleCreate()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import eventBus from '@/eventBus';
import { PROFILE_PATH } from '@/config/config.js';
import objUrl from '@/mixins/objUrl.js';
import profilePics from '@/assets/avatars/avatar04.png';
import { formatInputDate } from '@/utils';

export default {
  components: {},
  props: ['oldForm'],
  mixins: [validation, objUrl],
  data() {
    return {
      initial: true,
      working: false,
      check: false,
      PROFILE_PATH,
      steps: ['Basic'],
      Form: {
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        occupation: '',
        birthdate: '',
        region: '',
        city: '',
        subcity: '',
        address: '',
        houseNumber: '',
        phoneNumber: [],
        email: '',
        profilePic: '',
      },
      profilePicObj: profilePics,

      errors: {},
      meta: [
        { key: 'Form.firstName', label: 'Name' },
        { key: 'Form.middleName', label: 'Fathers name' },
        { key: 'Form.gender', label: 'Gender' },
        { key: 'Form.birthdate', label: 'Birth Date' },
        { key: 'Form.phoneNumber', label: 'Phone Number' },
        { key: 'Form.email', label: 'This' },
      ],
    };
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['firstName', 'middleName', 'gender'].includes(key)) {
            acc[key] = { required };
          } else if (['phoneNumber'].includes(key)) {
            acc[key] = { required };
          } else if (['email'].includes(key)) {
            acc[key] = { email };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    ...mapState('main', ['error', 'globalSchoolConfig']),
    ...mapGetters(['user']),
    isGreg() {
      return (
        this.globalSchoolConfig &&
        this.globalSchoolConfig.calendarType == 'greg'
      );
    },

    REGION_OPTIONS() {
      return [
        { label: 'Addis Ababa', value: 'Addis Ababa' },
        { label: 'Afar', value: 'Afar' },
        { label: 'Amahara', value: 'Amahara' },
        { label: 'Beninshangul', value: 'Beninshangul' },
        { label: 'Dire Dawa', value: 'Dire Dawa' },
        { label: 'Gambela', value: 'Gambela' },
        { label: 'Harar', value: 'Harar' },
        { label: 'Oromo', value: 'Oromo' },
        { label: 'Sidama', value: 'Sidama' },
        { label: 'Somali', value: 'Somali' },
        { label: 'Southern Region', value: 'Southern Region' },
        { label: 'Tigray', value: 'Tigray' },
      ];
    },
    OCCUPATIONS() {
      return [
        { label: 'Civil Servant', value: 'civil servant' },
        { label: 'Merchant', value: 'merchant' },
        { label: 'Banker', value: 'banker' },
        { label: 'Private', value: 'private' },
      ];
    },
    GENDER_OPTIONS() {
      return [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ];
    },

    update() {
      if (this.oldForm) return true;
      return false;
    },
  },
  // watch: {
  //   OCCUPATIONS() {
  //     if (this.selectedOcupation == 'other') {
  //
  //     }
  //   },
  // },
  methods: {
    ...mapActions('main', ['request', 'upload']),

    async handleCreate() {
      this.initial = false;
      this.check = true;
      if (!this.isValid()) return;

      const multidatas = ['phoneNumber', 'birthdate'];

      let formData = new FormData();
      Object.keys(this.Form).forEach((key) => {
        if (multidatas.includes(key) && this.Form[key])
          formData.append(key, JSON.stringify(this.Form[key]));
        else if (this.Form[key] != '' && this.Form[key] != undefined)
          formData.append(key, this.Form[key]);
      });

      this.working = true;
      let response = await this.upload({
        method: 'post',
        url: 'parent/parentProfile',
        data: formData,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'successfully created parent',
        });
        this.handleClose(response.record);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }

      this.working = false;
      this.initial = true;
    },

    populateForm() {
      const data = this.oldForm;
      this.Form = {
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        gender: data.gender,
        birthdate: this.isGreg
          ? formatInputDate(data.birthdate)
          : data.birthdate,
        region: data.region,
        city: data.city,
        subcity: data.subcity,
        address: data.address,
        houseNumber: data.houseNumber,
        phoneNumber: data.phoneNumber,
        occupation: data.occupation,
        email: data.email,
        profilePic: data.profilePic,
        school: data.school,
      };
    },

    async handleUpdate() {
      this.initial = false;

      if (!this.isValid()) return;

      const multidatas = ['phoneNumber'];

      let formData = new FormData();
      Object.keys(this.Form).forEach((key) => {
        if (multidatas.includes(key) && this.Form[key])
          formData.append(key, JSON.stringify(this.Form[key]));
        else if (this.Form[key] != '' && this.Form[key] != undefined)
          formData.append(key, this.Form[key]);
      });

      this.working = true;

      let response = await this.upload({
        method: 'put',
        url: 'parent/parentProfile/' + this.oldForm._id,
        data: formData,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'successfully updated official',
        });
        this.handleClose(response.record);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
      this.initial = true;
    },
    handleClose(response) {
      this.$emit('close', {
        invokeCb: true,
        response,
      });
    },

    async populateDefaultForms() {
      let response = await this.request({
        method: 'get',
        url: 'school/schoolProfile/my-school',
      });
      if (response && !response.error) {
        const school = response.record;
        this.Form['region'] = school['region'];
        this.Form['city'] = school['city'];
      }
    },
  },

  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    if (this.oldForm) await this.populateForm();
    else await this.populateDefaultForms();
  },
};
</script>

<style></style>
