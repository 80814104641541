<template>
  <div class="">
    <div class="pt-4 px-7 bg-white flex justify-between">
      <p class="font-semibold">Session Management</p>
      <div class="flex gap-2">
        <base-button
          label="Working Days"
          icon="calendar"
          :primary="false"
          class="px-2 bg-gray-100 flex-shrink-0"
          code="ses-wdy"
          @click="handleWorkingDays"
        />
        <base-button
          label="Generate"
          icon="cog"
          class="px-2 rounded-default"
          
          @click="handleGenerate"
          code="ses-gen"
        />
      </div>
    </div>
    <div class="px-4 bg-white">
      <div class="w-1/4">
        <base-select
          v-if="!isSchoolOfficial"
          :items="SCHOOL_OPTIONS"
          v-model="selectedSchoolId"
          :searchable="true"
        />
      </div>
    </div>

    <week-session-view
      :school="selectedSchoolId"
      :WORKING_DAYS="WORKING_DAYS"
      v-if="(selectedSchoolId || isSchoolOfficial) && authCodes.includes('ses-view')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WeekSessionView from './components/WeekSessionView.vue';
import eventBus from '@/eventBus.js';
import { static_roles } from '@/config/constants';

export default {
  data() {
    return {
      module: 'schedule',
      permission: {},
      isSchoolOfficial: false,
      selectedSchoolId: '',
      school_list: [],
      working_days: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false,
      },
    };
  },
  components: {
    WeekSessionView,
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
    WORKING_DAYS() {
      return Object.keys(this.working_days)
        .map((day) => {
          if (this.working_days[day]) {
            return {
              label: day.toUpperCase(),
              value: day,
            };
          }
        })
        .filter(Boolean);
    },
    SCHOOL_OPTIONS() {
      return this.school_list.map((school) => ({
        label: school.name,
        value: school._id,
      }));
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    handleWorkingDays() {
      eventBus.$emit('open-modal', {
        modalId: 38,
        cb: this.fetchWorkingDays,
      });
    },

    handleGenerate() {
      let componentProps = {
        school_id: this.selectedSchoolId,
        isSchoolOfficial: this.isSchoolOfficial,
      };
      eventBus.$emit('open-modal', { modalId: 26, componentProps });
    },
    async fetchSchools() {
      if (!this.isSchoolOfficial) {
        let response = await this.request({
          method: 'get',
          url: 'school/schoolProfile',
        });
        if (response && !response.error) {
          this.school_list = response.record;
          this.selectedSchoolId = response.record[0]._id;
        } else this.school_list = [];
      }
    },
    async fetchWorkingDays() {
      let response = await this.request({
        method: 'get',
        url: 'school/config/',
      });

      if (response && !response.error) {
        if (response.record.working_days)
          this.working_days = response.record.working_days;
      } else {
        eventBus.$emit('open-toast', {
          message: response.message,
          error: true,
        });
      }
    },
    checkStatic() {
      const role = this.user.role;
      if (!static_roles.includes(role)) {
        this.isSchoolOfficial = true;
        const permissions = this.user.permissions;
        if (permissions) {
          let permission = permissions.find(
            (p) => p.module.name === this.module
          );
          this.permission = permission;
        }
      }
    },
  },
  created() {
    this.checkStatic();
    if (!this.isSchoolOfficial) this.fetchSchools();
    this.fetchWorkingDays();
  },
};
</script>

<style></style>
