<template>
  <div>
    <div>
      <d-table
        #default="{ item }"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :hasActions="true"
        :handlers="handlers1"
        :buttons="TBUTTONS"
        @enroll="handleSelectStudent"
        @masstransfer="handleMassTransfer"
        :loading="loading"
        :searchProps="searchProps"
      >
        <td v-for="(header, i) in headers" :key="i">
          <div v-if="item.student" class="px-2">
            <div v-if="header.key === 'profilePic'">
              <div class="flex space-x-5 items-center">
                <div
                  class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center"
                >
                  <img
                    v-if="item.student.profilePic"
                    :src="PROFILE_PATH + item.student.profilePic"
                    class="w-full h-full object-cover rounded-full"
                    v-viewer="{ inline: false, movable: true }"
                    alt=""
                    crossorigin="anonymous"
                  />
                  <icon
                    v-else
                    :size="18"
                    name="user"
                    class="w-full h-full"
                  ></icon>
                </div>
                <div @click="displayUserDetail(item.student.id)">
                  <p
                    class="text-sm font-semibold uppercase text-textDark hover:underline cursor-pointer"
                  >
                    {{
                      `${item.student.firstName} ${item.student.middleName} ${item.student.lastName}`
                    }}
                  </p>
                  <p class="text-textLight">{{ item.student.email }}</p>
                </div>
              </div>
            </div>
            <div
              v-else-if="
                header.key === 'student.phoneNumber' &&
                item.student.phoneNumber.length
              "
            >
              {{ item.student['phoneNumber'].join(' | ') }}
            </div>

            <div v-else-if="header.key === 'status'">
              <!-- {{ item['status'] }} -->
              <status-chip
                :label="getProp(item, 'student.status')"
                style="max-width: fit-content"
              />
            </div>
            <div v-else>
              {{ getProp(item, header.key) }}
            </div>
          </div>
        </td>
      </d-table>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus';
import { PROFILE_PATH } from '@/config/config.js';
export default {
  components: { DTable },
  props: ['ay_id', 'grade'],
  data() {
    return {
      items: [],
      totalItems: '',
      PROFILE_PATH,
      loading: true,
      Form: {},
      headers: [
        { label: 'User', key: 'profilePic' },
        { label: 'User Id', key: 'student.userId' },
        { label: 'Username', key: 'student.username' },
        { label: 'Age', key: 'student.age' },
        { label: 'Gender', key: 'student.gender' },
        { label: 'Phone Number', key: 'student.phoneNumber' },
        { label: 'Status', key: 'status' },
        // { label: '', key: '' },
        // { label: 'Label Five', key: '' },
      ],
      TBUTTONS: [
        {
          label: 'Enroll Student',
          value: 'enroll',
          icon: 'plus',
        },
        {
          label: 'Mass Transfer',
          value: 'masstransfer',
          icon: 'exchange',
        },
      ],
      handlers1: [
        {
          label: 'Transfer Section',
          icon: 'exchange-alt',
          handler: this.handleSectionTransfer,
          idParam: true,
          filter: () => this.authCodes.includes('enrst-rm'),
        },
        {
          label: 'Remove',
          icon: 'times',
          handler: this.handleRemove,
          idParam: true,
          filter: () => this.authCodes.includes('enrst-rm'),
        },
      ],
      searchProps: {
        searchUrl: 'school/studentSection/table-search/',
        searchId: this.ay_id,
      },
    };
  },

  computed: {
    ...mapGetters(['authCodes']),
    handlers() {
      return this.handlers1.filter(
        (v) => !v.code && v.code && this.authCodes.includes(v.code)
      );
    },
  },

  watch: {
    ay_id: {
      deep: true,
      immediate: true,
      handler: function () {
        this.fetchItems();
      },
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,
    displayUserDetail(id) {
      let componentProps = {
        id,
        type: 'student',
        url: 'student/studentProfile/',
      };
      eventBus.$emit('open-modal', {
        modalId: 39,
        componentProps,
      });
    },
    handleSectionTransfer(id) {
      let componentProps = {
        id,
        grade: this.grade,
        academic_year: this.ay_id,
      };
      eventBus.$emit('open-modal', {
        modalId: 42,
        componentProps,
        cb: this.fetchItems,
      });
    },

    async fetchItems() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'school/studentSection/ay/' + this.ay_id,
      });
      if (response && !response.error) {
        this.items = response.record;
        this.totalItems = response.total;
      } else {
        this.items = [];
      }
      this.loading = false;
    },
    handleDetail(id) {
      /// TODO: GET STUDENT DETAIL
      this.$router.push({ name: 'student-detail', params: { id } });
    },

    handleRemove(id) {
      let componentProps = {
        message:
          'Are you sure you want to unassign this student from this section?',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedRemove,
        cbParams: { id },
      });
    },
    async proceedRemove({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/studentSection/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Student Removed Successfully',
        });
        this.fetchItems();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    handleSelectStudent() {
      console.log('grade: ', this.grade);
      let componentProps = {
        usertype: 'Enroll Students',
        grade: this.grade,
        multiple: true,
      };
      eventBus.$emit('open-modal', {
        modalId: 37,
        componentProps,
        cb: this.handleEnrollStudent,
      });
    },
    handleMassTransfer() {
      let componentProps = {
        ay_id: this.ay_id,
      };
      eventBus.$emit('open-modal', {
        modalId: 47,
        componentProps,
        cb: this.fetchItems,
      });
    },

    onStudentSelected({ response: { customer: student } }) {
      /// TODO: link the student to the section

      this.handleEnrollStudent(student);
    },

    async handleEnrollStudent({ response: { customer: students } }) {
      let stu_ids = students.map((student) => student._id);
      let response = await this.request({
        method: 'post',
        url: 'school/studentSection/',
        data: {
          academic_year: this.ay_id,
          students: stu_ids,
        },
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'student enrolled successfylly',
        });

        this.fetchItems();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },

  created() {
    // this.fetchItems();
  },
};
</script>
<style></style>
