<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title> {{ isUpdate ? 'Update' : 'Add New' }} Role </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-1 gap-5">
          <base-input
            label="Role *"
            placeholder="role name"
            v-model="Form.name"
            :errors="errors.name"
            type="text"
            check
          />
          <base-text-area
            rows="3"
            label="Description"
            placeholder="description"
            v-model="Form.description"
            :errors="errors.description"
            type="text"
          />

          <switch-button label="Is Active" v-model="Form.status" />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions, mapGetters } from 'vuex';
import SwitchButton from '../base/SwitchButton.vue';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm', 'school_id'],
  mixins: [validation],
  data() {
    return {
      Form: {
        school: this.school_id,
        name: '', //name of the role
        description: '',
        status: '', // active, closed, not_started
      },
      errors: {},
      meta: [{ key: 'Form.name', label: 'Role' }],
      working: false,
      initial: true,
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
      this.initial = true;
    },
    populateData() {
      this.Form.description = this.oldForm.description;
      this.Form.name = this.oldForm.name;

      this.Form['status'] = this.oldForm['status'] === 'active' ? true : false;
    },
    async handleCreate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      let response = await this.request({
        method: 'post',
        url: 'role',
        data: newForm,
      });
      if (response && !response.error) {
        //   this.oldForm = response.oldForm;
        eventBus.$emit('open-toast', {
          message: 'Role Added Successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true,
        });
      }
      this.working = false;
    },
    async handleUpdate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      let response = await this.request({
        method: 'put',
        url: 'role/' + this.oldForm._id,
        data: newForm,
      });
      if (response && !response.error) {
        //   this.oldForm = response.oldForm;
        eventBus.$emit('open-toast', {
          message: 'Role updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true,
        });
      }
      this.working = false;
    },
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    ...mapGetters('main', ['berror']),
    isUpdate() {
      if (this.oldForm) return true;
      else return false;
    },
  },
  created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    if (this.isUpdate) this.populateData();
  },
};
</script>

<style></style>
