<template>
  <div class="bg-white space-y-1 p-3">
    <div class="">
      <div class="grid grid-cols-3 gap-3 text-left">
        <div class="text-textDarkest tracking-wide capitalize">
          payment term<span class="text-error"> *</span>
        </div>
        <div class="text-textDarkest tracking-wide capitalize flex-shrink-0">
          payment starting date<span class="text-error"> *</span>
        </div>
        <div class="text-textDarkest tracking-wide capitalize">
          due date<span class="text-error"> *</span>
        </div>
      </div>
      <div
        v-for="(bill, index) in terms"
        :key="index"
        class="w-full grid grid-cols-10 gap-3 mt-2 text-left"
      >
        <base-select
          placeholder="select term"
          v-model="terms[index].paymentTermId"
          :items="paymentTermOptions"
          :errors="errors[index]?.paymentTermId"
          class="col-span-3"
          required
          check
        />
        <base-input
          type="date"
          placeholder=""
          v-model="terms[index].payment_starting_date"
          class="col-span-3"
          :errors="errors[index]?.payment_starting_date"
          required
          check
        />
        <base-input
          type="date"
          placeholder=""
          v-model="terms[index].payment_due_date"
          class="col-span-3"
          :errors="errors[index]?.payment_due_date"
          required
          check
        />
        <!-- <base-eth-calendar
          v-if="!isGreg"
          v-model="terms[index].payment_starting_date"
          :errors="errors[index].payment_starting_date"
          class="col-span-3"
          required
          check
        />

        <base-eth-calendar
          v-if="!isGreg"
          v-model="terms[index].payment_due_date"
          :errors="errors[index].payment_due_date"
          class="col-span-3"
          required
          check
        /> -->

        <div class="flex gap-3 items-center">
          <base-button
            :primary="false"
            icon="plus"
            class="text-primary bg-gray-100 p-1 hover:bg-dgray"
            @click="_addTerm(index)"
          />
          <base-button
            v-if="terms.length > 1"
            :primary="false"
            icon="trash-alt"
            class="text-error bg-gray-100 p-1 hover:bg-error hover:text-white"
            @click="removeTerm(index)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import { validateArray } from '../../../utils';
import { mapActions, mapGetters } from 'vuex';
const _META_INFO = [
  { key: `paymentTermId`, label: 'Payment term' },
  { key: `payment_starting_date`, label: 'Starting date' },
  { key: `payment_due_date`, label: 'Due date' },
];
export default {
  mixins: [validation],
  components: {},
  name: 'BillInformationForm',
  data() {
    return {
      errors: [{}],
      meta: [_META_INFO],
      paymentTermOptions: [],
      expanded: false,
      check: false,
    };
  },
  computed: {
    ...mapGetters('fee', ['terms']),
    // ...mapState('main', ['globalSchoolConfig']),

    // meta() {
    //   return this.terms.map(() => )
    // }
    // isGreg() {
    //   return (
    //     this.globalSchoolConfig &&
    //     this.globalSchoolConfig.calendarType == 'greg'
    //   );
    // },
  },

  watch: {
    terms: {
      deep: true,
      immediate: false,
      handler: function () {
        this.isValid();
      },
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    ...mapActions('fee', ['addTerm', 'removeTerm', 'clearTerms']),

    validateArray,
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    isValid() {
      let errors = this.validateArray('terms', this.meta, this.$v);
      if (errors) {
        this.errors = errors;
        return false;
      } else {
        this.errors = {};
        return true;
      }
    },
    _addTerm() {
      this.check = true;
      this.meta.push(_META_INFO);
      this.addTerm();
      this.isValid();
    },
    async getPaymentTerms() {
      this.paymentTermOptions = [];
      this.clearTerms();
      const response = await this.request({
        url: `school/fee/all-payment-terms`,
        method: 'get',
      });
      if (response) {
        this.paymentTermOptions = response.items.map(
          ({ title: label, _id: value }) => ({ label, value })
        );
      } else {
        this.paymentTermOptions = [];
      }
    },
  },
  created() {
    this.getPaymentTerms();
  },

  validations() {
    return {
      terms: {
        $each: {
          paymentTermId: {
            required,
          },
          payment_starting_date: {
            required,
          },
          payment_due_date: {
            required,
          },
        },
      },
    };
  },
};
</script>

<style></style>
