import EnrollmentTypeList from '@/views/protected/school_structure_management/enrollment_type/List.vue';
import AcademicYearList from '@/views/protected/school_structure_management/academic_year/List.vue';
import GradeList from '@/views/protected/school_structure_management/grade/List.vue';
import SectionList from '@/views/protected/school_structure_management/section/List.vue';
import SubjectList from '@/views/protected/school_structure_management/subject/List.vue';

export default [
  {
    path: 'enrollment-type-list',
    name: 'enrollment-type-list',
    component: EnrollmentTypeList,
    props: true,
  },
  {
    path: 'grade-list',
    name: 'grade-list',
    component: GradeList,
    props: true,
  },
  {
    path: 'section-list',
    name: 'section-list',
    component: SectionList,
    props: true,
  },
  {
    path: 'academic-year',
    name: 'academic-year-list',
    component: AcademicYearList,
    props: true,
  },
  {
    path: 'subject',
    name: 'subject-list',
    component: SubjectList,
    props: true,
  },
];
