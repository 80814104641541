<template>
  <div class="grid grid-cols-4 items-start h-full">
    <div
      class="p-3 space-y-3 bg-white border-r-1 border-gray-100 rounded-l h-full overflow-y-auto"
    >
      <!-- <div v-if="false" class="space-y-3">
        <div
          class="text-textMedium flex items-stretch rounded-lg border-1 border-dgray shadow-md focus-within:border-primary"
          style="border-width: 2px"
        >
          <div
            class="flex items-center justify-center px-3 bg-primary text-white"
          >
            <icon name="search" :size="12" />
          </div>
          <input
            class="flex-auto outline-none bg-gray-100 py-2 px-2 rounded-r-lg text-textMedium text-lg tracking-wider"
            :placeholder="placeholder"
            v-model="Form.keyword"
            @keyup.enter="handleSearch"
          />
        </div>
        <div v-if="Form.keyword" class="text-right text-sm text-textMedium">
          Hit ENTER to search
        </div>
      </div> -->
      <!-- <div style="height: 1px" class="bg-dgray"></div> -->
      <div class="space-y-2">
        <div class="font-semibold text-textMedium">Search Type</div>
        <div
          v-for="(searchType, index) in searchTypes1"
          :key="index"
          class="flex justify-between space-x-2 p-4"
        >
          <div class="-space-y-1">
            <div class="capitalize">{{ searchType.label }}</div>
            <div class="text-xs text-darkGray leading-tight">
              {{ searchType.description }}
            </div>
          </div>
          <switch-button
            class="flex-shrink-0"
            :value="searchType.label === Form.searchType"
            @input="toggleSearchType(searchType.label)"
          />
        </div>
      </div>

      <hr class="text-dgray" />

      <div class="space-y-2">
        <div class="font-semibold text-textMedium">Search Criteria</div>
        <div class="p-4 space-y-1">
          <!-- <base-check-select
            :items="searchCriteria[Form.searchType]"
            v-model="Form.selectedSearchCriteria"
          /> -->
          <div
            v-for="({ label, value }, index) in searchCriteria[Form.searchType]"
            :key="index"
            class="p-1"
          >
            <switch-button
              class="flex-shrink-0"
              :label="label"
              :value="Form.selectedSearchCriteria == value"
              @input="
                (input) =>
                  input ? (Form.selectedSearchCriteria = value) : null
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-span-2 p-2 bg-white border-r-1 border-gray-100 h-full overflow-y-auto"
    >
      <div class="lg:mx-16 lg:my-8 xl:my-8 sticky top-0">
        <div
          class="text-textMedium flex items-stretch rounded-full border-1 border-gray-200 focus-within:border-dgray"
        >
          <input
            class="relative flex-auto outline-none rounded-l-full pl-8 bg-gray-50 py-2 px-2 rounded-r-lg text-textMedium"
            :placeholder="placeholder"
            v-model="Form.keyword"
            @input="handleSearchss"
            v-on:keyup.enter="handleSearchss"
          />
          <div
            class="right-8 flex items-center justify-center bg-gray-200 px-6 text-textDark rounded-r-full"
          >
            <icon name="search" :size="18" class="transform rotate-90" />
          </div>
        </div>

        <div class="text-dgray text-sm flex justify-end">
          <div class="mt-2">
            Found {{ recordsFiltered.length }} Search Result{{
              recordsFiltered.length > 1 ? 's' : ''
            }}
          </div>
        </div>
      </div>

      <students
        v-if="Form.searchType === 'student'"
        :students="recordsFiltered"
        :loading="loading"
        :initial="initial"
      />
      <teachers
        v-if="Form.searchType === 'teacher'"
        :teachers="recordsFiltered"
        :loading="loading"
        :initial="initial"
      />
      <school-officials
        v-if="Form.searchType === 'school official'"
        :school_officials="recordsFiltered"
        :loading="loading"
        :initial="initial"
      />
      <parents
        v-if="Form.searchType === 'parent'"
        :parents="recordsFiltered"
        :loading="loading"
        :initial="initial"
      />
      <!-- <transactions
        v-if="Form.searchType === 'transaction'"
        :transactions="recordsFiltered"
        :loading="loading"
        :initial="initial"
      /> -->
      <pagination
        v-if="totalPages > 0"
        :currentPage="currentPage"
        :pageSize="rowsPerPage"
        :totalPages="totalPages"
        @changepage="handlePageChange"
        @changepagesize="handlePageSizeChange"
        class="pt-8 text-textLight"
      />
    </div>
    <div
      class="p-4 bg-white rounded-r border-r-1 border-gray-100 space-y-2 h-full overflow-y-auto"
    >
      <div class="font-semibold text-textMedium">Filter Result</div>

      <template v-if="Form.searchType == 'student'">
        <div class="p-4">
          <base-multi-select
            class="col-span-2"
            label="Year level"
            placeholder="please select year levels"
            v-model="Form.grades"
            :items="GRADE_OPTIONS"
            :searchable="true"
            @input="handleToggle"
          />
          <div class="col-span-2 flex">
            <base-check-box
              :selected="allGradesSelected"
              @toggle="handleToggleGrade"
              :size="20"
            />
            <div>All year level</div>
          </div>
          <br />
          <base-multi-select
            class="col-span-2"
            label="Active Sections"
            placeholder=""
            v-model="Form.sections"
            :items="SECTION_OPTIONS"
            :searchable="true"
            @input="handleToggle"
          />
          <div class="col-span-2 flex">
            <base-check-box
              :selected="allSectionsSelected"
              @toggle="handleToggleSection"
              :size="20"
            />
            <div>All Sections</div>
          </div>
        </div>
      </template>

      <hr class="text-dgray" />

      <div class="space-y-2">
        <div class="-space-y-1">
          <div class="font-semibold text-textMedium">Status</div>
          <div class="text-textMedium text-xs">Filter by status</div>
        </div>
        <div class="p-4">
          <base-check-select
            :items="statusItems[Form.searchType]"
            v-model="Form.status"
            class="capitalize"
          />
        </div>
      </div>

      <hr class="text-dgray" />

      <div class="space-y-2">
        <div class="-space-y-1">
          <div>Date</div>
          <div class="text-textMedium text-xs">Filter by registration date</div>
        </div>
        <div class="p-4">
          <base-input
            type="date"
            label="From"
            v-model="Form.createdAt.from"
            padding="small"
          />
          <base-input
            type="date"
            label="To"
            v-model="Form.createdAt.to"
            padding="small"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckSelect from '../../../components/base/BaseCheckSelect.vue';
import SwitchButton from '../../../components/base/SwitchButton.vue';
import { mapGetters, mapActions } from 'vuex';
import { isSameOrBefore, isSameOrAfter } from '@/utils';
import Students from '../../../components/card/search/Students.vue';
import Teachers from '../../../components/card/search/Teachers.vue';
import Parents from '../../../components/card/search/Parents.vue';
import SchoolOfficials from '../../../components/card/search/SchoolOfficials.vue';
// import Transactions from '../../../components/card/search/Transactions.vue';
import Pagination from '../../../components/pagination/Pagination.vue';
// import { BILLING_BASE_URL } from '../../../config/config';
export default {
  components: {
    SwitchButton,
    BaseCheckSelect,
    Students,
    Teachers,
    SchoolOfficials,
    Parents,
    // Transactions,
    Pagination,
  },
  data() {
    return {
      debounce: null,
      currentPage: 1,
      rowsPerPage: 8,
      totalPages: 0,
      records: [],
      grades: [],
      sections: [],
      Form: {
        keyword: '',
        searchType: 'student',
        selectedSearchCriteria: 'name',
        status: [
          'verified',
          'pending',
          'Approved',
          'suspended',
          'paid',
          'partial_paid',
          'partial paid',
        ],
        createdAt: {
          from: '',
          to: '',
        },
        sections: [],
        grades: [],
      },
      URLs: {
        student: 'student/search',
        transaction: 'billing/stmt/search',
      },
      searchTypes: [
        {
          label: 'student',
          description:
            'Search student by name, ID, phoneNumber, or search index.',
          code: 'ser-stu',
        },
        {
          label: 'teacher',
          description:
            'Search teacher by name, ID, phoneNumber, or search index.',
          code: 'ser-thr',
        },
        {
          label: 'school official',
          description:
            'Search teacher by name, ID, phoneNumber, or search index.',
          code: 'ser-stf',
        },
        {
          label: 'parent',
          description:
            'Search parent by name, ID, phoneNumber, or search index.',
          code: 'ser-par',
        },
      ],
      searchCriteria: {
        student: [
          { label: 'Full name', value: 'name' },
          { label: 'ID', value: 'userId' },
          { label: 'Phone number', value: 'phoneNumber' },
          { label: 'Search index', value: 'search_index' },
        ],
        teacher: [
          { label: 'Full name', value: 'name' },
          { label: 'ID', value: 'userId' },
          { label: 'Phone number', value: 'phoneNumber' },
          { label: 'Search index', value: 'search_index' },
        ],
        'school official': [
          { label: 'Full name', value: 'name' },
          { label: 'ID', value: 'userId' },
          { label: 'Phone number', value: 'phoneNumber' },
          { label: 'Search index', value: 'search_index' },
        ],
        parent: [
          { label: 'Full name', value: 'name' },
          { label: 'ID', value: 'userId' },
          { label: 'Phone number', value: 'phoneNumber' },
          { label: 'Search index', value: 'search_index' },
        ],

        // transaction: [
        //   { label: 'Transaction code', value: 'trId' },
        //   { label: 'Student Name', value: 'name' },
        //   { label: 'Student ID', value: 'cust_util_id' },
        // ],
      },
      statusItems: {
        student: ['verified', 'pending', 'suspended'].map((value) => ({
          value,
          label: value,
        })),
        teacher: ['verified', 'pending', 'suspended'].map((value) => ({
          value,
          label: value,
        })),
        'school official': ['verified', 'pending', 'suspended'].map(
          (value) => ({
            value,
            label: value,
          })
        ),
        parent: ['verified', 'pending', 'suspended'].map((value) => ({
          value,
          label: value,
        })),

        // transaction: ['Paid', 'Partial Paid'].map((value) => ({
        //   value,
        //   label: value,
        // })),
      },
      statusKey: {
        student: 'status',
        teacher: 'status',
        'school official': 'status',
        parent: 'status',
        // transaction: 'current_payment_status',
      },
      baseURLs: {
        student: 'student/studentProfile/search',
        teacher: 'school/teacherProfile/search',
        'school official': 'school/official/search',
        parent: 'parent/parentProfile/search',
        // transaction: BILLING_BASE_URL + 'billing/stmt/search',
      },
      loading: false,
      initial: true,
    };
  },
  computed: {
    ...mapGetters(['authCodes']),
    ...mapGetters('main', ['berror']),
    searchTypes1() {
      return this.searchTypes.filter((v) => this.authCodes.includes(v.code));
    },
    placeholder() {
      return 'Search ' + this.Form.searchType + '...';
    },
    // class year/year level
    GRADE_OPTIONS() {
      return this.grades.map(({ name: label, _id: value }) => ({
        label,
        value,
      }));
    },
    SECTION_OPTIONS() {
      return this.sections
        .filter(({ class: grade }) => this.Form.grades.includes(grade._id))
        .map(({ class: grade, name, _id: value }) => ({
          label: grade.name + ' - ' + name,
          value,
        }));
    },

    recordsFiltered() {
      let records = [...this.records];
      const statusKey = this.statusKey[this.Form.searchType];
      if (this.Form.status.length > 0)
        records = records.filter((record) =>
          this.Form.status.includes(record[statusKey])
        );

      if (this.Form.createdAt.from)
        records = records.filter((record) =>
          isSameOrAfter(record.createdAt, this.Form.createdAt.from)
        );

      if (this.Form.createdAt.to)
        records = records.filter((record) =>
          isSameOrBefore(record.createdAt, this.Form.createdAt.to)
        );
      return records;
    },
    url() {
      return (
        this.baseURLs[this.Form.searchType] +
        '?keyword=' +
        this.Form.keyword +
        '&search_key=' +
        [this.Form.selectedSearchCriteria] +
        '&page=' +
        this.currentPage +
        '&limit=' +
        this.rowsPerPage +
        (this.Form.searchType == 'student' ? this.studentGradeSectionQuery : '')
      );
    },
    studentGradeSectionQuery() {
      let query = '';
      if (!this.allGradesSelected) {
        query += '&class=' + this.Form.grades;
      }
      if (!this.allSectionsSelected) {
        query += '&section=' + this.Form.sections;
      }
      return query;
    },
    allGradesSelected() {
      return this.GRADE_OPTIONS.length == this.Form.grades.length;
    },
    allSectionsSelected() {
      return this.SECTION_OPTIONS.length == this.Form.sections.length;
    },
  },
  watch: {
    'Form.searchType': {
      immediate: true,
      handler() {
        this.Form.selectedSearchCriteria =
          this.searchCriteria[this.Form.searchType][0].value;
        this.records = [];
        this.totalPages = 0;
        this.initial = true;
        this.handleSearch();
      },
    },
    // 'Form.keyword': {
    //   immediate: true,
    //   handler() {
    //     this.handleSearch()
    //   },
    // },
    'Form.grades': {
      immediate: false,
      handler() {
        this.handleSearch();
      },
    },
    'Form.sections': {
      immediate: false,
      handler() {
        this.handleSearch();
      },
    },
    currentPage: {
      deep: true,
      immediate: false,
      handler: function () {
        this.handleSearch();
      },
    },
    rowsPerPage: {
      deep: true,
      immediate: false,
      handler: function () {
        this.handleSearch();
      },
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    toggleSearchType(searchType) {
      this.Form.searchType = searchType;
    },
    handleToggle() {
      console.log('some action here');
    },
    handleToggleGrade() {
      if (this.allGradesSelected) {
        this.Form.grades = [];
      } else {
        this.Form.grades = this.GRADE_OPTIONS.map(({ value }) => value);
      }
    },
    handleToggleSection() {
      if (this.allSectionsSelected) {
        this.Form.sections = [];
      } else {
        this.Form.sections = this.SECTION_OPTIONS.map(({ value }) => value);
      }
    },
    handlePageChange(page) {
      if (page >= 1 && page <= this.totalPages) this.currentPage = page;
    },
    handlePageSizeChange(pageSize) {
      this.rowsPerPage = pageSize;
      this.currentPage = 1;
    },
    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.grades = response.record;
        this.Form.grades = this.grades.map(({ _id }) => _id);
      } else {
        this.grades = [];
      }
    },

    async fetchSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/me',
      });
      if (response && !response.error) {
        this.sections = response.record;
        this.Form.sections = this.sections.map(({ _id }) => _id);
      } else {
        this.sections = [];
      }
    },
    handleSearchss() {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.handleSearch();
      }, 500);
    },
    async handleSearch() {
      this.initial = false;
      this.loading = true;
      let response = await this.request({
        url: this.url,
        method: 'get',
      });
      if (response) {
        this.records = response.items;
        this.totalPages = response.total_pages;
      }
      this.loading = false;
    },
  },
  created() {
    this.fetchGrades();
    this.fetchSections();
  },
};
</script>

<style></style>
