<template>
  <div>
    <base-title
      :title="
        academic_year.name
          ? `Promote Students of ${academic_year.name} In ${academic_year.class.name} -  ${academic_year.section.name}`
          : 'No Academic Year History On this Section'
      "
      class="mb-2"
    >
      <template #actions>
        <div class="flex justify-center items-center space-x-2">
          <base-select
            placeholder=""
            v-model="activeGrade"
            :items="GRADE_OPTIONS"
            searchable
          />
          <base-select
            placeholder=""
            v-model="activeSection"
            :items="SECTION_OPTIONS"
            searchable
          />

          <base-select
            placeholder=""
            :items="ACADEMIC_YEARS"
            v-model="activeAy"
            searchable
          />
        </div>
      </template>
    </base-title>

    <div class="bg-white w-full p-1 px-4 mt-2 rounded-default">
      <div class="py-2" v-if="activeAy">
        <div class="grid grid-cols-4 gap-2">
          <div class="col-span-3 bg-white p-2 mt-1 overflow-auto">
            <d-table
              #default="{ item }"
              :headers="headers"
              :items="items"
              :totalItems="items.length"
              :loading="loading"
              :noDataMessage="noDataMessage"
            >
              <td v-for="(header, i) in headers" :key="i">
                <div v-if="item" class="px-2">
                  <div v-if="header.key === 'profilePic'">
                    <div class="flex space-x-5 items-center">
                      <div
                        class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center"
                      >
                        <img
                          v-if="item.profilePic"
                          :src="PROFILE_PATH + item.profilePic"
                          class="w-full h-full object-cover rounded-full"
                          v-viewer="{ inline: false, movable: true }"
                          alt=""
                          crossorigin="anonymous"
                        />
                        <icon
                          v-else
                          :size="18"
                          name="user"
                          class="w-full h-full"
                        ></icon>
                      </div>
                      <div @click="displayUserDetail(item.id)">
                        <p
                          class="text-sm font-semibold uppercase text-textDark hover:underline cursor-pointer"
                        >
                          {{ item.firstName }} {{ item.middleName }}
                          {{ item.lastName }}
                        </p>
                        <p class="text-textLight">{{ item.email }}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      header.key === 'phoneNumber' && item.phoneNumber.length
                    "
                  >
                    {{ item['phoneNumber'].join(' | ') }}
                  </div>

                  <div v-else-if="header.key === 'status'">
                    <div class="flex justify-between items-center">
                      <div
                        class="h-8 w-8 rounded-full flex justify-center items-center cursor-pointer hover:bg-success hover:text-white"
                        :class="{
                          'bg-success text-white':
                            promotion_status[item._id] &&
                            promotion_status[item._id]['status'] === 'pass',
                          ' bg-gray-100 text-textDarkest':
                            !promotion_status[item._id] ||
                            promotion_status[item._id]['status'] != 'pass',
                        }"
                        @click="changeStatus(item._id, 'pass')"
                      >
                        <p class="" style="max-width: fit-content">P</p>
                      </div>

                      <div
                        class="h-8 w-8 rounded-full flex justify-center items-center cursor-pointer hover:bg-orange hover:text-white"
                        :class="{
                          'bg-orange text-white':
                            promotion_status[item._id] &&
                            promotion_status[item._id]['status'] === 'fail',
                          ' bg-gray-100 text-textDarkest':
                            !promotion_status[item._id] ||
                            promotion_status[item._id]['status'] != 'fail',
                        }"
                        @click="changeStatus(item._id, 'fail')"
                      >
                        <p class="" style="max-width: fit-content">F</p>
                      </div>
                      <div
                        class="h-8 w-8 rounded-full flex justify-center items-center cursor-pointer hover:bg-error hover:text-white"
                        :class="{
                          'bg-error text-white':
                            promotion_status[item._id] &&
                            promotion_status[item._id]['status'] === 'withdraw',
                          ' bg-gray-100 text-textDarkest':
                            !promotion_status[item._id] ||
                            promotion_status[item._id]['status'] != 'withdraw',
                        }"
                        @click="changeStatus(item._id, 'withdraw')"
                      >
                        <p class="" style="max-width: fit-content">W</p>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    {{ getProp(item, header.key) }}
                  </div>
                </div>
              </td>
            </d-table>
          </div>
          <div class="mt-1">
            <div class="bg-white p-5 h-full">
              <div
                class="flex justify-center bg-orange text-white py-3 flex items-center rounded-default w-full mb-5 text-sm"
              >
                <p>Overview Chart</p>
              </div>

              <div class="flex justify-center">
                <apexchart
                  ref="donut"
                  width="300"
                  type="donut"
                  :options="chartOptions"
                  :series="series"
                  @dataPointSelection="handleSelectStatus"
                  @markerClick="handleMarkerClick"
                ></apexchart>
              </div>

              <div class="space-y-4 py-2">
                <base-select
                  placeholder=""
                  v-model="selectedGrade"
                  :items="MY_GRADE_OPTIONS"
                  searchable
                />
                <base-select
                  placeholder=""
                  v-model="selectedSection"
                  :items="MY_SECTION_OPTIONS"
                  searchable
                />

                <!-- WARNING -->

                <div
                  v-if="selectedGrade === activeGrade"
                  class="col-span-2 flex cursor-pointer rounded-lg text-primary bg-gray-100"
                >
                  {{
                    `Students Cannot be promoted to the same grade level, please select the next grade!`
                  }}
                </div>

                <!-- INFO -->

                <div
                  v-else-if="selected_academic_year.name"
                  class="col-span-2 flex cursor-pointer rounded-lg text-primary bg-gray-100"
                >
                  {{
                    `Total Students of ${passCount} out of ${items.length} will be promoted to ${selected_academic_year.class.name} -  ${selected_academic_year.section.name} (${selected_academic_year.name})
                
`
                  }}
                </div>
              </div>
              <!-- <div class="flex justify-center mt-8">
                <base-button
                  icon="check-circle"
                  label="Promote Students"
                  @click="handlePromotion"
                  :working="working"
                  :disabled="selectedGrade === activeGrade"
                  class="px-3"
                  code="att-edit"
                />
              </div> -->
              <div class="flex justify-center mt-8">
                <base-button
                  icon="check-circle"
                  label="Promote Students"
                  @click="handlePromotion"
                  :working="working"
                  class="px-3"
                  code="att-edit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <base-no-data
        v-else
        message="No Closed Academic Year on this section! Please Close and try again!"
      />
    </div>
  </div>
</template>

<script>
import { PROFILE_PATH } from '@/config/config.js';
import eventBus from '@/eventBus';
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    DTable,
  },
  data() {
    return {
      working: false,

      activeGrade: '',
      activeSection: '',
      activeAy: '',
      gradeItems: [],
      sectionItems: [],
      academic_years: [],
      academic_year: {},
      selected_academic_year: {},

      myGradeItems: [],
      mySectionItems: [],
      selectedGrade: '',
      selectedSection: '',

      noData: false,
      noDataMessage: 'Students In this section are already promoted!',
      headers: [
        { label: 'User', key: 'profilePic' },
        { label: 'Student Id', key: 'userId' },
        // { label: 'Gender', key: 'student.gender' },
        // { label: 'Age', key: 'student.age' },

        { label: 'Grade', key: 'class.name' },
        { label: 'Section', key: 'section.name' },

        { label: 'Phone Number', key: 'phoneNumber' },
        { label: 'Academic Status', key: 'status' },
      ],

      PROFILE_PATH,

      STATUS_OPTIONS: ['pass', 'fail', 'withdraw'],

      errors: {},

      selectedStatusIndex: -1,
      promotion_status: {},
      items: [],
      pass: 0,
      fail: 0,
      withdraw: 0,

      chartOptions: {
        labels: ['Pass', 'Fail', 'Withdraw'],
        legend: {
          position: 'bottom',
          fontSize: '17px', // Set the font size here
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
          },
        },
        // fill: {
        //   colors: ['#3bb979', '#ec3118', '#ffa500']
        // },
        colors: ['#3bb979', '#ffc145', '#ff6b6c'],
        // selection: {
        //   fill: {
        //     color: '#ffffff',
        //     opacity: 0.0
        //   }
        // },
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState('main', ['error']),

    series() {
      let pass = 0;
      let fail = 0;
      let withdraw = 0;
      Object.keys(this.promotion_status).forEach((key) => {
        if (this.promotion_status[key]['status'] === 'pass') pass++;
        if (this.promotion_status[key]['status'] === 'fail') fail++;
        if (this.promotion_status[key]['status'] === 'withdraw') withdraw++;
      });

      return [pass, fail, withdraw];
    },
    passCount() {
      return Object.keys(this.promotion_status).filter(
        (key) => this.promotion_status[key].status === 'pass'
      ).length;
    },
    failCount() {
      return Object.keys(this.promotion_status).filter(
        (key) => this.promotion_status[key].status === 'fail'
      ).length;
    },
    withdrawCount() {
      return Object.keys(this.promotion_status).filter(
        (key) => this.promotion_status[key].status === 'withdraw'
      ).length;
    },
    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },

    SECTION_OPTIONS() {
      return this.sectionItems.map((section) => ({
        label: section.name,
        value: section._id,
      }));
    },
    ACADEMIC_YEARS() {
      return this.academic_years.map((academic_year) => ({
        label: academic_year.name,
        value: academic_year._id,
      }));
    },
    MY_GRADE_OPTIONS() {
      return this.myGradeItems.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },

    MY_SECTION_OPTIONS() {
      return this.mySectionItems.map((section) => ({
        label: section.name,
        value: section._id,
      }));
    },

    selectedStatus() {
      if (this.selectedStatusIndex === -1) return 'All';
      return this.STATUS_OPTIONS[this.selectedStatusIndex];
    },
  },
  watch: {
    activeGrade() {
      this.fetchSections();
    },
    activeSection() {
      this.fetchAcademicYears();
    },
    activeAy() {
      this.fetchAcademicYear();
      this.fetchItems();
    },
    selectedGrade() {
      this.fetchMySections();
    },
    selectedSection() {
      this.fetchMyActiveAcademicYear();
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,

    changeStatus(studentId, status) {
      this.promotion_status[studentId] = { status };
      this.promotion_status = { ...this.promotion_status };
    },

    handleSelectStatus(_, __, selected) {
      this.setSelectedStatusIndex(selected.dataPointIndex);
    },

    handleMarkerClick(markerClick) {
      console.info(markerClick);
      return;
    },

    setSelectedStatusIndex(index) {
      if (index === this.selectedStatusIndex) this.selectedStatusIndex = -1;
      else this.selectedStatusIndex = index;
    },

    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
        this.activeGrade = response.record[0]._id;
      } else {
        this.gradeItems = [];
        this.activeGrade = '';
      }
    },

    async fetchSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/class/' + this.activeGrade,
      });
      if (response && !response.error) {
        this.sectionItems = response.record;
        this.activeSection = response.record[0]._id;
      } else {
        this.sectionItems = [];
        this.activeSection = '';
      }
    },

    async fetchAcademicYears() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/section-history/' + this.activeSection,
      });
      if (response && !response.error) {
        this.academic_years = response.record;
        this.activeAy = this.academic_years[0]._id;
        this.academic_year = response.record[0];
      } else {
        this.academic_years = [];
        this.activeAy = '';
        this.noData = true;
      }
    },

    async fetchAcademicYear() {
      if (this.activeAy) {
        let response = await this.request({
          method: 'get',
          url: 'school/academicYear/' + this.activeAy,
        });
        if (response && !response.error) {
          this.academic_year = response.record;
        } else {
          this.academic_year = {};
          this.noData = true;
        }
      } else {
        eventBus.$emit('open-toast', {
          message:
            'Please Close Active Academic Year For The Selected Section!',
          error: true,
        });
      }
    },

    async fetchMyGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.myGradeItems = response.record;
        this.selectedGrade = response.record[0]._id;
      } else {
        this.myGradeItems = [];
        this.selectedGrade = '';
      }
    },

    async fetchMySections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/class/' + this.selectedGrade,
      });
      if (response && !response.error) {
        this.mySectionItems = response.record;
        this.selectedSection = response.record[0]._id;
      } else {
        this.mySectionItems = [];
        this.selectedSection = '';
      }
    },

    async fetchMyActiveAcademicYear() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/active/' + this.selectedSection,
      });
      if (response && !response.error) {
        this.selected_academic_year = response.record;
      } else {
        this.selected_academic_year = {};
        this.noData = true;
      }
    },

    async fetchItems() {
      this.loading = true;
      if (this.activeAy) {
        let response = await this.request({
          method: 'get',
          url: 'school/studentSection/promotion-history/' + this.activeAy,
        });
        if (response && !response.error) {
          this.items = response.record;
          // this.totalItems = response.total;

          this.promotion_status = {};

          this.items.forEach((student) => {
            this.promotion_status[student._id] = {
              status: student.status || 'pass',
              // remark: student.remark || undefined,
            };
          });

          this.promotion_status = { ...this.promotion_status };
        } else {
          this.items = [];
          // this.totalItems = 0;
        }
      } else {
        eventBus.$emit('open-toast', {
          message:
            'Please Close Active Academic Year For The Selected Section!',
          error: true,
        });
      }

      this.loading = false;
    },

    async handlePromotion() {
      this.working = true;
      let students = [];
      const new_ay = this.selected_academic_year._id;
      Object.keys(this.promotion_status).forEach((key) => {
        students.push({
          student: key,
          status: this.promotion_status[key]['status'],
        });
      });

      try {
        let response = await this.request({
          method: 'put',
          url: 'school/studentSection/promote-students/' + new_ay,
          data: { students },
        });

        if (response && !response.error) {
          this.working = false;
          eventBus.$emit('open-toast', {
            message: 'Students Are Promoted Successfully!',
          });

          this.promotion_status = {};
        } else {
          this.working = false;
          eventBus.$emit('open-toast', {
            message: this.error,
            error: true,
          });
        }
        this.fetchItems();

        this.isDirty = false;
      } catch (error) {
        eventBus.$emit('open-taost', {
          message: this.error,
          error: true,
        });
      }
    },
  },

  async created() {
    await this.fetchGrades();
    await this.fetchMyGrades();
  },
};
</script>

<style></style>
