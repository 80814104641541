<template>
  <div>
    <base-title title="Manage Student">
      <template #actions>
        <div class="flex justify-center items-center space-x-2">
          <base-input
            v-if="isGreg"
            type="date"
            class="ml-auto"
            style="max-width: fit-content"
            v-model="reportDate"
          />
          <base-eth-calendar v-if="!isGreg" v-model="reportDate" />

          <base-select
            :items="GRADE_OPTIONS"
            v-model="activeGrade"
            searchable
            clearable
          />
          <base-button
            icon="exchange"
            label="Mass Update"
            :primary="false"
            class="bg-gray-100 flex-shrink-0"
            @click="handleMassTransfer"
          />
          <base-button
            icon="plus"
            label="Export"
            :primary="false"
            class="bg-gray-100 flex-shrink-0"
            :working="working"
            @click="exportCsv"
          />
          <base-button
            icon="plus"
            label="Add New"
            :isVisible="permission.add"
            class="px-3 flex-shrink-0"
            @click="handleCreateNew"
            code="stu-add"
          />
        </div>
      </template>
    </base-title>

    <div class="bg-white p-1 px-4 mt-2 rounded-default">
      <div>
        <d-table
          #default="{ item }"
          :headers="headers"
          :items="items"
          :totalItems="totalItems"
          :pageRequest="true"
          :hasActions="true"
          :handlers="handlers"
          :loading="loading"
          :searchProps="searchProps"
          code="stu-view"
          sortUrl="student/studentProfile/my-students"
        >
          <td v-for="(header, i) in headers" :key="i">
            <div class="px-2">
              <div v-if="header.key === 'profilePic'">
                <div class="flex space-x-5 items-center">
                  <div
                    class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center"
                  >
                    <img
                      v-if="item.profilePic"
                      :src="PROFILE_PATH + item.profilePic"
                      v-viewer="{ inline: false, movable: true }"
                      class="w-full h-full object-cover rounded-full cursor-pointer"
                      alt=""
                      crossorigin="anonymous"
                    />
                    <icon
                      v-else
                      :size="18"
                      name="user"
                      class="w-full h-full"
                    ></icon>
                  </div>
                  <div>
                    <p
                      class="cursor-pointer hover:underline font-semibold text-textDarkest uppercase"
                      @click="handleDetail(item._id)"
                    >
                      {{ item.firstName + ' ' + item.middleName }}
                    </p>
                    <p class="text-textLight">{{ item.username }}</p>
                  </div>
                </div>
              </div>
              <div v-else-if="header.key === 'class'">
                {{ item['class'] ? item['class']['name'] : 'NA' }}
              </div>

              <div v-else-if="header.key === 'phoneNumber'">
                {{ item['phoneNumber'].join(' | ') }}
              </div>
              <div v-else-if="header.key === 'school'">
                {{ item['school'] ? item['school']['name'] : 'NA' }}
              </div>
              <div v-else-if="header.key === 'section'">
                {{ item.section ? item.section.name : '--' }}
              </div>

              <div v-else-if="header.key === 'payer'">
                {{ item['payer'] ? item['payer']['name'] : 'NA' }}
              </div>
              <div class="capitalize" v-else-if="header.key === 'parents'">
                {{
                  item['parents'].length > 0
                    ? item['parents'][0]['parent']
                      ? item['parents'][0]['parent']['firstName'] +
                        ' ' +
                        item['parents'][0]['parent']['middleName'] +
                        ' | ' +
                        item['parents'][0]['relation']
                      : '--'
                    : '--'
                }}
              </div>
              <div v-else-if="header.key === 'status'">
                <status-chip
                  :label="item['status']"
                  rounded
                  style="max-width: fit-content"
                />
              </div>
              <div v-else class="capitalize">
                {{ getProp(item, header.key) }}
              </div>
            </div>
          </td>
        </d-table>
      </div>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString, stringifyQueryObj } from '@/utils';
import eventBus from '../../../../eventBus';
import { mapActions, mapGetters, mapState } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';

export default {
  components: { DTable },
  data() {
    return {
      PROFILE_PATH,
      items: [],
      totalItems: '',
      module: 'student',
      permission: {},
      gradeItems: [],
      activeGrade: '',
      reportDate: '',
      loading: false,
      working: false,
      headers: [
        { label: 'Student', key: 'profilePic' },
        { label: 'Student Id', key: 'userId' },
        { label: 'Gender', key: 'gender' },
        { label: 'Age', key: 'age' },
        { label: 'Grade', key: 'class' },
        { label: 'Section', key: 'section' },
        { label: 'Phone Number', key: 'phoneNumber' },
        // { label: 'Address', key: 'address' },
        { label: 'Guardian', key: 'parents' },
        { label: 'Status', key: 'status' },
        // { label: '', key: '' },
        // { label: 'Label Five', key: '' },
      ],
      handlers: [
        {
          label: 'Detail',
          icon: 'eye',
          name: 'detail',
          handler: this.handleDetail,
          idParam: true,
          filter: () => this.authCodes.includes('stu-dtl'),
        },

        {
          label: 'Edit',
          icon: 'pencil-alt',
          name: 'update',
          handler: this.handleEdit,
          idParam: true,
          filter: () => this.authCodes.includes('stu-edit'),
        },
        {
          label: 'Change Status',
          icon: 'pencil-alt',
          name: 'update',
          handler: this.handleChangeStatus,
          fullParam: true,
          filter: () => this.authCodes.includes('stu-stat'),
        },
        {
          label: 'Link To Billing',
          icon: 'link',
          name: 'link',
          handler: this.handleLinkBilling,
          idParam: true,
          filter: () => this.authCodes.includes('stu-stat'),
        },
        {
          label: 'Reset Password',
          icon: 'pencil-alt',
          name: 'update',
          handler: this.handleResetPassword,
          idParam: true,
          filter: () => this.authCodes.includes('stu-rst'),
        },

        {
          label: 'Delete',
          icon: 'trash-alt',
          name: 'delete',
          handler: this.handleDelete,
          idParam: true,
          filter: () => this.authCodes.includes('stu-rm'),
        },
      ],
      searchProps: {
        searchUrl: 'student/studentProfile/table-search',
        queries: {},
      },
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
    ...mapState('main', ['globalSchoolConfig']),

    isGreg() {
      return (
        this.globalSchoolConfig &&
        this.globalSchoolConfig.calendarType == 'greg'
      );
    },

    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name + ' - ' + grade.enrollment.code,
        value: grade._id,
      }));
    },
  },
  watch: {
    activeGrade: {
      deep: true,
      immediate: false,
      handler: function () {
        this.fetchStudents();
        this.searchProps.queries.class = this.activeGrade;
      },
    },
    reportDate: {
      deep: true,
      immediate: false,
      handler: function () {
        this.fetchStudents();
      },
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,
    handleDelete(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the item',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },
    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'student/studentProfile/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'deleted successfully' });
        this.fetchStudents();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    handleEdit(id) {
      this.$router.push({ name: 'student-update', params: { id } });
    },
    handleDetail(id) {
      this.$router.push({ name: 'student-detail', params: { id } });
    },
    handleCreateNew() {
      this.$router.push({ name: 'student-create' });
    },
    async handleResetPassword(id) {
      let response = await this.request({
        method: 'put',
        url: 'student/studentProfile/reset-password/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-modal', {
          modalId: 5,
          componentProps: { user: response.record },
        });
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    async fetchStudents() {
      this.loading = true;
      let url = 'student/studentProfile/my-students';
      let queryString = '';
      queryString += stringifyQueryObj({
        page: 1,
        limit: 10,
        date: this.reportDate ? this.reportDate : undefined,
        grade: this.activeGrade ? this.activeGrade : undefined,
      });

      url += queryString;
      let response = await this.request({
        method: 'get',
        url: url,
      });
      if (response && !response.error) {
        this.items = response.record;
        this.totalItems = response.total;
      } else this.items = [];
      this.loading = false;
    },

    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active/',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
      } else {
        this.gradeItems = [];
      }
    },

    handleChangeStatus(user) {
      eventBus.$emit('open-modal', {
        modalId: 4,
        componentProps: {
          status: user.status,
          id: user._id,
          profile_type: 'student',
        },
        cb: this.fetchStudents,
      });
    },

    async handleLinkBilling(id) {
      let response = await this.request({
        method: 'post',
        url: 'student/studentProfile/linkToInfinity/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'student linked successfully',
        });
        this.fetchStudents();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    async exportCsv() {
      this.working = true;
      let url = 'student/studentProfile/my-students';
      let queryString = '';
      queryString += stringifyQueryObj({
        page: 1,
        limit: 10000,
        date: this.reportDate ? this.reportDate : undefined,
        grade: this.activeGrade ? this.activeGrade : undefined,
      });

      url += queryString;
      let response = await this.request({
        method: 'get',
        url: url,
      });
      if (response && !response.error) {
        const items = response.record;

        const i = items.map((it) => {
          return { ...it, guardian: { ...it.parents[0] } };
        });
        const h = [
          { label: 'UserId', key: 'userId' },
          { label: 'First Name', key: 'firstName' },
          { label: 'Father Name', key: 'middleName' },
          { label: 'Grand Father Name', key: 'lastName' },
          { label: 'Gender', key: 'gender' },
          { label: 'Date Of Birth', key: 'birthdate' },
          { label: 'Grade', key: 'class.name' },
          { label: 'Section', key: 'section.name' },
          { label: 'Region', key: 'region' },
          { label: 'City', key: 'city' },
          { label: 'Subcity', key: 'subcity' },

          { label: 'Address', key: 'address' },

          { label: 'Email', key: 'email' },

          { label: 'Phone Number', key: 'phoneNumber' },
          { label: 'Payer', key: 'payer.name' },
          { label: 'Discount', key: 'discount.name' },

          { label: 'Service', key: 'service' },

          { label: 'Guardian First Name', key: 'guardian.parent.firstName' },
          { label: 'Guardian Father Name', key: 'guardian.parent.middleName' },
          {
            label: 'Guardian Grand Father Name',
            key: 'guardian.parent.lastName',
          },
          { label: 'Guardian Gender', key: 'guardian.parent.gender' },

          { label: 'Guardian Date Of Birth', key: 'guardian.parent.birthdate' },
          { label: 'Relationship', key: 'guardian.relation' },
          { label: 'Email', key: 'guardian.parent.email' },
          { label: 'Phone Number', key: 'guardian.parent.phoneNumber' },

          // { label: 'Address', key: 'address' },
          { label: 'Status', key: 'status' },
        ];
        let componentProps = {
          title: 'students',
          headers: h,
          items: i,
        };
        eventBus.$emit('open-modal', { modalId: 35, componentProps });
      }
      this.working = false;
    },
    handleMassTransfer() {
      let componentProps = {};
      eventBus.$emit('open-modal', {
        modalId: 48,
        componentProps,
        cb: this.fetchStudents,
      });
    },
  },

  async created() {
    await this.fetchGrades();
    await this.fetchStudents();
  },
};
</script>

<style></style>
