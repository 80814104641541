<template>
  <div class="bg-white px-2 py-5">
    <!-- <p>attendance view</p>
    <calendar-heat
      :values="[{ date: '2018-9-22', count: 6 }]"
      end-date="2023-9-22"
    /> -->
    <div class="mb-8 flex justify-between">
      <p class="text-textDarkest">
        Attendance Activity for year, {{ activeYear }}
      </p>
      <div>
        <base-select
          v-model="activeYear"
          :items="YEAR_OPTIONS"
          :errors="errors"
        />
      </div>
    </div>
    <div class="flex space-x-1">
      <div v-for="(month, k) in months" :key="k" class="">
        <div class="text-sm font-thin text-textDark">{{ month.name }}</div>
        <div
          class="w-full grid grid-flow-col grid-rows-7 gap-y-1 gap-x-1"
          :class="{
            'grid-cols-5': month.days > 28,
            'grid-cols-4': month.days <= 28,
          }"
        >
          <attendance-block
            v-for="i in 7 * (month.days > 28 ? 5 : 4)"
            :key="i"
            :month="month"
            :day="i"
            :activeYear="activeYear"
            :attendance="
              formattedAttendance[
                activeYear +
                  '-' +
                  month.number +
                  '-' +
                  (`${i}`.length === 1 ? '0' + i : i)
              ]
            "
          />
        </div>
      </div>
    </div>
    <div>
      <!-- {{ formattedAttendance }}
      {{ attendanceList }} -->
    </div>
    <div class="flex space-x-5 mt-10 justify-between">
      <div class="w-1/2"></div>
      <div class="">
        <apexchart
          ref="donut"
          width="350"
          type="donut"
          :options="chartOptions"
          :series="series"
          @dataPointSelection="handleSelectStatus"
          @markerClick="handleMarkerClick"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import AttendanceBlock from './AttendanceBlock.vue';
// const CalHeatmap = require('cal-heatmap');
// import CalendarHeat from 'vue-calendar-heatmap';
export default {
  components: {
    // CalendarHeat,
    AttendanceBlock,
  },
  props: ['userId'],
  data() {
    return {
      months: [
        {
          name: 'January',
          days: 31,
          number: '01',
        },
        {
          name: 'February',
          days: 28,
          number: '02',
        },
        {
          name: 'March',
          days: 31,
          number: '03',
        },
        {
          name: 'April',
          days: 30,
          number: '04',
        },
        {
          name: 'May',
          days: 31,
          number: '05',
        },
        {
          name: 'June',
          days: 30,
          number: '06',
        },
        {
          name: 'July',
          days: 31,
          number: '07',
        },
        {
          name: 'August',
          days: 31,
          number: '08',
        },
        {
          name: 'September',
          days: 30,
          number: '09',
        },
        {
          name: 'October',
          days: 31,
          number: '10',
        },
        {
          name: 'November',
          days: 30,
          number: '11',
        },
        {
          name: 'December',
          days: 31,
          number: '12',
        },
      ],
      attendanceList: [],
      activeYear: '2023',
      chartOptions: {
        labels: ['present', 'absent', 'late'],
        plotOptions: {
          pie: {
            expandOnClick: true,
          },
        },
        // fill: {
        //   colors: ['#3bb979', '#ec3118', '#ffa500']
        // },
        colors: ['#3bb979', '#ff6b6c', '#ffc145'],
        // selection: {
        //   fill: {
        //     color: '#ffffff',
        //     opacity: 0.0
        //   }
        // },
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },
        },
      },
      // series: [22, 34, 55]
    };
  },

  computed: {
    ...mapState('main', ['error']),
    YEAR_OPTIONS() {
      let years = [];

      for (let i = 0; i > -50; i--) {
        let v = moment().add(i, 'years').year();

        years.push({ label: `${v}`, value: `${v}` });
      }

      return years;
    },
    series() {
      let late = 0;
      let absent = 0;
      let present = 0;

      this.attendanceList.forEach((att) => {
        if (att['status'] === 'present') present++;
        if (att['status'] === 'absent') absent++;
        if (att['status'] === 'late') late++;
      });

      return [present, absent, late];
    },
    formattedAttendance() {
      let formatted = {};
      this.attendanceList.forEach((att) => {
        formatted[att.formatted_date] = att;
      });

      return formatted;
    },
  },

  watch: {
    activeYear() {
      this.fetchAttendance();
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    async fetchAttendance() {
      let response = await this.request({
        method: 'get',
        url: 'school/attendance/history/' + this.userId + '/' + this.activeYear,
      });
      if (response && !response.error) {
        this.attendanceList = response.record;
      } else {
        this.attendanceList = [];
      }
    },
  },
  created() {
    this.activeYear = moment().year();
    this.fetchAttendance();
  },
};
</script>

<style scoped>
.grid-cols-59 {
  grid-template-columns: repeat(59, minmax(0, 1fr));
}

.grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}
</style>
