import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import main from './modules/main';
import socket from './modules/socket';
import passwordStore from './modules/password';
import printer from './modules/printer';
import fee from './modules/fee';
import timer from './modules/timer';
import migration from './modules/migration.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        passwordStore,
        user,
        main,
        socket,
        printer,
        fee,
        timer,
        migration,
    }
});