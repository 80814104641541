<template>
    <div class="p-6 flex flex-col items-center justify-center">
      <div class="w-20 h-20 rounded-full bg-gray-100 flex items-center justify-center">
        <img
          v-if="user.photo"
          v-viewer="{ inline: false, movable: true }"
          class="w-full h-full rounded-full hover:opacity-75 cursor-pointer"
          :src="PROFILE_PATH + user.photo"
          alt=""
        />
        <avatar
          v-else-if="shortName"
          :name="shortName"
          :size="80"
        />
      </div>
      <div class="p-4 text-center">
        <div class="text-xl capitalize font-semibold">{{ user.name }}</div>
        <div class="font-sans">@{{ user.username }}</div>
      </div>
    </div>
</template>

<script>
import Avatar from '@/components/shared/Avatar/Avatar.vue';
import { PROFILE_PATH } from '@/config/config';
import { _2Letters } from '@/mixins/_2Letters';
import { mapGetters } from 'vuex';
export default {
  components: { Avatar },
  mixins: [_2Letters],
  data() {
    return {
      PROFILE_PATH,
    };
  },
  computed: {
    ...mapGetters(['user']),
    shortName() {
      return this.user ? this.get_2Letters(this.user.name) : '';
    },
  },
};
</script>

<style></style>
