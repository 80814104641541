import MySchool from '@/views/protected/school_management/MySchool.vue';
import Assessment from '@/views/protected/school_management/assessment/List.vue';

export default [
  {
    path: 'school-pref',
    name: 'school-pref',
    component: MySchool,
  },

  {
    path: 'assessments',
    name: 'assessments',
    component: Assessment,
  },
];
