<template>
  <div>
    <slot name="label" :label="label">
      <p v-if="label" class="mb-0">
        {{ label
        }}<span v-if="required && !success" class="text-error"> *</span>
      </p>
    </slot>
    <div class="input-box">
      <icon
        v-if="icon"
        :name="icon"
        :size="iconSize"
        class="icon-style"
        :class="iconStyle"
      />
      <input
        class="appearance-none border-1 w-full px-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500"
        :class="{
          'py-1': dense,
          'py-2': !dense,
          'rounded-none': rounded,
          'border-1': bordered,
          'rounded-default': !rounded,
          capitalize: capitalize,
          uppercase: caps,
          'focus:outline-none focus:bg-white  focus:ring-2 focus:ring-secondary':
            success,
          'border-error bg-red-50 placeholder-red-900 focus:border-rose focus:ring-1 focus:ring-rose focus:outline-none':
            !success && check,
          'bg-gray-100': filled,
          'pl-8': icon,
          'bg-gray-100-100 border-none appearance-none text-gray-500':
            $attrs.disabled,
        }"
        id="inline-full-name"
        :placeholder="placeholder"
        @input="handleInput"
        @keyup.enter="handleOnEnter"
        @focus="handleFocus"
        @blur="handleBlur"
        v-bind="$attrs"
      />
    </div>
    <div v-if="check" class="text-xs" style="color: #ec3118">
      <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    placeholder: { type: String, required: false },
    size: { type: Number, default: 16 },
    dense: { type: Boolean, default: false },
    capitalize: { type: Boolean, default: false },
    caps: { type: Boolean, default: false },
    outlineColor: { type: String, default: 'border-blue-500' },
    filled: { type: Boolean, default: false },
    label: { type: String },
    errors: { type: Array, default: () => [] },
    icon: { type: String },
    iconStyle: { type: String },
    iconSize: { type: Number },
    inputclass: { type: String },
    roundedlg: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
    // inputType: { type: String, default: 'text' },
    rounded: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    check: { type: Boolean, default: false },
    // value: {
    //   required: false,
    // },
  },

  data() {
    return {};
  },

  methods: {
    handleInput(v) {
      if (this.$attrs.type == 'number') {
        const value = Number(v.target.value);
        if (value < 0) return;
        const min = Number(this.$attrs.min) || 0;
        const max = Number(this.$attrs.max) || Number.POSITIVE_INFINITY;

        if (value < min || value > max) {
          return;
        }
      }

      this.$emit('input', v.target.value);
    },
    handleOnEnter() {
      this.$emit('enter');
    },
    handleFocus() {
      this.$emit('focus');
    },
    handleBlur() {
      this.$emit('blur');
    },
  },

  computed: {
    success() {
      return !this.errors.length;
      // return false;
    },
  },
};
</script>

<style scoped>
.icon-style {
  top: 25%;
  left: 6px;
  position: absolute;
}

.custom-input:focus {
  /* border: 2px solid #0a2a66; */
  /* border: 2px solid #3bb979; */
  border: 2px solid #1eaae7;

  /* outline-color: #3bb979; */
}

.custom-input[type='number']:focus {
  border: none;
  outline: none;

  /* border: 2px solid #1eaae7; */
}

.input-box {
  position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
