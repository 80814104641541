<template>
  <div>
    <div class="flex p-10 space-x-10" style="width: 29.7cm; height: 21cm;">
      <div class=" w-1/2 border-default">
        one
      </div>
      <div class=" w-1/2 border-default">
        two
      </div>
    </div>
    <div class="flex p-10 space-x-10 " style="width: 29.7cm; height: 21cm;">
      <div class=" w-1/2 border-4 bg-orange">
        <div v-for="i in 10" :key="i" class=" border-b-default px-2 py-1">
          hello
        </div>
      </div>
      <div class=" w-1/2 border-default">
        four
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('printer', ['singleTranscriptData'])
  }
};
</script>

<style></style>
