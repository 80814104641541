<template>
  <div class="">
    <loader v-if="loading"></loader>
    <div class="" v-else-if="userDetail">
      <div
        class="p-5 bg-white grid grid-cols-5 items-center shadow-sm border-1 border-gray-100 rounded-lg"
      >
        <div
          class="w-32 h-32 bg-gray-100 overflow-hidden rounded-full flex justify-center items-center"
        >
          <img
            v-if="userDetail.profilePic"
            :src="PROFILE_PATH + userDetail.profilePic"
            v-viewer="{ inline: false, movable: true }"
            class="w-full cursor-pointer"
            alt=""
            crossorigin="anonymous"
          />
          <icon v-else :size="44" name="user" class="text-textDark"></icon>
        </div>
        <div class="col-span-3">
          <h3 class="font-bold text-xl capitalize">
            {{ userDetail.firstName }} {{ userDetail.middleName }}
            {{ userDetail.lastName }}
          </h3>
          <h5 class="text-darkGray" v-if="userDetail.class">
            {{ userDetail.class.name }}
          </h5>
          <h5 class="text-xs text-secondary" v-if="userDetail.role">
            {{ userDetail.role }}
          </h5>
          <div
            class="text-white px-2 mt-2 rounded-sm"
            style="max-width: fit-content"
            :class="{
              'bg-verified': userDetail.status == 'verified',
              'bg-pending': userDetail.status == 'pending',
              'bg-suspended': userDetail.status == 'suspended',
            }"
          >
            {{ userDetail.status }}
          </div>
        </div>
        <div class="">
          <base-qrcode :value="userDetail.userId"></base-qrcode>
        </div>
      </div>
      <br />

      <div class="bg-white p-3 shadow-sm border-1 border-gray-100 rounded-lg">
        <span class="font-bold">{{ userType.toUpperCase() }} INFORMATION</span>
        <div class="p-5 grid grid-cols-3 gap-8">
          <div class="">
            <span class="text-darkGray">Name</span><br />
            <span class="capitalize">{{ userDetail.firstName }}</span>
          </div>
          <div class="">
            <span class="text-darkGray">Father's Name</span><br />
            <span class="capitalize">{{ userDetail.middleName }}</span>
          </div>
          <div class="">
            <span class="text-darkGray">Grandfather's Name</span><br />
            <span class="capitalize">{{ userDetail.lastName }}</span>
          </div>
          <div class="" v-if="userDetail.age">
            <span class="text-darkGray">Age</span><br />
            <span class="">{{ userDetail.age }}</span>
          </div>
          <div class="">
            <span class="text-darkGray">Gender</span><br />
            <span class="capitalize">{{ userDetail.gender }}</span>
          </div>
          <div class="">
            <span class="text-darkGray">User ID</span><br />
            <span class="capitalize">{{ userDetail.userId }}</span>
          </div>
          <div class="" v-if="userDetail.class">
            <span class="text-darkGray">Year Level</span><br />
            <span class="">{{ userDetail.class.name }}</span>
          </div>
          <div class="" v-if="userDetail.section">
            <span class="text-darkGray">Section</span><br />
            <span class="">{{ userDetail.section.name }}</span>
          </div>
        </div>
      </div>
      <br />

      <div class="bg-white p-3 shadow-sm border-1 border-gray-100 rounded-lg">
        <span class="font-bold">CONTACT & ADDRESS</span>
        <div class="p-5 grid grid-cols-3 gap-8">
          <div class="">
            <span class="text-darkGray">Phone Number</span><br />
            <span class="" v-if="userDetail['phoneNumber']">
              {{ userDetail['phoneNumber'].join(' | ') }}</span
            >
          </div>
          <div class="" v-if="userDetail.email">
            <span class="text-darkGray">Email</span><br />
            <span class="">{{ userDetail.email }}</span>
          </div>
          <div class="" v-if="userDetail.address">
            <span class="text-darkGray">Street Address</span><br />
            <span class="">{{ userDetail.address }}</span>
          </div>
          <div class="" v-if="userDetail.city">
            <span class="text-darkGray">City</span><br />
            <span class="">{{ userDetail.city }}</span>
          </div>
          <div class="" v-if="userDetail.region">
            <span class="text-darkGray">Region</span><br />
            <span class="">{{ userDetail.region }}</span>
          </div>
        </div>
      </div>
    </div>
    <base-no-data v-else :message="`no ${userType} profile`" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';

export default {
  props: {
    userId: { type: String },
    userType: { type: String },
    routeURL: { type: String },
  },

  data() {
    return {
      userDetail: '',
      PROFILE_PATH,
      loading: false,
    };
  },

  methods: {
    ...mapActions('main', ['request']),
    offdayDetail() {
      this.$router.push({ name: 'teacher-offday' });
    },

    async fetchUser() {
      this.loading = true;
      let response = await this.request({
        method: 'get', //this.userId
        url: this.routeURL + this.userId,
      });
      if (response && !response.error) {
        this.userDetail = response.record;
        if (this.userType == 'school official') {
          this.userDetail.role = this.userDetail.role.name;
        }
      } else {
        this.userDetail = '';
      }
      this.loading = false;
    },
  },

  created() {
    this.fetchUser();
  },
};
</script>
