import apiRequest from '../../services/apiRequest'
import { navService } from '../../services/storageService'
let nav = navService.getActiveNav()

const state = {
  activeNav: nav || 'dashboard',
  currencies: [],
  error: '',
  working: false,
  globalSchoolConfig: null
  
}

const mutations = {
  SMART_SET: (state, { stateKey, data }) => {
    state[stateKey] = data
  },
  SET_ERROR: (state, { message }) => {
    /**
     * FYI: if you want to use errorCode in your error display.
     * Set it here
     */
    state.error = message
  },
  SET_WORKING: (state, { bool }) => {
    state.working = bool;
  },
  SET_SCHOOL_CONFIG: (state, { data }) => {
    state.globalSchoolConfig = data;
  }
}

const actions = {
  async fetchSchoolConfig({ commit }){
    try {
      const { data } = await apiRequest.request({
        method: 'get',
        url: 'school/config/',
      })
      commit('SET_SCHOOL_CONFIG', { data: data.record })
    } catch (e) {
      commit('SET_ERROR', { error: e.errorCode, message: e.message })
    }
  },
  setErrorEmpty({ commit }) {
    commit('SET_ERROR', { message: '' })
  },
  setData({ commit }, { stateKey, data }) {
    commit('SMART_SET', { stateKey, data })
  },
  async requestMutate({ commit }, { stateKey, ...params }) {
    try {
      const { data } = await apiRequest.request(params)
      commit('SMART_SET', { stateKey, data: data.items })
      return true
    } catch (e) {
      commit('SET_ERROR', { error: e.errorCode, message: e.message })
      return false
    }
  },
  async request({ commit }, params) {
    try {
      commit("SET_WORKING", { bool: true })
      const response = await apiRequest.request(params)
      if(response.error) {
        commit('SET_ERROR', { error: response.statusCode, message: response.message || response.joi })
      }
      commit("SET_WORKING", { bool: false })
      return response ? response.data : true
    } catch (e) {
      commit("SET_WORKING", { bool: false })
      commit('SET_ERROR', { error: e.errorCode, message: e.message })
      return false
    }
  },
  async upload({ commit }, data) {
    try {
      const response = await apiRequest.upload(data)
      return response
    } catch (e) {
      // console.log(e.AuthenticationError, e.error, e.errorCode)
      commit('SET_ERROR', { error: e.errorCode, message: e.message })
      return false
    }
  },
  async getApplication({ commit }, params) {
    try {
      const { data } = await apiRequest.getApplication(params)
      return data
    } catch (e) {
      commit('SET_ERROR', { error: e.errorCode, message: e.message })
      return false
    }
  },
}

const getters = {
  activeNav: state => state.activeNav,
  currencies: state => state.currencies,
  berror: state => state.error,
}

const main = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default main
