<template>
  <div
    class="w-full h-36 p-2 rounded-default session-card shadow-default"
    :class="{
      'cursor-pointer hover:shadow-default': mode === 'selection',
    }"
    :style="{
      backgroundColor: themes[session.category].color,
      color: themes[session.category].text,
    }"
    @click="handleToggleSelection(session)"
  >
    <div class="w-full flex justify-between space-x-1">
      <div class="flex">
        <p
          class="truncate w-30"
          :title="session.name"
          v-tippy="{ arrow: true, theme: 'honeybee' }"
        >
          {{ session.name }}
        </p>
      </div>
      <div>
        <icon :name="themes[session.category].icon" :size="16" />
      </div>
    </div>
    <div v-if="mode === 'selection'">
      <!-- <icon
        name="circle"
        class=""
        :class="{
          'text-orange': true,
          'text-gray': false,
        }"
        :size="18"
      /> -->
      <div>
        <div v-if="session.teacher.length > 0" class="py-1 flex justify-start">
          <div
            class="inline-flex"
            v-for="(teacher, i) in session.teacher"
            :key="i"
          >
            <div class="h-8 w-8 rounded-full flex justify-center items-center">
              <img
                v-if="teacher.profilePic"
                :src="PROFILE_PATH + teacher.profilePic"
                class="w-full h-full object-cover rounded-full"
                alt=""
                crossorigin="anonymous"
              />
              <icon v-else :size="12" name="user" class="w-full h-full"></icon>
            </div>
            <p
              v-if="teacher && teacher.firstName"
              class="px-2 text-sm uppercase text-textDark capitalize hover:underline cursor-pointer truncate w-30"
            >
              {{ i == 1 ? ' , ' : '' }} {{ teacher.firstName }}
              {{ teacher.middleName }}
            </p>
          </div>
        </div>
        <div v-else>--</div>
        <div v-if="session.course" class="py-1 flex justify-start">
          <icon :size="12" name="course" class="w-full h-full"></icon>
          <p class="px-2 text-xs text-textDark">
            {{ session.course.name }}
          </p>
        </div>
        <div v-else>--</div>
      </div>
    </div>
    <div class="flex items-center space-x-2">
      <icon
        name="clock"
        :size="14"
        :class="{ 'text-success': session.category === 'class' }"
      />
      <p class="text-xs">{{ session.start_time }} - {{ session.end_time }}</p>
    </div>

    <div
      class="flex space-x-5 mt-2 justify-end"
      style="height: 12px"
      v-if="mode === 'management'"
    >
      <icon
        name="pencil-alt"
        :size="12"
        class="text-textMedium hover:text-success cursor-pointer session-action hidden"
        :style="{
          color: themes[session.category].text,
        }"
        v-tippy="{ arrow: true, theme: 'honeybee' }"
        content="edit session"
        @click="handleEdit"
        v-if="authCodes.includes('ses-edit')"
      />
      <icon
        name="trash-alt"
        :size="12"
        class="text-textMedium hover:text-error cursor-pointer session-action hidden"
        :style="{
          color: themes[session.category].text,
        }"
        v-tippy="{ arrow: true, theme: 'honeybee' }"
        content="delete session"
        @click="handleDelete"
        v-if="authCodes.includes('ses-rm')"
      />
    </div>
  </div>
</template>

<script>
import { sessionThemes } from '@/config/constants.js';
import { PROFILE_PATH, IMAGES_PATH } from '@/config/config.js';
import { mapGetters } from 'vuex';

export default {
  props: ['session', 'sequence', 'mode', 'selectedSessions'],
  data() {
    return {
      PROFILE_PATH,
      IMAGES_PATH,
      themes: sessionThemes,
    };
  },
  computed: {
    ...mapGetters(['authCodes']),
  },
  methods: {
    handleEdit() {
      this.$emit('edit', this.session);
    },

    handleDelete() {
      this.$emit('delete', this.session._id);
    },

    handleToggleSelection(selection) {
      if (this.mode !== 'selection') return;
      this.$emit('toggle', selection._id);
    },
  },
};
</script>

<style>
.session-card:hover .session-action {
  display: block;
}

.tippy-tooltip.honeybee-theme {
  background-color: #1eaae7;
  color: white;
}

.tippy-popper[x-placement^='top'] .tippy-tooltip.honeybee-theme .tippy-arrow {
  color: #1eaae7;
  color: #1eaae7;
}
</style>
