<template>
  <div class="space-y-1">
    <div class="grid grid-cols-3 bg-white gap-12 p-8">
      <base-select
        label="Payment type"
        placeholder="payment type"
        v-model="Form.payment_type"
        :errors="errors.payment_type"
        :items="payment_type_options"
        searchable
      />
      <div class="col-span-2">
        <div class="capitalize font-semibold">bill information</div>
        <bill-info-form />
      </div>
    </div>
    <generate-bill-filter-form
      :isReg="getIsRegular"
      :class="{
        'pointer-events-none opacity-50 bg-gray-50': !Form.payment_type,
        'bg-white': Form.payment_type,
      }"
    />
  </div>
</template>

<script>
// import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import GenerateBillFilterForm from './GenerateBillFilterForm.vue';
import BillInfoForm from './BillInfoForm.vue';
export default {
  components: {
    GenerateBillFilterForm,
    BillInfoForm,
  },
  name: 'GenerateBillForm',
  data() {
    return {
      Form: {
        school: this.school,
        payment_type: '',
      },
      group_options: [],
      payment_type_options: [],
      errors: {},
      isReg: false,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    getIsRegular() {
      let _ = this.payment_type_options.filter(
        (opt) => opt.rest.id === this.Form.payment_type
      );
      return _.length ? _[0].rest.isRegular : false;
    },
  },
  watch: {
    'Form.payment_type': {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.smartSet({ stateKey: 'payment_type', data: val });
        if (val) {
          const paymentTypeBillingId = this.payment_type_options.find(
            ({ value }) => value == val
          ).rest.billingId;
          console.info({ val, paymentTypeBillingId });
          this.smartSet({
            stateKey: 'paymentTypeBillingId',
            data: paymentTypeBillingId,
          });
        } else {
          this.smartSet({ stateKey: 'paymentTypeBillingId', data: undefined });
        }
      },
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    ...mapActions('fee', ['smartSet']),

    populateData() {
      Object.keys(this.Form).forEach((key) => {
        this.Form[key] = this.oldForm[key];
      });

      this.Form['status'] = ['active', 'inactive'].includes(
        this.oldForm['status']
      )
        ? false
        : true;
    },
    async getPaymentGroups() {
      const response = await this.request({
        method: 'get',
        url: 'school/paymentAmount/active',
      });
      if (response) {
        this.group_options = response.record.map(
          ({ _id: value, name: label }) => ({ label, value })
        );
      } else {
        this.group_options = [];
      }
    },
    async getPaymentTypes() {
      const response = await this.request({
        method: 'get',
        url: 'school/paymentType',
      });
      if (response) {
        this.payment_type_options = response.record.map(
          ({ _id: value, name: label, ...rest }) => ({ label, value, rest })
        );
      } else {
        this.payment_type_options = [];
      }
    },
  },
  created() {
    this.getPaymentTypes();
    this.getPaymentGroups();
    if (this.isUpdate) this.populateData();
  },
};
</script>

<style></style>
