<template>
  <tippy
    v-if="actions.length > 0"
    placement="right"
    trigger="click"
    theme="light"
    :arrow="arrow"
    animation="scale"
    size="large"
    interactive
    ref="actions-tt"
  >
    <template v-slot:trigger>
      <slot
        ><base-button
          :primary="false"
          :icon="btnO9n === 'horizontal' ? 'ellipsis-h' : 'ellipsis-v'"
          class="text-textDark hover:bg-primary hover:text-white rounded-full p-2 text-sm"
      /></slot>
    </template>

    <div class="py-2 text-left">
      <div
        class="pt-1 pb-2 mb-2 border-b border-brightGray uppercase text-center text-textMedium"
      >
        Actions
      </div>
      <div
        class="py-2 px-4 hover:bg-gray-100 cursor-pointer grow-active text-textDark hover:text-primary grid grid-cols-4 gap-2"
        v-for="(action, i) in actions"
        :key="i"
        @click="smartHandler(action)"
      >
        <div class="flex items-center">
          <icon v-if="action.icon" :name="action.icon" :size="12" />
        </div>
        <div class="col-span-3 text-sm flex items-center capitalize">
          {{ action.label }}
        </div>
      </div>
    </div>
  </tippy>
</template>

<script>
export default {
  props: {
    handlers: { type: Array, default: () => [] },
    item: {},
    arrow: { type: Boolean, default: true },
    btnO9n: { type: String, default: 'horizontal' }, //?btnO9n=btnOrientation
  },
  computed: {
    actions() {
      return this.handlers.filter((handler) =>
        !handler.filter ? true : handler.filter(this.item)
      );
    },
  },
  methods: {
    hideActionDialog() {
      this.$refs['actions-tt'].tip.hide();
    },
    smartHandler(action) {
      this.hideActionDialog();
      if (action?.fullParam) action.handler(this.item);
      else if (action?.idParam) action.handler(this.item._id);
      else action.handler();
    },
  },
};
</script>

<style></style>
