import AySectionList from '@/views/protected/enrollment/academic_section/Index.vue';
import EnrollmentHistory from '@/views/protected/enrollment/enrollment_history/Index.vue';
import TeacherCourse from '@/views/protected/enrollment/teacher_course/Index.vue';
import GradePromotion from '@/views/protected/enrollment/grade_promotion/Index.vue';
import GraduateStudents from '@/views/protected/enrollment/graduate_students/Index.vue';
import StudentId from '../../views/protected/academic/StudentId/Index.vue';

export default [
  {
    path: 'academic-section',
    name: 'academic-section',
    component: AySectionList,
    props: true,
  },
  {
    path: 'enrollment-history',
    name: 'enrollment-history',
    component: EnrollmentHistory,
    props: true,
  },
  {
    path: 'teacher-course',
    name: 'teacher-course',
    component: TeacherCourse,
    props: true,
  },
  {
    path: 'grade-promotion',
    name: 'grade-promotion',
    component: GradePromotion,
    props: true,
  },
  {
    path: 'graduate-students',
    name: 'graduate-students',
    component: GraduateStudents,
    props: true,
  },
  {
    path: 'student_id',
    name: 'student_id',
    component: StudentId,
  },
];
