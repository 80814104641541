<template>
  <div>
    <base-title :title="(update ? 'Update' : 'Create') + ' System Officials'">
      <template #actions>
        <div class="flex space-x-4">
          <base-button :primary="false" icon="arrow-left" label="Go Back" class=" bg-gray-100 text-textDarkest px-3"
            @click="handleGoBack" />
          <base-button icon="plus" label="Save" class=" px-3" @click="update ? handleUpdate() : handleCreate()"
             />
        </div>
      </template>
    </base-title>
    <div class=" mt-2 bg-white p-5">
      <stepper :steps="steps" @finished="update ? handleUpdate() : handleCreate()"
        :finish_label="update ? 'Update' : 'Create'">
        <template #Basic>
          <div class=" grid grid-cols-3 gap-5 my-8">
            <div class=" col-span-3">
              <div class=" w-48 h-56 bg-gray-100 cursor-pointer" @click="handleOpenDialog('profile-input')">
                <input class="" style="display:none;" ref="profile-input"
                  @change="handleSelectFile('profile-input', 'Form.profilePic')" type="file"
                  accept=".png, .jpg, .jpeg, .gif" />
                <img :src="profilePicObj
                  ? profilePicObj
                  : PROFILE_PATH + Form.profilePic
                  " alt="" class=" object-cover w-full h-full" />
              </div>
            </div>
            <base-input label="First Name" placeholder="first name" v-model="Form.firstName" :errors="errors.firstName"
              check />
            <base-input label="Last Name" placeholder="last name" v-model="Form.lastName" :errors="errors.lastName"
              check />
            <base-select label="Gender" placeholder="gender" v-model="Form.gender" :errors="errors.gender"
              :items="GENDER_OPTIONS" check />
            <base-input label="Birth Date" placeholder="birth date" type="date" v-model="Form.birthdate"
              :errors="errors.birthdate" check />
            <base-input label="Address" placeholder="address" v-model="Form.address" :errors="errors.address" check />
            <base-input label="Phone Number" placeholder="phone number" v-model="Form.phoneNumber"
              :errors="errors.phoneNumber" check />
            <base-input label="Email" placeholder="email" v-model="Form.email" :errors="errors.email" check />
            <base-select label="Role" :items="ROLE_OPTIONS" placeholder="email" v-model="Form.role" :errors="errors.role"
              check />
          </div>
        </template>
        <template #More>
          <div>{{ mode }} {{ id }}</div>
        </template>
      </stepper>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import Stepper from '@/components/Stepper.vue';
import { minLength, required, email } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import eventBus from '@/eventBus';
import { PROFILE_PATH } from '@/config/config.js';
import objUrl from '@/mixins/objUrl.js';
export default {
  components: { Stepper },
  props: {
    mode: { type: String, default: 'create' },
    id: { type: String }
  },
  mixins: [validation, objUrl],
  data() {
    return {
      working: false,
      initial: true,
      PROFILE_PATH,
      steps: ['Basic'],
      Form: {
        firstName: '',
        lastName: '',
        gender: '',
        birthdate: '',
        address: '',
        phoneNumber: '',
        email: '',
        profilePic: '',
        role: ''
      },
      profilePicObj: '',
      required_fields: [
        'firstName',
        'lastName',
        'gender',
        'birthdate',
        'address',
        'phoneNumber',
        'email',
        'profilePic',
        'role'
      ],
      errors: {},
      meta: [
        { key: 'Form.firstName', label: 'First name' },
        { key: 'Form.lastName', label: 'Last Name' },
        { key: 'Form.gender', label: 'gender' },
        { key: 'Form.birthdate', label: 'Birth date' },
        { key: 'Form.address', label: 'Address' },
        { key: 'Form.phoneNumber', label: 'Phone number' },
        { key: 'Form.email', label: 'This' },
        { key: 'Form.role', label: 'role' },
      ],
    };
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['firstName', 'lastName', 'gender', 'birthdate', 'address', 'role'].includes(key)) {
            acc[key] = { required }
          } else if (['phoneNumber'].includes(key)) {
            acc[key] = { required, minLength: minLength(10) }
          } else if (['email'].includes(key)) {
            acc[key] = { required, email }
          } else acc[key] = {}
          return acc
        }, {}),
      },
    }
  },
  computed: {
    ...mapState('main', ['error']),
    GENDER_OPTIONS() {
      return [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' }
      ];
    },
    ROLE_OPTIONS() {
      return [
        {
          label: 'Course Author',
          value: 'course_author'
        },
        {
          label: 'Registrar',
          value: 'registrar'
        }
      ];
    },
    update() {
      if (this.id) return true;
      return false;
    }
  },
  methods: {
    ...mapActions('main', ['request', 'upload']),
    handleGoBack() {
      this.$router.back();
    },
    // handleOpenDialog(ref) {
    //   this.$refs[ref].click()
    // },
    // handleSelectFile(ref) {
    //   this.Form.profilePic = this.$refs[ref].files[0]
    // },
    async handleCreate() {
      this.working = false
      // return eventBus.$emit('open-modal', { modalId: 3, componentProps: { user: { password: 'kdhfkd7675' } } })

      this.initial = false;
      if (!this.isValid())
        return

      let formData = new FormData();
      Object.keys(this.Form).forEach(key => {
        formData.append(key, this.Form[key]);
      });

      let response = await this.upload({
        method: 'post',
        url: 'reb/official',
        data: formData
      });
      if (response && !response.error) {
        this.working = true
        eventBus.$emit('open-modal', {
          modalId: 3,
          componentProps: { user: response.record }
        });
        return eventBus.$emit('open-toast', {
          message: 'successfully created author'
        });
      } else {
        this.working = false
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true
        });
      }
      this.initial = true
    },

    populdateForm(data) {

      this.Form = {
        firstName: data.firstName,
        lastName: data.lastName,
        gender: data.gender,
        birthdate: data.birthdate,
        address: data.address,
        phoneNumber: data.phoneNumber,
        email: data.email,
        profilePic: data.profilePic,
        role: data.role
      };
    },

    async handleFetchUser() {
      let response = await this.request({
        method: 'get',
        url: 'reb/official/' + this.id
      });
      if (response && !response.error) {
        this.populdateForm(response.record[0]);
      }
    },

    async handleUpdate() {
      this.working = true

      this.initial = false;
      if (!this.profilePicObj) delete this.Form.profilePic;
      if (!this.isValid())
        return eventBus.$emit('open-toast', {
          message: this.error,
          error: true
        });
      delete this.Form.profilePic;

      let formData = new FormData();
      Object.keys(this.Form).forEach(key => {
        formData.append(key, this.Form[key]);
      });

      let response = await this.upload({
        method: 'put',
        url: 'reb/official/' + this.id,
        data: formData
      });
      if (response && !response.error) {
        this.working = false
        return eventBus.$emit('open-toast', {
          message: 'successfully updated official'
        });
      } else {
        this.working = false
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true
        });
      }

    }
  },
  created() {
    this.meta.forEach(key => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid()
        }
      })
    })

    if (this.update) this.handleFetchUser();
  }
};
</script>

<style></style>
