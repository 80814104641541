<template>
  <div>
    <div v-if="code == undefined || authCodes.includes(code)" class="space-y-4">
      <div class="flex space-x-2 justify-between items-center">
        <div class="flex items-center gap-2">
          <div v-if="title || letCreate" class="flex space-x-2 items-center">
            <div class="text-textDarkest">{{ title }}</div>
            <div
              v-if="letCreate"
              class="p-2 hover:bg-primary hover:text-white cursor-pointer rounded-full lg-grow-active"
              @click="createItem"
            >
              <icon name="search" :size="14" />
            </div>
          </div>

          <div v-if="!hidePagination" class="flex space-x-1 items-center">
            <div>Show:</div>
            <base-select
              notBordered
              :items="pageSizeOptions"
              v-model="Form.pageSize"
              padding="small"
              background="transparent"
            >
              <template #default="{ item }">
                <div>{{ item.label }}</div>
              </template>
              <template #option="{ items }">
                <div v-for="item in items" :key="item.value">
                  {{ item.label }}
                </div>
              </template>
            </base-select>
          </div>
          <div v-if="!hidePagination" class="flex items-center space-x-3">
            <div>{{ beginning }} - {{ ending }} of {{ totalItems }}</div>
            <div class="flex items-center">
              <div
                class="p-2 hover:bg-primary hover:text-white cursor-pointer rounded-full lg-grow-active"
                @click="goPrevious"
              >
                <icon name="chevron-left" :size="12" />
              </div>
              <div
                class="p-2 hover:bg-primary hover:text-white cursor-pointer rounded-full lg-grow-active"
                @click="goNext"
              >
                <icon name="chevron-right" :size="12" />
              </div>
            </div>
          </div>
          <div v-if="multiSelect && rowsSelected.length">
            {{ rowsSelected.length }} Selected
          </div>
          <div class="flex-auto">
            <slot name="more-head-display"></slot>
          </div>
        </div>

        <div v-if="!hideSearch" class="flex items-center">
          <input
            type="text"
            v-model="Form.searchValue"
            @input="debounceSearch"
            class="bg-gray-50 border-1 border-gray-200 focus:bg-white rounded-full py-1 w-full px-4 outline-none"
            placeholder="Search..."
          />
          <div class="cursor-pointer -ml-6">
            <icon
              name="search"
              class="text-textDark transform rotate-90"
              :size="12"
            ></icon>
          </div>
        </div>

        <div class="flex items-center gap-2">
          <div
            v-if="!hideFilter"
            class="p-2 bg-gray-100 text-textDark rounded-full hover:bg-primary hover:text-white cursor-pointer"
            title="Filter"
          >
            <icon name="filter" class="" :size="12"></icon>
          </div>

          <div v-if="buttons" class="flex space-x-4">
            <base-button
              v-for="(button, index) in buttons"
              :key="index"
              :icon="button.icon"
              :label="button.label"
              @click="addItem(button.value)"
              :primary="false"
              class="bg-gray-100 flex-shrink-0"
            />
          </div>

          <div v-if="!hideSort" class="flex space-x-6 items-center">
            <base-select
              notBordered
              :items="sortOptions"
              v-model="newestFirst"
              padding="small"
              background="#fff"
            >
              <template #default="{ item }">
                <div class="flex space-x-1 items-center">
                  <div class="text-textLight">Sort by:</div>
                  <div>{{ item.label }}</div>
                </div>
              </template>
            </base-select>
          </div>
        </div>
      </div>
      <table class="w-full">
        <thead class="">
          <tr>
            <td
              v-if="multiSelect"
              class="bg-thead rounded-tl-lg cursor-pointer"
              @click="toggleAll"
            >
              <div class="flex items-center p-2 pr-0">
                <div
                  class="w-4 h-4 text-white rounded-full flex justify-center items-center border-1 border-gray-400"
                  :class="allSelected ? 'bg-primary border-primary' : ''"
                >
                  <icon v-if="allSelected" name="check" :size="7" />
                </div>
              </div>
            </td>
            <td
              v-for="(header, i) in headers"
              :key="header.key"
              class="py-1 pl-2 bg-thead uppercase text-textLight text-xs font-semibold cursor-pointer hover:text-textDarkest"
              :class="{
                'rounded-tl-lg pl-2': i === 0,
                'rounded-tr-lg': hasActions && i === headers.length - 1,
              }"
            >
              <div
                class="flex items-center gap-1"
                @click="sortColumn(header.key)"
              >
                {{ header.label }}
                <icon v-if="!hideColumnSort" name="sort" :size="10" />
              </div>
            </td>
            <td
              v-if="hasActions"
              class="py-1 pl-2 bg-thead uppercase text-center text-textLight text-xs font-semibold rounded-tr-lg"
            >
              Actions
            </td>
          </tr>
        </thead>
        <tbody v-if="loader" class="bg-white">
          <tr>
            <td :colspan="headers.length + 1" class="p-6">
              <loader type="table" :amount="6"></loader>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="items.length > 0" class="bg-white">
          <tr
            v-for="(item, index) in itemsDisplayed"
            :key="item._id"
            class="hover:bg-gray-100 text-sm text-textDark"
            :class="{
              'bg-gray-50': index % 2 === 0,
              'cursor-pointer': multiSelect,
            }"
          >
            <td v-if="multiSelect" class="pt-1">
              <div
                @click="toggleRow(item._id)"
                class="flex items-center p-2 pr-0"
              >
                <div
                  class="w-4 h-4 rounded-full text-white flex justify-center items-center border-1 border-gray-400"
                  :class="
                    rowsSelected.includes(item._id)
                      ? 'bg-primary border-primary'
                      : ''
                  "
                >
                  <icon
                    v-if="rowsSelected.includes(item._id)"
                    name="check"
                    :size="7"
                  />
                </div>
              </div>
            </td>
            <slot :item="item" :index="index">
              <td v-for="(header, i) in headers" :key="header.key" class="py-1">
                <div class="py-1" :class="{ 'pl-2': i === 0 }">
                  {{ getProp(item, header.key) }}
                </div>
              </td>
            </slot>
            <td v-if="hasActions" class="p-2">
              <div class="flex justify-center items-center">
                <actions :handlers="handlers" :item="item" btnO9n="vertical" />
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="headers.length">
              <base-no-data v-if="noDataMessage" :message="noDataMessage" />

              <base-no-data v-else message="no data" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div v-else class="text-error uppercase">
      You don't have privilege to see this!
    </div> -->
  </div>
</template>

<script>
import { getPropByString } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import Actions from '../card/Actions.vue';
import eventBus from '../../eventBus';
import { stringifyQueryObj } from '@/utils';
export default {
  components: { Actions },
  props: [
    'items',
    'totalItems',
    'headers',
    'title',
    'hasActions',
    'handlers',
    'letCreate',
    'loading',
    'hideSort',
    'pageRequest',
    'hideFilter',
    'hidePagination',
    'hideSearch',
    'buttons',
    'searchProps',
    'code',
    'multiSelect',
    'sortUrl',
    'hideColumnSort',
    'noDataMessage',
  ],
  data() {
    return {
      rowsSelected: [],
      newestFirst: true,
      isLoading: false,
      ascending: true,
      selectedColumn: '',
      Form: {
        searchValue: '',
        pageSize: 10,
        pageNumber: 0,
        sort: '',
      },
      pageSizeOptions: [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '15', value: 15 },
        { label: '20', value: 20 },
        { label: '25', value: 25 },
      ],
      sortOptions: [
        { label: 'Latest', value: false },
        { label: 'Oldest', value: true },
      ],
      debounce: null,
    };
  },
  computed: {
    ...mapGetters(['authCodes']),
    loader() {
      return this.isLoading || this.loading;
    },
    beginning() {
      return this.Form.pageNumber * this.Form.pageSize + 1;
    },
    ending() {
      let ending = this.beginning + this.Form.pageSize - 1;
      if (ending > this.totalItems) return this.totalItems;
      return ending;
    },
    itemsDisplayed() {
      if (this.pageRequest) {
        return this.items;
      } else {
        return this.filteredItems.slice(this.beginning - 1, this.ending);
      }
    },
    allSelected() {
      return this.items.length === this.rowsSelected.length;
    },
    hasSelected() {
      return this.rowsSelected.length > 0;
    },
    filteredItems() {
      const searchValue = this.Form.searchValue || '';
      const regex = new RegExp(searchValue, 'i');
      return this.items.filter((item) =>
        regex.test(this.getNestedValue(item, 'student.firstName') || '')
      );
    },
  },
  watch: {
    newestFirst() {
      this.sortColumn('createdAt');
    },
    'Form.pageSize': {
      deep: true,
      immediate: true,
      handler: function () {
        this.Form.pageNumber = 0;
        if (this.pageRequest) {
          this.syncTable();
        }
      },
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,

    toggleAll() {
      if (this.allSelected) {
        this.rowsSelected = [];
        this.$emit('selected', this.rowsSelected);
        this.$emit('selectedItems', []);
        return [];
      }
      this.rowsSelected = this.items.map(({ _id }) => _id);
      this.$emit('selectedItems', this.items);
      this.$emit('selectedItemIDs', this.rowsSelected);
    },

    toggleRow(id) {
      if (!this.multiSelect) return;
      if (this.rowsSelected.includes(id)) {
        this.$emit(
          'selected',
          (this.rowsSelected = this.rowsSelected.filter((row) => row !== id))
        );
        this.$emit(
          'selectedItems',
          this.items.filter((v) => this.rowsSelected.includes(v._id))
        );
        return (this.rowsSelected = this.rowsSelected.filter(
          (row) => row !== id
        ));
      }
      this.rowsSelected.push(id);
      this.$emit('selected', this.rowsSelected);
      let selectedItems = this.items.filter((v) =>
        this.rowsSelected.includes(v._id)
      );
      this.$emit('selectedItems', selectedItems);
      this.$emit('selectedItemIDs', this.rowsSelected);
    },

    goPrevious() {
      let beginningPageReached = this.beginning === 1;
      if (beginningPageReached) return;
      this.Form.pageNumber--;
      if (this.pageRequest) {
        this.syncTable();
      }
    },

    goNext() {
      let endOfPageReached =
        this.totalItems - this.beginning < this.Form.pageSize;
      if (endOfPageReached) return;
      this.Form.pageNumber++;
      if (this.pageRequest) {
        this.syncTable();
      }
    },

    async sortColumn(column) {
      let objectPath = column.split('.');
      if (objectPath.length > 1) {
        column = objectPath[objectPath.length - 1];
      }
      if (this.hideColumnSort) {
        return;
      } else {
        if (column === 'profilePic') {
          column = 'firstName';
        }
        this.Form.sort =
          this.selectedColumn != column
            ? column
            : this.ascending
            ? column
            : `-${column}`;
        if (this.selectedColumn != column) {
          this.ascending = false;
        } else {
          this.ascending = !this.ascending;
        }
        this.selectedColumn = column;
      }
      if (this.pageRequest) {
        this.syncTable();
      } else {
        this.sortLocalItems(this.Form.sort);
      }
    },

    debounceSearch() {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        if (this.pageRequest) {
          this.syncTable();
        }
      }, 500);
    },

    getNestedValue(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    },

    async syncTable() {
      let string_query = stringifyQueryObj({
        page: this.Form.pageNumber + 1,
        limit: this.Form.pageSize,
        keyword: this.Form.searchValue,
        sort: this.Form.sort,
        ...this.searchProps.queries,
      });

      let url = this.searchProps.searchId
        ? this.searchProps.searchUrl + this.searchProps.searchId
        : this.searchProps.searchUrl;

      url += string_query;
      this.isLoading = true;
      let response = await this.request({
        method: 'get',
        url,
      });
      if (response && !response.error) {
        this.items = response.items;
      } else {
        this.items = [];
      }
      this.isLoading = false;
    },

    sortLocalItems(col) {
      if (col.startsWith('-')) {
        col = col.split('-')[1];
        this.items.sort((a, b) => a.student[col].localeCompare(b.student[col]));
      } else {
        this.items.sort((a, b) => b.student[col].localeCompare(a.student[col]));
      }
    },

    createItem() {
      this.$emit('create');
    },

    addItem(button) {
      this.$emit(button);
    },
  },

  created() {
    eventBus.$on('emptySelected', async () => {
      this.rowsSelected = [];
    });
    this.sortLocalItems('-.createdAt');
  },
};
</script>

<style>
.dark-gray {
  background-color: #8f92a1;
}
</style>
