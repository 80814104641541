import LabOverview from '@/views/protected/lab_management/Overview.vue';
import CategoriesList from '@/views/protected/lab_management/CategoriesList.vue';
import SimulationList from '@/views/protected/lab_management/SimulationList.vue';
export default [{
        path: 'overview',
        name: 'lab-overview',
        component: LabOverview
    },
    {
        path: 'categories-list',
        name: 'lab-categories-list',
        component: CategoriesList
    },
    {
        path: 'simulation-list',
        name: 'lab-books-list',
        component: SimulationList
    },

]