<template>
  <div class="flex flex-col space-y-2 bg-white p-4">
    <div class="text-lg font-semibold text-textLight capitalize">
      placeholders
    </div>
    <div class="capitalize flex flex-wrap space-x-3 justify-start">
      <base-button
        v-for="({ label, placeholder }, index) in interpoles"
        :label="label"
        :key="index"
        @click="insertInterpole(placeholder)"
        class="px-2"
      >
      </base-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      interpoles: [
        {
          label: 'student name',
          placeholder: 'full name'
        },
        {
          label: 'bill amount',
          placeholder: 'amount'
        }
      ]
    };
  },
  computed: {
  },

  methods: {
    insertInterpole(placeholder) {
      this.$emit('insert-interpole', placeholder);
    }
  },

  mounted() {},

  created() {}
};
</script>
