<template>
  <div>
    <base-title title="Manage Roles">
      <template #actions>
        <div class="flex">
          <base-button
            icon="plus"
            label="Add Role"
            :isVisible="permission.add"
            class="px-3"
            @click="handleCreateNew"
            code="rl-add"
          />
        </div>
      </template>
    </base-title>

    <div class="bg-white p-1 px-4 mt-2 rounded-default">
      <div>
        <d-table
          #default="{ item }"
          :headers="headers"
          :items="items"
          :hasActions="true"
          :handlers="handlers"
          code="rl-view"
          :hideColumnSort="true"
        >
          <td v-for="(header, i) in headers" :key="i">
            <div class="px-2">
              <div v-if="header.key === 'status'">
                <status-chip
                  :label="item[header.key]"
                  style="max-width: fit-content"
                />
              </div>
              <div class="px-2" v-else>
                {{ getProp(item, header.key) }}
              </div>
            </div>
          </td>
        </d-table>
      </div>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import eventBus from '../../../eventBus';
import { mapActions, mapGetters, mapState } from 'vuex';
import { static_roles } from '@/config/constants';

export default {
  components: { DTable },
  data() {
    return {
      items: [],
      module: 'role',
      permission: {},
      headers: [
        { label: 'Role', key: 'name' },
        { label: 'Description', key: 'description' },
        { label: 'Status', key: 'status' },
        // { label: '', key: '' },
        // { label: 'Label Five', key: '' },
      ],
      handlers: [
        {
          label: 'Edit',
          name: 'update',
          icon: 'pencil-alt',
          handler: this.handleEdit,
          fullParam: true,
          filter: () => this.authCodes.includes('rl-edit'),
        },
        {
          label: 'Delete',
          name: 'delete',
          icon: 'trash-alt',
          handler: this.handleDelete,
          idParam: true,
          filter: () => this.authCodes.includes('rl-rm'),
        },
        // {
        //   label: 'Permission',
        //   name: 'Permission',
        //   icon: 'shield-alt',
        //   handler: this.handlePermission,
        //   fullParam: true,
        // },
      ],
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
    ...mapState('main', ['error']),
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,
    handleDelete(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the item',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },
    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'role/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'deleted successfully' });
        this.fetchRoles();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    handleEdit(item) {
      let componentProps = { oldForm: item };
      eventBus.$emit('open-modal', {
        modalId: 27,
        componentProps,
        cb: this.fetchRoles,
        cbParams: { item },
      });
    },
    handlePermission(item) {
      this.$router.push(`roles/permission/${item._id}/${item.name}`);
    },
    //   handleDetail(id) {
    //     this.$router.push({ name: 'detail-course-autor', params: { id } });
    //   },
    handleCreateNew() {
      let componentProps = {
        school_id: this.selectedSchoolId,
      };
      eventBus.$emit('open-modal', {
        modalId: 27,
        componentProps,
        cb: this.fetchRoles,
      });
    },

    async fetchRoles() {
      let response = await this.request({
        method: 'get',
        url: 'role/my-roles',
      });
      if (response && !response.error) this.items = response.record;
      else this.items = [];
    },
    checkStatic() {
      const role = this.user.role;
      if (!static_roles.includes(role)) {
        this.isSchoolOfficial = true;
        const permissions = this.user.permissions;
        let permission = permissions.find((p) => p.module.name === this.module);
        this.permission = permission;
        this.handlers = this.handlers.filter(
          (action) => permission[action.name]
        );
      }
    },
  },

  created() {
    this.fetchRoles();
  },
};
</script>

<style></style>
