var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full space-y-1"},[_vm._t("label",function(){return [(_vm.label)?_c('p',[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required && !_vm.success)?_c('span',{staticClass:"text-error"},[_vm._v(" *")]):_vm._e()]):_vm._e()]},{"label":_vm.label}),_c('div',{staticClass:"flex flex-wrap leading-tight bg-gray-50 border-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2 gap-1",class:{
      'bg-gray-50': _vm.success,
      'border-error bg-red-50 focus:border-rose focus:ring-1 focus:ring-rose focus:outline-none':
        !_vm.success && _vm.check,
      'rounded-default': !_vm.rounded,
      'rounded-none': _vm.rounded,
    }},[_vm._l((_vm.items),function(number,index){return _c('div',{key:index,staticClass:"border-1 border-gray-200 p-1 px-2 rounded-full"},[_c('div',{staticClass:"flex gap-1 items-center justify-center"},[_vm._v(" "+_vm._s(number)+" "),_c('div',{staticClass:"bg-primary text-white p-1 rounded-full flex items-center justify-center cursor-pointer"},[_c('icon',{attrs:{"size":10,"name":"times"},on:{"click":function($event){return _vm.removeItem(number)}}})],1)])])}),_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.itemText),expression:"itemText"}],staticClass:"bg-gray-50 w-full outline-none p-1",class:{
        'bg-gray-50 border-textLight': _vm.success,
        'border-error bg-red-50 placeholder-red-900': !_vm.success && _vm.check,
      },attrs:{"type":"tel","maxlength":"10","placeholder":!_vm.success ? _vm.placeholder : ''},domProps:{"value":(_vm.itemText)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addItem.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.popItem.apply(null, arguments)}],"blur":_vm.addItem,"input":function($event){if($event.target.composing)return;_vm.itemText=$event.target.value}}},'input',_vm.$attrs,false)),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formattedItems),expression:"formattedItems"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.formattedItems)},on:{"input":function($event){if($event.target.composing)return;_vm.formattedItems=$event.target.value}}})],2),(!_vm.success && _vm.check)?_c('div',{staticClass:"text-xs",staticStyle:{"color":"#ec3118"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i},[_vm._v(_vm._s(error))])}),0):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }