<template>
  <div
    v-if="open"
    class="toast bg-white p-3 px-4 fixed rounded-lg space-y-2"
    style="width: 28vw; box-shadow: 0 0 60px -20px #000"
  >
    <div class="flex items-center justify-between">
      <div
        v-if="toastErr"
        class="text-error font-bold text-sm uppercase flex items-center space-x-2"
      >
        <div>
          <icon name="exclamation-circle" :size="16" />
        </div>
        <div>Error</div>
      </div>
      <div
        v-else-if="warning"
        class="text-orange font-bold text-sm uppercase flex items-center space-x-2"
      >
        <div>
          <icon name="exclamation-circle" :size="16" />
        </div>
        <div>Warning</div>
      </div>
      <div
        v-else
        class="text-success font-bold text-sm uppercase flex items-center space-x-2"
      >
        <div>
          <icon name="check-circle" :size="16" />
        </div>
        <div>Success</div>
      </div>
      <div
        class="inline-block py-1 cursor-pointer text-textMedium hover:text-primary grow-active"
        @click="handleClose"
      >
        <icon name="times" />
      </div>
    </div>
    <div class="text-sm text-textMedium">
      {{ message }}
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus.js';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      open: false,
      message: 'No message',
      toastErr: false,
      warning: false,
    };
  },
  methods: {
    handleClose() {
      this.open = false;
    },
    closeLater(timeout) {
      setTimeout(() => {
        this.handleClose();
      }, timeout * 1000);
    },
  },
  computed: {
    ...mapState('main', ['error']),
  },
  created() {
    eventBus.$on(
      'open-toast',
      ({ message, timeout = 4, error = false, warning = false }) => {
        this.message = message || this.error;
        this.toastErr = error;
        this.warning = warning;
        this.open = true;
        this.closeLater(timeout);
      }
    );
  },
};
</script>

<style>
.toast {
  left: 1rem;
  bottom: 6rem;
  z-index: 10010;
  animation: shakeToast 1.3s;
}
@keyframes shakeToast {
  0% {
    transform: translate(0, 10%);
  }
  25% {
    transform: translate(0, -15%);
  }
  50% {
    transform: translate(0, 5%);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
