<template>
  <div>
    <Base-Title :title="(isUpdate ? 'Update' : 'Create New') + ' School'">
      <template #actions>
        <base-button
          label="BACK"
          class="bg-gray-100 text-textDarkest px-3"
          icon="arrow-left"
          @click="handleGoBack"
        />
      </template>
    </Base-Title>
    <div class="bg-white p-5 mt-1">
      <stepper
        :steps="['Basic', 'Photo']"
        @finished="handleFinished"
        :finish_label="isUpdate ? 'Update' : 'Create'"
      >
        <template #Basic>
          <div class="grid grid-cols-3 gap-5 my-10">
            <base-input
              label="School Name"
              placeholder="School Name"
              v-model="Form.name"
              :errors="errors.name"
            />
            <base-input
              label="School Code"
              placeholder="School Code"
              v-model="Form.code"
              :errors="errors.code"
            />
            <base-input
              label="Description"
              placeholder="description"
              v-model="Form.description"
              :errors="errors.description"
            />
            <base-input
              label="Region"
              placeholder="region"
              v-model="Form.region"
              :errors="errors.region"
            />
            <base-input
              label="City"
              placeholder="city"
              v-model="Form.city"
              :errors="errors.city"
            />
            <base-input
              label="Address"
              placeholder="address"
              v-model="Form.address"
              :errors="errors.address"
            />
            <base-input
              label="Phone Number"
              placeholder="phone number"
              v-model="Form.phoneNumber"
              :errors="errors.phoneNumber"
            />
            <base-input
              label="Email"
              placeholder="email"
              v-model="Form.email"
              :errors="errors.email"
            />
            <base-multi-select
              label="Grade Level"
              placeholder="grade level"
              v-model="Form.level"
              :items="LEVEL_OPTIONS"
            />
            <base-input
              label="Moto"
              placeholder="moto"
              v-model="Form.moto"
              :errors="errors.moto"
            />
            <div
              class="bg-gray-100 p-2 flex space-x-3 items-center px-3 cursor-pointer"
              @click="handleOpenDialog('icon-file-input')"
            >
              <input
                type="file"
                ref="icon-file-input"
                @change="handleSelectFile('icon-file-input', 'Form.logo')"
                accept=".png, .jpg, .jpeg, .gif"
                style="display: none"
              />
              <icon
                v-if="!logoObj && !Form.icon"
                name="image"
                class="text-primary"
                :size="20"
              />
              <div v-else class="w-5 h-5">
                <img
                  :src="logoObj || LOGO_PATH + Form.icon"
                  v-viewer="{ inline: false, movable: true }"
                  class="w-full h-full object-cover"
                  alt=""
                />
              </div>
              <p v-if="!logoObj && !Form.icon">Upload logo</p>
              <p v-else>{{ logoObj ? Form.logo.name : Form.logo }}</p>
            </div>
          </div>
        </template>
        <template #Photo>
          <div v-if="Form.photo && Form.photo.length" class="grid grid-cols-4">
            <div v-for="(photo, i) in Form.photo" :key="i">
              {{ photo }}
            </div>
          </div>
          <div v-else>
            <div class="flex justify-center items-center">
              <div class="flex my-10">
                <base-button
                  label="Upload"
                  @click="handleUploadPhoto"
                  class="px-3"
                />
                <input type="file" multiple style="display: none" />
              </div>
            </div>
          </div>
        </template>
      </stepper>
    </div>
  </div>
</template>

<script>
import Stepper from '@/components/Stepper.vue';
import objUrl from '@/mixins/objUrl';
import eventBus from '../../../eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
export default {
  components: { Stepper },
  mixins: [objUrl],
  props: ['id'],
  data() {
    return {
      errors: {},
      logoObj: '',
      Form: {
        name: '',
        code: '',
        region: '',
        city: '',
        address: '',
        phoneNumber: '',
        email: '',
        level: '', /// Elementary
        moto: '',
        logo: '',
        description: '',
        // photo: [] /// list of images
      },
    };
  },

  computed: {
    ...mapState('main', ['error']),
    LEVEL_OPTIONS() {
      return [
        { label: 'KG', value: 'kg' },
        { label: 'Elementary', value: 'elementary' },
        { label: 'High School', value: 'highschool' },
        { label: 'Preparatory', value: 'preparatory' },
      ];
    },

    isUpdate() {
      if (this.id) return true;
      else return false;
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    handleUploadPhoto() {},
    handleGoBack() {
      this.$router.back();
    },
    async handleFinished() {
      try {
        let formData = new FormData();
        Object.keys(this.Form).forEach((key) => {
          if ((key === 'logo' && this.logoObj) || key !== 'logo') {
            if (key === 'level')
              formData.append('level', JSON.stringify(this.Form['level']));
            else formData.append(key, this.Form[key]);
          }
        });

        let response = await this.request({
          method: this.isUpdate ? 'put' : 'post',
          url: 'school/schoolProfile' + (this.isUpdate ? '/' + this.id : ''),
          data: formData,
        });
        if (response && !response.error) {
          eventBus.$emit('open-toast', {
            message: 'school created successfully',
          });

          this.$router.push({ name: 'school-list' });
        } else {
          eventBus.$emit('open-toast', {
            message: this.error,
            error: true,
          });
        }
      } catch (error) {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    async handleFetchSchool() {
      let response = await this.request({
        method: 'get',
        url: 'school/schoolProfile/' + this.id,
      });
      if (response && !response.error) {
        this.populdateForm(response.record[0]);
      }
    },

    populdateForm(record) {
      Object.keys(this.Form).forEach((key) => (this.Form[key] = record[key]));
    },
  },
  mounted() {
    if (this.isUpdate) this.handleFetchSchool();
  },
};
</script>

<style></style>
