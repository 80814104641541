<template>
  <div class=" bg-white z-10 relative shadow-lg " style="min-height: 430px;">
    <!-- cover view -->
    <div
      class=" flex z-0 absolute top-0 bottom-40 right-0 left-0 cursor-pointer"
      style="height: 280px"
      @click="handleClick"
    >
      <img
        :src="IMAGES_PATH + channel.coverImage"
        alt=""
        class=" object-cover w-full h-full"
      />
    </div>
    <!-- skew -->
    <div
      class=" bg-white z-10 transform -skew-y-6  absolute  right-0 left-0 cursor-pointer "
      style="top: 144px; bottom: 24px"
      @click="handleClick"
    ></div>
    <!-- detail view -->
    <div
      class="  z-20 absolute  bottom-0 right-0 left-0 flex flex-col  "
      style="top: 160px;"
    >
      <div
        class="px-4 py-2 flex-grow flex flex-col cursor-pointer"
        @click="handleClick"
      >
        <p class=" text-lg font-semibold">{{ channel.name }}</p>
        <div class=" grid grid-cols-2 my-auto gap-3">
          <div v-for="i in 4" :key="i">
            <div class="flex space-x-2 items-center">
              <icon name="library" class=" text-secondary" />
              <p>Aug 12, 2021</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class=" w-full bg-gray-100 py-2 px-4 flex justify-between items-center"
      >
        <div>
          <p>
            <span class=" text-lg font-semibold">{{ channel.no_courses }}</span>
            Courses
          </p>
          <div class="flex space-x-2">
            <icon
              name="star-hallow"
              v-for="i in 5"
              :key="i"
              class=" text-orange"
              @click="handleClick"
            />
          </div>
        </div>
        <div>
          <base-button
            label="DETAIL"
            class=" rounded-full shadow-xl py-0"
            @click="handleClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IMAGES_PATH } from '@/config/config.js';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import eventBus from '@/eventBus.js';

export default {
  props: {
    channel: {
      required: true
    }
  },
  data() {
    return {
      IMAGES_PATH
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClick() {
      this.$emit('click');
    },

    handleEditChannel() {
      let componentProps = {
        channel: this.channel
      };
      eventBus.$emit('open-modal', {
        modalId: 3,
        componentProps,
        cb: this.onUpdate
      });
    },

    onUpdate() {
      this.$emit('updated');
    },

    computed: {
      ...mapState('main', ['error']),
    },

    async handleDeleteChannel() {
      let response = await this.request({
        method: 'delete',
        url: 'reb/channel/' + this.channel._id
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'channel deleted successfully'
        });
        this.$emit('deleted');
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true
        });
      }
    }
  }
};
</script>

<style></style>
