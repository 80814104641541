<template>
  <div>
    <base-title title="Payment Groups">
      <template #actions>
        <div class="flex">
          <base-button
            icon="plus"
            label="Add New"
            class="px-3"
            @click="handleCreateNew"
            code="pg-add"
          />
        </div>
      </template>
    </base-title>
    <div class="bg-white p-5 mt-2 rounded-default">
      <d-table
        #default="{ item }"
        :headers="headers"
        :items="items"
        :totalItems="items.length"
        :hasActions="true"
        :handlers="handlers"
        :loading="loading"
        code="pg-view"
        :hideColumnSort="true"
      >
        <td v-for="(header, i) in headers" :key="i">
          <div v-if="header.key === 'name'">
            <p class="text font-semibold text-textDarkest uppercase">
              {{ item[header.key] }} - {{ item['payment_type']['name'] }}
            </p>
          </div>
          <div v-else-if="header.key === 'class'" class="flex space-x-3">
            <base-list :items="item.class"> </base-list>
          </div>
          <div v-else-if="header.key === 'status'">
            <status-chip
              :label="item[header.key]"
              style="max-width: fit-content"
            />
          </div>
          <div v-else>
            {{ getProp(item, header.key) }}
          </div>
        </td>
      </d-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DTable from '@/components/table/DTable.vue';
import { getPropByString as getProp } from '@/utils';
import eventBus from '@/eventBus.js';
export default {
  components: {
    DTable,
  },
  data() {
    return {
      handlers: [
        {
          label: 'Edit',
          icon: 'pencil-alt',
          handler: this.handleEdit,
          fullParam: true,
          filter: () => this.authCodes.includes('pg-edit'),
        },
        {
          label: 'Delete',
          icon: 'trash-alt',
          handler: this.handleDelete,
          idParam: true,
          filter: () => this.authCodes.includes('pg-rm'),
        },
      ],
      items: [],
      loading: false,
      headers: [
        { label: 'Name', key: 'name' },
        { label: 'payment type', key: 'payment_type.name' },
        { label: 'class', key: 'class' },
        { label: 'Amount', key: 'amount' },
        { label: 'Status', key: 'status' },
      ],
    };
  },
  computed: {
    ...mapGetters(['authCodes']),
  },
  methods: {
    ...mapActions('main', ['request']),
    handleCreateNew() {
      eventBus.$emit('open-modal', {
        modalId: 29,
        cb: this.fetchDocumentsList,
      });
    },

    getProp,

    async fetchDocumentsList() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'school/paymentAmount',
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
      this.loading = false;
    },

    async handleEdit(item) {
      // item.payment_type = item.payment_type._id;
      // item.class = item.class.map(({ _id }) => _id);
      // let componentProps = {
      //   oldForm: item,
      // };
      // item.payment_type = item.payment_type._id;
      // item.group = item.group._id;
      let componentProps = {
        oldForm: {
          name: item.name,
          _id: item._id,
          status: item.status,
          amount: item.amount,
          class: item.class.map(({ _id }) => _id),
          payment_type: item.payment_type._id,
        },
      };
      eventBus.$emit('open-modal', {
        modalId: 29,
        componentProps,
        cb: this.fetchDocumentsList,
      });
    },

    handleChangeStatus(item) {
      let componentProps = {
        id: item._id,
        status: item.status,
      };
      eventBus.$emit('open-modal', {
        modalId: 6,
        componentProps,
        cb: this.fetchDocumentsList,
      });
    },

    handleDelete(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the payment group',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },
    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/paymentAmount/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'deleted successfully' });
        this.fetchDocumentsList();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },

  created() {
    this.fetchDocumentsList();
  },
};
</script>

<style></style>
