<template>
  <div class="w-full">
    <loader type="dashboard" v-if="working"></loader>
    <div v-else>
      <div v-if="authCodes.includes('da-view')" class="w-11/12 mx-auto">
        <div>
          <p class="text-lg text-textDarkest mt-4 mb-6">Dashboard</p>
        </div>
        <div class="grid grid-cols-4 gap-4">
          <!-- left div -->
          <div class="col-span-3">
            <!-- cards -->
            <div class="grid lg:grid-cols-4 grid-cols-2 gap-x-4 gap-y-6 z-20">
              <router-link
                v-for="(card, i) in top_cards"
                :key="i"
                :to="cards[card.title].path"
                class="rounded-lg flex bg-white p-5 shadow-lg relative z-10 cursor-pointer"
                :style="{
                  background: `linear-gradient(45deg, ${
                    cards[card.title].color
                  })`,
                }"
              >
                <div class="rounded-md flex">
                  <div class="mx-3 my-2">
                    <icon
                      fill="white"
                      :name="cards[card.title].type"
                      :size="50"
                    />
                  </div>
                  <div class="text-white">
                    <div>
                      <p class="text-sm opacity-75">
                        {{ cards[card.title].title }}
                      </p>
                    </div>
                    <div>
                      <p class="font-semibold text-lg">{{ card.total_item }}</p>
                    </div>
                    <!-- <div>
                    <p class="text-sm opacity-80">
                      Total user operations <b>$30.0</b>
                    </p>
                  </div> -->
                  </div>
                </div>
              </router-link>
            </div>

            <!-- graph -->
            <div class="py-5">
              <div class="bg-white w-full mt-2 p-5">
                <div>Users Activity</div>
                <apexchart
                  width="100%"
                  height="350"
                  type="line"
                  :options="chartOneOptions"
                  :series="seriesOne"
                ></apexchart>
              </div>
            </div>
          </div>

          <!-- right div -->
          <div class="">
            <!-- calendar -->
            <div class="w-full max-w-full border-none">
              <v-calendar
                :style="{
                  border: 'none',
                  width: '100%',
                }"
                :attributes="attributes"
                :weekday-format="(day) => day.substr(0, 3)"
              ></v-calendar>
            </div>

            <!-- schedule -->
            <!-- <div>
              <p class="p-2">Schedules</p>
              <div class="px-4" v-for="(schedule, i) in schedules" :key="i">
                <div class="mt-2 rounded-md w-full flex h-20 shadow-lg">
                  <div class="mx-3 my-auto">
                    <icon fill="gray" :name="schedule.type" :size="60" />
                  </div>
                  <div class="my-auto">
                    <p class="text-lg font-semibold">{{ schedule.title }}</p>
                    <p class="text-red-900">{{ schedule.date }}</p>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- message -->
            <div class="">
              <p class="p-2">Messages</p>

              <div v-if="MESSAGES.length > 0">
                <div class="px-4" v-for="(message, i) in MESSAGES" :key="i">
                  <div class="mt-2 rounded-md w-full flex h-20 shadow-sm">
                    <div class="w-1/4 mx-3 my-auto relative">
                      <div
                        class="w-16 h-16 rounded-full bg-gray-100 flex justify-center items-center"
                      >
                        <img
                          v-if="message.profile"
                          :src="PROFILE_PATH + message.profile"
                          v-viewer="{ inline: false, movable: true }"
                          class="w-full h-full object-cover rounded-full"
                          alt=""
                          crossorigin="anonymous"
                        />
                        <icon
                          v-else
                          :size="18"
                          name="user"
                          class="w-full h-full"
                        ></icon>
                      </div>
                      <div
                        v-if="message.active"
                        class="h-3 w-3 rounded-full bg-success absolute bottom-0 right-1"
                      ></div>
                    </div>
                    <div class="my-auto w-full">
                      <div class="flex justify-between">
                        <p class="text-lg font-semibold">{{ message.name }}</p>
                        <p class="text-xs font-semibold pr-2">
                          {{ message.date }}
                        </p>
                      </div>

                      <p class="text-red-900">{{ message.message }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <base-no-data v-else message="no recent messages" />
            </div>
          </div>
        </div>
      </div>
      <!--<div v-else class="text-error uppercase flex justify-center items-center h-88">
        You don't have the privilege to see this.
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';

export default {
  data() {
    return {
      PROFILE_PATH,
      working: false,
      item: {},
      timeChunk: 'daily',
      colors: ['#00d0c2', '#4c71f0', '#f0854c', '#ff6060'],
      year: 2023,
      month: 5,
      messages: [],
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: new Date(),
        },
        {
          dot: true,
          dates: new Date(2023, 4, 10),
        },
        {
          dot: true,
          dates: new Date(2023, 4, 18),
        },
        {
          dot: true,
          dates: new Date(2023, 4, 23),
        },
      ],
      top_cards: [],
      cards: {
        students: {
          title: 'Students',
          type: 'user-graduate',
          color: '#7f00ff, #e100ff',
          value: '234',
          path: '/registrations/student',
        },
        teachers: {
          title: 'Teachers',
          type: 'chalkboard-user',
          color: '#ee2a7b, #ff7db8',
          value: '234',
          path: '/registrations/teacher',
        },
        parents: {
          title: 'Parents',
          type: 'user',
          color: '#fbb040, #f9ed32',
          value: '234',
          path: '/registrations/parent',
        },
        officials: {
          title: 'Staff',
          type: 'school',
          color: '#00a1ff, #00ff8f',
          value: '234',
          path: '/registrations/school-official',
        },
      },
      options: {
        chart: {
          stacked: false,
        },
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },

        labels: ['mon', 'tue', 'wed', 'thur', 'fri', 'sat'],
        markers: {
          size: 0,
        },
        // xaxis: {
        //   type: 'datetime',
        // },
        yaxis: {
          title: {
            text: 'Users',
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' Users';
              }
              return y;
            },
          },
        },
      },
      series: [
        {
          name: 'teacher',
          type: 'line',
          data: [12, 20, 44, 45, 53, 64],
        },
        {
          name: 'student',
          type: 'line',
          data: [120, 153, 159, 170, 185, 223],
        },
        {
          name: 'parent',
          type: 'line',
          data: [20, 12, 12, 412, 23, 12],
        },
        {
          name: 'staff',
          type: 'line',
          data: [20, 12, 12, 412, 23, 12],
        },
      ],
      schedules: [
        {
          title: 'Review Annual Report',
          type: 'lightbulb',
          date: '9 June 2021, at 1:00 PM',
        },
        {
          title: 'Soccer Competition',
          type: 'trophy',
          date: '9 June 2021, at 1:00 PM',
        },
        {
          title: 'Music Event',
          type: 'music',
          date: '9 June 2021, at 1:00 PM',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
    MESSAGES() {
      return this.messages.map((message) => {
        return {
          name: message.receiver.firstName,
          message: message.lastMessage,
          profile: message.receiver.profilePic,
          date: message.time,
          active: true,
        };
      });
    },
    chartOneOptions() {
      return {
        chart: {
          stacked: false,
        },
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },
        labels: this.item.students.map((users) => users.label),
        markers: {
          size: 0,
        },
        // xaxis: {
        //   type: 'datetime',
        // },
        yaxis: {
          title: {
            text: 'Users',
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' Users';
              }
              return y;
            },
          },
        },
      };
    },
    seriesOne() {
      let tmpArray = [];
      let obj = {
        name: 'Students',
        type: 'line',
        data: this.item.students.map((user) => user.data),
      };
      tmpArray.push(obj);
      obj = {
        name: 'Teachers',
        type: 'line',
        data: this.item.teachers.map((user) => user.data),
      };
      tmpArray.push(obj);
      obj = {
        name: 'Parents',
        type: 'line',
        data: this.item.parents.map((user) => user.data),
      };
      tmpArray.push(obj);
      obj = {
        name: 'Staff',
        type: 'line',
        data: this.item.officials.map((user) => user.data),
      };
      tmpArray.push(obj);
      return tmpArray;
    },
  },
  watch: {
    timeChunk(n) {
      this.fetchStats(n);
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    getDay(date) {
      const WeekDays = ['7', '1', '2', '3', '4', '5', '6'];
      return WeekDays[date.weekday];
    },
    async fetchStats(timeChunk) {
      let response = await this.request({
        method: 'get',
        url: 'dashboard/' + timeChunk + '/' + 7,
      });
      if (response && !response.error) {
        this.item = response.items;
        this.top_cards = this.item.card_info;
      }
    },
    async fetchMessages() {
      let response = await this.request({
        method: 'get',
        url: 'message/recent_messages',
      });
      if (response && !response.error) {
        this.messages = response.record;
      }
    },
  },
  async created() {
    this.working = true;
    this.timeChunk = 'daily';
    await this.fetchStats(this.timeChunk);
    await this.fetchMessages();
    this.working = false;
  },
};
</script>

<style></style>
