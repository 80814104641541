export const bindJoiErrors = {
    methods: {
        bindJoiErrors(source, keys = "", setter) {
            const errors = keys
              .split(" ")
              .reduce(
                (acc, key) => (source[key] ? { ...acc, [key]: source[key] } : acc),
                {},
              )
            setter(errors)
          }
        
    },
}