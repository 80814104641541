<template>
  <div class="bg-white">
    <div v-if="activeAy">
      <div class="flex justify-between items-start p-2">
        <div>
          <p class="text-lg font-bold text-black">My Academic History</p>
          <p class="text-secondary">
            {{ ACTIVE_ACADEMICYEAR.name }} -
            {{ ACTIVE_ACADEMICYEAR.class.name }} -
            {{ ACTIVE_ACADEMICYEAR.section.name }}
          </p>
        </div>

        <div class="flex items-center space-x-3">
          <!-- <div class=" p-2 flex items-center justify-center rounded-full bg-secondary shadow-lg cursor-pointer hover:opacity-75" @click="printTranscript">
          <icon name="print" :size="18" class=" text-white" />
        </div> -->
          <base-select
            :items="ACADEMIC_YEARS"
            w48
            v-model="activeAy"
            searchable
          />
        </div>
      </div>
      <div v-if="academic_history.length > 0" class="space-y-1 p-2">
        <div
          v-for="(history, i) in academic_history"
          :key="i"
          class="border-1 border-grayBg shadow-sm p-2"
        >
          <div>
            <p class="text-sm text-textDark">
              Instructor - {{ history.teacher.firstName }}
              {{ history.teacher.middleName }} {{ history.teacher.lastName }}
            </p>
          </div>
          <div>
            <p class="text-lg font-semibold text-textDarkest">
              {{ history.course.name }}
            </p>
          </div>
          <div
            class="w-full flex justify-between my-2 border-1 border-r-0 rounded-default border-dgray"
          >
            <div
              v-for="(result, i) in history.results"
              :key="i"
              class="w-full border-r-1 border-dgray"
            >
              <p class="p-2 text-sm text-textMedium border-b-1 border-dgray">
                {{ result.manualAssessment.name }} ({{
                  result.manualAssessment.maxResult
                }})
              </p>
              <p class="p-2 text-sm">{{ result.result }}</p>
            </div>
          </div>
          <div class="flex flex-col items-end justify-end">
            <p class="text-success">
              {{ history.totalResult }}
              / {{ history.totalMaxResult }}
            </p>
            <p class="text-success">{{ history.grade }}</p>
          </div>
        </div>
      </div>
      <base-no-data v-else message="No Assessments are given yet" />
    </div>
    <base-no-data v-else message="No Active Academic Year" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
export default {
  props: ['userId'],
  data() {
    return {
      academic_years: [],
      activeAy: '',
      academic_history: [],
      ay_sections: [],
    };
  },
  computed: {
    ...mapState('main', ['error']),
    ACADEMIC_YEARS() {
      return this.academic_years.map((academic_year) => ({
        label: academic_year.name,
        value: academic_year._id,
      }));
    },
    ACTIVE_ACADEMICYEAR() {
      return this.academic_years.find(
        (academic_year) => academic_year._id == this.activeAy
      );
    },
  },
  watch: {
    activeAy() {
      this.fetchAcademicHistory();
    },
  },
  methods: {
    ...mapActions('printer', ['smartSet']),
    ...mapActions('main', ['request']),
    async fetchAcademicYears() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/student-history/' + this.userId,
      });
      if (response && !response.error) {
        this.academic_years = response.record;
        this.activeAy = this.academic_years[0]._id;
        this.activeAyName = this.academic_years[0]._id;
      } else this.academic_years = [];
    },
    async fetchAcademicHistory() {
      let response = await this.request({
        method: 'get',
        url:
          'school/manualAssessmentResult/assessment-history/' +
          this.activeAy +
          '/' +
          this.userId,
      });
      if (response && !response.error) {
        this.academic_history = response.record;
      } else this.academic_history = [];
    },
    printTranscript() {
      this.smartSet({ stateKey: 'showSingleTranscript', data: true });
      this.smartSet({ stateKey: 'printer_on', data: true });

      // window.onafterprint = this.closePrinter;
      // setTimeout(() => window.print(), 500);
    },

    closePrinter() {
      this.smartSet({ stateKey: 'showSingleTranscript', data: false });
      this.smartSet({ stateKey: 'printer_on', data: false });
    },
  },
  created() {
    this.fetchAcademicYears();
  },
};
</script>

<style></style>
