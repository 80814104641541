<template>
  <div>
    <div>
      <d-table
        #default="{ item }"
        :headers="headers"
        :items="items"
        :hasActions="true"
        :handlers="handlers1"
        @printId="handlePrintIdMulti"
        @selectedItems="selectedItems1($event)"
        :loading="loading"
        :buttons="TBUTTONS"
        :multiSelect="true"
      >
        <td v-for="(header, i) in headers" :key="i">
          <div v-if="header.key === 'profilePic'">
            <div class="flex space-x-5 items-center px-2">
              <div
                class="h-10 w-10 rounded-full bg-gray-100 flex justify-center items-center space-x-1"
              >
                <img
                  v-if="item.student.profilePic"
                  :src="PROFILE_PATH + item.student.profilePic"
                  class="w-full h-full object-cover rounded-full flex-none"
                  v-viewer="{ inline: false, movable: true }"
                  alt=""
                  crossorigin="anonymous"
                />
                <icon
                  v-else
                  :size="18"
                  name="user"
                  class="w-full h-full"
                ></icon>
              </div>
              <div @click="displayUserDetail(item.student.id)">
                <p
                  class="text-sm font-semibold uppercase text-textDark hover:underline cursor-pointer"
                >
                  {{ item.student.firstName + ' ' + item.student.middleName }}
                </p>
                <p class="text-textLight">{{ item.student.email }}</p>
              </div>
            </div>
          </div>
          <div
            v-else-if="
              header.key === 'phoneNumber' && item.student.phoneNumber.length
            "
          >
            {{ item.student['phoneNumber'].join(' | ') }}
          </div>

          <div v-else-if="header.key === 'status'">
            <!-- {{ item.student['status'] }} -->
            <status-chip
              :label="getProp(item.student, 'status')"
              style="max-width: fit-content"
            />
          </div>
          <div v-else>
            {{ getProp(item.student, header.key) }}
          </div>
        </td>
      </d-table>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { getPropByString } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus';
import { PROFILE_PATH } from '@/config/config.js';
export default {
  components: { DTable },
  props: ['ay_id'],
  data() {
    return {
      items: [],
      selectedItems: [],
      PROFILE_PATH,
      loading: false,
      Form: {},
      headers: [
        { label: 'User', key: 'profilePic' },
        { label: 'Sch Id', key: 'schoolId' },
        { label: 'Username', key: 'username' },
        { label: 'Age', key: 'age' },
        { label: 'Gender', key: 'gender' },
        { label: 'Phone Number', key: 'phoneNumber' },
        { label: 'Status', key: 'status' },
        // { label: '', key: '' },
        // { label: 'Label Five', key: '' },
      ],
      TBUTTONS: [
        {
          label: 'Print Id',
          value: 'printId',
          icon: 'print',
          // code: 'enrst-add',
        },
      ],
      handlers1: [
        {
          label: 'Print Id',
          icon: 'print',
          handler: this.handlePrintId,
          fullParam: true,
          // filter: () => this.authCodes.includes('enrst-rm'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['authCodes']),
  },

  watch: {
    ay_id() {
      this.fetchItems();
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    getProp: getPropByString,

    selectedItems1(e) {
      this.selectedItems = e;
    },

    handlePrintId(user) {
      let componentProps = {
        students: [user],
      };
      eventBus.$emit('open-modal', {
        modalId: 45,
        componentProps,
      });
    },

    handlePrintIdMulti() {
      if (this.selectedItems.length == 0)
        return eventBus.$emit('open-toast', {
          error: true,
          message: 'You have to select students first.',
        });
      else {
        let selectedItems = this.selectedItems;
        let componentProps = {
          students: selectedItems,
        };
        eventBus.$emit('open-modal', {
          modalId: 45,
          componentProps,
        });
      }
    },
    displayUserDetail(id) {
      let componentProps = {
        id,
        type: 'student',
        url: 'student/studentProfile/',
      };
      eventBus.$emit('open-modal', {
        modalId: 39,
        componentProps,
      });
    },

    async fetchItems() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'school/studentSection/ay/' + this.ay_id,
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
      this.loading = false;
    },
    handleDetail(id) {
      /// TODO: GET STUDENT DETAIL
      this.$router.push({ name: 'student-detail', params: { id } });
    },

    async proceedRemove({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/studentSection/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Student Removed Successfully',
        });
        this.fetchItems();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    handleSelectStudent() {
      let componentProps = {
        usertype: 'Enroll Students',
        ay_id: this.ay_id,
        multiple: true,
      };
      eventBus.$emit('open-modal', {
        modalId: 37,
        componentProps,
        cb: this.handleEnrollStudent,
      });
    },

    onStudentSelected({ response: { customer: student } }) {
      /// TODO: link the student to the section

      this.handleEnrollStudent(student);
    },

    async handleEnrollStudent({ response: { customer: students } }) {
      let stu_ids = students.map((student) => student._id);
      let response = await this.request({
        method: 'post',
        url: 'school/studentSection/',
        data: {
          academic_section: this.ay_id,
          students: stu_ids,
        },
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'student enrolled successfylly',
        });

        this.fetchItems();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },

  created() {
    this.fetchItems();
  },
};
</script>
<style></style>
