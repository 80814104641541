<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title>Transfer Section</template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-1 gap-5">
          <div>Transfer To:</div>
          <base-select
            :items="ACADEMIC_YEARS"
            v-model="Form.academic_year"
            searchable
          />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { validation } from '@/mixins/validationMixin.js';
export default {
  components: { ModalTemplate },
  props: ['id', 'grade', 'academic_year'],
  mixins: [validation],

  data() {
    return {
      academic_years: [],
      Form: {
        academic_year: '',
        student: this.id,
      },
      meta: [
        { key: 'Form.academic_year', label: 'Section' },
        { key: 'Form.student', label: 'Student' },
      ],
    };
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['academic_year', 'student'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    ACADEMIC_YEARS() {
      return this.academic_years
        .filter((ay) => ay._id !== this.academic_year)
        .map((ay) => ({
          label: ay.class.name + ' - ' + ay.section.name,
          value: ay._id,
        }));
    },
  },
  methods: {
    ...mapActions('main', ['request']),

    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },

    async fetchAcademicYears() {
      this.activeAy = '';
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/class/' + this.grade,
      });
      if (response && !response.error) {
        this.academic_years = response.record;
        this.activeAy = this.academic_years[0]._id;
      } else {
        this.activeAy = '';
        this.academic_years = [];
      }
    },

    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      this.handleCreate();
      this.initial = true;
    },

    async handleCreate() {
      let newForm = { ...this.Form };
      let response = await this.request({
        method: 'put',
        url: 'school/studentSection/transfer-section',
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Student transfered successfully!',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },
  async created() {
    await this.fetchAcademicYears();
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
  },
};
</script>

<style></style>
