<template>
  <div class="text-textMedium space-y-4">
    <!-- <div class="flex space-x-2 justify-between border-b border-dgray pb-2">
      <div class="font-semibold">Students</div>
      <div class="text-15">Found {{ students.length }} search result{{ students.length > 1 ? 's' : '' }}</div>
    </div> -->
    <!-- TODO circule progress -->
    <loader v-if="loading"></loader>
    
    <div v-else-if="school_officials && school_officials.length > 0" class="space-y-1">
      <school-official v-for="schoolOfficial in school_officials" :key="schoolOfficial._id" :schoolOfficial="schoolOfficial" />
    </div>
    <div v-else>
      <div v-if="initial" class="space-y-1">
        <file-search class="h-48" />
        <div class="text-center">Search for school officials</div>
      </div>
      <div v-else>
        <div class="font-medium">No school official</div>
        <div class="text-sm">Oops no school official can be found with the given search keyword and the filter criteria.</div>
      </div>
    </div>
  </div>
</template>

<script>
import SchoolOfficial from './SchoolOfficial.vue'
import FileSearch from '../../shared/svgs/FileSearch.vue'
export default {
  components: { SchoolOfficial, FileSearch },
  props: ['school_officials', 'loading', 'initial'],
  watch: {
    school_officials: function(v){
      console.log('watdh', v);
    }
  },
  created(){
    console.log('school official', this.school_officials);
  }
}
</script>

<style></style>
