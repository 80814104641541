<template>
  <div>
    <base-title :title="'Manage Sections'" class="mb-2">
      <template #actions>
        <div class="flex space-x-3">
          <base-select
            :items="GRADE_OPTIONS"
            v-model="activeGrade"
            searchable
          />
          <base-button
            label="New Section"
            icon="plus"
            class="px-2 flex-shrink-0"
            @click="handleCreateSection"
            code="sec-add"
          />
        </div>
      </template>
    </base-title>

    <loader type="list" :amount="6" v-if="loading"></loader>

    <div v-else-if="!loading && items.length">
      <div
        v-if="authCodes.includes('sec-view')"
        class="mt-2 grid grid-cols-3 gap-2"
      >
        <div
          class="bg-white p-2 item-grid rounded-default"
          v-for="(item, i) in items"
          :key="i"
        >
          <div class="flex justify-between w-full">
            <div class="flex space-x-2 items-center">
              <p class="text-textDarkest uppercase">
                {{ item.name }}
              </p>
            </div>
            <div>
              <status-chip rounded :label="item.status" />
            </div>
          </div>
          <div></div>
          <div>
            <div class="flex space-x-5 mt-3">
              <icon
                v-if="authCodes.includes('sec-rm')"
                name="trash-alt"
                class="cursor-pointer text-textMedium"
                @click="handleDeleteItem(item._id)"
              />
              <icon
                v-if="authCodes.includes('sec-edit')"
                name="pencil-alt"
                class="cursor-pointer text-textMedium"
                @click="handleEditItem(i)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-else class="text-error flex h-88 items-center justify-center">
        You don't have a privilege to see this!
      </div> -->
    </div>
    <base-no-data v-else message="no sections added" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../../eventBus';
import { static_roles } from '@/config/constants';
export default {
  data() {
    return {
      module: 'structure',
      permission: {},
      isSchoolOfficial: false,
      activeGrade: '',
      items: [],
      gradeItems: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name + ' - ' + grade.enrollment.code,
        value: grade._id,
      }));
    },
  },
  watch: {
    activeGrade() {
      this.handleFetchSection();
    },
    user: {
      deep: true,
      immediate: true,
      handler: function (v) {
        if (v) {
          this.user1 = v;
          this.permission = v.permissions.find(
            (p) => p.module.name === this.module
          );
        }
      },
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleCreateSection() {
      let componentProps = { grade_id: this.activeGrade };
      eventBus.$emit('open-modal', {
        modalId: 10,
        componentProps,
        cb: this.handleFetchSection,
      });
    },

    async handleFetchSection() {
      this.loading = true;
      this.items = [];
      let response = await this.request({
        method: 'get',
        url: 'school/section/class/' + this.activeGrade,
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
      this.loading = false;
    },

    async handleFetchGrade() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'school/class/active/',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
        this.activeGrade = response.record[0]._id;
      } else {
        this.gradeItems = [];
      }
      this.loading = false;
    },

    async handleEditItem(index) {
      let componentProps = {
        oldForm: this.items[index],
      };
      eventBus.$emit('open-modal', {
        modalId: 10,
        componentProps,
        cb: this.handleFetchSection,
      });
    },

    async handleDeleteItem(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the selected grade',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },

    /// NOTE: do not actully delete the academic year just disable it.
    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/section/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'section deleted successfully',
        });
        this.handleFetchSection();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    async handleItemDetail(id = 0) {
      this.$router.push({
        name: 'section-detail',
        params: {
          school_id: this.school_id,
          ay_id: this.ay_id,
          grade_id: this.activeGrade,
          section_id: id,
        },
      });
    },
    checkStatic() {
      const role = this.user1.role;
      if (!static_roles.includes(role)) {
        this.isSchoolOfficial = true;
        const permissions =
          this.user1 && this.user1.permissions ? this.user1.permissions : [];
        let permission = permissions.find((p) => p.module.name === this.module);
        this.permission = permission;
      }
    },
  },

  created() {
    this.checkStatic();
    this.handleFetchGrade();
  },
};
</script>

<style scoped>
.item-grid {
  animation: slider 0.5s;
}

@keyframes slider {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
