<template>
  <div>
    <base-title
      :title="
        academic_year.name
          ? `Enrollment History for ${academic_year.name}`
          : 'No Academic Year History On this Section'
      "
      class="mb-2"
    >
      <template #actions>
        <div class="flex justify-center items-center space-x-2">
          <base-button
            icon="plus"
            label="Export"
            :primary="false"
            class="bg-gray-100 flex-shrink-0"
            @click="exportCsv"
          />
          <base-select
            placeholder=""
            v-model="activeGrade"
            :items="GRADE_OPTIONS"
            searchable
          />
          <base-select
            placeholder=""
            v-model="activeSection"
            :items="SECTION_OPTIONS"
            searchable
          />

          <base-select
            placeholder=""
            :items="ACADEMIC_YEARS"
            v-model="activeAy"
            searchable
          />
        </div>
      </template>
    </base-title>

    <div class="bg-white w-full p-1 px-4 mt-2 rounded-default">
      <div class="py-2" v-if="activeAy">
        <students-list ref="student-list" :ay_id="activeAy" />
      </div>
      <base-no-data v-else message="no sections added" />
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import StudentsList from './components/StudentsList.vue';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
export default {
  components: {
    StudentsList,
  },
  data() {
    return {
      activeGrade: '',
      activeSection: '',
      activeAy: '',
      gradeItems: [],
      sectionItems: [],
      academic_years: [],
      academic_year: {},
      noData: false,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },

    SECTION_OPTIONS() {
      return this.sectionItems.map((section) => ({
        label: section.name,
        value: section._id,
      }));
    },
    ACADEMIC_YEARS() {
      return this.academic_years.map((academic_year) => ({
        label: academic_year.name,
        value: academic_year._id,
      }));
    },
  },
  watch: {
    activeGrade() {
      this.fetchSections();
    },
    activeSection() {
      this.fetchAcademicYears();
    },
    activeAy() {
      this.fetchAcademicYear();
    },
  },

  methods: {
    ...mapActions('main', ['request']),

    exportCsv() {
      const items = this.$refs['student-list'].items;
      this.handleExportCsv(items);
    },
    handleExportCsv(items) {
      const i = items.map((it) => {
        return { ...it.student, guardian: { ...it.student.parents[0] } };
      });
      const h = [
        { label: 'UserId', key: 'userId' },
        { label: 'First Name', key: 'firstName' },
        { label: 'Father Name', key: 'middleName' },
        { label: 'Grand Father Name', key: 'lastName' },
        { label: 'Gender', key: 'gender' },
        { label: 'Date Of Birth', key: 'birthdate' },
        { label: 'Grade', key: 'class.name' },
        { label: 'Section', key: 'section.name' },
        { label: 'Region', key: 'region' },
        { label: 'City', key: 'city' },
        { label: 'Subcity', key: 'subcity' },

        { label: 'Address', key: 'address' },

        { label: 'Email', key: 'email' },

        { label: 'Phone Number', key: 'phoneNumber' },
        { label: 'Payer', key: 'payer.name' },
        { label: 'Discount', key: 'discount.name' },
        { label: 'Service', key: 'service' },

        { label: 'Guardian First Name', key: 'guardian.parent.firstName' },
        { label: 'Guardian Father Name', key: 'guardian.parent.middleName' },
        {
          label: 'Guardian Grand Father Name',
          key: 'guardian.parent.lastName',
        },
        { label: 'Guardian Gender', key: 'guardian.parent.gender' },

        { label: 'Guardian Date Of Birth', key: 'guardian.parent.birthdate' },
        { label: 'Relationship', key: 'guardian.relation' },
        { label: 'Email', key: 'guardian.parent.email' },
        { label: 'Phone Number', key: 'guardian.parent.phoneNumber' },

        // { label: 'Address', key: 'address' },
        { label: 'Status', key: 'status' },
      ];
      let componentProps = {
        title: `students ${this.academic_year.class.name} - ${this.academic_year.section.name} - ${this.academic_year.name}`,
        headers: h,
        items: i,
      };
      eventBus.$emit('open-modal', { modalId: 35, componentProps });
    },

    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
        this.activeGrade = response.record[0]._id;
      } else {
        this.gradeItems = [];
        this.activeGrade = '';
      }
    },

    async fetchSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/class/' + this.activeGrade,
      });
      if (response && !response.error) {
        this.sectionItems = response.record;
        this.activeSection = response.record[0]._id;
      } else {
        this.sectionItems = [];
        this.activeSection = '';
      }
    },
    async fetchAcademicYears() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/section-history/' + this.activeSection,
      });
      if (response && !response.error) {
        this.academic_years = response.record;
        this.activeAy = this.academic_years[0]._id;
        this.academic_year = response.record[0];
      } else {
        this.academic_years = [];
        this.activeAy = '';
        this.noData = true;
      }
    },

    async fetchAcademicYear() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/' + this.activeAy,
      });
      if (response && !response.error) {
        this.academic_year = response.record;
      } else {
        this.academic_year = {};
        this.noData = true;
      }
    },
  },

  async created() {
    await this.fetchGrades();
  },
};
</script>

<style></style>
