import { render, staticRenderFns } from "./PaymentTypesList.vue?vue&type=template&id=16469615&scoped=true&"
import script from "./PaymentTypesList.vue?vue&type=script&lang=js&"
export * from "./PaymentTypesList.vue?vue&type=script&lang=js&"
import style0 from "./PaymentTypesList.vue?vue&type=style&index=0&id=16469615&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16469615",
  null
  
)

export default component.exports