<template>
  <div
    class=" h-screen w-screen fixed top-0 right-0 left-0 bottom-0 z-50 bg-grayBg flex"
    style="z-index: 1000"
  >
    <div class=" w-1/3 flex flex-col ">
      <p class=" text-center font-semibold text-xl py-2">Course Content</p>
      <!-- search area -->
      <div class=" px-2 w-full">
        <div class="flex w-full">
          <base-input
            class=" flex-grow"
            placeholder="search for course content"
            :rounded="false"
          />
          <base-button label="search" />
        </div>
      </div>
      <!-- list area -->
      <div class=" flex-grow overflow-y-auto mx-2 bg-white mt-2">
        <!-- header -->
        <div class=" px-3 py-2">
          <p></p>
          <p class=" text-textLg text-textDark font-semibold">
            {{ selectedCurriculumCategory.name }}
          </p>
        </div>
        <div class=" divide-y divide-gray-100">
          <course-content-entry
            v-for="(curriculumCategory, i) in curriculumCategories"
            contentType="curriculum_category"
            :content="curriculumCategory"
            :key="i"
            @click="handleSelectCurriculumCategory(curriculumCategory)"
            @updated="fetchCurriculumCategories"
            @deleted="fetchCurriculumCategories"
            @movedown="handleMoveDown(i, 'category')"
            @moveup="handleMoveUp(i, 'category')"
          />
          <course-content-entry
            v-for="(content, index) in contents"
            :contentType="content.contentType"
            :content="content"
            :key="index"
            @click="handleDisplayContent"
            @updated="fetchContents"
            @deleted="fetchContents"
            @movedown="handleMoveDown(i, 'content')"
            @moveup="handleMoveUp(i, 'content')"
          />
        </div>
      </div>
    </div>

    <video-content-display
      v-if="content && content.contentType == 'video' && true"
      :content="content"
      @end="onEnd"
      @start="onStart"
    />
    <pdf-content-display
      v-else-if="content && content.contentType == 'document'"
      :content="content"
    />
    <simulation-content-display
      v-else-if="content && content.contentType == 'simulation'"
      :content="content"
    />
  </div>
</template>

<script>
import CourseContentEntry from './components/CourseContentEntry.vue';
// import VideoPlayer from '@/components/players/VideoPlayer.vue';
// import sampleVideo from '@/assets/videos/sample.mp4';
import VideoContentDisplay from './players/Video.vue';
import PdfContentDisplay from './players/Pdf.vue';
import SimulationContentDisplay from './players/Simulation.vue';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';

export default {
  components: {
    CourseContentEntry,
    VideoContentDisplay,
    PdfContentDisplay,
    SimulationContentDisplay
    // VideoPlayer
  },
  props: ['categoryId', 'courseId', 'contentId', 'ctaId'],
  data() {
    return {
      // sampleVideo,
      curriculumCategories: [],
      contents: [],
      selectedCurriculumCategory: '',
      content: ''
    };
  },

  computed: {
    ...mapState('main', ['error']),
    insideSchool() {
      return Boolean(this.ctaId);
    }
  },

  methods: {
    ...mapActions('main', ['request']),

    handleDisplayContent(content) {
      this.content = content;
    },

    handleSelectCurriculumCategory(category) {
      this.selectedCurriculumCategory = category;
      this.fetchContents();
      this.fetchCurriculumCategories();
    },

    async fetchContents() {
      if (!this.selectedCurriculumCategory && !this.categoryId) return;

      let response = await this.request({
        method: 'get',
        url:
          'reb/courseContent/category/' +
          (this.selectedCurriculumCategory._id || this.categoryId)
      });
      if (response && !response.error) {
        this.contents = response.record;
      } else {
        this.contents = [];
      }
    },

    async fetchCurriculumCategories() {
      let url = 'reb/curriculumCategory/heads/' + this.courseId;
      if (this.selectedCurriculumCategory || this.categoryId)
        url =
          'reb/curriculumCategory/nodes/' +
          (this.selectedCurriculumCategory
            ? this.selectedCurriculumCategory._id
            : this.categoryId);

      let response = await this.request({ method: 'get', url });
      if (response && !response.error) {
        this.curriculumCategories = response.record;
      } else {
        this.curriculumCategories = [];
      }
    },

    async fetchCurriculumCategory() {
      let response = await this.request({
        method: 'get',
        url: 'reb/curriculumCategory/' + this.categoryId
      });
      if (response && !response.error) {
        this.selectedCurriculumCategory = response.record;
      } else {
        this.selectedCurriculumCategory = '';
      }
    },

    async fetchContent() {
      let response = await this.request({
        method: 'get',
        url: 'reb/courseContent/' + this.contentId
      });
      if (response && !response.error) {
        this.content = response.record[0];
      } else {
        this.content = '';
      }
    },

    async onStart() {
      if (this.insideSchool) return;

      await this.request({
        method: 'post',
        url: 'reb/contentLog/',
        data: {
          course: this.courseId,
          curriculumCategory: this.categoryId,
          content: this.contentId
        }
      });
    },

    async onEnd() {
      await this.request({
        method: 'put',
        url: 'reb/contentLog/',
        data: {
          course: this.courseId,
          curriculumCategory: this.categoryId,
          content: this.contentId
        }
      });
    }
  },

  created() {
    this.fetchContents();
    this.fetchCurriculumCategories();
    this.fetchCurriculumCategory();
    this.fetchContent();
  }
};
</script>

<style></style>
