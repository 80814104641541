<template>
  <div>
    Index Dashboard
  </div>
</template>

<script>
export default {}
</script>

<style></style>
