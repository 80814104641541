<template>
  <div class=" w-full pt-5 pb-5">
    <div class=" grid grid-cols-4 gap-5" v-if="items.length">
      <course-entry
        v-for="(course, i) in FORMATTED_ITEMS"
        :key="i"
        :course="course"
        @click="handleSelectCourse(course._id)"
      />
    </div>
    <div v-else class=" h-40 items-center flex justify-center text-center">
      <p class=" text-textLight">No Content Found</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import CourseEntry from '@/views/protected/course/components/CourseEntry.vue';
export default {
  components: {
    CourseEntry
  },
  data() {
    return {
      items: []
    };
  },
  computed: {
    ...mapState('main', ['error']),
    FORMATTED_ITEMS() {
      return this.items.map(item => item.courseCurriculum);
    }
  },
  methods: {
    ...mapActions('main', ['request']),

    handleSelectCourse(courseId) {
      this.$router.push({
        name: 'n-course-detail',
        params: { courseId: courseId }
      });
    },

    async fetchSubscribedCourses() {
      let response = await this.request({
        method: 'get',
        url: 'reb/transaction/school-subscribed'
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
    }
  },
  created() {
    this.fetchSubscribedCourses();
  }
};
</script>

<style></style>
