<template>
  <modal-template @save="handleResetPassword" @cancel="handleClose">
    <template #title>
      Reset Password
    </template>
    <template #default>
      <div class=" space-y-5">
        <base-input
          label="New Password"
          placeholder="new password"
          :type="inputType"
          v-model="Form.newPassword"
        />
        <base-input
          label="Confirm Password"
          placeholder="confirm password"
          :type="inputType"
          v-model="Form.confirmPassword"
        />
        <base-check-box
        label="Show Passwords"
        :selected="selected"
        @toggle="showPassword"
      />


      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import eventBus from '../../eventBus';
export default {
  props: [],
  data() {
    return {
      Form: {
        newPassword: '',
        confirmPassword: ''
      },
      selected: false,
      inputType: "password"

    };
  },
  computed: {
    ...mapState('main', ['error']),
  },
  methods: {
    handleClose(invokeCb = false) {
      this.$emit('close', { invokeCb });
    },

    ...mapActions('main', ['request']),
    async handleResetPassword() {
      if (this.Form.confirmPassword != this.Form.newPassword)
        return eventBus.$emit('open-toast', {
          message: this.error,
          error: true
        });

      let response = await this.request({
        method: 'put',
        url: 'reb/official/reset-password',
        data: this.Form
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'successfully updated password'
        });
        this.handleClose();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true
        });
      }
    },
    showPassword() {
      if (this.inputType === 'password') {
        this.selected = true;
        this.inputType = 'text';
      } else {
        this.selected = false;
        this.inputType = 'password';
      }
    },

  },
  components: {
    ModalTemplate
  }
};
</script>

<style></style>
