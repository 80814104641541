<template>
  <div>
    <base-title :title="'Students Roaster'">
      <template #actions>
        <div class="flex space-x-3">
          <base-select
            placeholder=""
            v-model="activeGrade"
            :items="GRADE_OPTIONS"
            searchable
          />
          <base-select
            placeholder=""
            v-model="activeSection"
            :items="SECTION_OPTIONS"
            searchable
          />

          <base-select
            placeholder=""
            :items="ACADEMIC_YEARS"
            v-model="activeAy"
            searchable
          />
          <base-button
            @click="PrintRoaster"
            label="Print"
            icon="print"
            class="px-2"
          />
        </div>
      </template>
    </base-title>
    <loader v-if="loading" />
    <div v-else class="bg-white p-5 mt-2 rounded-default">
      <d-table
        class=""
        #default="{ item, index }"
        :headers="HEADERS"
        :items="items"
        :hasActions="false"
        :loading="loading"
        :pageSize="70"
        :multiSelect="true"
        @selectedItems="selectedItems($event)"
      >
        <td v-for="(header, i) in HEADERS" :key="i" class="border-1">
          <div class="py-1">
            <div v-if="header.key === 'no'" class="text-center">
              {{ index + 1 }}
            </div>
            <div v-else-if="header.key === 'name'" class="px-2">
              <p class="text font-semibold text-textDarkest uppercase">
                <!-- {{item[header.key]}} -->
                {{ item.student.firstName }} {{ item.student.middleName }}
                {{ item.student.lastName }}
              </p>
            </div>
            <div v-else-if="header.key === 'userId'" class="text-center">
              <p class="text font-semibold text-textDarkest uppercase">
                {{ item.student.userId }}
              </p>
            </div>
            <div
              v-else-if="header.key === 'total' && item.total"
              class="text-center font-semibold uppercase text-textDarkest"
            >
              {{ item.total }}
            </div>
            <div
              v-else-if="header.key === 'average' && item.average"
              class="text-center font-semibold uppercase text-textDarkest"
            >
              {{ item.average }}
            </div>
            <div
              v-else-if="header.key === 'rank' && item.rank"
              class="text-center font-semibold uppercase text-textDarkest"
            >
              {{ item.rank }}
            </div>
            <div
              v-else-if="header.dynamic && header.key.includes('total')"
              class="text-center"
            >
              <p class="text font-semibold uppercase text-textDarkest">
                {{
                  getCourseName(header.key, item.courses) &&
                  getCourseName(header.key, item.courses).studentCourse
                    ? getCourseName(header.key, item.courses).studentCourse
                        .total
                    : ''
                }}
              </p>
            </div>
            <div
              v-else-if="header.dynamic && header.key.includes('grade')"
              class="text-center"
            >
              <p class="text font-semibold uppercase text-textDarkest">
                {{
                  getCourseName(header.key, item.courses) &&
                  getCourseName(header.key, item.courses).studentCourse
                    ? getCourseName(header.key, item.courses).studentCourse
                        .grade
                    : ''
                }}
              </p>
            </div>

            <div
              v-else-if="header.dynamic && header.key.includes('rank')"
              class="text-center"
            >
              <p class="text font-semibold uppercase text-textDarkest">
                {{
                  getCourseName(header.key, item.courses) &&
                  getCourseName(header.key, item.courses).studentCourse
                    ? getCourseName(header.key, item.courses).studentCourse.rank
                    : ''
                }}
              </p>
            </div>
            <div
              v-else-if="header.key === 'status'"
              class="text-center capitalize"
            >
              {{ item.status }}
              <!-- <status-chip
                :label="item[header.key]"
                style="max-width: fit-content"
              /> -->
            </div>
            <div v-else>
              <div class="px-4"></div>
            </div>
          </div>
        </td>
      </d-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapState } from 'vuex';
import DTable from '@/components/table/SelectableTable.vue';
import eventBus from '@/eventBus.js';
export default {
  components: {
    DTable,
  },
  data() {
    return {
      activeGrade: '',
      activeSection: '',
      activeAy: '',
      gradeItems: [],
      sectionItems: [],
      academic_years: [],
      academic_year: {},
      noData: false,
      loading: false,
      items: [],
      courses: [],
      selected: [],
      // headers: [
      //   { label: 'Name', key: 'name' },
      //   // { label: 'discount', key: 'discount' },
      //   // { label: 'Status', key: 'status' },
      // ],
    };
  },
  computed: {
    ...mapState('main', ['error']),
    ...mapGetters(['authCodes']),
    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },

    SECTION_OPTIONS() {
      return this.sectionItems.map((section) => ({
        label: section.name,
        value: section._id,
      }));
    },
    ACADEMIC_YEARS() {
      return this.academic_years.map((academic_year) => ({
        label: academic_year.name,
        value: academic_year._id,
      }));
    },
    HEADERS() {
      let courses = this.courses.map((c) => {
        return [
          {
            label: c.name,
            key: c.code + '/s',
            // key: c._id,
            dynamic: true,
          },
          {
            label: 'Total',
            key: c.code + '/total',
            // key: c._id,
            dynamic: true,
          },
          {
            label: 'Grade',
            key: c.code + '/grade',
            // key: c._id,
            dynamic: true,
          },
          {
            label: 'Rank',
            key: c.code + '/rank',
            // key: c._id,
            dynamic: true,
          },
        ];
      });
      // console.log('courses', courses);
      courses = courses.flat();
      let dynamic_headers = [
        { label: 'No', key: 'no' },
        { label: 'Name', key: 'name' },
        { label: 'Student Id', key: 'userId' },
        ...courses,
      ];
      dynamic_headers.push(
        { label: 'Total', key: 'total' },
        { label: 'Average', key: 'average' },
        { label: 'Rank', key: 'rank' },
        { label: 'Academic Status', key: 'status' }
      );
      // console.log('header', dynamic_headers);
      return dynamic_headers;
    },
  },
  watch: {
    activeGrade() {
      this.fetchSections();
    },
    activeSection() {
      this.fetchAcademicYears();
    },
    activeAy() {
      this.fetchRoaster();
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
        this.activeGrade = response.record[0]._id;
      } else {
        this.gradeItems = [];
        this.activeGrade = '';
      }
    },

    async fetchSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/class/' + this.activeGrade,
      });
      if (response && !response.error) {
        this.sectionItems = response.record;
        this.activeSection = response.record[0]._id;
      } else {
        this.sectionItems = [];
        this.activeSection = '';
      }
    },
    async fetchAcademicYears() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/section/' + this.activeSection,
      });
      if (response && !response.error) {
        this.academic_years = response.record;
        this.activeAy = this.academic_years[0]._id;
        this.academic_year = response.record[0];
      } else {
        this.academic_years = [];
        this.activeAy = '';
        this.noData = true;
      }
    },

    async fetchAcademicYear() {
      let response = await this.request({
        method: 'get',
        url: 'school/academicYear/' + this.activeAy,
      });
      if (response && !response.error) {
        this.academic_year = response.record;
      } else {
        this.academic_year = {};
        this.noData = true;
      }
    },
    PrintRoaster() {
      if (this.selected.length == 0)
        return eventBus.$emit('open-toast', {
          error: true,
          message: 'you have not selected any data. please select to print!',
        });
      let selectedItems = this.items.filter((v) =>
        this.selected.includes(v._id)
      );

      eventBus.$emit('open-modal', {
        modalId: 46,
        componentProps: {
          data: selectedItems.reverse(),
          courses: this.courses,
        },
      });
    },

    getCourseName(key, PT) {
      // console.log('asdf', key, PT);
      if (!PT) return '';
      return key &&
        PT.find((v) => {
          // console.log(v.course.name, v.course == key);
          return v.course.code == key.split('/')[0];
        })
        ? PT.find((v) => {
            return v.course.code == key.split('/')[0];
          })
        : '';
    },

    async fetchRoaster() {
      let response = await this.request({
        method: 'get',
        url: 'school/studentAcademic/roaster/' + this.activeAy,
      });
      if (response && !response.error) {
        // this.courses = response.record.data.courses;
        this.courses = response.courses;
        this.items = response.record;
        // .map(v => ({_id: v.student._id, student: v.student, data: v.data}));
      } else {
        this.courses = [];
        this.items = [];
      }
    },
    async fetchCourses() {
      let response = await this.request({
        method: 'get',
        url: 'school/courseYear/semester-courses/' + this.activeAy,
      });
      if (response && !response.error) {
        this.courses = response.record;
      } else {
        this.courses = [];
      }
    },
    selectedItems(e) {
      console.log('selected', e);
      this.selected = e;
    },
  },

  async created() {
    this.loading = true;
    await this.fetchGrades();
    // await this.fetchCourses();
    this.loading = false;
    // await this.fetchRoaster()
    // await this.fetchDocumentsList();
  },
};
</script>

<style></style>
