<template>
  <div class=" ">
    <base-title title="Manage Channels">
      <template #actions>
        <div class="flex">
          <base-button
            icon="exchange-alt"
            label="Sync"
            class="px-3"
            @click="syncContents"
            
          />
        </div>
      </template>
    </base-title>
    <!-- header -->
    <div class="flex space-x-5 mb-0 bg-white py-5 px-5">
      <div
        class="cursor-pointer"
        :class="{
          'border-b-2 border-primary': activeTab == 'all_channels',
          'text-textLight': activeTab != 'all_channels',
        }"
        @click="selectTab('all_channels')"
      >
        <p class="text">All Channels</p>
      </div>
      <div
        class="cursor-pointer"
        @click="selectTab('subscribed_courses')"
        :class="{
          'border-b-2 border-primary': activeTab == 'subscribed_courses',
          'text-textLight': activeTab != 'subscribed_courses',
        }"
      >
        My Subscriptions
      </div>
    </div>

    <div class="bg-white">
      <div class="px-8 py-10" v-if="activeTab === 'all_channels'">
        <all-channels />
      </div>
      <div v-if="activeTab === 'subscribed_courses'" class="px-8">
        <school-subscriptions />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import eventBus from '../../../eventBus';
import SchoolSubscriptions from './SubscribedCourses.vue';
import AllChannels from './AllChannels.vue';

export default {
  components: {
    AllChannels,
    SchoolSubscriptions,
  },
  data() {
    return {
      tabs: ['all_channels', 'subscribed_courses'],
      items: [],
      activeTab: 'all_channels',
      working: false
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    selectTab(tab) {
      this.activeTab = tab;
    },
    async syncContents() {
      this.working = true;
      let response = await this.request({
        method: 'put',
        url: '/reb/courseContent/import/contents',
      });
      if (response && !response.error) {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: 'Sync completed successfully',
        });
        this.handleClose(true);
      } else {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },
};
</script>

<style></style>
