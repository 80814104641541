import apiService from './apiService';
import { handleError } from './handleErrorService';
import { tokenService, userInfoService } from './storageService';
// import actions from "../store/modules/main"

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const userService = {
  signin: async function (signin) {
    const requestData = {
      method: 'post',
      url: 'school/official/login',
      data: signin,
    };

    try {
      const { data } = await apiService.customRequest(requestData);

      tokenService.saveToken(data.token);
      apiService.setHeader();
      // console.log('data gg', data.user, data.authorized_actions);
      let user = {
        role: data.user.role,
        permissions: data.user.permissions,
        name: data.user.firstName + ' ' + data.user.middleName,
        username: data.user.username,
        isBillingSystemUser: data.user.isBillingSystemUser,
        billingId: data.user.billingId,
        id: data.user.id,
        authModules: data.modules,
        actions: data.authorized_actions,
        authCategories: data.categories,
      };
      // console.info(user);
      userInfoService.saveUser(user);

      return {
        token: data.token,
        user,
      };
    } catch (error) {
      if (error.response)
        error.response.data.errors = error.response.data.message;
      return handleError(error);
    }
  },
  getMe: async function () {
    const requestData = {
      method: 'get',
      url: 'school/official/me',
    };

    try {
      const { data } = await apiService.customRequest(requestData);

      apiService.setHeader();
      const user = {
        id: data.record._id,
        name: data.record.firstName + ' ' + data.record.middleName,
        username: data.record.username,
        role: data.record.role.name,
        isBillingSystemUser: data.record.isBillingSystemUser,
        billingId: data.record.billingId,
        permissions: data.record.role.permissions,
      };
      return user;
    } catch (error) {
      return handleError(error);
    }
  },
  logout: async function () {
    try {
      const requestData = {
        method: 'post',
        url: 'log/logout',
        data: {},
      };
      apiService.customRequest(requestData);
      tokenService.removeToken();
      apiService.removeHeader();
    } catch (error) {
      return handleError(error);
    }
  },
};

export { userService, AuthenticationError };
