<template>
  <div class="flex flex-col h-full p-3">
    <base-title
      :title="'Print Preview - Student Id'"
      class="font-bold uppercase"
    >
      <template #actions>
        <div class="flex space-x-3 items-start">
          <button
            v-print="'#printable'"
            class="p-2 flex items-center justify-center mt-2 rounded-default bg-primary shadow-lg cursor-pointer hover:opacity-75"
          >
            <icon name="print" :size="14" class="text-white mr-2" />
            <span class="text-white">Print</span>
          </button>
        </div>
      </template>
    </base-title>
    <kild></kild>
    <spacer class="mb-2" />

    <!-- <loader v-if="loading"></loader> -->
    <div id="printable">
      <div
        class="bg-white p-1 px-8 grid grid-cols-2 gap-2"
        v-for="student in students"
        :key="student"
      >
        <div class="bg-white rounded-lg border-gray-200 border-1">
          <div
            class="rounded-t-lg border-b-1 border-gray-300 bg-gray-50 p-2 flex items-center justify-between gap-3"
          >
            <div class="flex items-center gap-2">
              <img
                :src="LOGO_PATH + student.student.school.logo"
                class="w-12 h-12 rounded-full"
                alt=""
              />
              <div class="">
                <p class="uppercase font-semibold">
                  {{ student.student.school.name }}
                </p>
                <p class="text-xs text-gray-500">Student ID</p>
              </div>
            </div>
            <div
              class="flex items-center gap-2 bg-gray-100 p-2 rounded-default"
            >
              <p class="text-xs">Powered by:</p>
              <img src="@/assets/henonLogo.png" class="w-6 h-6" alt="" />
            </div>
          </div>
          <div class="grid grid-cols-8 gap-2 items-center p-2">
            <div class="h-48 col-span-3">
              <img
                :src="PROFILE_PATH + student.student.profilePic"
                class="h-full object-cover"
                alt=""
              />
            </div>
            <div class="flex flex-col gap-3 col-span-5 px-6">
              <div class="">
                <p class="text-xs text-gray-400">Full Name / ሙሉ ስም</p>
                <p class="text-sm">{{ student.student.fullName }}</p>
              </div>
              <div class="">
                <p class="text-xs text-gray-400">ID Number / መለያ ቁጥር</p>
                <p class="text-sm">{{ student.student.userId }}</p>
              </div>
              <div class="">
                <p class="text-xs text-gray-400">Grade / ክፍል</p>
                <p class="text-sm">{{ student.student.class.name }}</p>
              </div>
              <div class="">
                <p class="text-xs text-gray-400">Sex / ፆታ</p>
                <p class="text-sm">{{ student.student.gender }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white border-gray-200 border-1 rounded-lg py-3">
          <div
            class="border-b-1 h-12 bg-black p-2 flex items-center gap-3"
          ></div>
          <div class="grid grid-cols-8 gap-2 items-center p-2">
            <div class="flex flex-col gap-3 col-span-5 px-6">
              <div class="">
                <p class="text-xs text-gray-400">Phone No / ስልክ ቁጥር</p>
                <p class="capitalize text-sm">
                  +251{{ student.student.phoneNumber[0] }}
                </p>
              </div>
              <div class="">
                <p class="text-xs text-gray-400">Region / ክልል</p>
                <p class="capitalize text-sm">{{ student.student.region }}</p>
              </div>
              <div class="">
                <p class="text-xs text-gray-400">City / ከተማ</p>
                <p class="capitalize text-sm">
                  {{ student.student.city }}
                </p>
              </div>
              <div class="">
                <p class="text-xs text-gray-400">Subcity or Woreda / ክፍለከተማ</p>
                <p class="capitalize text-sm">{{ student.student.address }}</p>
              </div>
            </div>
            <div class="col-span-3">
              <base-qrcode
                :value="student.student.userId"
                :options="{ width: 200 }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions } from 'vuex';
import print from 'vue-print-nb';
import { PROFILE_PATH, LOGO_PATH } from '@/config/config.js';

export default {
  props: ['students'],
  directives: {
    print,
  },
  data() {
    return {
      loading: true,
      PROFILE_PATH,
      LOGO_PATH,
    };
  },
};
</script>
<style scoped>
@media print {
  @page {
    size: portrait;
  }
}
</style>
