<template>
  <div class="flex justify-center">
    <div
      v-for="item in dispayedItems"
      :key="item._id"
      class="text font-semibold px-3 py-2 bg-brighterGray border border-textMedium"
    >
      {{ item.name }}
    </div>
    <div
      v-if="!showAll"
      @click="showHoverModel = !showHoverModel"
      class="text font-semibold px-3 py-2 bg-brighterGray border border-textMedium"
    >
      ...
    </div>
    <!-- <div
      v-if="showHoverModel"
      @mouseleave="showHoverModel = false"
      class="hover-model"
    >
      <div
        v-for="item in items.slice(items_no)"
        :key="item._id"
        class="hover-grade-item"
      >
        {{ item.name }}
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, default: () => [] },
    showAll: { type: Boolean, default: false },
    items_no: { type: Number, default: 4 },
  },
  data() {
    return {
      showHoverModel: false,
    };
  },
  computed: {
    dispayedItems() {
      return this.showAll ? this.items : this.items.slice(0, this.items_no);
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
