<template>
  <UserDetail :userId="id" userType="teacher" routeURL="school/teacherProfile/">
  </UserDetail>
</template>

<script>
import UserDetail from '@/views/protected/user_management/student_management/components/UserDetails.vue';
export default {
  props: {
    id: { type: String },
  },
  components: {
    UserDetail,
  },
};
</script>

<style></style>
