<template>
  <div>
    <div class="bg-white p-3 py-2 mb-2 flex justify-between">
      <p class="text-textDarkest font-semibold">Lab Management</p>
      <base-button
        label="Categories"
        class="py-0"
        @click="handleOpenCategoryList"
      />
    </div>
    <div class="bg-white px-3 py-8">
      <d-table
        #default="{ item }"
        :headers="headers"
        :items="items"
        :hasActions="true"
        :handlers="handlers"
        :letCreate="selectedCategory ? true : false"
        @create="handleAddSimulation"
        :loading="loading"
        :hideColumnSort="true"
      >
        <td v-for="(header, i) in headers" :key="i">
          <div class="px-2">
            <div v-if="header.key === 'profilePic'">
              <div class="flex space-x-5 items-center">
                <div class="h-10 w-10 rounded-full bg-gray-100">
                  <img
                    :src="PROFILE_PATH + item.profilePic"
                    class="w-full h-full object-cover rounded-full"
                    alt=""
                  />
                </div>
                <div>
                  <p
                    class="text-lg text-textDarkest uppercase text-textDarkest"
                  >
                    {{ item.firstName + ' ' + item.lastName }}
                  </p>
                  <p class="text-textLight">{{ item.email }}</p>
                </div>
              </div>
            </div>
            <div v-else-if="header.key === 'status'">
              <status-chip
                :label="item['status']"
                style="max-width: fit-content"
              />
            </div>
            <div v-else-if="header.key === 'cover'">
              <div class="w-10 h-10">
                <img
                  :src="LAB_METADATA_PATH + item.coverPage"
                  class="h-full w-full object-cover rounded-default"
                  alt=""
                />
              </div>
            </div>
            <div v-else-if="header.key === 'parent'">
              <p v-if="item['parent']['name']">
                {{ getProp(item, header.key) }}
              </p>
              <p v-else>Not Available</p>
            </div>
            <div v-else>
              {{ getProp(item, header.key) }}
            </div>
          </div>
        </td>
      </d-table>
    </div>
  </div>
</template>

<script>
import eventBus from '../../../eventBus';
import DTable from '@/components/table/DTable.vue';
import { getPropByString as getProp } from '@/utils';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import { LAB_METADATA_PATH } from '@/config/config.js';
export default {
  components: {
    DTable,
  },
  data() {
    return {
      LAB_METADATA_PATH,
      selectedCategory: '',
      items: [],
      loading: false,
      headers: [
        { label: '', key: 'cover' },
        { label: 'Name', key: 'name' },
        { label: 'Parent', key: 'parent.name' },
        { label: 'Status', key: 'status' },
      ],
      handlers: [
        {
          label: 'Edit',
          icon: 'pencil-alt',
          handler: this.handleUpdateBook,
          fullParam: true,
        },
        {
          label: 'Detail',
          icon: 'eye',
          handler: this.handleDetail,
          idParam: true,
        },
        {
          label: 'Remove',
          icon: 'times',
          handler: this.handleRemove,
          idParam: true,
        },
      ],
    };
  },
  computed: {
    ...mapState('main', ['error']),
  },
  methods: {
    ...mapActions('main', ['request']),
    getProp,
    handleOpenCategoryList() {
      eventBus.$emit('open-modal', {
        modalId: 18,
        cb: this.onCategorySelected,
      });
    },

    onCategorySelected({ response }) {
      this.selectedCategory = response.category;
      this.fetchItems();
    },
    handleEdit(fullParam) {
      let componentProps = {
        category: fullParam,
      };
      eventBus.$emit('open-modal', {
        modalId: 14,
        cb: this.fetchItems,
        componentProps,
      });
    },

    async handleRemove(id) {
      let response = await this.request({
        method: 'delete',
        url: 'reb/lab/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'deleted successfully' });
        this.fetchItems();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    handleAddSimulation() {
      let componentProps = {
        parent: this.selectedCategory,
      };

      eventBus.$emit('open-modal', {
        modalId: 19,
        cb: this.fetchItems,
        componentProps,
      });
    },

    handleUpdateBook(simulation) {
      let componentProps = {
        simulation,
      };
      eventBus.$emit('open-modal', {
        modalId: 19,
        cb: this.fetchItems,
        componentProps,
      });
    },

    async fetchItems() {
      this.loading = true;
      this.items = [];
      let url = 'reb/lab/';
      if (this.selectedCategory)
        url = 'reb/lab/parent/' + this.selectedCategory;

      let response = await this.request({
        method: 'get',
        url,
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
      this.loading = false;
    },
  },

  created() {
    this.fetchItems();
  },
};
</script>

<style></style>
