<template>
  <div>
    <base-title title="Subjects">
      <template #actions>
        <div class="flex">
          <base-button
            icon="plus"
            label="Add New"
            class="px-3"
            @click="handleCreateNew"
            code="sub-add"
          />
        </div>
      </template>
    </base-title>
    <div class="bg-white p-5 mt-2 rounded-default">
      <d-table
        #default="{ item }"
        :headers="headers"
        :items="items"
        :hasActions="true"
        :handlers="handlers"
        :loading="loading"
        :hideSearch="true"
        :hideFilter="true"
        code="sub-view"
        :hideColumnSort="true"
      >
        <td v-for="(header, i) in headers" :key="i">
          <div class="px-2">
            <!-- <div v-if="header.key === 'name'">
              <p class="text font-semibold uppercase text-textDarkest">
                {{ item[header.key] }}
              </p>
            </div> -->
            <div v-if="header.key === 'name'">
              <div class="flex space-x-5 items-center">
                <icon
                  name="course"
                  class="w-20 h-20 object-cover rounded-lg"
                  alt=""
                />
                <div class="flex space-x-3 items-center">
                  <p class="font-semibold uppercase text-textDarkest">
                    {{ item.name }}
                  </p>
                </div>
              </div>
            </div>
            <div v-else-if="header.key === 'class'" class="flex space-x-3">
              <base-list :items="item.classes"> </base-list>
            </div>
            <div v-else-if="header.key === 'status'">
              <status-chip
                :label="item[header.key]"
                style="max-width: fit-content"
              />
            </div>
            <div v-else>
              {{ getProp(item, header.key) }}
            </div>
          </div>
        </td>
      </d-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DTable from '@/components/table/DTable.vue';
import { getPropByString as getProp } from '@/utils';
import eventBus from '@/eventBus.js';
export default {
  components: {
    DTable,
  },
  data() {
    return {
      loading: false,

      handlers: [
        // {
        //   label: 'Detail',
        //   icon: 'eye',
        //   handler: this.handleDetail,
        //   idParam: true,
        // },

        {
          label: 'Edit',
          icon: 'pencil-alt',
          handler: this.handleEdit,
          fullParam: true,
          filter: () => this.authCodes.includes('sub-edit'),
        },
        {
          label: 'Delete',
          icon: 'trash-alt',
          handler: this.handleDelete,
          idParam: true,
          filter: () => this.authCodes.includes('sub-rm'),
        },
      ],
      items: [],
      headers: [
        { label: 'Name', key: 'name' },
        { label: 'Code', key: 'code' },
        { label: 'Class', key: 'class' },
        { label: 'Status', key: 'status' },
      ],
    };
  },
  computed: {
    ...mapGetters(['authCodes']),
  },
  methods: {
    ...mapActions('main', ['request']),
    handleCreateNew() {
      eventBus.$emit('open-modal', {
        modalId: 36,
        cb: this.fetchDocumentsList,
      });
    },

    getProp,

    async fetchDocumentsList() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'school/subject/me',
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
      this.loading = false;
    },

    async handleEdit(item) {
      // item.classes = item.classes.map(({ _id }) => _id);
      let componentProps = {
        // oldForm: item,
        oldForm: {
          name: item.name,
          classes: item.classes.map(({ _id }) => _id),
          status: item.status,
          _id: item._id,
        },
      };
      eventBus.$emit('open-modal', {
        modalId: 36,
        componentProps,
        cb: this.fetchDocumentsList,
      });
    },

    handleChangeStatus(item) {
      let componentProps = {
        id: item._id,
        status: item.status,
      };
      eventBus.$emit('open-modal', {
        modalId: 6,
        componentProps,
        cb: this.fetchDocumentsList,
      });
    },

    handleDelete(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the subject',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },
    async proceedDelete({ id }) {
      console.log('skdjflk', id);
      let response = await this.request({
        method: 'delete',
        url: 'school/subject/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'deleted successfully' });
        await this.fetchDocumentsList();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },

  async created() {
    await this.fetchDocumentsList();
  },
};
</script>

<style></style>
