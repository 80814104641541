<template>
  <div ref="main-width" class="overflow-auto">
    <!-- {{ mainWidth }} -->
    <!-- {{ bodyWidth }} -->
    <loader type="list" :amount="15" v-if="loading"></loader>

    <!-- table -->
    <div
      v-else-if="!isEmptyArr(courses) && !isEmptyArr(studentResults)"
      class="border-grayBg border-1 w-full"
    >
      <!-- header -->
      <div class="flex">
        <div
          class="border-grayBg border-l-1"
          :style="{
            width: '288px',
            'min-width': '288px',
          }"
        ></div>
        <div
          v-for="(subject, i) in subjectHeaders"
          :key="i"
          class="border-grayBg border-l-1 bg-success text-white py-2 px-2"
          :style="{
            width: cellWidth * subject.items.length + 'px',
            'min-width': cellWidth * subject.items.length + 'px',
          }"
        >
          <p
            v-if="subject.isReport"
            class="whitespace-nowrap overflow-ellipsis text-center text-sm truncate"
            :title="subject.label"
          >
            SUMMARY
          </p>
          <p
            v-else
            class="whitespace-nowrap overflow-ellipsis text-center text-sm truncate"
            :title="subject.label"
          >
            {{ subject.label }}
          </p>
        </div>
      </div>
      <div class="flex border-grayBg border-b-1">
        <div
          :style="{
            width: '288px',
            'min-width': '288px',
          }"
        >
          <div class="px-5 py-10">
            <div class="flex space-x-3 items-center">
              <div class="h-3 w-3 rounded-full bg-success"></div>
              <p>Good</p>
            </div>
            <div class="flex space-x-3 items-center">
              <div class="h-3 w-3 rounded-full bg-orange"></div>
              <p>Medium</p>
            </div>
            <div class="flex space-x-3 items-center">
              <div class="h-3 w-3 rounded-full bg-error"></div>
              <p>Poor</p>
            </div>
          </div>
        </div>
        <div
          v-for="(assessment, i) in assessmentItems"
          :key="i"
          class="border-grayBg border-l-1"
          :style="{
            width: cellWidth + 'px',
            'min-width': cellWidth + 'px',
          }"
        >
          <div
            class="transform rotate-180 flex space-y-3 justify-between items-center py-2"
            style="writing-mode: vertical-rl"
          >
            <p class="text-textDark" v-if="assessment.isTotal">Total</p>
            <p class="text-textDark" v-if="assessment.isAverage">Average</p>
            <p class="text-textDark" v-if="assessment.isRank">Rank</p>
            <p class="text-textDark" v-if="assessment.isGrade">Grade</p>

            <p class="text-textDark" v-if="assessment.isStuTotal">Total</p>
            <p class="text-textDark" v-if="assessment.isStuAverage">Average</p>
            <p class="text-textDark" v-if="assessment.isStuRank">Rank</p>
            <p class="text-textDark" v-if="assessment.isStuStatus">Status</p>

            <p class="text-textDark" v-else>{{ assessment.name }}</p>
            <icon
              name="check-circle"
              class="transform rotate-90 text-success"
            />
          </div>
        </div>
      </div>

      <!-- body -->
      <base-no-data
        v-if="isEmptyArr(studentResults)"
        message="no students are enrolled to this section"
      />
      <div v-else ref="body">
        <div
          class="flex border-grayBg border-b-1"
          v-for="(studentResult, k) in studentResults"
          :key="k"
        >
          <div style="width: 288; min-width: 288px">
            <div
              class="px-3 overflow-ellipsis py-1 space-x-3 flex items-center"
            >
              <div
                class="h-8 w-8 rounded-full bg-gray-100 flex justify-center items-center"
              >
                <img
                  v-if="studentResult.profilePic"
                  :src="PROFILE_PATH + studentResult.profilePic"
                  class="w-full h-full object-cover rounded-full"
                  v-viewer="{ inline: false, movable: true }"
                  alt=""
                  crossorigin="anonymous"
                />
                <icon
                  v-else
                  :size="18"
                  name="user"
                  class="w-full h-full"
                ></icon>
              </div>
              <p
                class="capitalize text-sm text-textMedium cursor-pointer hover:text-secondary"
              >
                {{
                  studentResult.firstName +
                  '  ' +
                  studentResult.middleName +
                  '  ' +
                  studentResult.lastName
                }}
              </p>
            </div>
          </div>
          <div
            v-for="(assessmentItem, i) in assessmentItems"
            :key="i"
            class="border-white border-l-1 border-b-1"
            style=""
            :style="{
              width: cellWidth + 'px',
              'min-width': cellWidth + 'px',
            }"
          >
            <grade-block
              :value="studentResult.results"
              :results="studentResult.results"
              :total="studentResult.total"
              :rank="studentResult.rank"
              :average="studentResult.average"
              :status="studentResult.status"
              :assessmentItem="assessmentItem"
              :cta="assessmentItem.cta"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- No Data -->
    <base-no-data v-else message="no courses are assigned to this section" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import { isEmptyArr } from '@/utils';
import GradeBlock from './GradeBlock.vue';
import { PROFILE_PATH } from '@/config/config.js';
export default {
  props: ['ay_id'],
  components: {
    GradeBlock,
  },
  data() {
    return {
      isEmptyArr,
      PROFILE_PATH,
      bodyWidth: 0,
      mainWidth: 0,
      courses: [],
      loading: false,
      studentResults: [],
      colors: {
        good: {
          text: '#ec3118',
          bg: '#ffe0e0',
        },
        medium: {
          text: '',
          bg: '',
        },
        poor: {
          text: '#ec3118',
          bg: '#ffe0e0',
        },
      },
      manualAssessmentResult: {},
    };
  },
  computed: {
    ...mapState('main', ['error']),
    cellWidth() {
      // return (this.bodyWidth - 288) / 25;
      return 50;
    },

    subjectHeaders() {
      return this.courses.map((course) => ({
        label: course.course,
        value: course._id,
        items: course.items,
        isReport: course.isReport,
      }));
    },

    assessmentItems() {
      let items = [];
      this.subjectHeaders.forEach((header) => {
        if (header.items) {
          header.items.forEach((item) => {
            items.push({
              cta: header.value ? header.value : 'overall',
              ...item,
            });
          });
        }
      });

      return items;
    },
  },

  watch: {
    ay_id() {
      // this.fetchData();
      this.fetchRoaster();
    },
  },
  // watch: {
  //   ay_id(newVal, oldVal) {
  //     if (newVal !== oldVal) {
  //       this.fetchData();
  //     }
  //   },
  // },
  methods: {
    ...mapActions('main', ['request']),
    async fetchRoaster() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'school/studentAcademic/mark-list/' + this.ay_id,
      });
      if (response && !response.error) {
        this.studentResults = response.students;
        this.courses = response.courses;
      } else {
        this.studentResults = [];
        this.courses = [];
      }
      this.loading = false;
    },
  },
  mounted() {
    this.bodyWidth = this.$refs['body'].clientWidth;
    this.mainWidth = this.$refs['main-width'].clientWidth;
  },
  async created() {
    await this.fetchRoaster();
    // await this.fetchData();
  },
};
</script>

<style></style>
