var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisible && (_vm.code == '' || _vm.authCodes.includes(_vm.code)))?_c('div',{staticClass:"base-btn tracking-widest cursor-pointer flex space-x-1 items-center justify-center rounded-default",class:{
    'bg-primary text-white': _vm.primary && !_vm.outlined,
    ' bg-white border-gray-200 border hover:translate-x-2 hover:text-primary hover:border-gray-300':
      _vm.outlined,
    'bg-textLight text-white opacity-50 cursor-not-allowed': _vm.working,
    idle: !_vm.working,
    'p-0': _vm.dense,
    'p-1': !_vm.dense,
    'cursor-not-allowed': _vm.disabled,
  },attrs:{"title":_vm.title},on:{"click":_vm.handleClick}},[(_vm.working)?_c('icon',{staticClass:"animate-spin",attrs:{"size":_vm.iconSize,"name":"spinner"}}):_vm._e(),(!_vm.working && _vm.icon && !_vm.iconRight)?_c('icon',{attrs:{"size":_vm.iconSize,"name":_vm.icon}}):_vm._e(),(_vm.label)?_c('div',{staticClass:"text-sm py-1 px-2"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(!_vm.working && _vm.icon && _vm.iconRight)?_c('icon',{attrs:{"size":_vm.iconSize,"name":_vm.icon}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }