<template>
  <div ref="main" class="h-full">
    <div
      v-if="mode === 'view'"
      class="flex justify-center cursor-text select-none text-sm h-full items-center"
      :style="{
        'background-color': colors[value.label]
          ? colors[value.label]['bg']
          : '#fef08a',
        color: colors[value.label] ? colors[value.label]['text'] : 'black',
      }"
    >
      {{ value.result }}
    </div>
    <!-- <div v-else class="w-full h-full flex items-center justify-center">
      <input
        type="number"
        name=""
        v-model="value.result"
        id=""
        class="w-full outline-none"
      />
    </div> -->
  </div>
</template>

<script>
export default {
  props: ['results', 'assessmentItem', 'total', 'average', 'rank', 'status'],
  data() {
    return {
      mode: 'view', /// view, edit,
      // width: 0,
      colors: {
        high: {
          text: '#379237', //'#033988',
          bg: '#e5fdd1', //fffbe9
        },
        moderate: {
          text: '#f2921d',
          bg: '#fffbeb',
        },
        low: {
          text: '#ec3118',
          bg: '#ffe0e0',
        },
      },
    };
  },

  computed: {
    isCourseTotal() {
      return Boolean(this.assessmentItem.isTotal);
    },

    isCourseGrade() {
      return Boolean(this.assessmentItem.isGrade);
    },

    isCourseRank() {
      return Boolean(this.assessmentItem.isRank);
    },

    isAverage() {
      return Boolean(this.assessmentItem.isStuAverage);
    },
    isRank() {
      return Boolean(this.assessmentItem.isStuRank);
    },
    isTotal() {
      return Boolean(this.assessmentItem.isStuTotal);
    },
    isStatus() {
      return Boolean(this.assessmentItem.isStuStatus);
    },
    value() {
      let val = {
        result: 0,
        label: 'low',
      };
      let id = this.assessmentItem._id;
      let cta = this.assessmentItem.cta;
      //

      //
      //
      if (this.isCourseRank) {
        id = cta + '-rank';
        console.log('cta: ', id);
        if (this.results[id]) val.result = this.results[id];
      } else if (this.isCourseTotal) {
        id = cta + '-total';
        if (this.results[id]) val.result = this.results[id];
      } else if (this.isCourseGrade) {
        id = cta + '-grade';
        if (this.results[id]) val.result = this.results[id];
      } else if (this.isAverage) {
        console.log('isAverage: ', this.isAverage);
        console.log('average: ', this.average);

        val.result = this.average;
      } else if (this.isRank) {
        val.result = this.rank;
      } else if (this.isTotal) {
        val.result = this.total;
      } else if (this.isStatus) {
        val.result = this.status;
      } else {
        if (this.results[id]) val = this.results[id];
      }
      //

      // if (this.results[id]) {
      //   return this.results[id];
      // return 1;
      // }

      return val;
    },
    scoreStatus() {
      let random = this.value * Math.random(1);
      if (random > 0 && random < 3) return 'good';
      else if (random >= 3 && random < 7) return 'medium';
      else return 'poor';
    },
  },
  methods: {
    onEnter() {
      this.mode = 'view';
    },
    handleEdit() {
      if (this.mode === 'view') this.mode = 'edit';
      else this.mode = 'view';
    },
  },

  mounted() {
    //
    // // this.width = this.$refs.main.clientWidth;
  },
};
</script>

<style></style>
