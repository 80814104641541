<template>
  <div class="flex flex-col space-y-3">
    <interpole-actions @insert-interpole="insertInterpole" />
    <div class="bg-white p-3">
      <pre>{{ selectionStart }}</pre>
      <pre>{{ selectionEnd }}</pre>
      <base-text-area
        ref="message-input"
        placeholder="Enter your message"
        v-model="message"
      />
    </div>
  </div>
</template>
<script>
import InterpoleActions from './InterpoleActions.vue';

export default {
  components: { InterpoleActions },
  data() {
    return {
      message: '',
      selectionStart: 0,
      selectionEnd: 0,
    };
  },
  watch: {
    '$refs': {
      deep: true,
      immediate: true,
      handler: function (val,) {
        // if (!val) return;
        
        this.selectionStart = val.selectionStart;
        this.selectionEnd = val.selectionEnd;
      },
    },
  },
  computed: {
    },

  methods: {
    insertInterpole(interpole) {
      const textarea = this.$refs['message-input'];

      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;

      const textBefore = textarea.value.substring(0, startPos);
      const textAfter = textarea.value.substring(endPos, textarea.value.length);

      this.message = textBefore + interpole + textAfter;
    },
  },

  mounted() {},

  created() {},
};
</script>
