<template>
  <div class=" bg-white p-5 border-gray-100">
    <p class=" text-textLight font-semibold">Overview</p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
