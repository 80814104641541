<template>
  <div class="flex flex-col space-y-3 bg-brightGray">
    <message-form />
  </div>
</template>
<script>
import MessageForm from '../components/MessageForm.vue';
export default {
  components: { MessageForm },
  data() {
    return {};
  },
  computed: {
    },

  methods: {},

  mounted() {},

  created() {}
};
</script>
