import SignIn from '../views/open/SignIn.vue'
export default [
  {
    path: '/signin',
    component: SignIn,
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
]
