<template>
  <div class=" bg-white border border-gray-100 pb-4">
    <div class=" p-5">
      <div class=" flex space-x-5 items-center">
        <base-button
          :primary="false"
          class=" bg-grayBg py-0"
          label="top"
          @click="backtop"
          v-show="selectedCurriculumCategory"
        />
        <div
          v-for="(category, i) in categoryHistory"
          :key="i"
          class=" cursor-pointer space-x-3 items-center flex"
          @click="handleSelectCurriculumCategory(category, true, i)"
        >
          <p class=" text-textDarkest font-semibold hover:text-orange">
            {{ category.name }}
          </p>
          <div>
            <icon
              name="chevron-right"
              class=" text-orange"
              v-if="categoryHistory.length"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- header -->
    <div class=" flex justify-between p-5">
      <div class=" flex">
        <base-input
          class=" rounded-none"
          style="border-radius: 0px;"
          placeholder="Search Course Content"
          :rounded="false"
        />
        <base-button label="Search" />
      </div>
    </div>
    <!-- list area -->
    <div class=" flex-grow overflow-y-auto mx-2 bg-white">
      <!-- header -->
      <div class=" px-3 py-2">
        <p></p>
        <p class=" text text-textDark font-semibold">
          {{ selectedCurriculumCategory.name }}
        </p>
      </div>
      <div class=" divide-y divide-gray-100">
        <course-content-entry
          v-for="(curriculumCategory, i) in curriculumCategories"
          contentType="curriculum_category"
          :content="curriculumCategory"
          :key="i"
          :index="i"
          @click="handleSelectCurriculumCategory(curriculumCategory)"
          @updated="fetchCurriculumCategories"
          @deleted="fetchCurriculumCategories"
          @movedown="handleMoveDown(i, 'category')"
          @moveup="handleMoveUp(i, 'category')"
        />
        <course-content-entry
          v-for="(content, i) in contents"
          :contentType="content.contentType"
          :content="content"
          :key="i"
          :index="i"
          :started="STARTED_CONTENTS.includes(content._id)"
          :completed="COMPLETED_CONTENTS.includes(content._id)"
          @click="handleDisplayContent"
          @updated="fetchContents"
          @deleted="fetchContents"
          @movedown="handleMoveDown(i, 'content')"
          @moveup="handleMoveUp(i, 'content')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import eventBus from '../../../../eventBus';
import CourseContentEntry from '@/views/protected/content/components/CourseContentEntry.vue';
export default {
  props: {
    courseId: {
      required: true
    },
    ctaId: {
      type: String,
      required: false
    }
  },
  components: {
    CourseContentEntry
  },
  data() {
    return {
      isContentDirty: false,
      isCategoryDirty: false,
      selectedCurriculumCategory: '',
      curriculumCategories: [],
      contents: [],
      categoryHistory: [],
      progressLog: []
    };
  },
  computed: {
    ...mapState('main', ['error']),
    insideSchool() {
      return Boolean(this.ctaId);
    },

    COMPLETED_CONTENTS() {
      let tmpArr = [];
      this.progressLog.forEach(progress => {
        if (progress.status === 'completed') tmpArr.push(progress.content);
      });

      return tmpArr;
    },

    STARTED_CONTENTS() {
      let tmpArr = [];
      this.progressLog.forEach(progress => {
        if (progress.status === 'started') tmpArr.push(progress.content);
      });

      return tmpArr;
    }
  },

  methods: {
    ...mapActions('main', ['request']),

    handleMoveUp(index, type) {
      if (index == 0) return;
      if (type === 'category') {
        let category = this.curriculumCategories.splice(index, 1)[0];
        this.curriculumCategories.splice(index - 1, 0, category);
        this.isCategoryDirty = true;
      } else {
        let content = this.contents.splice(index, 1)[0];
        this.contents.splice(index - 1, 0, content);
        this.isContentDirty = true;
      }
    },

    handleMoveDown(index, type) {
      if (index == this.curriculumCategories.length - 1) return;
      if (type === 'category') {
        let category = this.curriculumCategories.splice(index, 1)[0];
        this.curriculumCategories.splice(index + 1, 0, category);
        this.isCategoryDirty = true;
      } else {
        let content = this.contents.splice(index, 1)[0];
        this.contents.splice(index + 1, 0, content);
        this.isContentDirty = true;
      }
    },

    handleAddContent() {
      let componentProps = {
        courseId: this.courseId,
        curriculumCategoryId: this.selectedCurriculumCategory._id
      };
      eventBus.$emit('open-modal', {
        modalId: 5,
        componentProps,
        cb: this.fetchContents
      });
    },

    handleAddCategory() {
      let componentProps = {
        courseId: this.courseId,
        curriculumCategoryId: this.selectedCurriculumCategory._id
      };
      eventBus.$emit('open-modal', {
        modalId: 6,
        componentProps,
        cb: this.fetchCurriculumCategories
      });
    },

    async fetchContents() {
      if (!this.selectedCurriculumCategory) return (this.contents = []);

      let response = await this.request({
        method: 'get',
        url: 'reb/courseContent/category/' + this.selectedCurriculumCategory._id
      });
      if (response && !response.error) {
        this.contents = response.record;
      } else {
        this.contents = [];
      }
    },

    async fetchCurriculumCategories() {
      let url = 'reb/curriculumCategory/heads/' + this.courseId;
      if (this.selectedCurriculumCategory)
        url =
          'reb/curriculumCategory/nodes/' + this.selectedCurriculumCategory._id;

      let response = await this.request({ method: 'get', url });
      if (response && !response.error) {
        this.curriculumCategories = response.record;
      } else {
        this.curriculumCategories = [];
      }
    },

    backtop() {
      this.categoryHistory = [];
      this.selectedCurriculumCategory = '';
      this.fetchContents();
      this.fetchCurriculumCategories();
    },

    handleSelectCurriculumCategory(curriculumCategory, isHumbergur, index) {
      if (isHumbergur) {
        this.categoryHistory = this.categoryHistory.slice(0, index + 1);
      } else this.categoryHistory.push(curriculumCategory);

      this.selectedCurriculumCategory = curriculumCategory;
      this.fetchContents();
      this.fetchCurriculumCategories();
    },

    handleDisplayContent(content) {
      
      this.$router.push({
        name: 'n-content-display-page',
        params: {
          courseId: this.courseId,
          categoryId: this.selectedCurriculumCategory._id,
          contentId: content._id,
          ctaId: this.insideSchool ? this.ctaId : undefined
        }
      });
    },

    async handleSave() {
      if (this.isContentDirty) await this.handleSaveContentSequence();
      if (this.isCategoryDirty) await this.handleSaveCategorySequence();
      this.isContentDirty = false;
      this.isCategoryDirty = false;
    },

    async handleSaveContentSequence() {
      let sequence = this.contents.map((content, index) => ({
        content: content._id,
        sequence: index + 1
      }));

      let response = await this.request({
        method: 'put',
        url: 'reb/courseContent/sequence',
        data: { contents: sequence }
      });
      if (response && !response.error) {
        this.item = response.item;
        eventBus.emit('open-toast', { message: 'content successfully' });
        this.fetchContents();
        this.fetchCurriculumCategories();
      } else {
        eventBus.emit('open-toast', {
          message: this.error,
          error: true
        });
      }
    },

    async handleSaveCategorySequence() {
      let sequence = this.curriculumCategories.map((category, index) => ({
        category: category._id,
        sequence: index + 1
      }));

      let response = await this.request({
        method: 'put',
        url: 'reb/curriculumCategory/sequence',
        data: { categories: sequence }
      });
      if (response && !response.error) {
        this.item = response.item;
        eventBus.emit('open-toast', { message: 'item updated' });
        this.fetchContents();
        this.fetchCurriculumCategories();
      } else {
        eventBus.emit('open-toast', {
          message: this.error,
          error: true
        });
      }
    }
  },

  created() {
    this.fetchContents();
    this.fetchCurriculumCategories();
  }
};
</script>

<style></style>
