<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title> create category</template>
    <template>
      <div class=" space-y-5">
        <base-input
          label="Name"
          placeholder="category name"
          v-model="Form.name"
          :errors="errors.name"
        />
        <base-select :items="PARENT_OPTIONS" v-model="Form.parent" />
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions, mapState } from 'vuex';

export default {
  components: { ModalTemplate },
  props: ['parent', 'category'],
  data() {
    return {
      Form: {
        name: '',
        parent: this.parent
      },
      errors: {},
      parents: []
    };
  },
  computed: {
    ...mapState('main', ['error']),
    ...mapState('main', 'error'),
    PARENT_OPTIONS() {
      return this.parents.map(parent => ({
        label: parent.name,
        value: parent._id
      }));
    },

    isUpdate() {
      return Boolean(this.category);
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },

    async handleSave() {
      if (!this.Form.parent) delete this.Form.parent;

      let response = await this.request({
        method: this.isUpdate ? 'put' : 'post',
        url: 'reb/labCategory/' + (this.isUpdate ? this.category._id : ''),
        data: this.Form
      });
      if (response && !response.error) {
        this.handleClose(true);
        eventBus.$emit('open-toast', {
          message: 'category created successfully'
        });
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true
        });
      }
    },

    async fetchItems() {
      let response = await this.request({
        method: 'get',
        url: 'reb/labCategory'
      });
      if (response && !response.error) {
        this.parents = response.record;
      } else {
        this.parents = [];
      }
    },

    populate() {
      this.Form.name = this.category.name;
      this.Form.parent = this.category.parent;
    }
  },
  created() {
    this.fetchItems();
    if (this.isUpdate) this.populate();
  }
};
</script>

<style></style>
