export function validateConfig(configs) {
  let allHasId = configs.every(config => 'id' in config);
  let allHasUniqueId = configs.every(config => {
    let unique = configs.filter(({ id }) => id === config.id).length < 2;
    return unique;
  });

  if (!allHasId) {
    console.error('Modal Configuration Error: id is required.');
    return false;
  }

  if (!allHasUniqueId) {
    console.error('Modal Configuration Error: id must be unique.');
    return false;
  }

  return true;
}
