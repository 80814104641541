<template>
  <div>
    <div>
      <base-title title="Manage Assessments" class="mb-2">
        <template #actions>
          <div class="flex justify-center items-center space-x-2">
            <base-button
              label="Add New"
              icon="plus"
              @click="handleAssessment"
              code="aca-add"
              class="bg-gray-100 flex-shrink-0"
            />
            <base-select
              placeholder=""
              v-model="activeGrade"
              :items="GRADE_OPTIONS"
              searchable
            />
          </div>
        </template>
      </base-title>

      <loader type="list" :amount="6" v-if="loading"></loader>

      <div class="bg-white p-5 mt-2 rounded-default">
        <d-table
          #default="{ item }"
          :headers="headers"
          :items="items"
          :totalItems="items.length"
          :hasActions="true"
          :handlers="handlers"
          :loading="loading"
          code="dis-view"
          :hideColumnSort="true"
        >
          <td v-for="(header, i) in headers" :key="i">
            <div class="px-2">
              <div v-if="header.key === 'name'">
                <p class="text font-semibold text-textDarkest uppercase">
                  {{ item[header.key] }}
                </p>
              </div>

              <div v-else-if="header.key === 'status'">
                <status-chip
                  :label="item[header.key]"
                  style="max-width: fit-content"
                />
              </div>
              <div class="px-2" v-else>
                {{ getProp(item, header.key) }}
              </div>
            </div>
          </td>
        </d-table>
      </div>
    </div>
  </div>
</template>

<script>
import DTable from '@/components/table/DTable.vue';
import { mapActions, mapGetters } from 'vuex';
import { getPropByString } from '@/utils.js';
import eventBus from '@/eventBus.js';

export default {
  components: {
    DTable,
  },
  data() {
    return {
      activeGrade: '',
      gradeItems: [],
      items: [],
      headers: [
        { label: 'Name', key: 'name' },
        { label: 'Maximum Mark', key: 'maxResult' },
        { label: 'description', key: 'description' },
        { label: 'Status', key: 'status' },
      ],
      handlers: [
        {
          label: 'Edit',
          icon: 'pencil-alt',
          handler: this.handleEditItem,
          fullParam: true,
          filter: () => this.authCodes.includes('pg-edit'),
        },
        {
          label: 'Delete',
          icon: 'trash-alt',
          handler: this.handleDeleteItem,
          idParam: true,
          filter: () => this.authCodes.includes('pg-rm'),
        },
      ],
      working: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['authCodes']),
    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name + ' - ' + grade.enrollment.code,
        value: grade._id,
      }));
    },
  },
  watch: {
    activeGrade() {
      this.fetchAssessments();
    },
  },
  methods: {
    ...mapActions('main', ['request']),

    getProp: getPropByString,

    handleAssessment() {
      eventBus.$emit('open-modal', {
        modalId: 53,
        cb: this.fetchAssessments,
      });
    },

    async fetchAssessments() {
      this.loading = true;
      this.items = [];
      let response = await this.request({
        method: 'get',
        url: 'school/assessment/' + this.activeGrade,
      });
      if (response && !response.error) this.items = response.record;
      else this.items = [];
      this.loading = false;
    },

    handleEditItem(assessment) {
      let componentProps = {
        oldForm: assessment,
        grade: this.activeGrade,
      };
      eventBus.$emit('open-modal', {
        modalId: 54,
        componentProps,
        cb: this.fetchAssessments,
      });
    },

    handleDeleteItem(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the item',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },

    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/assessment/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'assessment deleted successfully',
        });
        this.fetchAssessments();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    async fetchGrades() {
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
        this.activeGrade = response.record[0]._id;
      } else {
        this.gradeItems = [];
        this.activeGrade = '';
      }
    },
  },
  async created() {
    this.working = true;
    await this.fetchGrades();
    this.working = false;
  },
};
</script>

<style></style>
