<template>
  <div class="">
    <div class="flex justify-end p-8">
      <base-button label="print record" v-print="'#printable'" />
    </div>
    <div class="" id="printable">
      <div
        v-for="certificate in certificates"
        :key="certificate"
        class="p-8 grid gap-10"
      >
        <div class="grid grid-cols-2 gap-3">
          <div
            class="bg-yellow-200 rounded-lg p-3 flex flex-col gap-6 py-16 pb-20"
          >
            <div class="flex flex-col gap-4">
              <p class="font-semibold text-xl text-center">Semester 1</p>
              <div class="flex flex-col gap-8">
                <p>Homeroom Teacher's comments</p>
                <hr />
                <hr />
              </div>
              <div class="flex flex-col gap-8">
                <p>Sign</p>
                <hr />
              </div>
              <div class="flex flex-col gap-8">
                <p>Parent's comments</p>
                <hr />
                <hr />
              </div>
              <div class="flex flex-col gap-8">
                <p>Sign</p>
                <hr />
              </div>
            </div>
            <div class="flex flex-col gap-4">
              <p class="font-semibold text-xl text-center">Semester 2</p>
              <div class="flex flex-col gap-8">
                <p>Homeroom Teacher's comments</p>
                <hr />
                <hr />
              </div>
              <div class="flex flex-col gap-8">
                <p>Sign</p>
                <hr />
              </div>
              <div class="flex flex-col gap-8">
                <p>Parent's comments</p>
                <hr />
                <hr />
              </div>
              <div class="flex flex-col gap-8">
                <p>Sign</p>
                <hr />
              </div>
            </div>
          </div>

          <div class="bg-yellow-200 rounded-lg p-3 flex flex-col gap-10 py-16">
            <div class="flex flex-col items-center gap-4">
              <img
                :src="LOGO_PATH + certificate.school.logo"
                class="w-20 h-20 rounded-full"
                alt=""
              />
              <p class="font-bold text-xl">{{ certificate.school }}</p>
              <div class="text-sm flex flex-col items-center gap-1">
                <p>Address: {{ certificate.school }}</p>
                <p>Tel: {{ certificate.school }}</p>
                <p>Website: {{ certificate.school }}</p>
              </div>
            </div>
            <p class="text-center font-semibold">STUDENT REPORT CARD</p>
            <hr class="" />
            <div class="p-4 flex flex-col gap-4">
              <div class="grid grid-cols-3">
                <P class="font-semibold"
                  >Name:
                  <span class="font-normal"
                    >{{ certificate.student }} {{ certificate.student }}</span
                  ></P
                >
                <p class="font-semibold">
                  Sex:
                  <span class="font-normal">{{ certificate.student }}</span>
                </p>
                <p class="font-semibold">
                  Age:
                  <span class="font-normal">{{ certificate.student.age }}</span>
                </p>
                <p class="font-semibold">
                  Grade:
                  <span class="font-normal">{{ certificate.class_name }}</span>
                </p>
                <p class="font-semibold">
                  Section:
                  <span class="font-normal">{{
                    certificate.section_name
                  }}</span>
                </p>
                <p class="font-semibold">
                  Academic Year:
                  <span class="font-normal">{{
                    certificate.academic_year_name
                  }}</span>
                </p>
                <p class="font-semibold">
                  Promoted to:
                  <span class="font-normal">{{
                    Number(certificate.class_name.split(' ')[1]) + 1
                  }}</span>
                </p>
              </div>
            </div>

            <hr class="" />
            <div class="grid grid-cols-2 p-4">
              <div class="">
                <p class="font-semibold">Homeroom Teacher:</p>
                <p>Name: _______________</p>
                <p>Sign: _______________</p>
              </div>
              <div class="">
                <p class="font-semibold">Director:</p>
                <p>Sign: _______________</p>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-3 h-screen">
          <div class="bg-yellow-200 rounded-lg p-3 flex flex-col gap-10 py-10">
            <table>
              <thead>
                <tr class="bg-yellow-300">
                  <th class="border-1 p-2">Subject</th>
                  <th class="border-1 p-2">Mark</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class=""
                  v-for="course in certificate.courses"
                  :key="course"
                >
                  <td class="border-1 p-2">{{ course.course_name }}</td>
                  <td class="border-1 p-2 text-center">{{ course.total }}</td>
                </tr>
                <tr class="text-center">
                  <td class="border-1 p-2 text-right font-semibold">Total</td>
                  <td class="border-1 p-2">{{ certificate.total }}</td>
                </tr>
                <tr class="text-center">
                  <td class="border-1 p-2 text-right font-semibold">Average</td>
                  <td class="border-1 p-2">{{ certificate.average }}</td>
                </tr>
                <tr class="text-center">
                  <td class="border-1 p-2 text-right font-semibold">Rank</td>
                  <td class="border-1 p-2">
                    {{ certificate.rank }}/{{ certificates.length }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bg-yellow-200 rounded-lg p-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import print from 'vue-print-nb';
import { LOGO_PATH } from '@/config/config.js';

export default {
  data() {
    return {
      loading: true,
      LOGO_PATH,
    };
  },
  props: ['certificates'],
  directives: {
    print,
  },
};
</script>
<style scoped>
@media print {
  @page {
    size: landscape;
  }
}
</style>
