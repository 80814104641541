<template>
  <div class="flex items-center gap-2">
    <div
      class="w-4 h-4 rounded-sm text-white flex justify-center items-center border-1 border-gray-400"
      @click="toggle"
      :class="selected ? 'bg-primary border-primary' : ''"
    >
      <icon v-if="selected" name="check" :size="7" />
    </div>
    <div class="flex-auto text-textDark">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: '' },
    size: { type: Number, default: 22 },
    selected: Boolean,
    value: String || Number,
  },
  methods: {
    toggle() {
      this.$emit('toggle', this.value);
    },
  },
};
</script>

<style></style>
