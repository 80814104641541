<template>
  <div class="space-y-4">
    <div class="flex space-x-2 justify-between items-center">
      <div class="flex-auto">
        <slot name="more-head-display"></slot>
      </div>

    </div>
    <div class=" pt-4 ">
      <div class="flex space-x-4  max-w-max ml-auto items-center">
        <div class="flex space-x-2 items-center">
          <div class="font-bold text-textDarkest">{{ title }}</div>
          <div
            v-if="letCreate"
            class="
              p-2
              hover:bg-primary
              hover:text-white
              cursor-pointer
              rounded-full
              lg-grow-active
            "
            @click="createItem"
          >
            <icon name="plus" :size="14" />
          </div>
        </div>
        <div class="flex space-x-1 items-center">
          <div>Show:</div>
          <base-select
            :items="pageSizeOptions"
            v-model="pageSize"
            padding="small"
            background="transparent"
          >
            <template #default="{ item }">
              <div>{{ item.label }}</div>
            </template>
            <template #option="{ items }">
              <div v-for="item in items" :key="item.value">
                {{ item.label }}
              </div>
            </template>
          </base-select>
        </div>
        <div class="flex items-center space-x-3">
          <div>{{ beginning }} - {{ ending }} of {{ items.length }}</div>
          <div class="flex items-center">
            <div
            class="
                p-2
                hover:bg-primary
                hover:text-white
                cursor-pointer
                rounded-full
                lg-grow-active
                "
                @click="goPrevious"
                >
                <icon name="chevron-left" :size="12" />
            </div>
            <div
              class="
                p-2
                hover:bg-primary
                hover:text-white
                cursor-pointer
                rounded-full
                lg-grow-active
              "
              @click="goNext"
            >
              <icon name="chevron-right" :size="12" />
            </div>
          </div>
        </div>
        <div v-if="multiSelect">Selected : {{ rowsSelected.length }}</div>

      </div>
    </div>
    <table class="w-full">
      <thead class="">
        <tr class="  border-1">
          <td
            v-if="multiSelect"
            class="bg-thead  rounded-tl-lg cursor-pointer border-1 bg-gray-200"
            @click="toggleAll"
          >
            <div class="flex items-center p-2 pr-0">
              <div
                class="
                  w-5
                  h-5
                  text-white
                  rounded-sm
                  flex
                  justify-center
                  items-center
                "
                :class="allSelected ? 'bg-primary' : 'dark-gray'"
              >
                <icon name="check" :size="allSelected ? 14 : 12" />
              </div>
            </div>
          </td>
          <td
            v-for="(header, i) in headers"
            :key="header.key"
            class="py-3 pl-0 bg-thead capitalize text-center text-textDarkest font-bold border-1 bg-gray-200"
            :style="i >= 3 && i != headers.length-1? 'writing-mode: vertical-rl' : ''"
            :class="{
              '': !hasSelected && i === 0,
              '': i === headers.length - 1
            }"
          >
          <!--rounded-tl-lg pl-2 rounded-tr-lg -->
            {{ header.label }}
          </td>
          <td
            v-if="hasActions"
            class="py-1 pl-2 bg-thead uppercase text-textLight text-xs font-semibold rounded-tr-lg"
          >
            Actions
          </td>
        </tr>
      </thead>
      <tbody v-if="loading" class="bg-white">
        <tr>
          <td :colspan="headers.length" class="p-6">
            <div class="flex justify-center space-x-2 items-center">
              <icon :size="20" name="spin" class="spinner text-primary" />
              <div>Loading</div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="items.length > 0" class="bg-white border-1">
        <tr
          v-for="(item, i) in itemsDisplayed"
          :key="item._id"
          class=" hover:bg-gray-50 border-1 px-4"
          :class="{ 'cursor-pointer': multiSelect }"
          @click="toggleRow(item._id)"
        >
          <td v-if="multiSelect" class="pt-1 border-1">
            <div class="flex items-center p-2 pr-0">
              <div
                class="
                  w-5
                  h-5
                  text-white
                  rounded-sm
                  flex
                  justify-center
                  items-center
                "
                :class="
                  rowsSelected.includes(item._id) ? 'bg-primary' : 'dark-gray'
                "
              >
                <icon
                  name="check"
                  :size="rowsSelected.includes(item._id) ? 14 : 12"
                />
              </div>
            </div>
          </td>
          <slot :item="item" :index="i" class="border-1">
            <td v-for="(header, i) in headers" :key="header.key" class="py-1 border-1">
              <div class="py-1 border-1" :class="{ 'pl-2': i === 0 }">
                {{ getProp(item, header.key) }}
              </div>
            </td>
          </slot>
          <td v-if="hasActions" class="p-2">
            <div class="flex justify-start items-center">
              <actions :handlers="handlers" :item="item" />
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else class="bg-white">
        <tr>
          <td :colspan="headers.length" class="p-6">
            <div class="flex justify-center text-error">No data</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getPropByString } from '@/utils';
import Actions from '../card/Actions.vue';

export default {
  props: [
    'items',
    'headers',
    'title',
    'handlers',
    'multiSelect',
    'letCreate',
    'loading',
    'pageSize'
  ],
  components: { Actions },
  data() {
    return {
      rowsSelected: [],
      // pageSize: 10,
      pageNumber: 0,
      newestFirst: true,
      pageSizeOptions: [
        { label: '5', value: 5 },
        { label: '10', value: 10 },
        { label: '15', value: 15 },
        { label: '20', value: 20 },
        { label: '70', value: 70 }
      ],
      sortOptions: [
        { label: 'Newest Apply', value: true },
        { label: 'Earliest Apply', value: false }
      ]
    };
  },
  computed: {
    hasActions() {
      if (this.handlers) return true;
      else return false;
    },
    allSelected() {
      return this.items.length === this.rowsSelected.length;
    },
    hasSelected() {
      return this.rowsSelected.length > 0;
    },
    beginning() {
      return this.pageNumber * this.pageSize + 1;
    },
    ending() {
      let ending = this.beginning + this.pageSize - 1;
      if (ending > this.items.length) return this.items.length;
      return ending;
    },
    itemsDisplayed() {
      return this.items.slice(this.beginning - 1, this.ending);
    }
  },
  watch: {
    newestFirst() {
      this.sortitems();
    },
    pageSize() {
      this.pageNumber = 0;
    }
  },
  methods: {
    getProp: getPropByString,
    toggleAll() {
      if (this.allSelected) {
          this.rowsSelected = [];
          this.$emit('selected', this.rowsSelected);
          this.$emit('selectedItems', []);
          return []
      }
      this.rowsSelected = this.items.map(({ _id }) => _id);
      this.$emit('selectedItems', this.rowsSelected);
    },
    toggleRow(id) {
      if (!this.multiSelect) return;
      if (this.rowsSelected.includes(id)){
        this.$emit('selected', this.rowsSelected = this.rowsSelected.filter(
          row => row !== id
          ))
        this.$emit('selectedItems', this.items.filter(v => this.rowsSelected.includes(v._id)))
        return (this.rowsSelected = this.rowsSelected.filter(
          row => row !== id
        ));
      }
      this.rowsSelected.push(id);
      this.$emit('selectedItems', this.rowsSelected);
      if(this.rowsSelected.length == 0) this.$emit('selectedItems', [])
      // this.$emit('selected', this.rowsSelected);
      // let selectedItems = this.items.map(v => this.rowsSelected.includes(v._id))
      // this.$emit('selectedItems', this.rowsSelected);
    },
    hideActionDialog() {
      this.$refs['actions-tt'].tip.hide();
    },
    smartHandler(handler) {
      this.hideActionDialog();
      handler({ itemsSelected: this.rowsSelected });
    },
    goPrevious() {
      let beginningPageReached = this.beginning === 1;
      if (beginningPageReached) return;
      this.pageNumber--;
    },
    goNext() {
      let endOfPageReached = this.items.length - this.beginning < this.pageSize;
      if (endOfPageReached) return;
      this.pageNumber++;
    },
    createItem() {
      this.$emit('create');
    },

    sortitems() {
      if (this.newestFirst) {
        this.items.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      } else {
        this.items.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      }
    }
  },

  created() {
    this.sortitems();
  }
};
</script>

<style>
.dark-gray {
  background-color: #8f92a1;
}
</style>
