<template>
  <div class="py-2 px-4 text-textMedium space-y-2">
    <div class="p-2 text-2xl text-center border-b border-brightGray">
      Select Course
    </div>
    <div class="space-y-1">
      <div>
        <div class="py-2">
          <base-input
            placeholder="Search Course..."
            v-model="search"
            @enter="fetchUser(search)"
            class=""
          />
        </div>
        <div></div>
      </div>
      <div class="space-y-1 overflow-y-auto p-2" style="height: 50vh">
        <div
          v-if="search && filteredCustomers.length === 0 && !working"
          class="text-lg"
        >
          No record matching to your search
        </div>
        <div v-if="!search && !working">
          type in course name or code in the box above.
        </div>
        <div v-if="working" class="w-full mt-10 flex justify-center">
          <bar-loader class="mx-auto w-1/3 block"></bar-loader>
        </div>
        <div v-if="!working">
          <!-- NOTE: INSERT THIS INSTEAD filteredCustomers -->
          <div
            v-for="course in filteredCustomers"
            :key="course._id"
            class="flex items-start space-x-2 cursor-pointer p-2 rounded"
            :class="
              selectedId === course._id || selectedIds.includes(course._id)
                ? 'bg-primary'
                : 'hover:bg-lightGreen'
            "
            @click="selectCourse(course)"
          >
            <div>
              <div
                class="w-16 h-16 rounded bg-brightGray"
                :class="
                  selectedId === course._id || selectedIds.includes(course._id)
                    ? 'bg-white'
                    : 'bg-primary'
                "
              >
                <img
                  class="w-full h-full rounded hover:opacity-75"
                  v-if="course.image"
                  :src="IMAGES_PATH + course.image"
                  v-viewer="{ inline: false, movable: true }"
                  alt=""
                />
              </div>
            </div>
            <div
              :class="
                selectedId === course._id || selectedIds.includes(course._id)
                  ? 'text-white'
                  : 'text-textDark'
              "
              class="w-full"
            >
              <div class="flex justify-space w-full">
                <div
                  class="text-lg uppercase space-x-2 flex items-center"
                  :class="
                    selectedId === course._id ||
                    selectedIds.includes(course._id)
                      ? 'text-white'
                      : 'text-textDark'
                  "
                >
                  <p>{{ course.name }}</p>
                  <!-- <div
                    class=" w-3 h-1 "
                    :class="
                      selectedId === course._id ||
                      selectedIds.includes(course._id)
                        ? 'bg-white'
                        : 'bg-primary'
                    "
                  ></div>
                  <p>{{ course.course_code }}</p> -->
                </div>
                <div class="ml-auto">
                  <icon
                    name="check-circle"
                    :size="21"
                    v-if="selectedId === course._id"
                    class="text-white"
                  />
                </div>
              </div>
              <div
                class="flex space-x-2 items-center"
                :class="
                  selectedId === course._id || selectedIds.includes(course._id)
                    ? 'text-white'
                    : 'text-textDark'
                "
              >
                <div>
                  <p>GRADE</p>
                </div>
                <div
                  class="w-3 h-1"
                  :class="
                    selectedId === course._id ||
                    selectedIds.includes(course._id)
                      ? 'bg-white'
                      : 'bg-primary'
                  "
                ></div>
                <div>{{ course.grade }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <pagination
          :currentPage="currentPage"
          :pageSize="pageSize"
          :totalPages="totalPages"
          @changepage="handlePageChange"
          @changepagesize="handlePageSizeChange"
        />
      </div>
      <div class="flex justify-between pt-4 pb-2">
        <base-button
          label="cancel"
          :primary="false"
          class="py-1 px-4 text-textMedium bg-gray-100 hover:bg-danger hover:text-error rounded"
          @click="cancel"
        />
        <base-button
          v-if="selectedCustomer || selectedCustomers.length"
          label="continue"
          class="py-1 px-4 rounded"
          @click="handleContinue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { IMAGES_PATH } from '@/config/config';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import { stringifyQueryObj } from '@/utils';
import BarLoader from '@/components/collection/BarLoader.vue';
import Pagination from '@/components/pagination/Pagination.vue';

export default {
  components: { BarLoader, Pagination },
  props: {
    multiple: {
      type: Boolean,
    },
    usertype: {
      type: String,
      default: 'student',
    },
  },
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,

      searchByOptions: [
        { label: 'Customer ID', value: 'isCustID' },
        { label: 'Tel', value: 'isTel' },
        { label: 'Name', value: 'isCustName' },
        { label: 'Index', value: 'isIndex' },
      ],
      searchBy: 'isCustName',
      working: false,
      search: '',
      IMAGES_PATH,
      selectedCustomer: undefined,
      selectedCustomers: [],
      customers: [],
    };
  },
  computed: {
    ...mapState('main', ['error']),
    filteredCustomers() {
      //   if (this.search)
      //     return this.customers.filter(customer =>
      //       [
      //         'firstName',
      //         'lastName',
      //         'tel',
      //         'search_index',
      //         'customer_id'
      //       ].some(key => RegExp(this.search, 'i').test(customer[key]))
      //     );
      return this.customers;
    },
    selectedId() {
      return this.selectedCustomer?._id;
    },

    /// for multiple option
    selectedIds() {
      return this.selectedCustomers.map((cus) => cus._id);
    },
  },
  watch: {
    search() {
      if (!this.search) this.selectedCustomer = undefined;
    },
  },
  filters: {
    // beautify: formatePhoneNumber,
  },
  methods: {
    ...mapActions('main', ['request']),
    async fetchUser(search) {
      this.working = true;
      let query_obj = {
        page: this.currentPage || undefined,
        limit: this.pageSize,
        // cust_name: search,
        keyword: search,
      };
      let string_query = stringifyQueryObj(query_obj);

      let url = 'reb/courseCurriculum/search' + string_query;

      let response = await this.request({
        method: 'get',
        url,
        data: { search: search, [this.searchBy]: true },
      });
      this.working = false;
      if (response) {
        this.customers = response.items;
        this.totalPages = Math.ceil(response.total_pages / this.pageSize);
      } else this.customers = [];
    },
    selectCourse(course) {
      if (!this.multiple) {
        if (this.selectedId !== course._id) this.selectedCustomer = course;
        else this.selectedCustomer = undefined;
      } else {
        if (!this.selectedIds.includes(course._id))
          this.selectedCustomers.push(course);
        else
          this.selectedCustomers.splice(
            this.selectedCustomers.findIndex((cus) => cus._id === course._id),
            1
          );
      }
    },
    cancel() {
      this.$emit('close');
    },
    handleContinue() {
      this.$emit('close', {
        invokeCb: true,
        response: {
          customer: this.multiple
            ? this.selectedCustomers
            : this.selectedCustomer,
        },
      });
    },
    handlePageChange(page) {
      if (page >= 1 && page <= this.totalPages) this.currentPage = page;
      this.fetchUser(this.search);
    },

    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.currentPage = 1;
      this.fetchUser(this.search);
    },
  },
};
</script>

<style></style>
