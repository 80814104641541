<template>
  <div class="space-y-2">
    <div class="text-textDarkest">{{ label }}</div>
    <div class="flex space-x-3">
      <div
        class="py-2 px-4 rounded flex items-center space-x-2 cursor-pointer hover:opacity-75"
        :class="{ 'border border-success': item.value === value }"
        :style="{
          boxShadow: item.value === value ? '0 0 5px #3bb979' : '0 0 3px #8993a466',
        }"
        v-for="item in items"
        :key="item.value"
        @click="immutalble ? null : handleToggle(item.value)"
      >
        <icon v-if="item.value === value" name="check-circle" :size="20" fill="#3bb979" />
        <div class="text-textDark tracking-widest">{{ item.label }}</div>
      </div>
    </div>
    <div v-if="!success" class="text-xs" style="color: #ec3118">
      <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: { type: String, default: 'Select' },
    items: { type: Array, default: () => [] },
    immutalble: { type: Boolean, default: false },
    success: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
  },
  methods: {
    handleToggle(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style></style>
