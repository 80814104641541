<template>
  <div>
    <div class=" bg-white p-5">
      Detail View.
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
