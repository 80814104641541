<template>
  <div class="px-2 pb-2 h-full">
    <base-title title="">
      <template #actions>
        <div class="flex">
          <base-button
            label="Update"
            icon="pencil-alt"
            @click="handleUpdateItem"
            class="px-3"
          />
        </div>
      </template>
    </base-title>
    <div class="grid grid-cols-3 text-textDark">
      <div class="col-span-2 flex flex-col space-y-1 pl-6">
        <div
          class="space-y-1 bg-white border-1 rounded py-3 px-6 border-gray-100 shadow-sm"
        >
          <div class="text-textMedium text-lg tracking-wide"></div>
          <div class="grid grid-cols-2 gap-x-3 gap-y-2">
            <base-input
              label="School Name"
              placeholder="School Name"
              v-model="Form.name"
              required
              :errors="errors.name"
              check
            />
            <base-input
              label="School Code"
              placeholder="School Code"
              v-model="Form.code"
              disabled
              required
              :errors="errors.code"
              check
            />
            <base-text-area
              label="Description"
              placeholder="description"
              class="col-span-2"
              v-model="Form.description"
              :errors="errors.description"
              :rows="2"
              check
            />
          </div>
        </div>
        <div
          class="space-y-1 bg-white border-1 rounded py-3 px-6 border-gray-100 shadow-sm"
        >
          <div class="text-textMedium text-lg tracking-wide capitalize">
            contact address
          </div>
          <div class="grid grid-cols-2 gap-x-3 gap-y-4">
            <base-select
              bordered
              label="Region"
              placeholder="Region"
              v-model="Form.region"
              :errors="errors.region"
              :items="REGION_OPTIONS"
              searchable
              check
            />
            <base-input
              label="City"
              placeholder="City"
              v-model="Form.city"
              :errors="errors.city"
              capitalize
              check
            />
            <base-input
              label="Subcity"
              placeholder="Subcity"
              v-model="Form.subcity"
              :errors="errors.subcity"
              capitalize
              check
            />
            <base-input
              label="Street Address"
              placeholder="Street address"
              v-model="Form.address"
              :errors="errors.address"
              capitalize
            />
            <base-input
              label="Email"
              placeholder="email"
              v-model="Form.email"
              class="row-start-3"
              :errors="errors.email"
              check
            />

            <div class="flex flex-col">
              <span>phone Number <span class="text-error">*</span></span>
              <div class="grid grid-cols-6 gap-2">
                <base-select
                  class="col-span-2"
                  :items="COUNTRY_CODE_OPTIONS"
                  v-model="countryCode"
                  minWFull="true"
                />
                <base-multitype
                  class="col-span-4"
                  placeholder="phone number"
                  v-model="Form.phoneNumber"
                  :items="Form.phoneNumber"
                  :errors="errors.phoneNumber"
                  check
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col space-y-8 bg-white border-1 rounded py-3 px-6 border-gray-100 shadow-sm"
      >
        <div class="space-y-2">
          <base-multi-select
            label="Grade Level"
            placeholder="grade level"
            v-model="Form.level"
            :errors="errors.level"
            :items="LEVEL_OPTIONS"
            check
          />

          <base-input
            label="Moto"
            placeholder="moto"
            v-model="Form.moto"
            :errors="errors.moto"
            check
          />
        </div>
        <div
          class="bg-gray-100 p-3 flex space-x-3 items-center px-3 border-2 border-dashed border-textLight rounded-lg cursor-pointer"
          @click="handleOpenDialog('icon-file-input')"
        >
          <input
            type="file"
            ref="icon-file-input"
            @change="handleSelectFile('icon-file-input', 'Form.logo')"
            style="display: none"
            accept=".png, .jpg, .jpeg, .gif"
          />
          <div class="space-y-1" v-if="!logoObj && !Form.icon">
            <icon name="image" class="text-primary" :size="48" />
            <p v-if="!logoObj && !Form.icon">Upload logo</p>
          </div>
          <div v-else class="self-center items-center">
            <img
              :src="logoObj || LOGO_PATH + Form.icon"
              class="w-48 object-cover"
              alt=""
            />
            <p class="text-left">{{ logoObj ? Form.logo.name : Form.logo }}</p>
          </div>
        </div>
        <div class="space-y-2">
          <switch-button
            label="Online Registration"
            v-model="Form.self_enroll"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import objUrl from '@/mixins/objUrl';
import eventBus from '../../../../eventBus';
import { mapActions, mapGetters } from 'vuex';
import SwitchButton from '../../../../components/base/SwitchButton.vue';

export default {
  components: { SwitchButton },
  mixins: [objUrl],
  props: ['id'],
  data() {
    return {
      working: false,
      errors: {},
      logoObj: '',
      Form: {
        name: '',
        code: '',
        region: '',
        city: '',
        subcity: '',
        address: '',
        phoneNumber: [],
        email: '',
        level: '', /// Elementary
        moto: '',
        logo: '',
        description: '',
        self_enroll: false,
        // photo: [] /// list of images
      },
    };
  },

  computed: {
    ...mapGetters(['user']),
    LEVEL_OPTIONS() {
      return [
        { label: 'KG', value: 'kg' },
        { label: 'Elementary', value: 'elementary' },
        { label: 'High School', value: 'highschool' },
        { label: 'Preparatory', value: 'preparatory' },
      ];
    },
    REGION_OPTIONS() {
      return [
        { label: 'Addis Ababa', value: 'Addis Ababa' },
        { label: 'Afar', value: 'Afar' },
        { label: 'Amahara', value: 'Amahara' },
        { label: 'Beninshangul', value: 'Beninshangul' },
        { label: 'Dire Dawa', value: 'Dire Dawa' },
        { label: 'Gambela', value: 'Gambela' },
        { label: 'Harar', value: 'Harar' },
        { label: 'Oromo', value: 'Oromo' },
        { label: 'Sidama', value: 'Sidama' },
        { label: 'Somali', value: 'Somali' },
        { label: 'Southern Region', value: 'Southern Region' },
        { label: 'Tigray', value: 'Tigray' },
      ];
    },
    COUNTRY_CODE_OPTIONS() {
      return [
        { label: '+251', value: '+251' },
        { label: '+1', value: '+1' },
        { label: '+44', value: '+44' },
        { label: '+32', value: '+32' },
        { label: '+33', value: '+33' },
        { label: '+49', value: '+49' },
        { label: '+30', value: '+30' },
        { label: '+249', value: '+249' },
        { label: '+971', value: '+971' },
        { label: '+41', value: '+41' },
        { label: '+86', value: '+86' },
        { label: '+45', value: '+45' },
        { label: '+253', value: '+253' },
        { label: '+20', value: '+20' },
        { label: '+291', value: '+291' },
      ];
    },
  },

  methods: {
    ...mapActions('main', ['request']),
    handleUploadPhoto() {},
    handleGoBack() {
      this.$router.back();
    },
    async handleUpdateItem() {
      try {
        const multidatas = ['phoneNumber', 'level'];

        let formData = new FormData();
        Object.keys(this.Form).forEach((key) => {
          if ((key === 'logo' && this.logoObj) || key !== 'logo') {
            if (multidatas.includes(key))
              formData.append(key, JSON.stringify(this.Form[key]));
            else if (this.Form[key] != '' && this.Form[key] != undefined)
              formData.append(key, this.Form[key]);
          }
        });

        this.working = true;
        let response = await this.request({
          method: 'put',
          url: 'school/schoolProfile/my-school',
          data: formData,
        });

        if (response.joiError) {
          this.bindJoiErrors(response.joi, 'name', (errors) => {
            console.info('set errors ', errors);
          });
        } else if (response && !response.error) {
          this.populateForm(response.record);

          eventBus.$emit('open-toast', {
            message: 'school information updated successfully',
          });
        } else {
          eventBus.$emit('open-toast', {
            message: this.error,
            error: true,
          });
        }
        this.working = false;
      } catch (error) {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    async handleFetchSchool() {
      let response = await this.request({
        method: 'get',
        url: 'school/schoolProfile/my-school',
      });

      if (response && !response.error) {
        this.populateForm(response.record);
      }
    },

    populateForm(record) {
      Object.keys(this.Form).forEach((key) => (this.Form[key] = record[key]));
    },
  },
  async created() {
    await this.handleFetchSchool();
  },
};
</script>

<style></style>
