<template>
  <div class="w-full">
    <div class="">
      <div class="flex justify-between">
        <p class="text-lg text-textDarkest mt-4 mb-6">Dashboard</p>
        <!-- <div class="grid grid-cols-3 gap-8 my-4 mr-2">
          <base-select
            :items="SECTION_OPTIONS"
            v-model="selectedSection"
            :searchable="true"
          />
          <base-button icon="filter" label="Filter" class="px-4 text-sm" />
        </div> -->
      </div>
      <div class="space-x-3 mt-2 py-4">
        <!-- cards -->
        <div class="grid lg:grid-cols-4 grid-cols-2 gap-x-4 gap-y-6 z-20">
          <div
            v-for="(card, i) in top_cards"
            :key="i"
            class="rounded-lg flex bg-white p-5 shadow-lg relative z-10 cursor-pointer"
            :style="{ 'background-color': cards[card.title].color }"
          >
            <div class="rounded-md flex">
              <div class="mx-3 my-auto">
                <icon fill="white" :name="cards[card.title].type" :size="60" />
              </div>
              <div class="text-white">
                <div>
                  <p class="text-sm opacity-75">
                    {{ cards[card.title].title }}
                  </p>
                </div>
                <div>
                  <p class="font-semibold text-lg">
                    {{
                      card.title == 'students' ? card.total_item : card.current
                    }}
                  </p>
                </div>
                <!-- <div>
                  <p class="text-sm opacity-80">
                    Total user operations <b>$30.0</b>
                  </p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- graphs -->
        <div class="grid grid-cols-3 gap-3">
          <!-- left div -->
          <div class="col-span-2 px-2 py-5 bg-white mt-2 p-5 w-full">
            <!-- graph -->
            <div>Students Activity</div>
            <apexchart
              width="100%"
              height="350"
              type="line"
              :options="chartOneOptions"
              :series="seriesOne"
            ></apexchart>
          </div>
          <!-- right div -->
          <div class="bg-white border px-2 py-5 mt-2 p-5 w-full">
            <!-- calendar -->
            <div v-if="!hasData">No Attendance Data</div>
            <apexchart
              class="py-10"
              width="100%"
              height="350"
              type="donut"
              :options="chartOptions"
              :series="hasData ? donutseries : donut_series"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      selectedSection: '',
      current_data: false,
      item: {},
      timeChunk: 'daily',
      colors: ['#00d0c2', '#4c71f0', '#f0854c', '#ff6060'],
      year: 2023,
      month: 5,
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: new Date(),
        },
        {
          dot: true,
          dates: new Date(2023, 4, 10),
        },
        {
          dot: true,
          dates: new Date(2023, 4, 18),
        },
        {
          dot: true,
          dates: new Date(2023, 4, 23),
        },
      ],
      top_cards: [],
      donut_cards: [],
      donut_series: [1, 1, 1],

      cards: {
        students: {
          title: 'Total Students',
          type: 'user-graduate',
          color: '#00d0c2',
          detail: '',
        },
        present: {
          title: 'Present Today',
          type: 'chalkboard-user',
          color: '#4c71f0',
          detail: '',
        },
        absent: {
          title: 'Absent Today',
          type: 'user',
          color: '#f0854c',
          detail: '',
        },
        late: {
          title: 'Late Students Today',
          type: 'school',
          color: '#ff6060',
          detail: '',
        },
      },
      options: {
        chart: {
          stacked: false,
        },
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },

        labels: ['mon', 'tue', 'wed', 'thur', 'fri', 'sat'],
        markers: {
          size: 0,
        },
        // xaxis: {
        //   type: 'datetime',
        // },
        yaxis: {
          title: {
            text: 'Users',
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' Users';
              }
              return y;
            },
          },
        },
      },

      series: [
        {
          name: 'teacher',
          type: 'line',
          data: [12, 20, 44, 45, 53, 64],
        },
        {
          name: 'student',
          type: 'line',
          data: [120, 153, 159, 170, 185, 223],
        },
        {
          name: 'parent',
          type: 'line',
          data: [20, 12, 12, 412, 23, 12],
        },
        {
          name: 'staff',
          type: 'line',
          data: [20, 12, 12, 412, 23, 12],
        },
      ],

      baroptions: {
        chart: {
          type: 'bar',
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
        },
      },
      barseries: [
        {
          name: 'sales',
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['user']),
    chartOneOptions() {
      return {
        chart: {
          stacked: false,
        },
        stroke: {
          width: 2,
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },
        labels: this.item.present.map((student) => student.label),
        markers: {
          size: 0,
        },
        // xaxis: {
        //   type: 'datetime',
        // },
        yaxis: {
          title: {
            text: 'Students',
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' Students';
              }
              return y;
            },
          },
        },
      };
    },
    seriesOne() {
      let tmpArray = [];
      let obj = {
        name: 'Absent',
        type: 'line',
        data: this.item.absent.map((student) => student.data),
      };
      tmpArray.push(obj);
      obj = {
        name: 'Present',
        type: 'line',
        data: this.item.present.map((student) => student.data),
      };
      tmpArray.push(obj);
      obj = {
        name: 'Late',
        type: 'line',
        data: this.item.late.map((student) => student.data),
      };
      tmpArray.push(obj);
      return tmpArray;
    },
    donutseries() {
      return this.donut_cards.map((donut) => donut.current);
    },
    hasData() {
      let percentages = this.donut_cards.map((donut) => donut.current);
      let t = percentages.reduce((a, b) => {
        let sum = Number(a) + Number(b);
        return sum;
      });
      return t == 0 ? false : true;
    },
    chartOptions() {
      let s = this.donut_cards.map((donut) => donut.title);
      const labels = { labels: s };
      return labels;
    },
    // SECTION_OPTIONS() {
    //   return this.sections.map((section) => {
    //     return {
    //       label: section.class.name + '-' + section.name,
    //       value: section.name,
    //     };
    //   });
    // },
  },
  watch: {
    timeChunk(n) {
      this.fetchStats(n);
    },
  },
  methods: {
    ...mapActions('main', ['request']),

    getDay(date) {
      const WeekDays = ['7', '1', '2', '3', '4', '5', '6'];

      return WeekDays[date.weekday];
    },
    async fetchStats(timeChunk) {
      let response = await this.request({
        method: 'get',
        url: 'dashboard/attendance/' + timeChunk + '/' + 7,
      });
      if (response && !response.error) {
        this.item = response.items;
        this.top_cards = this.item.card_info;
        this.donut_cards = this.item.donut_info;
      }
    },
    async fetchSections() {
      let response = await this.request({
        method: 'get',
        url: 'school/section/me',
      });
      if (response && !response.error) {
        this.sections = response.record;
      }
    },
  },
  created() {
    this.timeChunk = 'daily';
    this.fetchStats(this.timeChunk);
    this.fetchSections();
  },
};
</script>

<style></style>
