<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title>
      {{ isUpdate ? 'Update' : 'Create New' }} Enrollment Type
    </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-1 gap-5">
          <base-input
            label="Enrollment Type Name"
            placeholder="Enrollment type name"
            v-model="Form.name"
            :errors="errors.name"
            capitalize
            required
            check
          />
          <base-input
            label="Enrollment Type Code"
            placeholder="enrollment code"
            v-model="Form.code"
            :errors="errors.code"
            required
            check
            caps
          />
          <switch-button label="Is Active" v-model="Form.status" />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import SwitchButton from '../base/SwitchButton.vue';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm'],
  mixins: [validation],
  data() {
    return {
      Form: {
        name: '',
        code: '',
        status: '', // active, closed, not_started
      },
      errors: {},
      working: false,
      meta: [
        { key: 'Form.name', label: 'Enrollment Type name' },
        { key: 'Form.code', label: 'Enrollment Type code' },
      ],
      initial: true,
    };
  },
  computed: {
    ...mapState('main', ['error']),
    isUpdate() {
      if (this.oldForm) return true;
      else return false;
    },
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
      this.initial = true;
    },
    populateData() {
      Object.keys(this.oldForm).forEach((key) => {
        this.Form[key] = this.oldForm[key];
      });

      this.Form['status'] = ['closed', 'inactive'].includes(
        this.oldForm['status']
      )
        ? false
        : true;
    },
    async handleCreate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';
      newForm = {
        name: newForm.name,
        code: newForm.code,
        status: newForm.status,
      };

      let response = await this.request({
        method: 'post',
        url: 'school/enrollment',
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'enrollment type updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },

    async handleUpdate() {
      this.working = true;
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';

      newForm = {
        name: newForm.name,
        code: newForm.code,
        status: newForm.status,
      };

      let response = await this.request({
        method: 'put',
        url: 'school/enrollment/' + this.oldForm._id,
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'enrollment type updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },
  },
  created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
    if (this.isUpdate) this.populateData();
  },
};
</script>

<style></style>
