<template>
  <div>
    <tippy
      placement="right"
      theme="light"
      arrow
      size="large"
      interactive
      ref="attendance-block"
    >
      <template v-slot:trigger>
        <div
          class="h-4 w-4 bg-gray-100 rounded-sm hover:opacity-75 flex justify-center items-center"
          :class="{
            'bg-success': status === 'present',
            'bg-orange': status === 'late',
            'bg-error': status === 'absent',
          }"
          name="block"
        >
          <p class="text-xs"></p>
        </div>
      </template>
      <div class="w-72 flex flex-col items-start px-5 py-5">
        <div class="text-sm mb-1">
          {{ moment(fullDate).format('LLLL') }}
        </div>
        <div class="flex space-x-3 items-center">
          <div
            class="h-3 w-3 rounded-full"
            :class="{
              'bg-success': status === 'present',
              'bg-orange': status === 'late',
              'bg-error': status === 'absent',
            }"
          ></div>
          <div class="flex space-x-3">
            <p>{{ status }}</p>
            <p v-if="status === 'absent'">,{{ permissionStatement }}</p>
          </div>
        </div>
        <div class="border-l-4 px-3 border-orange my-3">
          {{ remark }}
        </div>
      </div>
    </tippy>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: ['day', 'activeYear', 'month', 'attendance'],
  computed: {
    remark() {
      return this.attendance ? this.attendance.remark : '';
    },
    hasPermission() {
      return this.attendance ? this.attendance.hasPermission : false;
    },
    permissionStatement() {
      return this.hasPermission ? 'with permission' : 'without permission';
    },
    status() {
      return this.attendance ? this.attendance['status'] : '';
    },
    fullDate() {
      return this.attendance
        ? this.attendance['date']
        : this.activeYear +
            ('-' +
              this.month.number +
              '-' +
              (`${this.day}`.length === 1 ? '0' + this.day : this.day));
    },
  },
  methods: {
    moment,
  },
};
</script>

<style></style>
