<template>
  <div class=" w-full px-2 py-2">
    <!-- top image container -->
    <div class=" h-72  w-full relative">
      <!-- overlay -->
      <div
        class="absolute top-0 right-0 bottom-0 left-0 bg-black opacity-20 z-10"
        style="opacity: 0.2"
      ></div>
      <!-- image -->
      <div class=" absolute top-0 right-0 left-0 bottom-0 z-0">
        <img
          :src="IMAGES_PATH + channel.coverImage"
          alt=""
          class=" object-cover w-full h-full"
        />
      </div>

      <!-- detail -->
      <div
        class=" absolute top-0 right-0 left-0 bottom-0 z-10 container py-6 px-10 flex flex-col justify-between"
      >
        <div class=" w-1/3 my-auto">
          <p class=" text-white text-2xl font-semibold uppercase">
            {{ channel.name }}
          </p>
          <p class=" text-white mt-0 text-lg font-extralight">
            {{ channel.description }}
          </p>
        </div>
        <!-- <div class="flex justify-between text-white">
          <div v-for="i in 3" :key="i">
            <p class=" text-sm">Total Students</p>
            <p class=" font-extralight text-2xl">10,000</p>
          </div>
        </div> -->
      </div>
    </div>

    <!-- packages container -->
    <div class=" my-8 container">
      <div class=" flex items-center justify-between">
        <p class=" text-xl text-textDarkest font-semibold ">Courses</p>
        <div class="flex space-x-2">
          <!-- <base-button label="Extend" @click="handleCreateNewCourse" outlined /> -->
          <!-- <base-button label="Create New" @click="handleCreateNewCourse" /> -->
        </div>
      </div>

      <div class=" grid grid-cols-4 mt-4 gap-5" v-if="courses.length">
        <course-entry
          v-for="(course, i) in courses"
          :key="i"
          @click="handleCourseSelected(course._id)"
          :course="course"
        />
      </div>
      <div v-else class=" text-center">
        <p class=" text-textLight text-xl my-12">There are no courses</p>
      </div>
    </div>
  </div>
</template>

<script>
import CourseEntry from '@/views/protected/course/components/CourseEntry.vue';
import eventBus from '@/eventBus.js';
import { IMAGES_PATH } from '@/config/config.js';
import { mapActions } from 'vuex';

export default {
  name: 'ContentDetail',
  props: {
    channelId: {
      type: String,
      required: true
    }
  },
  components: {
    CourseEntry
  },
  data() {
    return {
      IMAGES_PATH,
      channel: {},
      courses: []
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleCourseSelected(courseId) {
      
      this.$router.push({
        name: 'n-course-detail',
        params: { courseId: courseId }
      });
    },

    handleCreateNewCourse() {
      let componentProps = {
        channelId: this.channelId
      };
      eventBus.$emit('open-modal', {
        modalId: 4,
        componentProps,
        cb: this.getCourses
      });
    },

    async getChannel() {
      let response = await this.request({
        method: 'get',
        url: 'reb/channel/' + this.channelId
      });
      if (response && !response.error) {
        this.channel = response.record;
      } else {
        this.channel = [];
      }
    },

    async getCourses() {
      let response = await this.request({
        method: 'get',
        url: 'reb/courseCurriculum/channel/' + this.channelId
      });
      if (response && !response.error) {
        this.courses = response.record;
      } else {
        this.courses = [];
      }
    }
  },

  created() {
    this.getChannel();
    this.getCourses();
  }
};
</script>

<style></style>
