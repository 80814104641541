<template>
  <div>
    Pdf
  </div>
</template>

<script>
export default {};
</script>

<style></style>
