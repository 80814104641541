<template>
  <div
    v-if="isVisible && (code == '' || authCodes.includes(code))"
    :class="{
      'bg-primary text-white': primary && !outlined,
      ' bg-white border-gray-200 border hover:translate-x-2 hover:text-primary hover:border-gray-300':
        outlined,
      'bg-textLight text-white opacity-50 cursor-not-allowed': working,
      idle: !working,
      'p-0': dense,
      'p-1': !dense,
      'cursor-not-allowed': disabled,
    }"
    class="base-btn tracking-widest cursor-pointer flex space-x-1 items-center justify-center rounded-default"
    @click="handleClick"
    :title="title"
  >
    <icon v-if="working" :size="iconSize" name="spinner" class="animate-spin" />
    <icon v-if="!working && icon && !iconRight" :size="iconSize" :name="icon" />
    <div v-if="label" class="text-sm py-1 px-2">
      {{ label }}
    </div>
    <icon v-if="!working && icon && iconRight" :size="iconSize" :name="icon" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    dense: { type: Boolean, default: false },
    label: { type: String },
    primary: { type: Boolean, default: true },
    icon: { type: String },
    iconSize: { type: Number, default: 14 },
    iconRight: { type: Boolean },
    isVisible: { type: Boolean, default: true },
    outlined: { type: Boolean, default: false },
    title: { type: String },
    working: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    code: { type: String, default: '' },
  },
  computed: {
    // ...mapState('main', ['working']),
    ...mapGetters(['authCodes']),
  },
  created() {
    // console.log('btn ', this.code, this.code == '', this.authCodes);
  },
  methods: {
    handleClick() {
      if (this.working || this.disabled) return;
      this.$emit('click');
    },
  },
};
</script>

<style>
.base-btn.idle:hover {
  opacity: 0.8;
  transition: transform 0.5s;
}
.base-btn.idle:active {
  opacity: 0.7;
  transform: scale(1.1);
}
</style>
