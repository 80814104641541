export default {
    methods: {
        handleOpenDialog(ref) {
            this.$refs[ref].click();
        },

        // -- at change @change
        handleSelectFile(ref, key) {
            let keys = key.split('.');
            let file = this.$refs[ref].files[0];
            let keyLength = keys.length;

            if (keyLength === 2) this[keys[0]][keys[1]] = file;
            else if (keyLength === 1) this[keys[0]] = file;

            this.setObjUrl(keys);
        },

        setObjUrl(keys) {
            let objUrl =
                (keys.length === 1 ?
                    keys[0] :
                    keys.length === 2 ?
                    keys[1] :
                    undefined) + 'Obj';
            
            

            let keysLength = keys.length;

            if (this[objUrl]) URL.revokeObjectURL(this[objUrl]);

            if (keysLength === 1 && this[keys[0]]) {
                this[objUrl] = URL.createObjectURL(this[keys[0]]);
            } else if (keysLength === 2 && this[keys[0]][keys[1]]) {
                this[objUrl] = URL.createObjectURL(this[keys[0]][keys[1]]);
            }
        }
    }
}