var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2 px-4 text-textMedium space-y-2"},[_c('div',{staticClass:"p-2 text-xl text-center border-b border-brightGray"},[_vm._v(" Select "+_vm._s(_vm.usertype || 'Customer')+" ")]),_c('div',{staticClass:"space-y-1"},[_c('div',[_c('div',{staticClass:"py-2"},[_c('base-input',{attrs:{"placeholder":"Search ..."},on:{"enter":function($event){return _vm.fetchUser(_vm.search)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div')]),_c('div',{staticClass:"space-y-1 overflow-y-auto py-2",staticStyle:{"height":"50vh"}},[(_vm.search && _vm.filteredCustomers.length === 0 && !_vm.working)?_c('div',{staticClass:"text-lg"},[_vm._v(" No record matching to your search ")]):_vm._e(),(!_vm.search && !_vm.working)?_c('div',{staticClass:"text-sm"},[_vm._v(" Type in "+_vm._s(_vm.usertype || 'Customer')+" full name or userId in the box above. ")]):_vm._e(),(_vm.working)?_c('div',{staticClass:"w-full mt-10 flex justify-center"},[_c('bar-loader',{staticClass:"mx-auto w-1/3 block"})],1):_vm._e(),(!_vm.working)?_c('div',_vm._l((_vm.filteredCustomers),function(customer){return _c('div',{key:customer._id,staticClass:"flex items-center space-x-2 cursor-pointer p-2 rounded",class:_vm.selectedId === customer._id || _vm.selectedIds.includes(customer._id)
              ? 'bg-primary'
              : 'hover:bg-lightGreen',on:{"click":function($event){return _vm.selectCustomer(customer)}}},[_c('div',{staticClass:"h-16 w-16 rounded-full bg-gray-100 flex justify-center items-center"},[(customer.profilePic)?_c('img',{directives:[{name:"viewer",rawName:"v-viewer",value:({ inline: false, movable: true }),expression:"{ inline: false, movable: true }"}],staticClass:"w-full h-full object-cover rounded-full",attrs:{"src":_vm.PROFILE_PATH + customer.profilePic,"alt":"","crossorigin":"anonymous"}}):_c('icon',{staticClass:"w-full h-full",attrs:{"size":18,"name":"user"}})],1),_c('div',{staticClass:"w-full",class:_vm.selectedId === customer._id ||
              _vm.selectedIds.includes(customer._id)
                ? 'text-white'
                : 'text-textDark'},[_c('div',{staticClass:"flex justify-space w-full"},[_c('div',{staticClass:"text-lg capitalize",class:_vm.selectedId === customer._id ||
                  _vm.selectedIds.includes(customer._id)
                    ? 'text-white'
                    : 'text-textDark'},[_c('p',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(customer.firstName)+" "+_vm._s(customer.middleName)+" "+_vm._s(customer.lastName)+" ")])]),_c('div',{staticClass:"ml-auto"},[(customer.customer_type)?_c('div',{staticClass:"rounded px-2",class:_vm.selectedId === customer._id ||
                    _vm.selectedIds.includes(customer._id)
                      ? 'bg-white text-primary'
                      : 'bg-primary text-white'},[_vm._v(" "+_vm._s(customer.customer_type)+" ")]):_vm._e()])]),_c('div',{staticClass:"flex space-x-2 items-center",class:_vm.selectedId === customer._id ||
                _vm.selectedIds.includes(customer._id)
                  ? 'text-white'
                  : 'text-textDark'},[_c('div',[_c('icon',{attrs:{"name":"phone-alt","size":14}})],1),_c('div',[_vm._v(_vm._s(customer['phoneNumber'].join(' | ')))])])])])}),0):_vm._e()]),_c('div',[_c('pagination',{attrs:{"currentPage":_vm.currentPage,"pageSize":_vm.pageSize,"totalPages":_vm.totalPages},on:{"changepage":_vm.handlePageChange,"changepagesize":_vm.handlePageSizeChange}})],1),_c('div',{staticClass:"flex justify-between pt-4 pb-2"},[_c('base-button',{staticClass:"py-1 px-4 text-textMedium bg-gray-100 hover:bg-danger hover:text-error rounded",attrs:{"label":"cancel","primary":false},on:{"click":_vm.cancel}}),(_vm.selectedCustomer || _vm.selectedCustomers.length)?_c('base-button',{staticClass:"py-1 px-4 rounded",attrs:{"label":"continue"},on:{"click":_vm.handleContinue}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }