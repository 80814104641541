<template>
  <div class=" flex space-x-5 items-center">
    <div
      class="rounded-full flex cursor-pointer"
      :class="this.value ? 'justify-end' : 'justify-start'"
      style="width: 40px; padding: 1px;"
      :style="{
        background: this.value ? '#27c350' : '#d8d8d8',
      }"
      @click.stop="toggleSelect"
    >
      <div class="rounded-full bg-white" style="width: 20px;height: 20px; box-shadow: 0px 0px 1px 1px #d8d8d8;"></div>
    </div>
    <div>
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'label'],
  methods: {
    toggleSelect() {
      this.$emit('input', !this.value)
    },
  },
}
</script>

<style></style>
