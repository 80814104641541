<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title>
      {{ isUpdate ? 'Update' : 'Create New' }} Field Category
    </template>
    <template>
      <div class="space-y-5">
        <base-input
          label="name *"
          placeholder="name"
          v-model="Form.name"
          :errors="errors.name"
          check
        />
        <base-text-area
          label="description"
          placeholder="description"
          v-model="Form.description"
          :errors="errors.description"
        />
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
//import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import { required} from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate },
  props: ['oldForm'],
  mixins: [validation],
  data() {
    return {
      Form: {
        name: '',
        description: '',
      },
      errors: {},
      meta: [
        {key: 'Form.name', label: 'Name'},
      ],
      initial: true
    };
  },
  computed: {
    ...mapState('main', ['error']),
    isUpdate() {
      if (this.oldForm) return true;
      else return false;
    },
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name'].includes(key)) {
            acc[key] = { required}
          } else acc[key] = {}
          return acc
        }, {}),
      },
    }
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb, response) {
      this.$emit('close', { invokeCb, response });
    },
    async handleSave() {
      this.initial = false;
      if (!this.isValid())
        return 
      if (this.isUpdate) this.handleUpdate();
      else this.handleCreate();
      this.initial = true
    },
    async handleCreate() {
      this.handleClose(true, {
        name: this.Form.name,
        description: this.Form.description,
      });
    },

    async handleUpdate() {
      
      this.handleClose(true, {
        i: this.Form.i,
        name: this.Form.name,
        description: this.Form.description,
      });
    },

    populateData() {
      Object.keys(this.oldForm).forEach((key) => {
        this.Form[key] = this.oldForm[key];
      });

      this.Form['status'] = ['active', 'inactive'].includes(
        this.oldForm['status']
      )
        ? false
        : true;
    },
  },
  created() {
    this.meta.forEach(key => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid()
        }
      })
    })
    if (this.isUpdate) this.populateData();
  },
};
</script>

<style></style>
