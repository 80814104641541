<template>
  <div class="bg-white z-10 relative shadow-lg" style="min-height: 430px">
    <!-- cover view -->
    <div
      class="flex z-0 absolute top-0 bottom-40 right-0 left-0 cursor-pointer"
      style="height: 208px"
      @click="handleClick"
    >
      <img
        :src="IMAGES_PATH + course.image"
        alt=""
        class="object-cover w-full h-full"
      />
    </div>
    <!-- skew -->
    <div
      class="bg-white z-10 transform -skew-y-6 absolute right-0 left-0 cursor-pointer"
      style="top: 144px; bottom: 24px"
      @click="handleClick"
    ></div>
    <!-- detail view -->
    <div
      class="z-20 absolute bottom-0 bg-orang right-0 left-0 flex flex-col justify-between"
      style="top: 160px"
    >
      <div
        class="px-4 py-2 h-full flex flex-col cursor-pointer"
        @click="handleClick"
      >
        <p class="text-lg font-semibold">
          {{ course.name }} - {{ course.grade }}
        </p>
        <div class="grid grid-cols-2 my-auto gap-3">
          <div v-for="i in 4" :key="i">
            <div class="flex space-x-2 items-center">
              <icon name="link" class="text-secondary" />
              <p class="text-sm">Aug 12, 2021</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex px-5 py-5 space-x-5 justify-end"></div>
      <div class="w-full bg-grayBg py-2 px-4 flex justify-between items-center">
        <div>
          <p class="text-sm">
            <span class="text font-semibold">{{ course.price }} ETB</span>
            / student
          </p>
          <div class="flex space-x-2">
            <icon
              name="star-hallow"
              v-for="i in 5"
              :key="i"
              class="text-orange"
              @click="handleClick"
            />
          </div>
        </div>
        <div>
          <base-button
            label="DETAIL"
            class="rounded-full shadow-xl py-0"
            @click="handleClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IMAGES_PATH } from '@/config/config.js';
import { mapActions } from 'vuex';
import eventBus from '@/eventBus.js';

export default {
  props: {
    course: {
      required: true,
    },
  },
  data() {
    return {
      IMAGES_PATH,
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClick() {
      this.$emit('click');
    },

    handleEdit() {
      let componentProps = {
        course: this.course,
      };
      eventBus.$emit('open-modal', {
        modalId: 4,
        componentProps,
        cb: this.onUpdate,
      });
    },

    onUpdate() {
      this.$emit('updated');
    },

    async handleDelete() {
      let response = await this.request({
        method: 'delete',
        url: 'reb/courseCurriculum/' + this.course._id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'course deleted successfully',
        });
        this.$emit('deleted');
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },
};
</script>

<style></style>
