<template>
  <div class=" w-full">
    <div class=" h-72  py-8 px-8" style="background-color: #202b33">
      <div class="container flex space-x-10 w-full h-full">
        <div class=" w-1/3 h-full bg-white">
          <!-- {{ course }} -->
          <img
            :src="IMAGES_PATH + course.image"
            alt=""
            class=" w-full h-full object-cover"
          />
        </div>
        <div class=" w-2/3 h-full flex-grow flex flex-col justify-between">
          <p class=" font-extralight text-2xl text-gray-100">
            {{ course.name }}
          </p>
          <div class=" space-y-5 max-w-min">
            <div
              class=" max-w-min"
              style="max-width: fit-content;"
              v-if="!schoolSubscribed"
            >
              <base-button
                class=" bg-secondary whitespace-nowrap px-6"
                label="Subscribe"
                @click="handleSubscribe"
                v-if="!SUBSCRIBED_COURSES_LIST.includes(this.courseId)"
                
              />
              <div class=" bg-textDark py-2 px-5" v-else>
                <p class=" text-gray-100">Already Subscribed</p>
              </div>
            </div>
            <div v-else>
              <div class=" bg-gray-100-700 py-2 flex justify-center items-center">
                <p class=" text-gray-300">School Subscribed</p>
              </div>
            </div>
            <div class=" flex space-x-4">
              <div class="flex space-x-2">
                <icon
                  name="star-hallow"
                  v-for="i in 5"
                  :key="i"
                  class=" text-yellow-400"
                />
              </div>
              <div>
                <p class=" text-gray-100 whitespace-nowrap">0 Reviews</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=" bg-white ">
      <div class=" container pt-3">
        <div class=" space-x-10 flex">
          <div
            v-for="(tab, i) in tabs"
            :key="i"
            class=" text  font-semibold  py-1 px-2 border-primary cursor-pointer select-none"
            :class="{
              'active-tab text-primary': activeTab == tab,
              'text-textMedium': activeTab != tab
            }"
            @click="changeTab(tab)"
          >
            {{ tab }}
          </div>
        </div>
      </div>
    </div>
    <div class="container py-5">
      <!-- <p class=" text-lg font-semibold text-textDarkest">Title</p> -->
      <!-- 'Overview', 'Course Content', 'Q&A', 'Bookmarks', 'Announcements' -->
      <overview-tab v-if="activeTab === 'Overview'" />
      <course-contents-tab
        v-else-if="activeTab === 'Course Content'"
        :courseId="courseId"
        :ctaId="cta"
      />
      <QATab v-else-if="activeTab === 'Q&A'" />
      <bookmarks-tab v-else-if="activeTab === 'Bookmarks'" />
      <announcements-tab v-else-if="activeTab === 'Announcements'" />
    </div>
  </div>
</template>

<script>
import OverviewTab from './tabs/OverviewTab.vue';
import CourseContentsTab from './tabs/CourseContentsTab.vue';
import QATab from './tabs/QATab.vue';
import BookmarksTab from './tabs/BookmarksTab.vue';
import AnnouncementsTab from './tabs/AnnouncementsTab.vue';
import { IMAGES_PATH } from '@/config/config.js';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import eventBus from '@/eventBus.js';

export default {
  props: {
    courseId: {
      type: String,
      required: true
    },
    cta: {
      type: String,
      required: false
    }
  },
  components: {
    OverviewTab,
    CourseContentsTab,
    QATab,
    BookmarksTab,
    AnnouncementsTab
  },
  data() {
    return {
      working: false,
      IMAGES_PATH,
      tabs: ['Course Content', 'Overview', 'Q&A', 'Bookmarks', 'Announcements'],
      activeTab: 'Course Content',
      course: {},
      subscribedCourses: [],
      contentProgress: ''
    };
  },

  computed: {
    ...mapState('main', ['error']),
    SUBSCRIBED_COURSES_LIST() {
      return this.subscribedCourses.map(course => course.courseCurriculum._id);
    },

    schoolSubscribed() {
      return Boolean(this.cta);
    }
  },

  methods: {
    ...mapActions('main', ['request']),

    async handleResetProgress() {
      let response = await this.request({
        method: 'delete',
        url: 'reb/contentLog/' + this.courseId
      });
      if (response && !response.error) {
        this.fetchProgress();
        eventBus.$emit('open-toast', {
          message: 'progress reseted successfully'
        });
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true
        });
      }
    },

    async fetchSubscribedCourses() {
      let response = await this.request({
        method: 'get',
        url: 'reb/transaction/school-subscribed'
      });
      if (response && !response.error) {
        this.subscribedCourses = response.record;
      } else {
        eventBus.emit('open-toast', {
          message: this.error,
          error: true
        });
      }
    },

    async handleSubscribe() {
      this.working = true;
      let response = await this.request({
        method: 'post',
        url: 'reb/transaction/',
        data: {
          courseCurriculum: this.courseId
        }
      });
      if (response && !response.error) {
        this.working = false;
        eventBus.$emit('open-toast', { message: 'subscribed successfully' });
        this.fetchSubscribedCourses();
      } else {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true
        });
      }
    },

    changeTab(name) {
      this.activeTab = name;
    },

    async fetchCourse() {
      let response = await this.request({
        method: 'get',
        url: 'reb/courseCurriculum/' + this.courseId
      });
      if (response && !response.error) {
        this.course = response.record[0];
      } else {
        this.course = {};
      }
    }

    // async fetchProgress() {
    //   let response = await this.request({
    //     method: 'get',
    //     url: 'reb/contentLog/progress/' + this.courseId
    //   });
    //   if (response && !response.error) {
    //     this.contentProgress = response.record;
    //   } else {
    //     this.contentProgress = '';
    //   }
    // }
  },

  created() {
    this.fetchCourse();
    this.fetchSubscribedCourses();
    // this.fetchProgress();
  }
};
</script>

<style scoped>
.active-tab {
  border-bottom-width: 3px;
}
</style>
