// import Index from '../../views/protected/academic/Course_Exemption/Index.vue';
// import Create from '../../views/protected/academic/Course_Exemption/CreateExempt.vue';
import Grade_Verification from '../../views/protected/academic/Grade_Verification/Index.vue';
import MarkList from '@/views/protected/enrollment/mark_list/Index.vue';
import Roaster from '../../views/protected/academic/Student_Roaster/Roaster.vue';
import StudentCopy from '../../views/protected/academic/StudentCopy/Index.vue';
import AcademicStatus from '../../views/protected/academic/AcademicStatus/Index.vue';
import OtherStatus from '../../views/protected/academic/OtherStatus/Index.vue';
import REExam from '../../views/protected/academic/REExam/Index.vue';

export default [
  // {
  //   path: 'exemption',
  //   name: 'exemption',
  //   component: Index,
  // },
  // {
  //   path: 'exemption/new-exemption',
  //   name: 'new-exemption',
  //   component: Create,
  // },
  {
    path: 'grade_verification',
    name: 'grade_verification',
    component: Grade_Verification,
  },
  {
    path: 'mark-list',
    name: 'mark-list',
    component: MarkList,
    props: true,
  },
  {
    path: 'student_roaster',
    name: 'student_roaster',
    component: Roaster,
  },
  {
    path: 'student_copy',
    name: 'student_copy',
    component: StudentCopy,
  },

  {
    path: 'academic_status',
    name: 'academic_status',
    component: AcademicStatus,
  },
  {
    path: 'other_status',
    name: 'other_status',
    component: OtherStatus,
  },
  {
    path: 're_exam',
    name: 're_exam',
    component: REExam,
  },
];
