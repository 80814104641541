<template>
  <div
    class="capitalize px-2"
    :class="{ 'text-sm': sm, 'rounded-sm': rounded }"
    :style="{
      color: text ? (inverted ? 'white' : color.color) : 'white',
      'background-color': text ? 'transparent' : color.color,
    }"
    style="max-width: 100%"
  >
    <div v-if="label">
      {{ updatedLabel }}
    </div>
    <div v-else class="text-rose bg-red">NA</div>
  </div>
</template>

<script>
export default {
  name: 'StatusChip',
  props: {
    label: String,
    sm: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      let status = this.label.toLowerCase().split('_').join(' ');
      if (
        [
          'active',
          'available',
          'original',
          'verified',
          'paid',
          'active good stand',
          'approved',
          'published',
          'committee approved',
          'closed obligation met',
          'not required',
          'default',
        ].includes(status)
      ) {
        return { color: '#28CAAB', accent: '#ECFDF5' };
      } else if (['graduated', 'completed'].includes(status)) {
        return { color: '#0a2a66', accent: '#FFFBEB' };
      } else if (
        [
          'pending',
          'standby',
          'review',
          'na',
          'rescheduled',
          'not started',
          'inactive',
        ].includes(status)
      ) {
        return { color: '#FAA016', accent: '#FFFBEB' };
      } else if (
        [
          'rejected',
          'closed',
          'required',
          'active bad stand',
          'closed rescheduled',
          'suspended',
          'dismissed',
          'not available',
          'unpaid',
          'not started',
        ].includes(status)
      ) {
        // #F0447D
        return { color: '#ff5c4f', accent: '#FEF2F2' };
      } else if (['available-bc'].includes(status)) {
        return { color: '#fff', accent: '#22a96f' };
      } else {
        return '';
      }
    },

    updatedLabel() {
      return this.label.split('_').join(' ');
    },
  },
};
</script>

<style></style>
