<template>
  <div class="bg-white p-8 h-full grid grid-cols-5 gap-3">
    <div class="col-span-4 overflow-auto">
      <profile
        :userId="userId"
        :userType="userType"
        :routeURL="routeURL"
        v-if="activeTab === 'profile'"
      ></profile>
      <AttendanceView
        v-if="userType === 'student' && activeTab === 'attendance'"
        :userId="userId"
      ></AttendanceView>
      <AcademicBackground
        v-if="userType === 'student' && activeTab === 'academic_background'"
        :userId="userId"
        :routeURL="routeURL"
      ></AcademicBackground>
      <AcademicHistoryView
        v-if="userType === 'student' && activeTab === 'academic_history'"
        :userId="userId"
      ></AcademicHistoryView>
      <GuardianProfile
        :userId="userId"
        v-if="userType === 'student' && activeTab === 'guardian_info'"
      ></GuardianProfile>
    </div>

    <div
      class="flex flex-col gap-1 p-3 border-1 border-gray-100 shadow-sm rounded-lg"
    >
      <div v-for="(sidebar, i) in sidebarItems" :key="i">
        <div
          v-if="sidebar.userType.includes(userType)"
          class="p-2 pr-0 hover:bg-primaryalpha cursor-pointer flex justify-between items-center rounded-lg"
          :class="{
            'bg-gray-100':
              activeTab === sidebar.label.toLowerCase().replace(' ', '_'),
            'text-textDark':
              activeTab != sidebar.label.toLowerCase().replace(' ', '_'),
          }"
          :icon="sidebar.icon"
          @click="setActiveTab(sidebar.label)"
        >
          {{ sidebar.label }}
          <div
            class="h-3 w-1 bg-primary rounded-full"
            v-if="activeTab === sidebar.label.toLowerCase().replace(' ', '_')"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Profile from '@/views/protected/user_management/student_management/components/Profile.vue';
import AttendanceView from '@/views/protected/user_management/student_management/components/AttendanceView.vue';
import AcademicHistoryView from '@/views/protected/user_management/student_management/components/AcademicHistoryView.vue';
import GuardianProfile from '@/views/protected/user_management/student_management/components/GuardianProfile.vue';
import AcademicBackground from '@/views/protected/user_management/student_management/components/AcademicBackground.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Profile,
    AttendanceView,
    AcademicHistoryView,
    GuardianProfile,
    AcademicBackground,
  },
  props: {
    userId: { type: String },
    userType: { type: String },
    routeURL: { type: String },
  },
  data() {
    return {
      activeTab: 'profile', /// general, mark_list, attendance,  medical_history, academic_history

      sidebarItems1: [
        {
          label: 'Profile',
          icon: 'credential',
          handler: '',
          userType: ['student', 'teacher', 'parent', 'school official'],
        },
        {
          label: 'Guardian Info',
          icon: 'bars',
          handler: '',
          userType: ['student'],
        },
        {
          label: 'Academic Background',
          icon: 'bars',
          handler: '',
          userType: ['student'],
        },
        {
          label: 'Attendance',
          icon: 'link',
          handler: '',
          code: 'stu-atth',
          userType: ['student'],
        },
        {
          label: 'Academic History',
          icon: 'bars',
          handler: '',
          code: 'stu-acah',
          userType: ['student'],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['authCodes']),
    sidebarItems() {
      return this.sidebarItems1.filter(
        (v) => !v.code || (v.code && this.authCodes.includes(v.code))
      );
    },
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab.toLowerCase().replace(' ', '_');
    },
  },
};
</script>
