<template>
  <div class="w-full relative">
    <label class="text-textDarkest" v-if="label">{{ label }}</label>

    <div
      class="bg-gray-50 flex justify-between items-center border-1 border-gray-300 rounded-lg p-2 px-4 w-full text-sm"
      @click="openSelector"
      v-outsideClick="{ handler: handleBlur }"
    >
      <div class="">
        <span class="select-all">{{
          day.length == ''
            ? 'dd'
            : day.toString().length === 1
            ? `0${day}`
            : day
        }}</span
        >/<span class="select-all">{{
          month.length == ''
            ? 'mm'
            : month.toString().length === 1
            ? `0${month}`
            : month
        }}</span
        >/<span class="select-all">{{
          year.length == '' ? 'yyyy' : year
        }}</span>
      </div>
      <div class="flex gap-2">
        <p class="text-xs">eth</p>
        <icon name="calendar" :size="15" class="text-gray-600" />
      </div>
    </div>

    <div
      class="bg-white shadow-2xl rounded-lg absolute w-full z-50"
      v-if="selectorOpened"
      @click.stop
    >
      <div class="grid grid-cols-7 p-1">
        <div
          class="col-span-2 flex justify-center items-center text-gray-500 text-xs"
        >
          day
        </div>
        <div
          class="col-span-2 flex justify-center items-center text-gray-500 text-xs"
        >
          month
        </div>
        <div
          class="col-span-3 flex justify-center items-center text-gray-500 text-xs"
        >
          year
        </div>
        <div class="col-span-2 h-64 overflow-auto p-1">
          <div
            v-for="i in 30"
            :key="i"
            class="hover:bg-gray-200 rounded-default flex justify-center items-center p-1 cursor-default"
            @click="
              {
                dayPicked(i), handleChange();
              }
            "
            :class="day == i ? 'bg-gray-200' : ''"
          >
            {{ i }}
          </div>
        </div>
        <div class="col-span-2 h-64 overflow-auto p-1">
          <div
            v-for="i in 13"
            :key="i"
            class="hover:bg-gray-200 rounded-default flex justify-center items-center p-1 cursor-default"
            :class="month == i ? 'bg-gray-200' : ''"
            @click="
              {
                monthPicked(i), handleChange();
              }
            "
          >
            {{ i }}
          </div>
        </div>
        <div class="col-span-3 h-64 overflow-auto p-1" ref="yearScroll">
          <div
            v-for="i in 200"
            :key="i"
            class="hover:bg-gray-200 rounded-default flex justify-center items-center p-1 cursor-default"
            :class="year == i + 1920 ? 'bg-gray-200' : ''"
            @click="
              {
                yearPicked(i + 1920), handleChange();
              }
            "
          >
            {{ i + 1920 }}
          </div>
        </div>
      </div>
      <div class="flex justify-end p-1 px-4">
        <p
          class="text-blue-500 text-xs cursor-pointer"
          v-if="clearable"
          @click="clearDate"
        >
          clear
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { EthDateTime } from 'ethiopian-calendar-date-converter';

export default {
  props: {
    label: { type: String },
    clearable: { type: Boolean, default: true },
    isUpdate: { type: Boolean },
    value: String,
  },
  data() {
    return {
      selectorOpened: false,
      day: '',
      month: '',
      year: '',
    };
  },
  updated() {
    let scrollElement = this.$refs['yearScroll'];
    scrollElement.scrollTop =
      (scrollElement.scrollHeight - scrollElement.clientHeight) / 2 - 100;
  },
  watch: {
    value() {
      if (this.isUpdate) {
        this.populateDate();
      }
    },
  },

  methods: {
    openSelector() {
      this.selectorOpened = !this.selectorOpened;
      this.handleChange();
    },
    clearDate() {
      this.day = '';
      this.month = '';
      this.year = '';
      this.handleChange();
    },
    handleBlur() {
      this.selectorOpened = false;
    },
    dayPicked(day) {
      this.day = day;
    },
    monthPicked(month) {
      this.month = month;
    },
    yearPicked(year) {
      this.year = year;
    },

    populateDate() {
      if (this.value) {
        const { year, month, date } = this.convertToEth(this.value);
        this.day = date;
        this.month = month;
        this.year = year;
      }
    },
    convertToEuro(ethYear, ethMonth, ethDay) {
      const ethDateTime = new EthDateTime(ethYear, ethMonth, ethDay);
      let date = ethDateTime.toEuropeanDate().toISOString();
      return date;
    },
    convertToEth(euroDate) {
      const { year, month, date } = EthDateTime.fromEuropeanDate(
        new Date(euroDate)
      );
      return { year, month, date };
    },
    handleChange() {
      if (this.year && this.month && this.day) {
        this.$emit(
          'input',
          this.convertToEuro(this.year, this.month, this.day)
        );
      } else {
        this.$emit('input', '');
      }
    },
  },
  created() {
    if (this.value && this.isUpdate) {
      this.populateDate();
    }
  },
};
</script>

<style scoped>
*::-webkit-scrollbar {
  width: 8px;
  background-color: #e1e1e1;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #757575;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #3b3b3b;
}
</style>
