<template>
  <div class="py-2 px-4 text-textMedium space-y-2">
    <div class="p-2 text-xl text-center border-b border-brightGray">
      Select {{ usertype || 'Customer' }} ({{ selectedCustomers.length }})
    </div>
    <div class="space-y-1">
      <div>
        <div class="py-2">
          <base-input
            placeholder="Search ..."
            v-model="search"
            @enter="fetchUser(search)"
            class=""
          />
        </div>
        <div>
          <!-- <filter-comp :items="searchByOptions" v-model="searchBy" /> -->
          <!-- <base-radio :items="searchByOptions" v-model="searchBy" /> -->
          <!-- <base-switch
              style="max-width: fit-content"
              :items="searchByOptions"
              v-model="searchBy"
            /> -->
        </div>
      </div>
      <div class="space-y-0 overflow-y-auto" style="height: 50vh">
        <div
          v-if="search && filteredCustomers.length === 0 && !working"
          class="text-lg"
        >
          No record matching to your search
        </div>
        <div v-if="!search && !working" class="text-sm">
          Type in {{ usertype || 'Customer' }} full name or userId in the box
          above.
        </div>
        <div v-if="working" class="w-full mt-10 flex justify-center">
          <bar-loader class="mx-auto w-1/3 block"></bar-loader>
        </div>
        <div v-if="!working">
          <div
            v-for="customer in filteredCustomers"
            :key="customer._id"
            class="flex items-center space-x-2 cursor-pointer p-1 px-2 rounded"
            :class="
              selectedId === customer._id || selectedIds.includes(customer._id)
                ? 'bg-primary'
                : 'hover:bg-lightGreen'
            "
            @click="selectCustomer(customer)"
          >
            <div
              style="width: 46px; height: 40px"
              class="rounded-full bg-gray-100 flex justify-center items-center"
            >
              <img
                v-if="customer.profilePic"
                :src="PROFILE_PATH + customer.profilePic"
                class="w-full h-full object-cover rounded-full"
                v-viewer="{ inline: false, movable: true }"
                alt=""
                crossorigin="anonymous"
              />
              <icon v-else :size="18" name="user" class="w-full h-full"></icon>
            </div>
            <div
              :class="
                selectedId === customer._id ||
                selectedIds.includes(customer._id)
                  ? 'text-white'
                  : 'text-textDark'
              "
              class="w-full"
            >
              <div class="grid grid-cols-5 w-full">
                <div class="col-span-2">
                  <div
                    class="text-lg"
                    :class="
                      selectedId === customer._id ||
                      selectedIds.includes(customer._id)
                        ? 'text-white'
                        : 'text-textDark'
                    "
                  >
                    <p class="capitalize">
                      {{ customer.firstName }} {{ customer.middleName }}
                      {{ customer.lastName }}
                    </p>
                  </div>
                  <div
                    class="flex space-x-2 items-center text-xs"
                    :class="
                      selectedId === customer._id ||
                      selectedIds.includes(customer._id)
                        ? 'text-white'
                        : 'text-textDark'
                    "
                  >
                    <div>
                      <icon name="phone-alt" :size="12" />
                    </div>
                    <div>{{ customer['phoneNumber'].join(' | ') }}</div>
                  </div>
                </div>
                <div class="ml-auto col-span-1">
                  <div v-if="customer.gender" class="rounded px-1">
                    {{ customer.gender }}
                  </div>
                </div>
                <div class="ml-auto col-span-1">
                  <div v-if="customer.age" class="rounded px-1">
                    {{ customer.age }}
                  </div>
                </div>
                <div class="ml-auto col-span-1">
                  <div
                    v-if="customer.userId"
                    class="rounded px-1"
                    :class="
                      selectedId === customer._id ||
                      selectedIds.includes(customer._id)
                        ? 'bg-white text-primary'
                        : 'bg-primary text-white'
                    "
                  >
                    {{ customer.userId }}
                  </div>
                </div>
              </div>
              <!-- <div
                  class="flex space-x-2 items-center text-xs"
                  :class="
                    selectedId === customer._id ||
                    selectedIds.includes(customer._id)
                      ? 'text-white'
                      : 'text-textDark'
                  "
                >
                  <div>
                    <icon name="phone-alt" :size="12" />
                  </div>
                  <div>{{ customer['phoneNumber'].join(' | ') }}</div>
                </div> -->
            </div>
          </div>
        </div>
      </div>

      <div>
        <pagination
          :currentPage="currentPage"
          :pageSize="pageSize"
          :totalPages="totalPages"
          @changepage="handlePageChange"
          @changepagesize="handlePageSizeChange"
        />
      </div>
      <div class="flex justify-between pt-4 pb-2">
        <base-button
          label="cancel"
          :primary="false"
          class="py-1 px-4 text-textMedium bg-gray-100 hover:bg-danger hover:text-error rounded"
          @click="cancel"
        />
        <base-button
          v-if="selectedCustomer || selectedCustomers.length"
          label="continue"
          class="py-1 px-4 rounded"
          @click="handleContinue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { PROFILE_PATH } from '@/config/config';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import { stringifyQueryObj } from '@/utils';
import BarLoader from '@/components/collection/BarLoader.vue';
import Pagination from '@/components/pagination/Pagination.vue';

export default {
  components: { BarLoader, Pagination },
  props: {
    multiple: {
      type: Boolean,
    },
    usertype: {
      type: String,
      default: 'student',
    },
    grade: { type: String },
  },
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,

      searchByOptions: [
        { label: 'User ID', value: 'userId' },
        { label: 'Tel', value: 'isTel' },
        { label: 'Name', value: 'isCustName' },
        { label: 'Index', value: 'isIndex' },
      ],
      searchBy: 'isCustName',
      working: false,
      search: '',
      PROFILE_PATH,
      selectedCustomer: undefined,
      selectedCustomers: [],
      customers: [],
    };
  },
  computed: {
    ...mapState('main', ['error']),
    filteredCustomers() {
      // if (this.search)
      //   return this.customers.filter(customer =>
      //     [
      //       'firstName',
      //       'lastName',
      //       'tel',
      //       'search_index',
      //       'customer_id'
      //     ].some(key => RegExp(this.search, 'i').test(customer[key]))
      //   );
      return this.customers;
    },
    selectedId() {
      return this.selectedCustomer?._id;
    },

    /// for multiple option
    selectedIds() {
      return this.selectedCustomers.map((cus) => cus._id);
    },
  },
  watch: {
    search() {
      if (!this.search) this.selectedCustomer = undefined;
    },
  },
  filters: {
    // beautify: formatePhoneNumber,
  },
  methods: {
    ...mapActions('main', ['request']),
    async fetchUser(search) {
      this.working = true;
      let query_obj = {
        page: this.currentPage || undefined,
        limit: this.pageSize,
        // cust_name: search,
        keyword: search,
      };
      let string_query = stringifyQueryObj(query_obj);

      let url;
      switch (this.usertype) {
        case 'Parent':
          url = 'parent/parentProfile/search' + string_query;
          break;
        case 'Teacher':
          url = 'school/teacherProfile/search' + string_query;
          break;
        case 'Student':
          url = 'student/studentProfile/search' + string_query;
          break;
        case 'Enroll Students':
          url =
            'student/studentProfile/search-grade/' + this.grade + string_query;
          break;
      }

      let response = await this.request({
        method: 'get',
        url,
        data: { search: search, [this.searchBy]: true },
      });
      this.working = false;
      if (response) {
        this.customers = response.items;
        this.totalPages = Math.ceil(response.total_pages / this.pageSize);
      } else this.customers = [];
    },
    selectCustomer(customer) {
      if (!this.multiple) {
        if (this.selectedId !== customer._id) this.selectedCustomer = customer;
        else this.selectedCustomer = undefined;
      } else {
        if (!this.selectedIds.includes(customer._id))
          this.selectedCustomers.push(customer);
        else
          this.selectedCustomers.splice(
            this.selectedCustomers.findIndex((cus) => cus._id === customer._id),
            1
          );
      }
    },
    cancel() {
      this.$emit('close');
    },
    handleContinue() {
      this.$emit('close', {
        invokeCb: true,
        response: {
          customer: this.multiple
            ? this.selectedCustomers
            : this.selectedCustomer,
        },
      });
    },
    handlePageChange(page) {
      if (page >= 1 && page <= this.totalPages) this.currentPage = page;
      this.fetchUser(this.search);
    },

    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.currentPage = 1;
      this.fetchUser(this.search);
    },
  },
  async created() {
    await this.fetchUser('');
  },
};
</script>

<style></style>
