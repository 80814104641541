<template>
  <div>
    <base-title title="Manage School">
      <template #actions>
        <div class="flex">
          <base-button
            icon="plus"
            label="Add New"
            class="px-3"
            @click="handleCreateNew"
          />
        </div>
      </template>
    </base-title>
    <div class="bg-white p-5 mt-2 rounded-default">
      <d-table
        #default="{ item }"
        :headers="headers"
        :items="items"
        :hasActions="true"
        :handlers="handlers"
        :hideColumnSort="true"
      >
        <td v-for="(header, i) in headers" :key="i">
          <div v-if="header.key === 'logo'">
            <div class="flex space-x-5 items-center">
              <div class="h-10 w-10 rounded-full bg-gray-100">
                <img
                  :src="LOGO_PATH + item.logo"
                  v-viewer="{ inline: false, movable: true }"
                  class="w-full h-full object-cover rounded-full"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div v-else-if="header.key === 'name'">
            <p class="text font-semibold text-textDarkest uppercase">
              {{ item[header.key] }}
            </p>
          </div>
          <div v-else-if="header.key === 'status'">
            <status-chip
              :label="item[header.key]"
              style="max-width: fit-content"
            />
          </div>
          <div v-else>
            {{ getProp(item, header.key) }}
          </div>
        </td>
      </d-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DTable from '@/components/table/DTable.vue';
import { getPropByString as getProp } from '@/utils';
import { PROFILE_PATH, LOGO_PATH } from '@/config/config.js';
import eventBus from '@/eventBus.js';
export default {
  components: {
    DTable,
  },
  data() {
    return {
      PROFILE_PATH,
      LOGO_PATH,
      handlers: [
        {
          label: 'Detail',
          icon: 'eye',
          handler: this.handleDetail,
          idParam: true,
        },

        {
          label: 'Edit',
          icon: 'pencil-alt',
          handler: this.handleEdit,
          idParam: true,
        },
        {
          label: 'Delete',
          icon: 'trash-alt',
          handler: this.handleDelete,
          idParam: true,
        },

        {
          label: 'Change Status',
          icon: 'pencil-alt',
          handler: this.handleChangeStatus,
          fullParam: true,
        },
        {
          label: 'Manage Structure',
          icon: 'cog',
          handler: this.handleManageSchoolStructure,
          idParam: true,
        },
        // {
        //   label: 'Manage Course',
        //   icon: 'cog',
        //   handler: this.handleManageSchoolCourse,
        //   idParam: true
        // }
      ],
      items: [],
      headers: [
        { label: 'Logo', key: 'logo' },
        { label: 'Name', key: 'name' },
        { label: 'Region', key: 'region' },
        { label: 'City', key: 'city' },
        { label: 'Status', key: 'status' },
      ],
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleCreateNew() {
      this.$router.push({ name: 'school-create' });
    },

    getProp,

    async fetchSchoolList() {
      let response = await this.request({
        method: 'get',
        url: 'school/schoolProfile',
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [
          // { _id: 1, logo: '', name: '', region: '', city: '', status: '' }
        ];
      }
    },

    handleEdit(id) {
      this.$router.push({ name: 'school-update', params: { id } });
    },

    handleChangeStatus(school) {
      let componentProps = {
        id: school._id,
        status: school.status,
      };
      eventBus.$emit('open-modal', {
        modalId: 6,
        componentProps,
        cb: this.fetchSchoolList,
      });
    },

    handleDelete(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the School item',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },
    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/schoolProfile/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'deleted successfully' });
        this.fetchSchoolList();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
    handleDetail(id) {
      this.$router.push({
        name: 'my-school',
        params: { school_id: id },
      });
    },
    handleManageSchoolStructure(id) {
      this.$router.push({
        name: 'academic-year-list',
        params: { school_id: id },
      });
    },
    handleManageSchoolCourse(id) {
      this.$router.push({
        name: 'course-list',
        params: { school_id: id },
      });
    },
  },

  created() {
    this.fetchSchoolList();
  },
};
</script>

<style></style>
