<template>
  <div>
    <base-title title="Payment Types" class="mb-2">
      <template #actions>
        <div class="flex space-x-3">
          <base-button
            label="Back"
            icon="arrow-left"
            class="px-2 bg-gray-100 text-textDarkest"
            :primary="false"
            @click="$router.back()"
          />
          <!-- :isVisible="permission.add" -->
          <base-button
            label="New Payment Type"
            icon="plus"
            class="px-2"
            @click="handleCreatePaymentType"
            code="pt-add"
          />
        </div>
      </template>
    </base-title>

    <loader type="list" :amount="6" v-if="loading"></loader>

    <div v-else-if="items && items.length" class="grid grid-cols-3 gap-2">
      <div
        class="bg-white p-2 item-grid rounded-default"
        v-for="(item, i) in items"
        :key="i"
      >
        <div class="flex justify-between w-full">
          <div class="flex space-x-2 items-center">
            <p class="text-textDarkest capitalize">
              {{ item.name }}
            </p>
            <!-- <base-check-box
              :selected="item.isRegular"
              label="regular payment"
            /> -->
          </div>
          <div>
            <status-chip rounded :label="item.status" />
          </div>
        </div>
        <div></div>
        <div>
          <div class="flex space-x-5 mt-3">
            <!-- <icon
              v-if="permission.delete || !isSchoolOfficial"
              name="trash-alt"
              class="cursor-pointer text-textMedium"
              @click="handleDeleteItem(item._id)"
            /> -->
            <icon
              v-if="authCodes.includes('pt-edit')"
              name="pencil-alt"
              class="cursor-pointer text-textMedium"
              @click="handleEditItem(i)"
            />
            <!-- <icon
              v-if="permission.view || !isSchoolOfficial"
              name="eye"
              class="cursor-pointer text-textMedium"
              @click="handleItemDetail(item._id)"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <base-no-data v-else message="no payment type added" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../../eventBus';
import { static_roles } from '@/config/constants';
export default {
  props: ['school_id'],
  data() {
    return {
      module: 'structure',
      permission: {},
      isSchoolOfficial: false,
      items: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'authCodes']),
  },
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler: function (v) {
        if (v) {
          this.user1 = v;
          this.permission = v.permissions.find(
            (p) => p.module.name === this.module
          );
        }
      },
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleCreatePaymentType() {
      let componentProps = {
        school: this.school_id,
      };
      eventBus.$emit('open-modal', {
        modalId: 28,
        componentProps,
        cb: this.handleFetchPaymentTypes,
      });
    },

    async handleFetchPaymentTypes() {
      this.loading = true;
      this.items = [];
      let response = await this.request({
        method: 'get',
        url: 'school/paymentType',
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
      this.loading = false;
    },

    async handleEditItem(index) {
      let componentProps = {
        oldForm: this.items[index],
      };
      eventBus.$emit('open-modal', {
        modalId: 28,
        componentProps,
        cb: this.handleFetchPaymentTypes,
      });
    },

    // async handleDeleteItem(id) {
    //   let componentProps = {
    //     message: 'Are you sure you want to delete the selected payment type',
    //     title: 'This action is not reversible.',
    //   };
    //   eventBus.$emit('open-modal', {
    //     modalId: 1,
    //     componentProps,
    //     cb: this.proceedDelete,
    //     cbParams: { id },
    //   });
    // },

    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/paymentType/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'payment type deleted successfully',
        });
        this.handleFetchPaymentTypes();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },

    // async handleItemDetail(id = 0) {
    //   this.$router.push({
    //     name: 'section-list',
    //     params: { school_id: this.school_id, id },
    //   });
    // },

    checkStatic() {
      const role = this.user1.role;
      if (!static_roles.includes(role)) {
        this.isSchoolOfficial = true;
        const permissions =
          this.user1 && this.user1.permissions ? this.user1.permissions : [];
        let permission = permissions.find((p) => p.module.name === this.module);
        this.permission = permission;
      }
    },
  },

  created() {
    this.checkStatic();
    this.handleFetchPaymentTypes();
  },
};
</script>

<style scoped>
.item-grid {
  animation: slider 0.5s;
}

@keyframes slider {
  0% {
    transform: translateY(20px);
    opacity: 0%;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}
</style>
