<template>
  <div>
    <base-title title="Transport Groups">
      <template #actions>
        <div class="flex">
          <base-button
            icon="plus"
            label="Add New"
            class="px-3"
            @click="handleCreateNew"
          />
        </div>
      </template>
    </base-title>

    <div class="bg-white p-5 mt-2 rounded-default">
      <d-table
        #default="{ item }"
        :headers="headers"
        :items="items"
        :totalItems="items.length"
        :hasActions="true"
        :handlers="handlers"
        code="pg-view"
        :hideColumnSort="true"
      >
        <td v-for="(header, i) in headers" :key="i">
          <div class="px-2">
            <div v-if="header.key === 'name'">
              <p class="text font-semibold text-textDarkest uppercase">
                {{ item[header.key] }}
              </p>
            </div>
            <div
              v-else-if="header.key === 'payment_type'"
              class="flex space-x-3"
            >
              <p class="text font-semibold text-textDarkest uppercase">
                {{ item[header.key].name }}
              </p>
            </div>
            <div v-else-if="header.key === 'status'">
              <status-chip
                :label="item[header.key]"
                style="max-width: fit-content"
              />
            </div>
            <div v-else>
              {{ getProp(item, header.key) }}
            </div>
          </div>
        </td>
      </d-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DTable from '@/components/table/DTable.vue';
import { getPropByString as getProp } from '@/utils';
import eventBus from '@/eventBus.js';
export default {
  components: {
    DTable,
  },
  data() {
    return {
      handlers: [
        {
          label: 'Edit',
          icon: 'pencil-alt',
          handler: this.handleEdit,
          fullParam: true,
        },
        {
          label: 'Delete',
          icon: 'trash-alt',
          handler: this.handleDelete,
          idParam: true,
        },
      ],
      items: [],
      headers: [
        { label: 'Name', key: 'name' },
        { label: 'Payment Type', key: 'payment_type' },
        { label: 'Amount', key: 'amount' },
        { label: 'Status', key: 'status' },
      ],
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleCreateNew() {
      let componentProps = {
        school: this.school_id,
      };
      eventBus.$emit('open-modal', {
        modalId: 31,
        componentProps,
        cb: this.fetchDocumentsList,
      });
    },

    getProp,

    async fetchDocumentsList() {
      let response = await this.request({
        method: 'get',
        url: 'school/group',
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
    },

    async handleEdit(item) {
      let componentProps = {
        oldForm: item,
      };
      eventBus.$emit('open-modal', {
        modalId: 31,
        componentProps,
        cb: this.fetchDocumentsList,
      });
    },

    handleDelete(id) {
      let componentProps = {
        message: 'Are you sure you want to delete the payment group',
        title: 'This action is not reversible.',
      };
      eventBus.$emit('open-modal', {
        modalId: 1,
        componentProps,
        cb: this.proceedDelete,
        cbParams: { id },
      });
    },
    async proceedDelete({ id }) {
      let response = await this.request({
        method: 'delete',
        url: 'school/group/' + id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'deleted successfully' });
        this.fetchDocumentsList();
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
    },
  },

  async created() {
    await this.fetchDocumentsList();
  },
};
</script>

<style></style>
