<template>
  <modal-template
    @cancel="handleClose"
    @save="handleSave"
    :working="working"
    :saveLabel="isUpdate ? 'Update' : 'Save'"
  >
    <template #title>
      {{ isUpdate ? 'Update' : 'Create New' }} Discount
    </template>
    <template>
      <div>
        <base-input
          label="Name *"
          placeholder="name"
          v-model="Form.name"
          :errors="errors.name"
          check
        />
        <div class="grid grid-cols-3 gap-5 mt-4">
          <div>Payment Type</div>
          <div>Discount amount</div>
          <div>Is Rate</div>
        </div>
        <div
          class="grid grid-cols-3 gap-5 space-y-4 items-center"
          v-for="(payment_type, index) in payment_types"
          :key="index"
        >
          <div class="items-center">
            <p class="text font-semibold text-textDarkest uppercase">
              {{ payment_type.name }}
            </p>
          </div>
          <base-input
            type="number"
            placeholder="discount amount"
            v-model="discounts[payment_type._id].discount"
          />
          <switch-button v-model="discounts[payment_type._id].discount_type" />
        </div>

        <switch-button class="mt-4" label="Is Active" v-model="Form.status" />
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
import SwitchButton from '../../base/SwitchButton.vue';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';
import eventBus from '../../../eventBus';

export default {
  components: { ModalTemplate, SwitchButton },
  props: ['oldForm', 'school'],
  mixins: [validation],
  data() {
    return {
      Form: {
        name: '',
        status: 'active', // active, inactive,
        discounts: [],
      },
      discounts: {},
      payment_types: [],
      errors: {},
      ero: {},
      meta: [{ key: 'Form.name', label: 'Name' }],
      initial: false,
      working: false,
      exit: false,
    };
  },

  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    ...mapState('main', ['error']),
    isUpdate() {
      if (this.oldForm) return true;
      else return false;
    },
    disco() {
      return this.payment_types.map((v) => v._id);
    },
    PAYMENT_TYPE_OPTIONS() {
      return this.payment_types.map((payment_type) => ({
        label: payment_type.name,
        value: payment_type._id,
      }));
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      this.check();
      if (this.exit)
        return eventBus.$emit('open-toast', {
          message: 'discount is out of range (0-100)!',
          error: true,
        });
      if (this.isUpdate) await this.handleUpdate();
      else await this.handleCreate();
    },

    populateData() {
      // console.log('old form', this.oldForm, this.payment_types);

      this.payment_types.forEach((payment_type) => {
        this.discounts[payment_type._id] = {
          discount: 0,
          discount_type: false,
        };
      });
      if (this.isUpdate) {
        this.Form['name'] = this.oldForm['name'];
        this.Form['status'] =
          this.oldForm['status'] === 'active' ? true : false;

        Object.keys(this.discounts).map((payment_type_id) => {
          let new_discount = this.oldForm.discounts.find(
            (v) => v.payment_type == payment_type_id
          );
          this.discounts[payment_type_id] = {
            discount: new_discount ? new_discount.discount : 0,
            discount_type: new_discount
              ? new_discount.discount_type == 'rate'
                ? true
                : false
              : false,
          };
        });
      }

      this.discounts = { ...this.discounts };
      this.Form = { ...this.Form };
      // delete this.discounts.undefined;
    },
    check() {
      this.exit = false;
      Object.keys(this.discounts).map((acc) => {
        if (
          this.discounts[acc].discount_type &&
          (Number(this.discounts[acc].discount) < 0 ||
            this.discounts[acc].discount > 100)
        ) {
          console.log(
            'discount is out of range (0-100).',
            this.discounts[acc].discount
          );
          this.exit = true;
          return;
        }
        // else this.exit = false
      });
    },
    async handleCreate() {
      this.initial = false;
      if (!this.isValid()) return;
      // this.check()
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';
      newForm['discounts'] = Object.keys(this.discounts).map((payment_type) => {
        return {
          payment_type: payment_type,
          discount: this.discounts[payment_type].discount,
          discount_type: this.discounts[payment_type].discount_type
            ? 'rate'
            : 'flat',
        };
      });

      newForm = {
        name: newForm.name,
        discounts: newForm.discounts,
        status: newForm.status,
      };

      this.working = true;

      let response = await this.request({
        method: 'post',
        url: 'school/discount',
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'discount updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;

      this.initial = true;
    },

    async handleUpdate() {
      this.initial = false;
      if (!this.isValid()) return;
      // this.check()
      let newForm = { ...this.Form };
      if (this.Form['status']) newForm['status'] = 'active';
      else newForm['status'] = 'inactive';
      newForm['discounts'] = Object.keys(this.discounts).map((payment_type) => {
        return {
          payment_type: payment_type,
          discount: this.discounts[payment_type].discount,
          discount_type: this.discounts[payment_type].discount_type
            ? 'rate'
            : 'flat',
        };
      });

      newForm = {
        name: newForm.name,
        discounts: newForm.discounts,
        discount_type: newForm.discount_type,
        status: newForm.status,
      };

      this.working = true;

      let response = await this.request({
        method: 'put',
        url: 'school/discount/' + this.oldForm._id,
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'discount updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }

      this.working = false;
      this.initial = true;
    },

    async fetchPaymentTypesList() {
      let response = await this.request({
        method: 'get',
        url: 'school/paymentType/active',
      });
      if (response && !response.error) {
        this.payment_types = response.record;
      } else {
        this.payment_types = [];
      }
    },
  },
  async created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });

    await this.fetchPaymentTypesList();
    this.populateData();
  },
};
</script>

<style></style>
