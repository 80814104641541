<template>
  <div class=" px-2 py-5 pt-14 ">
    <div class=" mb-3 text-left">
      <p class=" text-textDarkest font-semibold">{{ parent.name }}</p>
    </div>
    <div v-if="working">
      <div class=" flex justify-center ">
        <icon name="spinner" class=" animate-spin text-textMedium" :size="30" />
      </div>
    </div>
    <div class=" space-y-2" v-else>
      <div
        v-for="(category, i) in categories"
        :key="i"
        class=" border  text-left px-2 py-1 overflow-hidden"
      >
        <div class=" flex justify-between items-center">
          <div
            class="cursor-pointer flex-grow"
            @click="selectCategory(category, true)"
          >
            {{ category.name }}
          </div>
          <div
            class=" cursor-pointer hover:text-secondary"
            @click="selectCategory(category, false)"
          >
            <icon name="plus" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      categories: [],
      parent: {},
      working: false
    };
  },

  methods: {
    ...mapActions('main', ['request']),

    handleClose(invokeCb, response) {
      this.$emit('close', { invokeCb, response });
    },

    selectCategory(category, close = false) {
      if (close) {
        return this.handleClose(true, { category: category._id });
      }

      this.parent = category;
      this.fetchCategories(category._id);
    },

    async fetchCategories(catId) {
      this.working = true;
      let url = 'reb/labCategory/';
      if (catId) url += catId;

      let response = await this.request({ method: 'get', url });
      if (response && !response.error) {
        this.categories = response.record;
      }
      this.working = false;
    }
  },

  created() {
    this.fetchCategories();
  }
};
</script>

<style></style>
