<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title>
      <div class="flex space-x-5 justify-center items-center">
        <div>
          {{ event.event.title }}
        </div>
        <div class="cursor-pointer hover:text-success">
          <icon name="eye" v-if="mode === 'update'" @click="handleToggleMode" />
        </div>
      </div>
    </template>
    <template>
      <div v-if="mode === 'detail'">
        <div class="flex space-x-5">
          <div
            class="h-7 w-7 rounded-default"
            :style="{ 'background-color': event.event.backgroundColor }"
          ></div>
          <p class="text-lg font-semibold">{{ event.event.title }}</p>
        </div>
        <div class="my-6 w-full flex space-x-4">
          <div class="w-1/2">
            <p class="text-sm">Start</p>
            <div class="flex space-x-3 items-center">
              <icon name="calendar" class="text-secondary" />
              <p class="text-sm mt-2">
                {{ moment(event.event.start.d.d).format('LLLL') }}
              </p>
            </div>
          </div>
          <div class="w-1/2">
            <p class="text-sm">End</p>
            <div class="flex space-x-3 items-center mt-2">
              <icon name="calendar" class="text-secondary" />
              <p class="text-sm">
                {{ moment(event.event.end.d.d).format('LLLL') }}
              </p>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div v-else-if="mode === 'update'">
        <div>
          <base-input
            label="Name"
            placeholder="event name"
            v-model="Form.name"
            :errors="errors.name"
            capitalize
            check
          />
          <!-- <datepicker class=" border-orange" /> -->
          <div class="flex space-x-5 my-2">
            <div class="w-1/2">
              <base-input
                type="datetime-local"
                v-model="Form.start"
                label="Start"
              />
            </div>
            <div class="w-1/2">
              <base-input
                type="datetime-local"
                v-model="Form.end"
                label="End"
              />
            </div>
          </div>
          <!-- <div class="flex w-full space-x-5">
            <div class="w-1/2">
              <base-select
                label="Category"
                placeholder="category"
                v-model="Form.eventType"
                :items="EVENT_TYPE_OPTIONS"
                :errors="errors.category"
              >
                <template #option="{ item }">
                  <div class="flex justify-between">
                    <div class="flex space-x-3 items-center">
                      <icon :name="item.icon" />
                      <p>{{ item.label }}</p>
                    </div>
                    <div>
                      <div
                        class="h-3 w-3"
                        :class="[]"
                        :style="{ 'background-color': item.color }"
                      ></div>
                    </div>
                  </div>
                </template>
              </base-select>
            </div>
          </div> -->
          <!-- colors selector -->
          <p class="text-textDarkest mt-8">Colors</p>
          <div class="w-full flex flex-wrap gap-y-4 gap-x-4 my-5">
            <div
              v-for="(color, i) in colors"
              :key="i"
              :class="{
                'border-4 w-full rounded-full border-secondary':
                  i == selectedColorIndex,
              }"
              style="max-width: fit-content"
              @click="selectedColorIndex = i"
            >
              <div class="h-12 w-12 rounded-full flex cursor-pointer shadow-lg">
                <div
                  class="w-1/2 rounded-l-full"
                  :style="{ 'background-color': color.primary }"
                ></div>
                <div
                  class="w-1/2 rounded-r-full"
                  :style="{ 'background-color': color.secondary }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-between pt-2 pb-1">
        <base-button
          label="cancel"
          :primary="false"
          class="py-1 px-4 text-textMedium bg-gray-100 hover:bg-danger hover:text-error rounded"
          @click="handleClose"
        />
        <div class="flex space-x-3 items-center">
          <div
            class="p-2 rounded-full bg-danger text-error cursor-pointer hover:opacity-75"
            @click="handleDelete"
            v-if="mode === 'detail' && authCodes.includes('cal-rm')"
          >
            <icon name="trash-alt" class="" />
          </div>
          <div
            class="p-2 rounded-full bg-secondary text-white cursor-pointer hover:opacity-75"
            @click="handleToggleMode"
            v-if="mode === 'detail' && authCodes.includes('cal-edit')"
          >
            <icon name="pencil-alt" class="" />
          </div>

          <base-button
            v-if="mode === 'update'"
            label="update"
            class="py-1 px-4 rounded"
            @click="handleSave"
            
          />
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { mapActions, mapGetters } from 'vuex';
import { mapState } from 'vuex';
import eventBus from '../../../eventBus';
import moment from 'moment';
import { eventColors, events } from '../../../config/constants';

export default {
  components: { ModalTemplate },
  props: ['event', 'teacherId'],
  data() {
    return {
      working: false,
      ctaList: [],
      mode: 'detail', /// detail, update
      Form: {
        name: '',
        primaryColor: '',
        secondaryColor: '',
        start: '',
        end: '',
      },
      selectedColorIndex: 0,
      errors: {},
      colors: eventColors,
    };
  },

  computed: {
    ...mapGetters(['authCodes']),
    ...mapState('main', ['error']),
    EVENT_TYPE_OPTIONS() {
      return events;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    moment,
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },

    async handleDelete() {
      let response = await this.request({
        method: 'delete',
        url: 'school/event/' + this.event.event.id,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', { message: 'deleted successfully' });
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }

      this.handleClose(true);
    },

    handleToggleMode() {
      if (this.mode === 'detail') this.mode = 'update';
      else if (this.mode === 'update') this.mode = 'detail';
    },

    async handleSave() {
      this.working = true;
      this.Form.primaryColor = this.colors[this.selectedColorIndex].primary;
      this.Form.secondaryColor = this.colors[this.selectedColorIndex].secondary;

      let response = await this.request({
        method: 'put',
        url: 'school/event/' + this.event.event.id,
        data: this.Form,
      });
      if (response && !response.error) {
        this.working = false;
        this.handleClose(true);
        return eventBus.$emit('open-toast', { message: 'saved successfully' });
      }
      this.working = false;
      eventBus.$emit('open-toast', {
        message: this.error,
        error: true,
      });

      this.handleClose(true);
    },

    populateData() {
      this.Form = {
        name: this.event.event.title,
        // eventType: this.event.event.category,
        start: moment(this.event.event.start.d.d).format('YYYY-MM-DDTHH:MM:SS'),
        end: moment(this.event.event.end.d.d).format('YYYY-MM-DDTHH:MM:SS'),
      };

      //

      this.selectedColorIndex = this.colors.findIndex(
        (item) =>
          item.primary === this.event.event.backgroundColor &&
          item.secondary === this.event.event.color
      );
    },
  },
  created() {
    this.populateData();
  },
};
</script>

<style scoped></style>
