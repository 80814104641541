<template>
  <div class=" bg-white border-gray-100 p-5">
    Announcements
  </div>
</template>

<script>
export default {};
</script>

<style></style>
