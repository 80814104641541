<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title> Change Academic Status </template>
    <template>
      <base-select
        :items="STATUS_OPTIONS"
        label="Select new Status"
        v-model="status"
      />
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '../../eventBus';
import { mapActions, mapState } from 'vuex';

export default {
  components: { ModalTemplate },
  props: ['students'],
  data() {
    return {
      // Form: {
      status: '',
      // },
      isWorking: false,
      STATUS_OPTIONS: [
        { label: 'Active', value: 'active' },
        // { label: 'Dropout', value: 'dropout' },
        // { label: 'Withdrawal', value: 'withdrawal' },
        { label: 'Completed', value: 'completed' },
        { label: 'Graduated', value: 'graduated' },
        { label: 'Dismissed', value: 'dismissed' },
      ],
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      this.isWorking = true;

      let base_url = 'student/studentProfile/';
      let rear = '';
      if (this.status == 'dropout') rear = 'set-dropout/';
      else if (this.status == 'active') rear = 'set-active/';
      else if (this.status == 'withdrawal') rear = 'set-withdrawn/';
      else if (this.status == 'completed') rear = 'set-completed/';
      else if (this.status == 'graduated') rear = 'set-graduated/';
      else if (this.status == 'dismissed') rear = 'set-dismissed/';

      let url = `${base_url}${rear}`;

      let response = await this.request({
        method: 'patch',
        // url: 'school/schoolProfile/update-status/' + this.id,
        url: url,
        data: { students: this.students },
      });
      if (response && !response.error) {
        this.handleClose(true);
        eventBus.$emit('open-toast', {
          message: 'status changed successfully',
        });
      } else {
        eventBus.$emit('open-toast', {
          message: this.berror,
          error: true,
        });
      }
      this.isWorking = false;
    },
  },
  computed: {
    ...mapState('main', ['berror']),
  },
  created() {
    this.Form.status = this.status;
  },
};
</script>

<style></style>
