<template>
  <div class="flex flex-col space-y-1">
    <base-title title="Generate Bill">
      <template #actions>
        <div class="flex">
          <base-button
            icon="check-circle"
            label="Order Bills"
            class="px-3"
            @click="handleOrderBills"
            :working="working || loading"
            code="ord-bill"
          />
        </div>
      </template>
    </base-title>
    <generate-bill-form class="" />
    <manual-bills-table
      class="flex-grow bg-white p-3"
      :class="{
        'pointer-events-none opacity-50 bg-gray-50': !payment_type,
        'bg-white': payment_type,
      }"
    />
  </div>
</template>

<script>
import GenerateBillForm from '@/components/forms/fee/GenerateBillForm.vue';
import ManualBillsTable from '../../../../components/forms/fee/ManualBillsTable.vue';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '../../../../eventBus';
import { required } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  name: 'GenerateBill',
  components: { GenerateBillForm, ManualBillsTable },
  mixins: [validation],
  data() {
    return {
      working: false,
      errors: {},
      meta: [{ key: 'payment_type', label: 'Payment Type' }],
      initial: true,
    };
  },

  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['payment_type'].includes(key)) {
            acc[key] = { required };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    ...mapGetters('fee', [
      'bills',
      'terms',
      'payment_type',
      'paymentTypeBillingId',
      'loading',
    ]),

    // selectedPaymentType() {
    //   if (this.payment_type) {
    //     return this.payment_type_options.find(
    //       ({ value }) => value == this.payment_type
    //     );
    //   } else {
    //     return '';
    //   }
    // },
  },
  methods: {
    ...mapActions('main', ['request']),
    async handleOrderBills() {
      this.working = true;
      this.initial = false;
      console.info({ paymentTypeBillingId: this.paymentTypeBillingId });
      // if (!this.isValid()) return;
      const response = await this.request({
        method: 'post',
        url: 'school/fee/order',
        data: {
          bills: this.terms,
          customers: this.bills,
          // payment_type: this.payment_type,
          payment_type: this.paymentTypeBillingId,
        },
      });
      if (response && !response.error) {
        this.working = false;
        console.info('generate bill response', response);
        eventBus.$emit('open-toast', {
          message: response.message,
        });
      } else {
        this.working = false;
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.initial = true;
    },
  },
  created() {
    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
  },
};
</script>

<style></style>
