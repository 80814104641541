<template>
  <div class="w-full">
    <label style="font-size: 16px" class="text-textDarkest" v-if="label"
      >{{ label }} <span v-if="required" class="text-error"> *</span></label
    >
    <div class="text-textDark relative">
      <div
        :style="'font-size: ' + 16 + 'px;'"
        class="flex justify-between items-center border-1 border-dgray cursor-pointer leading-tight bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
        :class="{
          'bg-white': success && !notBordered,
          'bg-danger': !success && !notBordered,
          'border-textLight': success && notBordered,
          'border-error': !success && notBordered,
          'rounded-default': !notRounded,
          'py-2 px-3': padding === 'big',
          'py-1 px-2': padding === 'small',
          [customClasses]: padding === 'custom',
        }"
        @click="handleOpen"
      >
        <div v-if="!value.length">Select</div>
        <div v-else>
          <slot :items="itemsSelected">
            <div class="inline-flex flex-wrap">
              <div
                v-for="(si, i) in itemsSelected"
                class="flex space-x-3 pr-2"
                :key="i"
              >
                <p v-if="si">
                  {{ si['label'] }}
                </p>
                <p v-if="si">|</p>
              </div>
            </div>
          </slot>
        </div>
        <icon v-if="selectorOpened" name="caret-up" />
        <icon v-else name="caret-down" />
      </div>

      <div
        v-if="selectorOpened"
        v-outsideClick="{ handler: handleBlur }"
        class="selector bg-white absolute z-1000 w-full shadow-2xl rounded-lg p-1"
      >
        <input
          v-if="searchable"
          type="text"
          class="outline-none w-full p-2 bg-gray-50 rounded-lg"
          placeholder="Search..."
          autofocus
          v-model="search"
          @click.stop
        />
        <div v-if="itemsFiltered.length === 0" class="p-2">No data</div>
        <div v-else class="overflow-y-auto py-2" style="max-height: 40vh">
          <div
            class="hover:bg-gray-100 cursor-pointer rounded-lg"
            :class="{
              'p-2': padding === 'big',
              'p-1': padding === 'small',
            }"
            v-for="item in itemsFiltered"
            :key="item.value"
            @click="handleSelect(item.value)"
          >
            <slot name="option" :item="item">
              <div class="flex space-x-4 items-center">
                <div>
                  <icon
                    name="check-square"
                    v-if="value.includes(item.value)"
                    class="text-primary"
                    :size="19"
                  />
                  <icon
                    v-else
                    name="square"
                    class="text-textLight"
                    :size="19"
                  />
                </div>
                <div>{{ item[searchKey] }}</div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!success" class="text-xs" style="color: #ec3118">
      <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Array, default: () => [] },
    label: { type: String },
    size: { type: Number, default: 16 },
    errors: { type: Array, default: () => [] },
    padding: { type: String, default: ['big', 'small', 'custom'][0] },
    items: { type: Array, default: () => [] },
    notBordered: { type: Boolean, default: false },
    notRounded: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    searchKey: { type: String, default: 'label' },
    customClasses: { type: String, default: '' },
  },
  computed: {
    success() {
      return this.errors.length === 0;
    },
    itemsFiltered() {
      return this.items.filter((item) =>
        RegExp('.*' + this.search + '.*', 'i').test(item[this.searchKey])
      );
    },
    itemSelected() {
      return this.items.find((item) => item.value === this.value);
    },
    itemsSelected() {
      if (this.items)
        return this.items.map((item) => {
          if (this.value.includes(item.value)) return item;
        });
      else return [];
    },
  },
  data() {
    return {
      selectorOpened: false,
      search: '',
    };
  },
  methods: {
    handleFocus() {
      this.selectorOpened = true;
    },
    handleBlur() {
      this.selectorOpened = false;
      this.search = '';
    },
    handleOpen() {
      if (!this.selectorOpened) this.handleFocus();
      else this.handleBlur();
    },
    handleSelect(value) {
      let new_items = [...this.value];
      if (new_items.includes(value))
        new_items.splice(new_items.indexOf(value), 1);
      else new_items.push(value);
      this.handleChange(new_items);
    },
    handleChange(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style>
.selector {
  animation: slideUp 0.2s;
}
@keyframes slideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
.z-1000 {
  z-index: 3000;
}
</style>
