<template>
  <div class="space-y-24">
    <loader v-if="loading"></loader>
    <div class="" v-else-if="userDetail.length">
      <div v-for="(user, i) in userDetail" :key="i">
        <div
          class="p-5 bg-white grid grid-cols-5 items-center shadow-sm border-1 border-gray-100 rounded-lg"
        >
          <div
            class="w-32 h-32 bg-gray-100 overflow-hidden rounded-full flex justify-center items-center"
          >
            <img
              v-if="user.parent.profilePic"
              :src="PROFILE_PATH + user.parent.profilePic"
              v-viewer="{ inline: false, movable: true }"
              class="w-full cursor-pointer"
              alt=""
              crossorigin="anonymous"
            />
            <icon v-else :size="44" name="user" class="text-textDark"></icon>
          </div>
          <div class="col-span-4">
            <h3 class="font-bold text-xl capitalize">
              {{ user.parent.firstName }} {{ user.parent.middleName }}
              {{ user.parent.lastName }}
            </h3>

            <span class="text-darkGray"
              >Relation -
              <span class="capitalize">{{ user.relation }}</span></span
            >

            <div
              class="text-white px-2 mt-2 rounded-sm"
              style="max-width: fit-content"
              :class="{
                'bg-verified': user.parent.status == 'verified',
                'bg-pending': user.parent.status == 'pending',
                'bg-suspended': user.parent.status == 'suspended',
              }"
            >
              {{ user.parent.status }}
            </div>
          </div>
        </div>
        <br />

        <div class="bg-white p-3 shadow-sm border-1 border-gray-100 rounded-lg">
          <span class="font-bold">GUARDIAN INFORMATION</span>
          <div class="p-5 grid grid-cols-3 gap-8">
            <div class="">
              <span class="text-darkGray text-sm">Name</span><br />
              <span class="capitalize">{{ user.parent.firstName }}</span>
            </div>
            <div class="">
              <span class="text-darkGray text-sm">Father's Name</span><br />
              <span class="capitalize">{{ user.parent.middleName }}</span>
            </div>
            <div class="">
              <span class="text-darkGray text-sm">Grandfather's Name</span
              ><br />
              <span class="capitalize">{{ user.parent.lastName }}</span>
            </div>
            <div class="" v-if="user.parent.date">
              <span class="text-darkGray text-sm">DOB</span><br />
              <span class=""
                >{{ user.parent.date }}/{{ user.parent.month }}/{{
                  user.parent.year
                }}</span
              >
            </div>
            <div class="">
              <span class="text-darkGray text-sm">Gender</span><br />
              <span class="capitalize">{{ user.parent.gender }}</span>
            </div>
            <div class="" v-if="user.parent.class">
              <span class="text-darkGray text-sm">Year Level</span><br />
              <span class="">{{ user.parent.class.name }}</span>
            </div>
          </div>
        </div>
        <br />

        <div class="bg-white p-3 shadow-sm border-1 border-gray-100 rounded-lg">
          <span class="font-bold">CONTACT & ADDRESS</span>
          <div class="p-5 grid grid-cols-3 gap-8">
            <div class="">
              <span class="text-darkGray text-sm">Phone Number</span><br />
              <span class="">
                {{ user.parent['phoneNumber'].join(' | ') }}</span
              >
            </div>
            <div class="" v-if="user.parent.email">
              <span class="text-darkGray text-sm">Email</span><br />
              <span class="">{{ user.parent.email }}</span>
            </div>
            <div class="" v-if="user.parent.address">
              <span class="text-darkGray text-sm">Street Address</span><br />
              <span class="">{{ user.parent.address }}</span>
            </div>
            <div class="" v-if="user.parent.city">
              <span class="text-darkGray text-sm">City</span><br />
              <span class="">{{ user.parent.city }}</span>
            </div>
            <div class="" v-if="user.parent.region">
              <span class="text-darkGray text-sm">Region</span><br />
              <span class="">{{ user.parent.region }}</span>
            </div>
          </div>
        </div>
        <hr class="m-8 text-dgray" />
      </div>
    </div>
    <base-no-data v-else message="no guardian added"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PROFILE_PATH } from '@/config/config.js';

export default {
  props: {
    userId: { type: String },
  },

  data() {
    return {
      userDetail: '',
      PROFILE_PATH,
      routeUrl: '',
      loading: false,
    };
  },

  methods: {
    ...mapActions('main', ['request']),
    offdayDetail() {
      this.$router.push({ name: 'teacher-offday' });
    },

    async fetchUser() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'student/studentProfile/' + this.userId,
      });
      if (response && !response.error) {
        this.userDetail = response.record.parents;
      } else {
        this.userDetail = '';
      }
      this.loading = false;
    },
  },

  created() {
    this.fetchUser();
  },
};
</script>
