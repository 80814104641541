<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title>set working days</template>
    <template>
      <div class="grid grid-cols-2 gap-1">
        <div v-for="(day, i) in WORKING_DAYS" :key="i">
          <div
            class="flex space-x-2 items-center border p-2 cursor-pointer hover:bg-brightGray text-textDark hover:text-primary"
            :class="
              Form.working_days[day.value]
                ? 'border-primary'
                : 'border-transparent'
            "
            @click="toggleHeader(day.value)"
          >
            <div
              style="padding: 0.375rem"
              :class="
                Form.working_days[day.value] ? 'bg-primary' : 'border-2 border-primary'
              "
              class="rounded-sm"
            >
              <icon
                name="check"
                fill="#fff"
                :size="Form.working_days[day.value] ? 14 : 10"
              />
            </div>

            <div class="capitalize">{{ day.label }}</div>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <div>{{ AVAILABLE_DAYS }} days selected</div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import { mapActions } from 'vuex';
import eventBus from '@/eventBus';

export default {
  components: {
    ModalTemplate,
  },
  data() {
    return {
      initial: true,
      Form: {
        working_days: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: false,
          sunday: false,
        },
      },
      errors: {},
    };
  },
  computed: {
    WORKING_DAYS() {
      return [
        { label: 'Monday', value: 'monday' },
        { label: 'Tuesday', value: 'tuesday' },
        { label: 'Wednesday', value: 'wednesday' },
        { label: 'Thursday', value: 'thursday' },
        { label: 'Friday', value: 'friday' },
        { label: 'Saturday', value: 'saturday' },
        { label: 'Sunday', value: 'sunday' },
      ];
    },
    AVAILABLE_DAYS() {
      return Object.keys(this.Form.working_days).filter(
        (day) => this.Form.working_days[day]
      ).length;
    },
  },
  methods: {
    ...mapActions('main', ['request']),

    toggleHeader(day) {
      this.Form.working_days[day] = !this.Form.working_days[day];
    },
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },

    async handleSave() {
      // make the format like this

      let response = await this.request({
        method: 'put',
        url: 'school/config/working-days',
        data: this.Form,
      });

      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          // message: response.message,
          message: 'Working Days Successfully Set',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: response.message,
          error: true,
        });
      }
    },

    async fetchWorkingDays() {
      let response = await this.request({
        method: 'get',
        url: 'school/config/',
      });

      if (response && !response.error) {
        if (response.record.working_days)
          this.Form.working_days = response.record.working_days;
      } else {
        eventBus.$emit('open-toast', {
          message: response.message,
          error: true,
        });
      }
    },
  },
  created() {
    this.fetchWorkingDays();
  },
};
</script>

<style></style>
