<template>
  <div
    class="px-3 py-2 my-1 rounded"
    style="max-width: 60%;"
    :class="{ 'ml-auto': isMine, 'mr-auto': !isMine }"
  >
    <div class="bg-white rounded-default">
      <p
        class="px-6 py-3"
        :class="{ 'text-white': isMine, 'text-textDarkest': !isMine }"
      >
        {{ message.message }}
      </p>
    </div>
    <div class="justify-end flex">
      <p
        class="text-xs "
        :class="{ 'text-white': isMine, 'text-textLight': !isMine }"
      >
        {{ message.time }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ['index', 'message'],
  computed: {
    ...mapGetters(['user']),
    isMine() {
      if (this.user.id === this.message.sender._id) return true;
      return false;
    }
  }
};
</script>

<style></style>
