<template>
  <modal-template @cancel="handleClose" @save="handleSave">
    <template #title> Create New Course </template>
    <template> </template>
    <template #footer>
      <div class="flex justify-between">
        <base-button
          label="cancel"
          :primary="false"
          class=" bg-gray-100 text-error"
        />
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
// import eventBus from '@/eventBus';
import { mapActions } from 'vuex';

export default {
  components: { ModalTemplate },
  props: [],
  data() {
    return {
      Form: {
        name: '',
        course_code: ''
      }
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },
    async handleSave() {
      this.handleClose(true);
    }
  },
  created() {}
};
</script>

<style></style>
