import Vue from 'vue';
// import Vue from 'vue/dist/vue.js'
import App from './App.vue';
import router from './router';
import store from './store';
import Vuelidate from 'vuelidate';
import loadSprites from './plugins/loadSvg';
import tippy from './plugins/tippy';
import VuePapaParse from 'vue-papa-parse';

import apiService from './services/apiService';
import { tokenService } from './services/storageService';

import { i18n } from './plugins/i18n.js';
import VueEllipseProgress from 'vue-ellipse-progress';
Vue.use(VueEllipseProgress);

Vue.use(VuePapaParse);

import { bindJoiErrors } from './mixins/joiError';
// import { installApex } from './plugins/apex';
// installApex();

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode);

import { Calendar } from 'v-calendar';
Vue.use(Calendar);
Vue.component('v-calendar', Calendar);

import { directives } from './directives/index';
directives.forEach((d) => Vue.directive(d.name, d.hooksContainer));

import '@/styles/tailwind.css';
import '@/styles/scrollbar.css';

import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
Vue.use(Viewer);

Vue.config.productionTip = false;
apiService.init(apiService.getBaseUrl());
apiService.addInterceptor();

if (tokenService.getToken()) {
  apiService.setHeader();
  apiService.mount401Interceptor();
}

import registerGlobally from './components/globals';
registerGlobally();

loadSprites();
tippy();

Vue.use(Vuelidate);

new Vue({
  router,
  store,
  i18n,
  mixins: [bindJoiErrors],
  render: (h) => h(App),
}).$mount('#app');
