<template>
  <div class=" flex-grow  overflow-auto">
    <div
      v-for="(item, i) in items"
      :key="i"
      class=" py-3 px-5 flex space-x-3 items-center cursor-pointer hover:bg-gray-50"
      :class="{ 'bg-gray-50': activeSection === item.section._id }"
      @click="handleClick(item.section)"
    >
      <div>
        <icon name="ellipsis-h" class=" text-secondary " />
      </div>
      <div>
        <p class=" text-sm">
          Grade {{ item.class.name }} - {{ item.section.name }}
        </p>
        <p class=" text-sm text-textLight">{{ item.noStudents }} students</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: ['activeSection'],
  data() {
    return {
      items: []
    };
  },
  methods: {
    ...mapActions('main', ['request']),
    async fetchItems() {
      let response = await this.request({
        method: 'get',
        url: 'school/teacherCourse/my-sections'
      });
      if (response && !response.error) {
        this.items = response.record;
      } else {
        this.items = [];
      }
    },

    handleClick(section) {
      this.$emit('sectionSelected', section._id);
    }
  },
  created() {
    // this.fetchItems();
  }
};
</script>

<style></style>
