<template>
  <modal-template @cancel="handleClose" :working="working" @save="handleSave">
    <template #title>
      {{ 'Create Assessment' }}
    </template>
    <template>
      <div class=" ">
        <div class="grid grid-cols-2 gap-5">
          <base-input
            class="col-span-2"
            label="Name"
            placeholder="name"
            v-model="Form.name"
            :errors="errors.name"
            check
            required
          />
          <base-input
            class="col-span-2"
            label="Description"
            placeholder="description"
            v-model="Form.description"
            :errors="errors.description"
          />
          <base-input
            type="number"
            class="col-span-2"
            label="Maximum Mark"
            placeholder="maximum mark"
            v-model="Form.maxResult"
            :errors="errors.maxResult"
            check
            required
          />

          <base-multi-select
            class="col-span-2"
            label="Active Grades"
            placeholder=""
            v-model="Form.class"
            :items="GRADE_OPTIONS"
            :errors="errors.class"
            :searchable="true"
            @input="handleToggleGrades"
          />
          <div class="col-span-2 flex">
            <base-check-box
              :selected="allGrades"
              @toggle="toggleAllGrades"
              :size="20"
            />
            <div>All Grades</div>
          </div>

          <!-- <div class="col-span-2 flex">
            <switch-button label="Is Active" v-model="Form.status" />
          </div> -->

          <!-- <div
            v-if="Form.close_previous"
            class="col-span-2 flex cursor-pointer rounded-lg text-primary bg-gray-100"
          >
            N.B - All Academic Years of the selected class and sections will be
            closed, and new one will be created and be active! Histories of
            previous datas will be still be available!
          </div> -->
        </div>
      </div>
    </template>
  </modal-template>
</template>

<script>
import ModalTemplate from '@/components/modals/ModalTemplate.vue';
import eventBus from '@/eventBus';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';
// import SwitchButton from '../base/SwitchButton.vue';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { validation } from '@/mixins/validationMixin.js';

export default {
  components: { ModalTemplate },
  props: ['oldForm'],
  mixins: [validation],
  data() {
    return {
      Form: {
        name: '',
        description: '',
        maxResult: 0,
        class: [],
        status: 'active', // active, closed, not_started
      },

      gradeItems: [],
      errors: {},
      meta: [
        { key: 'Form.name', label: 'Name' },
        { key: 'Form.description', label: 'Description' },
        { key: 'Form.maxResult', label: 'Maximum Result' },
        { key: 'Form.class', label: 'Grades' },
      ],
      working: false,
      initial: true,
    };
  },
  validations() {
    return {
      Form: {
        ...Object.keys(this.Form).reduce((acc, key) => {
          if (['name'].includes(key)) {
            acc[key] = { required };
          } else if (key == 'maxResult') {
            acc[key] = {
              required,
              minValue: minValue(1),
              maxValue: maxValue(100),
            };
          } else acc[key] = {};
          return acc;
        }, {}),
      },
    };
  },
  computed: {
    ...mapState('main', ['error']),

    GRADE_OPTIONS() {
      return this.gradeItems.map((grade) => ({
        label: grade.name,
        value: grade._id,
      }));
    },

    allGrades() {
      return this.GRADE_OPTIONS.length == this.Form.class.length;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleClose(invokeCb) {
      this.$emit('close', { invokeCb });
    },

    handleToggleGrades(n) {
      this.allGrades = this.class.length === n.length;
    },
    toggleAllGrades() {
      if (this.allGrades) {
        this.Form.class = [];
      } else {
        this.Form.class = this.GRADE_OPTIONS.map(({ value }) => value);
      }
    },
    async handleSave() {
      this.initial = false;
      if (!this.isValid()) return;
      else this.handleCreate();
      this.initial = true;
    },

    async handleCreate() {
      this.working = true;
      let newForm = { ...this.Form };
      // if (this.Form['status']) newForm['status'] = 'active';
      // else newForm['status'] = 'inactive';

      let response = await this.request({
        method: 'post',
        url: 'school/assessment',
        data: newForm,
      });
      if (response && !response.error) {
        eventBus.$emit('open-toast', {
          message: 'Assessment updated successfully',
        });
        this.handleClose(true);
      } else {
        eventBus.$emit('open-toast', {
          message: this.error,
          error: true,
        });
      }
      this.working = false;
    },

    async handleFetchGrades() {
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'school/class/active',
      });
      if (response && !response.error) {
        this.gradeItems = response.record;
      } else {
        this.gradeItems = [];
      }
      this.loading = false;
    },
  },
  async created() {
    await this.handleFetchGrades();

    this.meta.forEach((key) => {
      this.$watch(key.key, () => {
        if (!this.initial) {
          this.isValid();
        }
      });
    });
  },
};
</script>

<style></style>
