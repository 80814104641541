import LibraryOverview from '@/views/protected/library_management/Overview.vue';
import CategoriesList from '@/views/protected/library_management/CategoriesList.vue';
import BooksList from '@/views/protected/library_management/BooksList.vue';
export default [{
        path: 'overview',
        name: 'library-overview',
        component: LibraryOverview
    },
    {
        path: 'categories-list',
        name: 'library-categories-list',
        component: CategoriesList
    },
    {
        path: 'books-list',
        name: 'library-books-list',
        component: BooksList
    },

]