<template>
  <div class="bg-gray-100 h-screen w-screen flex flex-col" ref="container">
    <Header ref="header" />
    <div class="flex" :style="{ height: mainHeight + 'px' }">
      <l-nav class="flex-shrink-0" />
      <div class="h-full overflow-y-auto flex-auto p-2">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/layout/protected/Header.vue';
import LNav from '@/components/layout/protected/LNav.vue';
export default {
  components: { Header, LNav },
  data() {
    return {
      mainHeight: 0,
    };
  },
  mounted() {
    let container = this.$refs['container'];
    let header = this.$refs['header'];
    this.mainHeight = container.clientHeight - header.$el.clientHeight;
  },
};
</script>

<style></style>
