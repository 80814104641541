<template>
  <div class="w-full h-full flex absolute top-0 bottom-0 z-50 bg-gray-100">
    <div class="w-3/12 bg-white z-10 flex flex-col">
      <div class="py-3 px-5 cursor-pointer" @click="handleBack">
        <p class="text-primary font-semibold">HENON</p>
      </div>
      <div class="py-3 px-5">
        <base-input label="" placeholder="search someone" v-model="searchKey" />
      </div>
      <chat-sidebar
        @sectionSelected="onSectionSelected($event)"
        :activeSection="activeSection"
      />
      <my-contacts
        :activeSection="activeSection"
        @contactSelected="onContactSelected($event)"
        :selectedContactId="selectedContactId"
      />
    </div>
    <div class="w-9/12 flex flex-col">
      <div
        class="flex-grow h-full overflow-auto px-5 py-5 flex flex-col"
        v-if="!loading && messages.length"
        ref="message_container"
      >
        <message-entry
          v-for="(message, i) in messages"
          :key="i"
          :index="i"
          :message="message"
        />
      </div>
      <loader v-else-if="loading"></loader>
      <div
        v-else-if="!loading && !messages.length"
        class="flex-grow h-full flex items-center justify-center"
      >
        <div class="py-1 px-5">
          <p class="text-darkGray">
            {{
              selectedContactId
                ? 'there are no messages'
                : 'please select contact to start chatting'
            }}
          </p>
        </div>
      </div>
      <message-composer
        @messageSent="appendMessage($event)"
        :receiver="selectedContactId"
      />
    </div>
  </div>
</template>

<script>
import MessageComposer from './components/MessageComposer.vue';
import MessageEntry from './components/MessageEntry.vue';
import MyContacts from './components/MyContacts.vue';
import ChatSidebar from './components/ChatSidebar.vue';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/eventBus.js';

export default {
  components: {
    MessageComposer,
    MessageEntry,
    MyContacts,
    ChatSidebar,
  },
  data() {
    return {
      initial: true,
      searchKey: '',
      activeSection: '',
      messages: [],
      loading: false,
      selectedContactId: '',
    };
  },
  computed: {
    ...mapGetters('socket', ['sioClient']),
  },
  watch: {
    sioClient() {
      this.socketListen();
    },

    message() {
      this.scrolltoBottom();
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    handleBack() {
      this.$router.back();
    },

    onSectionSelected(response) {
      this.activeSection = response;
    },

    onParentSelected(id) {
      this.fetchChatMessage(id);
    },

    onStudentSelected(id) {
      this.fetchChatMessage(id);
    },

    onContactSelected(id) {
      this.selectedContactId = id;
      this.fetchChatMessage(id);
    },

    async fetchChatMessage(id) {
      this.initial = false;
      this.loading = true;
      let response = await this.request({
        method: 'get',
        url: 'message/all/' + id,
      });
      if (response && !response.error) {
        this.messages = response.record;
      } else {
        this.messages = [];
      }
      this.loading = false;
    },

    appendMessage(message) {
      this.messages.push(message);
      this.scrolltoBottom();
    },

    onNewMessage(data) {
      let message = data.record[0];
      if (
        message.sender._id != this.selectedContactId &&
        message.receiver._id != this.selectedContactId
      )
        return;

      eventBus.$emit('open-toast', { message: 'New Message' });
      this.appendMessage(message);
      //
    },

    socketListen() {
      this.sioClient.on('NEW_MESSAGE', this.onNewMessage);
    },

    scrolltoBottom() {
      if (
        this.$refs.message_container &&
        this.$refs.message_container.lastElementChild
      ) {
        this.$refs.message_container.lastElementChild.scrollIntoView(true);
      }
    },
  },

  created() {
    if (this.sioClient) this.socketListen();
  },
};
</script>

<style></style>
