<template>
  <div class="flex flex-col h-full p-3">
    <base-title :title="'Student Roaster'" class="font-bold uppercase">
      <template #actions>
        <div class="flex space-x-3 items-start">
          <button
            v-print="printable"
            class="p-2 flex items-center justify-center mt-2 rounded-default bg-primary shadow-lg cursor-pointer hover:opacity-75"
          >
            <icon name="print" :size="14" class="text-white mr-2" />
            <span class="text-white">Print</span>
          </button>
        </div>
      </template>
    </base-title>
    <spacer class="mb-2" />
    <!-- <pre>{{ student }}</pre> -->
    <loader v-if="loading"></loader>

    <div v-else id="printable" class="bg-white p-3 mt-0 flex-grow">
      <div
        class="flex items-center justify-between"
        style="font-family: Algerian; font-size: 26px"
      >
        <div class="flex justify-center">
          <img :src="LOGO_PATH + school.logo" class="h-16" />
        </div>
        <div class="flex flex-col items-center justify-center">
          <div class="font-bold">{{ school.name }}</div>
          <div class="font-bold">STUDENT ROASTER</div>
        </div>
        <div class="flex justify-center">
          <img :src="LOGO_PATH + school.logo" class="h-16" />
        </div>
      </div>
      <div class="bg-white p-5 mt-2 rounded-default">
        <d-table
          class="text-sm"
          #default="{ item, index }"
          :headers="HEADERS"
          :items="data"
          :hasActions="false"
          :loading="loading"
          :pageSize="70"
          :multiSelect="false"
        >
          <td v-for="(header, i) in HEADERS" :key="i" class="border-1">
            <div class="py-1">
              <div v-if="header.key === 'no'" class="text-center">
                {{ index + 1 }}
              </div>
              <div v-else-if="header.key === 'name'" class="px-2">
                <p class="text font-semibold text-textDarkest uppercase">
                  <!-- {{item[header.key]}} -->
                  {{ item.student.firstName }} {{ item.student.middleName }}
                  {{ item.student.lastName }}
                </p>
              </div>
              <div v-else-if="header.key === 'userId'" class="text-center">
                <p class="text font-semibold text-textDarkest uppercase">
                  {{ item.student.userId }}
                </p>
              </div>
              <div
                v-else-if="header.key === 'total' && item.total"
                class="text-center font-semibold uppercase text-textDarkest"
              >
                {{ item.total }}
              </div>
              <div
                v-else-if="header.key === 'average' && item.average"
                class="text-center font-semibold uppercase text-textDarkest"
              >
                {{ item.average }}
              </div>
              <div
                v-else-if="header.key === 'rank' && item.rank"
                class="text-center font-semibold uppercase text-textDarkest"
              >
                {{ item.rank }}
              </div>
              <div
                v-else-if="header.dynamic && header.key.includes('total')"
                class="text-center"
              >
                <p class="text font-semibold uppercase text-textDarkest">
                  {{
                    getCourseName(header.key, item.courses) &&
                    getCourseName(header.key, item.courses).studentCourse
                      ? getCourseName(header.key, item.courses).studentCourse
                          .total
                      : ''
                  }}
                </p>
              </div>
              <div
                v-else-if="header.dynamic && header.key.includes('grade')"
                class="text-center"
              >
                <p class="text font-semibold uppercase text-textDarkest">
                  {{
                    getCourseName(header.key, item.courses) &&
                    getCourseName(header.key, item.courses).studentCourse
                      ? getCourseName(header.key, item.courses).studentCourse
                          .grade
                      : ''
                  }}
                </p>
              </div>
              <div
                v-else-if="header.dynamic && header.key.includes('rank')"
                class="text-center"
              >
                <p class="text font-semibold uppercase text-textDarkest">
                  {{
                    getCourseName(header.key, item.courses) &&
                    getCourseName(header.key, item.courses).studentCourse
                      ? getCourseName(header.key, item.courses).studentCourse
                          .rank
                      : ''
                  }}
                </p>
              </div>
              <div
                v-else-if="header.key === 'status'"
                class="text-center capitalize"
              >
                {{ item.status }}
                <!-- <status-chip
                    :label="item[header.key]"
                    style="max-width: fit-content"
                  /> -->
              </div>
              <div v-else>
                <div class="px-4"></div>
              </div>
            </div>
          </td>
        </d-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DTable from '@/components/table/SelectableTable1.vue';
import { LOGO_PATH } from '../../config/config';

export default {
  props: ['data', 'courses'],
  components: { DTable },
  data() {
    return {
      // courses: [],
      loading: false,
      LOGO_PATH,
      school: {},
      reversed_data: [],
      printObj: {
        standard: 'landscape',
        id: 'printable',
        preview: true,
        previewTitle: 'print Title', // The title of the preview window. The default is 打印预览
        popTitle: 'good print',
      },
    };
  },
  computed: {
    ...mapGetters(['user']),
    HEADERS() {
      let courses = this.courses.map((c) => {
        return [
          {
            label: c.name,
            key: c.code + '/s',
            // key: c._id,
            dynamic: true,
          },
          {
            label: 'Total',
            key: c.code + '/total',
            // key: c._id,
            dynamic: true,
          },
          {
            label: 'Grade',
            key: c.code + '/grade',
            // key: c._id,
            dynamic: true,
          },
          {
            label: 'Rank',
            key: c.code + '/rank',
            // key: c._id,
            dynamic: true,
          },
        ];
      });
      // console.log('courses', courses);
      courses = courses.flat();
      let dynamic_headers = [
        { label: 'No', key: 'no' },
        { label: 'Name', key: 'name' },
        { label: 'Student Id', key: 'schoolId' },
        ...courses,
      ];
      dynamic_headers.push(
        { label: 'Total', key: 'total' },
        { label: 'Average', key: 'average' },
        { label: 'Rank', key: 'rank' },
        { label: 'Academic Status', key: 'status' }
      );
      // console.log('header', dynamic_headers);
      return dynamic_headers;
    },
  },
  methods: {
    ...mapActions('main', ['request']),
    getCourseName(key, PT) {
      if (!PT) return '';
      return key &&
        PT.find((v) => {
          // console.log(v.course.name, v.course == key);
          return v.course.code == key.split('/')[0];
        })
        ? PT.find((v) => {
            return v.course.code == key.split('/')[0];
          })
        : '';
    },
    async getSchool() {
      let response = await this.request({
        method: 'get',
        url: 'school/schoolProfile/my-school',
      });
      this.school = response && response.record ? response.record : {};
    },
  },

  async created() {
    this.loading = true;
    await this.getSchool();
    this.loading = false;
  },
};
</script>
<style>
.item-grid {
  animation: slider 0.5s;
}

@keyframes slider {
  0% {
    transform: translateY(20px);
    opacity: 0%;
  }

  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}
</style>
